// Guides Landing Page

// Intro
$p-guides-intro-height: 60vh;
$p-guides-intro-height-desk: rem(635px);
$p-guides-intro-img-right: -10.5rem;
$p-guides-intro-img-width: rem(981px);
$p-guides-intro-img-height: rem(902px);
$p-guides-intro-img-bottom-mobile: -6.8rem;

// Category
$p-guides-category-img-height-m: rem(120px);

.p-guides {
  overflow-x: hidden;

  // Intro
  &__intro {
    background: $theme-gradient-warm;

    &__hero {
      height: $p-guides-intro-height;
      position: relative;

      @include desktop {
        height: $p-guides-intro-height-desk;
      }
    }

    &__inner {
      width: 100%;
      position: relative;
      z-index: $gl-z-index-1;

      @include mobile {
        display: block;
        text-align: center;
        padding-top: $gl-spacer-3xl;
      }
    }

    &__img {
      position: absolute;
      left: 0;
      right: 0;
      margin: auto;
      width: 100%;
      bottom: $p-guides-intro-img-bottom-mobile;

      @include desktop {
        right: $p-guides-intro-img-right;
        left: auto;
        bottom: auto;
        top: 0;
        width: $p-guides-intro-img-width;
        height: $p-guides-intro-img-height;
      }
    }
  }

  &__subheader {
    letter-spacing: 8px;
    opacity: 0.4;

    @include desktop {
      font-size: $gl-font-size-base;
    }
  }

  &__caption {
    line-height: 2;
  }

  // Summary
  &__summary {
    position: relative;
  }

  // Info
  &__category{
    position: relative;

    &__title {
      position: absolute;
      left: rem($gl-spacer-m);
      bottom: rem($gl-spacer-m);
      color: $t-light;
      z-index: $gl-z-index-1;
      line-height: $gl-line-height-sm;
      margin-bottom: 0;
    }

    &__img-holder {

      @include mobile{
        height: $p-guides-category-img-height-m;
        overflow: hidden;
      }
    }

    &__img {
      width: 100%;

    }
    &__img1{
    display: inline-block;
  }
  }

  // Samples
  &__samples {
    overflow: hidden;
  }
}
