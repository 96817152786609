// Course Landing Page

// Intro
$p-course-intro-height: 64vh;
$p-course-intro-height-desk: rem(635px);
$p-course-intro-img-right: -15rem;
$p-course-intro-img-bottom: -1.3rem;
$p-course-intro-img-width: rem(1069px);
$p-course-intro-img-height: rem(733px);
$p-course-caption-width: rem(210px);
$p-course-caption-width-desk: rem(310px);
$p-course-caption-font-size-desk: $gl-font-size-base;

// Info
$p-course-info-expand-right: rem($gl-spacer-s);
$p-course-info-expand-bottom: 0.7rem;
$p-course-info-expand-right-desk: 0;
$p-course-info-expand-bottom-desk: 0.4rem;
$p-course-tooltip-size: rem(320px);

// Stages

.p-course {
  overflow-x: hidden;
  &_intro {
    background: url('/images/pages/course-select/bg_new.jpg') no-repeat center;
    background-size: cover;
    height: 560px;
    &__hero {
      height: 100%;
    }
  }

  // Intro
  &__intro {
    background: $theme-gradient-warm;

    &__hero {
      height: $p-course-intro-height;
      position: relative;

      @include desktop {
        height: $p-course-intro-height-desk;
      }
    }

    &__inner {
      width: 100%;
      position: relative;
      z-index: $gl-z-index-1;

      @include mobile {
        display: block;
        text-align: center;
        padding-top: $gl-spacer-3xl;
      }
    }

    &__img-mobile {
      position: absolute;
      left: 0;
      right: 0;
      margin: auto;
      width: 100%;
      bottom: $p-course-intro-img-bottom;
    }

    &__img-desktop {
      position: absolute;
      right: $p-course-intro-img-right;
      left: auto;
      bottom: auto;
      top: 0;
      width: $p-course-intro-img-width;
      height: $p-course-intro-img-height;
    }
  }

  &__subheader {
    letter-spacing: 2px;
    opacity: 0.4;

    @include desktop {
      font-size: rem($gl-font-size-base);
    }
  }

  &__caption {
    text-align: center;
    background-color: rgba($t-green-dark, 0.5);
    color: white;
    width: $p-course-caption-width;
    margin: auto;

    @include desktop {
      width: $p-course-caption-width-desk;
      font-size: $p-course-caption-font-size-desk;
      margin: initial;
    }
  }

  // Mistakes -- Stages
  &__stage {
    border-left: 2px solid $b-green;

    & + & {
      margin-top: $gl-spacer-xl;

      @include desktop {
        margin-top: $gl-spacer-xl;
      }
    }
  }

  // Penalty
  &__penalty {
    &__block {
      position: relative;

      @extend %o-block--white, %o-block--shadow, %o-block;

      @include mobile {
        padding: rem($gl-spacer-s);
      }
    }
  }

  // Suggestion
  &__suggestion {
    &__id {
      font-size: $gl-font-size-h3;
      font-weight: 500;
      color: $t-gray-3;

      @include desktop {
        font-size: rem(60px);
      }
    }
  }
}
