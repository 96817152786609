/* ==========================================================================
   #EMBEDDED CONTENT
   ========================================================================== */

/**
*
* <audio>, <canvas>, <embed>, <iframe>, <img>, <math>, <object>, <svg>, <video>
*
*/

iframe {
  border: none;
}
