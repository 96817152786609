// Color utilities
// Colors
@each $name, $color in $theme-clrs {
  .u-color-#{$name} {
    color: $color;
  }
  .u-bg-color-#{$name} {
    background-color: $color;
  }
}
