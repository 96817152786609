// Course card
.c-lt-course {
  padding: rem($gl-spacer-m) rem($gl-spacer-s);
  background: $theme-light-lt;
  display: block;

  @include desktop {
    padding: rem($gl-spacer-m);
    box-shadow: $gl-box-shadow-l;
    border-radius: $gl-radius-lg;
  }

  // Title
  &__title {
    font-size: rem($gl-font-size-h3g);
    margin-bottom: 4px;
    line-height: $gl-line-height-sm;
    color: $t-dark;
  }

  // Description
  &__text {
    font-size: rem($gl-font-size-xs);
    color: $t-gray-1;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    word-break: break-word;
    max-height: $gl-font-size-xs * $gl-line-height-base * 2;
  }
}
