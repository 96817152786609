// Booking List
.c-lt-bookings {
  overflow: hidden;

  // Title
  &__title {
    padding: rem($gl-spacer-s);
    margin: 0;
    font-weight: 600;
    line-height: $gl-line-height-sm;
    font-size: rem($gl-font-size-s);

    @include mobile {
      border-bottom: 1px solid $b-gray-2;

      &:not(:first-child) {
        border-top: 1px solid $b-gray-2;
      }
    }

    @include desktop {
      padding: rem($gl-spacer-s) rem($gl-spacer-m);
      background: tint($t-dark, 98%);
      font-size: rem($gl-font-size-base);
    }
  }

  // Inner
  &__inner {
    padding-left: rem($gl-spacer-s);

    @include desktop {
      padding-left: rem($gl-spacer-m);
    }
  }

  // Footer
  &__footer {
    border-top: 1px solid $b-gray-2;
    padding: rem($gl-spacer-s);
    text-align: center;

    &__link {
      color: $t-dark-active;
      display: block;
    }
  }
}
