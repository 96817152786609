/* ==========================================================================
   #EM
   ========================================================================== */

// A unitless function to convert px to em

@use "sass:math";

@function em($pixels, $context: $gl-font-size-base) {
  @if (unitless($pixels)) {
    $pixels: $pixels * 1px;
  }

  @if (unitless($context)) {
    $context: $context * 1px;
  }

  @return math.div($pixels, $context) * 1em;
}

.u-text-indent {
  text-indent: 2em;
}