//*
//  +box-sizing(border-box)

html, body
  height: 100%

body
  z-index: 0
  width: 100%
  background-position: top center
  background-repeat: no-repeat
  //background: $page_background
  //-webkit-font-smoothing: antialiased
  @media screen and (max-width: $break-mid)
    -webkit-text-size-adjust: 100% !important

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active)
  body
    font-family: "Helvetica Neue",Helvetica,Arial,"Microsoft YaHei","STHeiti","WenQuanYi Micro Hei",SimSun,sans-serif !important

@supports (-ms-accelerator: true)
  body
    font-family: "Helvetica Neue",Helvetica,Arial,"Microsoft YaHei","STHeiti","WenQuanYi Micro Hei",SimSun,sans-serif !important

html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video
  //margin: 0px
  padding: 0px
  border: 0
  outline: none
  vertical-align: baseline
article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section
  display: block

//ol, ul
//  list-style: none

//ol
//  li
//    margin:
//      left: 5px

blockquote, q
  quotes: none

blockquote
  &:before, &:after
    content: ''
    content: none

q
  &:before, &:after
    content: ''
    content: none

//a
//  text-decoration: none
//  &:hover, &:active
//    color: $link_color_hover
//    text-decoration: none !important
//  &:focus
//    color: inherit
//    text-decoration: none !important

hr
  border: 1px solid $bd-gray
  border-bottom: none

.clear
  position: relative
  clear: both

.left
  float: left

.right
  float: right

strong
  font-weight: bold

textarea, input[type="text"], input[type="password"], input[type="datetime"], input[type="datetime-local"], input[type="date"], input[type="month"], input[type="time"], input[type="week"], input[type="number"], input[type="email"], input[type="url"], input[type="search"], input[type="tel"], input[type="color"], .uneditable-input
  height: auto

textarea
  outline: none
