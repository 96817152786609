.label
  font-size: 12px
  vertical-align: middle
  padding: 2px 4px
  border-radius: 4px
  text-render: optimizeLegibility
  -webkit-font-smoothing: antialiased
  font-weight: 500

.label-inline
  display: inline-block

.label-goodprof
  background: #bdad86
  color: white
  margin:
    right: 5px
    top: -2px

.label-course
  border: 1px solid #ade0ef
  border-radius: 4px
  color: #95d6e9
  margin-top: -6px
  font-size: 13px
  font-weight: 600
  display: inline-block
  padding: 0 4px
  @media screen and (max-width: $break-small)
    font-size: 11px
    margin-top: -4px

.label-read, .label-unread
  padding: 2px 4px
  right: 0.5rem
  top: 0.5rem
.label-read
  background: #ade0ef
.label-unread
  background: #d2d3d5
.media
  .label-read, .label-unread
    padding: 2px 4px
    right: initial
    top: initial
    z-index: 99


.label-gray
  text-shadow: none
  border-radius: 2px
  color: #888
  background: #e3e4e5
  font-weight: normal
.label-success
  background: $b-green
  color: white
  font-size: 11px
  padding: 2px 14px 1px

.label-free
  background: $b-green
  font-size: 13px
  padding: 6px 8px

//.label-attachment
//  background: #F4F8FA
//  padding: 4px 5px
//  margin-right: 5px
//  border-radius: 4px

.label-tag
  //border-radius: 100px
  background-color: #f7f7f7
  font-size: 13px
  text-align: center
  color: #666666
  display: inline-block
  padding: 4px 8px

.label-mentor
  background: $b-green
  border-radius: 4px
  padding: 4px 8px

.label-vip
  background: #fff0d8
  color: #ffc161
.label-agency
  background: #ffeae1
  color: #ff9d6e
.label-progress
  background: #F1F9F8
  color: #4FB4A8
