// Study Resources Landing Page

// Intro
$p-notes-intro-height: 60vh;
$p-notes-intro-height-desk: rem(635px);
$p-notes-intro-img-right: -6rem;
$p-notes-intro-img-bottom-mobile: -2.7rem;
$p-notes-intro-img-width: rem(975px);
$p-notes-intro-img-height: rem(728px);

// Info
$p-notes-info-expand-right: rem($gl-spacer-s);
$p-notes-info-expand-bottom: 0.7rem;
$p-notes-info-expand-right-desk: 0;
$p-notes-info-expand-bottom-desk: 0.4rem;
$p-notes-tooltip-size: rem(320px);

.p-notes {
  overflow-x: hidden;

  // Intro
  &__intro {
    background: $theme-gradient-warm;

    &__hero {
      height: $p-notes-intro-height;
      position: relative;

      @include desktop {
        height: $p-notes-intro-height-desk;
      }
    }

    &__inner {
      width: 100%;
      position: relative;
      z-index: $gl-z-index-1;

      @include mobile {
        display: block;
        text-align: center;
        padding-top: $gl-spacer-3xl;
      }
    }

    &__img-mobile {
      position: absolute;
      left: 0;
      right: 0;
      margin: auto;
      width: 100%;
      bottom: $p-notes-intro-img-bottom-mobile;
    }

    &__img-desktop {
      position: absolute;
      right: $p-notes-intro-img-right;
      left: auto;
      bottom: auto;
      top: 0;
      width: $p-notes-intro-img-width;
      height: $p-notes-intro-img-height;
    }
  }

  &__subheader {
    letter-spacing: 2px;
    opacity: 0.4;

    @include desktop {
      font-size: rem($gl-font-size-base);
    }
  }

  // How -- Stages
  &__stage {
    padding-bottom: rem($gl-spacer-xs);
    border-bottom: 2px solid transparent;

    @include desktop {
      padding-bottom: rem($gl-spacer-m);
    }

    &--ltst {
      border-color: darken($p-essay-stages-color, 10%);
    }

    &--ltr {
      border-color: darken($p-essay-stages-color, 20%);
    }

    &--lt {
      border-color: darken($p-essay-stages-color, 30%);
    }

    &--dk {
      border-color: darken($p-essay-stages-color, 40%);
    }
  }
}
