/* ==========================================================================
   #TAG MIXINS
   ========================================================================== */

// Tag sizes
@mixin tag-size($pv, $ph, $font-size, $font-weight) {
  padding: $pv $ph;
  font-size: $font-size;
  font-weight: $font-weight;
}

// Tag variants
@mixin tag-variant(
  $background,
  $color,
  $important: null  
) {
  & {
    background-color: $background $important;
    border-color: $background $important;
    color: $color $important;
  }
}