///* ========================================================================
//   #COLORS
//   ======================================================================== */

// There are two prefixes that are used for color variables:
// 1. $clr indicates simple colors
// 2. $theme defines a complete set of colors that are used for website styling
// !!! Developers should use theme colors only for their css rules

// Read more:
// https://codepen.io/nicetransition/pen/QjwbRg
// http://fixate.it/blog/make-your-css-variable-names-suck-less/

/// Slightly lighten a color
/// @access public
/// @param {Color} $color - color to tint
/// @param {Number} $percentage - percentage of `$color` in returned color
/// @return {Color}
@function tint($color, $percentage) {
  @return mix(white, $color, $percentage);
}

/// Slightly darken a color
/// @access public
/// @param {Color} $color - color to shade
/// @param {Number} $percentage - percentage of `$color` in returned color
/// @return {Color}
@function shade($color, $percentage) {
  @return mix(black, $color, $percentage);
}

// All colors that are used in the project must be defined here

/* --------------- colors --------------- */

// text colors
$t-dark: #222222;
$t-gray-1: #757575;
$t-gray-2: #9b9b9b;
$t-gray-3: #b6b6b6;
$t-light: #ffffff;
$t-green: #12ccb3;
$t-green-dark: #018383;
$t-red: #FF5E3C;
$t-dark-active: #292929;
$t-coral-pink     : #CE2F2C !default;

$green: #12ccb3;

// background colors
$b-green: #12ccb3;
$b-green-lt: #41d6c2;
$b-green-dark: #009d88;
$b-gray-1: #f9f9f9;
$b-gray-2: #f6f6f6;
$b-gray-3: #b6b6b6;
$b-dark: #292929;
$b-orange-1: #ff9e44;
$b-orange-2: #edf7f6;
$b-orange-3: #e6fdf9;
$b-orange-4: #F2A63A;
$b-red-1: #ff5e3c;
$b-red-2: #ff6976;
$b-red-3: #fff4e3;


// border or divide line colors
$bd-gray: #eeeeee;
$bd-gray-dark: #dddddd;

// gradient colors
$g-green: linear-gradient(90deg, #7ACBCF 0%, #75D3C2 96.6%);
$g-blue: linear-gradient(270deg, #A9A7EE -3.88%, #749EE8 100%);
$g-yellow: linear-gradient(90deg, #F1A376 0%, #F6C97E 100%);


$page_background: $b-gray-2;
$text_color: $t-dark-active;

$clr-coral-pink     : #CE2F2C !default;
$clr-wheat          : #f3a33a !default;
$clr-wheat-dk       : #a9703b !default;
$clr-pale-grey      : #f1f9f8 !default;
$clr-white          : #fff !default;

// THEME COLORS

// Theme color variables should follow the flollowing formula for consistent naming.
//
// $theme-{category}-{alternative}-{hue/tint}
//
//
// 1. Categories:
//   Primary - Main color in the palette
//   Secondary - Contrast of primary color
//   Dark - Darkest color in the palette
//   Light - Lightest color in the palette
//   Accent - Highlight/Accent color to emphasize a part of the interface
//
// 2. Alternatives
//   alt - keyword for alternative color variant in the same category
//
// 3. Hues/Tints
// Tints and hues are named with respect to the main colour
// they are associated with, plus a suffix to describe their
// lightness or darkness with respect to that main colour:
//
//  lt => light
//  ltr => lighter
//  ltst => lightest
//
//  dk => dark
//  dkr => darker
//  dkst => darkest

$theme-primary-ltr    : $clr-pale-grey !default;
$theme-primary-ltst   : $b-gray-1 !default;

$theme-dark-lt        : $t-gray-1 !default;

$theme-light-lt       : $clr-white !default;

$theme-accent         : $clr-wheat !default;
$theme-accent-dk      : $clr-wheat-dk !default;
$theme-accent-lt      : tint($theme-accent, 96%) !default;
$theme-alert-lt       : tint($clr-coral-pink, 96%) !default;
$theme-success        : $b-green !default;

$theme-clrs: () !default;
$theme-clrs: map-merge(
  (
    'primary'        : $t-green,
    'primary-dk'     : $t-green-dark,
    'dark-dk'        : $t-dark,
    'dark'           : $t-gray-1,
    'dark-lt'        : $t-gray-2,
    'dark-ltr'       : $t-gray-3,
    'dark-ltst-1'    : $bd-gray-dark,
    'lt'             : $t-light,
    'active'         : $t-dark-active,
    'alert'         : $t-red,
    'orange'         : $b-orange-4,
    'green-dark'    :$b-green-dark
  ),
  $theme-clrs
);

// GRADIENTS

// [TODO] Need better naming for gradients
$theme-gradient-primary   : linear-gradient(341deg, #f0f9f8, #cdebe5);
$theme-gradient-warm      : linear-gradient(108deg, #E1F2F0, #D0EBEE);
$theme-gradient-cold      : linear-gradient(108deg, #ade0ef, #98b1e7);
$theme-gradient-cold-lt   : linear-gradient(108deg, #cce7dd, #9bd1e1);

$theme-gradients: () !default;
$theme-gradients: map-merge(
  (
    'primary'        : $theme-gradient-primary,
    'warm'           : $theme-gradient-warm,
    'cold'           : $theme-gradient-cold,
    'cold-lt'        : $theme-gradient-cold-lt
  ),
  $theme-gradients
);
