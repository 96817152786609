// Careers
.p-careers {
  // Intro
  &__intro {
    background: url("#{$image-path}/pages/career/careerbg.jpg") top center no-repeat;
    background-size: cover;
  }

  // Jobs
  &__job {

    &__inner {
      color: $t-dark;
      padding: rem($gl-spacer-l) rem($gl-spacer-s);
      background: $theme-light-lt;
      margin-bottom: rem($gl-spacer-m);
      text-align: center;

      @include desktop {
        padding: rem($gl-spacer-3xl) rem($gl-spacer-s);
      }
    }

    &__title {
      margin: rem($gl-spacer-xs) 0;
    }
  }
}
