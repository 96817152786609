//Static pages
div[location='how_pages'], div[location='tutors_pages'], div[location='payment_terms_pages'], div[location='terms_pages'], div[location='privacy_pages'], div[location='case_pages'], div[location='faq_pages'], div[location='schools_pages'], div[location='grades_pages']
    .content
        background: white !important
        min-width: 0
        text-render: optimizeLegibility
        -webkit-font-smoothing: antialiased

        h2
            margin: 2em auto 0.6em

    .hero
        background: #8A8BB2
        display: table
        width: 100%

        .fluid-wrap
            display: table-cell
            vertical-align: middle

        h1, p
            color: white

        p
            @media screen and (min-width: $break-mid)
                font-size: 21px !important

    hr
        margin: 6em 0 0

    .section_gray
        background: #f8f8fa

    .section_black
        background: #4f4d57

        h2
            color: white

        p
            color: #ccc

    .slide
        overflow: hidden
        padding: 8em 5%

        .browser-body
            border: 0

        .figure
            width: 45%
            vertical-align: middle

            .browser
                width: 95%

        .span5
            width: 50%

        p
            line-height: 1.6em
            font-size: 18px

            @media screen and (max-width: 1060px)
                font-size: 16px

    .slide#study
        .browser-body
            background-color: #f2f4f5

    .slide#qa, .slide#essay
        .browser-body
            background-color: #dedede

    .slide#guides
        .figure
            padding-top: 3em

    .slide#books
        .browser-body
            background-color: #d9dde3

//Legal
div[location='terms_pages'], div[location='payment_terms_pages'], div[location='privacy_pages']
    .content
        background: white

    .hero
        background: #a6b3b3 !important
        padding: 80px 0 30px !important

    .legal
        padding-top: 60px
        padding-bottom: 60px

        p, li
            line-height: 1.8em
            color: #444
            font-size: 16px

        ol.alpha
            list-style-type: lower-alpha

        ol
            list-style-type: decimal
            margin-left: 15px

            li
                padding: 10px 0

        ul
            list-style-type: disc
            margin-left: 30px

            li
                padding: 5px 0

        h5
            margin: 2em 0 0.5em

div[location='schools_pages']
    .hero
        background-image: linear-gradient(180deg, #eeedf6, #fff 100%)
        height: 300px

        @media screen and (max-width: 30rem)
            height: 200px

        .fluid-wrap
            background: url("#{$image-path}/home/map.svg") no-repeat center bottom !important
            background-size: cover !important

            h1
                color: $text_color

    .input-append
        .btn
            background: transparent
            z-index: 3
            left: 0

            .fa-search
                color: #888
                font-size: 1.25rem

        .search
            font-size: 20px
            padding-left: 3rem

            @media screen and (max-width: 30rem)
                font-size: 16px

    .nav-tabs
        a
            cursor: pointer

    .tab-content
        overflow: visible
        min-height: 300px

        .school-list__school
            padding: 0.5rem 0.75rem
            display: inline-block
            width: 25%
            box-sizing: border-box
            margin-right: -4px

            @media screen and (max-width: 480px)
                width: 50%

    .sm_tabs
        li
            a
                @media screen and (max-width: 48rem)
                    font-size: 14px !important

div[location='faq_pages']
    .content
        background: $page_background !important

    //padding-bottom: 3em
    .hero
        height: 400px
        background-color: #61CA67
        position: relative

        @media screen and (max-width: $break-device)
            height: 200px

        .bg
            opacity: 0.4
            position: absolute
            margin: auto
            top: 6em
            left: 0
            right: 0

            @media screen and (max-width: $break-device)
                top: 0

    .faq-ctn
        h2
            margin: 2em auto 1em

        .video
            width: 31%
            display: inline-block
            margin: 1em 1%
            overflow: hidden
            position: relative

            @media screen and (max-width: 850px)
                width: 47%

            @media screen and (max-width: 600px)
                width: 100%
                max-width: 365px
                margin: 1em auto

            .thumb
                height: 190px
                transition: 0.3s
                cursor: pointer

                &:hover
                    opacity: 0.5

            img
                cursor: pointer
                width: 60px
                height: 60px
                position: absolute
                left: 0
                right: 0
                top: 5em

            h3
                margin: 1em auto

                @media screen and (min-width: $break-device)
                    font-size: 21px

        =spritebox($left, $top, $height, $width)
            background-image: url("#{$image-path}/faq/faq-sprite.png")
            background-repeat: no-repeat
            background-position: $left $top
            height: $height
            width: $width

        .ico_homepage
            +spritebox(-0px, 0px, 190px, 370px)

        .ico_course
            +spritebox(-370px, 0px, 190px, 370px)

        .ico_essay
            +spritebox(-0px, -190px, 190px, 370px)

        .ico_expert
            +spritebox(-370px, -190px, 190px, 370px)

        .ico_lifeqa
            +spritebox(-0px, -380px, 190px, 370px)

        .ico_book
            +spritebox(-370px, -380px, 190px, 370px)

        .ico_doc
            +spritebox(-740px, 0px, 190px, 370px)

        .ico_email
            +spritebox(-740px, -190px, 190px, 370px)

        .ico_password
            +spritebox(-740px, -380px, 190px, 370px)

    .faqs
        background: white
        padding: 1em 0 8em
        margin-top: 6em
        border-top: 1px solid $bd-gray

        @media screen and (max-width: $break-device)
            margin-top: 3em

            h2
                margin-top: 1em

        .accordion
            max-width: 750px

            .accordion-group
                border: none
                border-bottom: 1px solid $bd-gray

            .accordion-heading
                border-radius: 0

                a
                    color: $text_color
                    font-size: 18px

                    &:hover
                        text-decoration: underline !important

                    &:focus
                        text-decoration: none

                    @media screen and (max-width: $break-device)
                        font-size: 15px

                .accordion-toggle
                    padding: 1em 0

            .accordion-inner
                padding: 2em 0
