// About

.p-courses {
  @include desktop {
    background: url('/images/pages/courses/steps_bg.png');
    background-size: 100% 100%;
  }
  .line {
    height: 6px;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.04);
    @include mobile {
      width: calc(100% + 48px);
      margin-left: -24px;
      margin-right: -24px;
    }
  }
  .height50 {
    height: 50px;
  }
  .input-items__li {
    background: #efefef !important;
    padding: 7.5px 12px !important;
    border-radius: 2px !important;
  }
  .mobile-left {
    text-align: center;
    @include mobile {
      text-align: left;
    }
  }
  &__point {
    display: inline-block;
    width: 8px;
    height: 8px;
    border-radius: 4px;
    background-color: $b-green;
  }
  &__table {
    vertical-align: middle;
    &__bg {
      background-color: #f0f9f7;
      &:hover > td {
        background-color: #f0f9f7 !important;
      }
    }
    &__header {
      .ant-table-thead > tr > th {
        color: white;
        font-size: 16px;
        text-align: center;
        vertical-align: middle;
        background-color: #60b4a8;
      }
    }
  }
  &__input {
    input::placeholder {
      font-size: 14px;
    }
    input[type=checkbox] {
      position: relative;
      display: inline-block;
      top: 2px;
      right: 2px;
      width: 15px !important;
      height: 15px;
    }
    input[type=checkbox]::before {
      position: absolute;
      display: block;
      content: '';
      border-radius: 1px;
      width: 15px !important;
      height: 15px;
      border: 1px solid #ccc;
    }

    input[type=checkbox]:checked::before {
      content: '';
      background-image: url("/images/pages/courses/check_bg_color.svg");
      background-size: cover;
      border: 0;
      background-color: $b-green;
    }
  }
  &__img {
    margin: 0 20%;
  }
  &__text {
    color: $t-gray-1;
  }
  &__br {
    border-right: 1px solid #E9E9E9;
  }
  &__pa0 {
    padding: 0
  }
  &__prep-btn {
    text-align: left;
    @include mobile {
      text-align: center;
    }
  }
  &-steps {
    &__img {
      position: absolute;
      bottom: 0;
      width: 66%;
      box-shadow: #ecf7f8 0px 0px 10px;
    }
    &__items {
      width: 2.5rem;
      height: 2.5rem;
      color: $t-gray-1;
      font-size: 1rem;
      border: 2px solid rgba(0, 0, 0, 0.1);
      line-height: 2.5rem;
      text-align: center;
      margin-top: 6px;
      &__done {
        background: $b-green;
        color: #fff;
        border: none;
      }
    }
    &__line {
      width: 0;
      height: 5rem;
      left: 1em;
      border: 1px dashed rgba(0, 0, 0, 0.12);
      margin: 0.5em 0;
      margin-left: 1.25em;
      @include mobile {
        height: 2rem;
      }
      &__done {
        border-color: $b-green;
      }
    }
  }
  &__left-contain {
    @include mobile {
      display: none;
    }
  }
  &__mobile-steps {
    display: none;
    @include mobile {
      display: block;
    }
  }
  &__confirm {
    &_line {
      height: 1px;
      border-top: 1px dashed gray;
    }
    &_edit {
      text-align: center;
      img, a {
        vertical-align: middle;
      }
    }
    &_item {
      //height: 3em;
      line-height: 3em;
      text-transform: capitalize;
      background: rgba(0,0,0,0.04);
      padding: 0 1em;
      border-radius: 2px;
      color: rgba(0, 0, 0, 0.8);
      &__not-cap {
        text-transform: none;
      }
    }
  }
  &__star {
    width: 32px !important;
    height: 32px !important;
  }
}
.ant-radio-checked .ant-radio-inner {
  border-color: $b-green;
  &::after {
    background: $b-green;
  }
}
.ant-radio-wrapper:hover .ant-radio, .ant-radio:hover .ant-radio-inner, .ant-radio-input:focus + .ant-radio-inner {
  border-color: $b-green;
}
