/* ==========================================================================
   #PHRASING CONTENT
   ========================================================================== */

/**
* Phrasing content defines the text and the mark-up it contains.
* Runs of phrasing content make up paragraphs.
* See https://developer.mozilla.org/en-US/docs/Web/Guide/HTML/Content_categories#Phrasing_content
*
* Examples:
* <abbr>, <a>, <b>, <bdo>, <br>, <cite>, <code>, <data>, <dfn>, <em>, <hr>, <i>, <kbd>,
* <mark>, <meter>, <noscript>, <output>, <progress>, <q>, <ruby>, <samp>, <small>,
* <span>, <strong>, <sub>, <sup>, <time>, <var>, <video>, <wbr>
*
*/

hr {
  border-top: 1px solid $bd-gray;
  border-width: 1px 0 0;
  margin: $gl-spacer-m auto;
}

p {
  margin: 0 0 rem($gl-spacer-m);
}
.halloween a{
  cursor: url(#{$image-path}/mooncakes/click.png), pointer
}

.single_festival a{
  cursor: url(#{$image-path}/mooncakes/click.png), pointer
}
a {
  color: $t-green-dark;
  text-decoration: none;
  transition: ($gl-duration-base * 0.5) color $gl-timing-func;
  cursor: pointer;

  @include hover-focus-active {
    text-decoration: none;
    color: inherit;
  }
}

mark {
  background-color: transparent;
  margin-left: rem(-4px);
  margin-right: rem(-4px);

//  @media screen and (min-width: $break-small) {
//    margin-left: rem(-$gl-spacer-xxs);
//    margin-right: rem(-$gl-spacer-xxs);
//  }
}
