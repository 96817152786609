//news
$img-path: '/images/pages/news';

.p-news {
  &__intro {
    background: url('#{$img-path}/intro_bg.jpg') no-repeat center;
    background-size: cover;
    height: 560px;
    &__hero {
      height: 100%;
    }
  }
  .player_box {
    margin: 0 auto;
    width: 80%;
    height: 547px;
    @include mobile{
      width:100%;
      height: 260px;
    }
  }
  .el_video {
    width: 100%;
    height: 340px;
  }
  &_hr {
    background: #f5f5f5;
    height: 4px;
    width: 100%;
  }
  .el_box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    // align-items: center;
    padding: 0px;
  }
  &-media {
    border-right: 2px solid #e1e1e1;
    @include mobile{
      border:none;
    }
  }
  &-analysis {
    &_text {
      line-height: 150%;
      text-align: center;
      max-width: 760px;
      margin: 40px auto 72px;
      @include mobile {
        margin: 20px auto;
      }
    }
    .bg {
      margin-top: -70px;
      background:$b-green;
      height: 142px;
    }
    .box_m {
      position: relative;
      z-index: 3;
      background: $t-light;
      box-shadow: 0px 32px 40px rgb(37 130 117 / 14%);
      border-radius: 12px;
    }
    .box {
      background: $t-light;
      box-shadow: -41px 41px 36px rgba(0, 0, 0, 0.0545509);
      border-radius: 12px;
      padding: 64px 56px;
      margin-right: -190px;
      position: relative;
      z-index: 9;
    }
  }
  &__elite {
    .img-h {
      height: 486px;
    }
    .c-slider__item {
      text-align: center;

      img {
        border-radius: 20px;
        -webkit-border-radius: 20px;
        -moz-border-radius: 20px;
        -ms-border-radius: 20px;
        -o-border-radius: 20px;
      }
    }
  }
}
