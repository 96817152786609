// Calendar
$cal-padding: $gl-spacer-xl;
$cal-text-font-size: $gl-font-size-base;
$cal-bg: $theme-light-lt;

// Header
$cal-header-height: 72px;
$cal-header-height-mobile: 56px;
$cal-header-padding-top: 10px;
$cal-icon-container-height: 44px;
$cal-icon-size: $gl-asset-size-xxs;

// Day
$cal-day-indicator-size: 5px;
$cal-day-margin-top: 6px;
$cal-day-padding-top: 4px;
$cal-day-padding-bottom: $gl-spacer-xxs;

// Today
$cal-num-size: $gl-spacer-xl;
$cal-num-size-mobile: $gl-spacer-l;
$cal-today-shadow: 0 2px 8px 0 rgba(133, 226, 202, 0.6);

// Title
$cal-title-font-size-s: $gl-font-size-xxs;
$cal-title-font-size-m: $gl-font-size-base;
$cal-title-font-size-l: $gl-font-size-h3g;
$cal-title-padding-bottom: $gl-spacer-l;
$cal-title-padding-bottom-mobile: $gl-spacer-s;

// Event
$cal-event-radius: $gl-radius-md;
$cal-event-time-font-size: $gl-font-size-xxs;
$cal-event-item-assignment-bg: $theme-primary-ltr;
$cal-event-item-exam-bg: $theme-alert-lt;
$cal-event-item-padding: $gl-spacer-xs;
$cal-event-item-margin: $gl-spacer-xxs;

.c-cal {
  position: relative;

  // Text
  &__text {
    font-size: $cal-text-font-size;
  }

  // Event
  &__event {
    &__empty {
      border: 1px solid $bd-gray;
      padding: $cal-padding;
      border-radius: $cal-event-radius;
      text-align: center;
    }

    &__item {
      padding: $cal-event-item-padding;
      margin-bottom: $cal-event-item-margin;
      border-radius: $cal-event-radius;

      &--assignment {
        background: $cal-event-item-assignment-bg;
      }

      &--exam {
        background: $cal-event-item-exam-bg;
      }

      &--accent {
        background: $b-gray-2;
        cursor: pointer;
      }
    }

    &__time {
      font-weight: 500;
      font-size: $cal-event-time-font-size;
      display: inline-block;
    }
  }

  // Modify Calendar widget
  $cal-class: 'monthly';

  .#{$cal-class} {
    background-color: $cal-bg;
    position: static;

    // Header
    &-header {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      width: 100%;
      height: rem($cal-header-height-mobile);
      margin: auto;

      @include desktop {
        border-bottom: 1px solid $bd-gray;
        height: rem($cal-header-height);
      }

      &-title-date {
        background-color: transparent !important;
        border: none !important;
        font-size: $cal-title-font-size-m;
        letter-spacing: 3px;
        font-weight: 500;

        @include desktop {
          font-size: $cal-title-font-size-l;
        }
      }
    }

    &-indicator-wrap {
      @include mobile {
        padding-top: 0;
        margin-top: rem(-$gl-spacer-xxs);
      }
    }

    &-prev,
    &-next {
      height: rem($cal-icon-container-height);
      opacity: 0.3;

      &:hover {
        opacity: 1;
      }
    }

    &-prev:after {
      border-width: 0 0 2px 2px;
    }

    &-next:after {
      border-width: 2px 2px 0 0;
    }

    // Days
    &-day {
      cursor: pointer;

      @include mobile {
        padding-top: rem($cal-day-padding-top);
        padding-bottom: rem($cal-day-padding-bottom);
      }

      &-title-wrap {
        font-size: $cal-title-font-size-s;
        font-weight: 600;
        color: $t-gray-3;
        padding-bottom: $cal-title-padding-bottom-mobile;

        @include desktop {
          padding-bottom: $cal-title-padding-bottom;
          font-weight: 500;
          font-size: $cal-title-font-size-m;
        }
      }

      &-wrap {
        font-size: $cal-text-font-size;
      }

      &-blank {
        background-color: $theme-light-lt;
      }

      &-number {
        width: rem($cal-num-size-mobile);
        height: rem($cal-num-size-mobile);
        border-radius: 50%;

        @include desktop {
          width: rem($cal-num-size);
          height: rem($cal-num-size);
        }
      }

      .#{$cal-class}-event-indicator {
        @include mobile {
          width: $cal-day-indicator-size;
          height: $cal-day-indicator-size;
          margin-top: $cal-day-margin-top;
          background-color: $b-green;
        }
      }

      &-event {
        @include desktop {
          padding-bottom: 0;
          padding-top: 0;
        }

        .#{$cal-class}-day-number {
          line-height: rem($cal-num-size-mobile);

          @include desktop {
            line-height: rem($cal-num-size);
          }

          &:hover {
            border: 1px solid $b-green;
          }
        }

        &.active {
          .#{$cal-class}-day-number {
            border: 1px solid $b-green;
          }
        }
      }
    }

    &-today {
      .#{$cal-class}-day-number {
        background-color: $b-green;
        box-shadow: $cal-today-shadow;
        color: $t-light;
        font-weight: bold;

        &:hover {
          border: none;
        }
      }
    }
  }
  // Full Calendar widget
  .fc {
    &-theme-standard {
      td,
      th {
        border: 0px solid var(--fc-border-color, #ddd);
      }
    }
    &-header-toolbar {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      width: 100%;
      height: rem($cal-header-height-mobile);
      margin: auto;

      @include desktop {
        border-bottom: 1px solid $bd-gray;
        height: rem($cal-header-height);
      }
    }
    &-toolbar {
      padding: $gl-spacer-s $gl-spacer-m;
      margin-bottom: 0;
      &-title {
        background-color: transparent !important;
        border: none !important;
        font-size: $cal-title-font-size-m;
        letter-spacing: 3px;
        font-weight: 500;

        @include desktop {
          font-size: $cal-title-font-size-l;
        }
      }

      h2 {
        font-size: $gl-font-size-base;
        text-transform: uppercase;
        line-height: 30px;
        letter-spacing: 2px;
        font-weight: 600;
      }
    }
    &-prev-button,
    &-next-button {
      height: 2.75rem;
      opacity: 0.3;
      &:after {
        border-color: #000;
        border-width: 0 0 0px 0px;
      }
      &:hover,
      &:active {
        opacity: 1;
        color: $t-dark;
        background-color: transparent;
      }

      .fc-icon {
        font-size: 2em;
        color: $t-dark;
      }
    }
    &-button-primary:not(:disabled):active {
      background: transparent;
      box-shadow: inset 0 0px 0px rgba(0, 0, 0, 0.15) !important;
    }
    &-button {
      background-color: transparent !important;
      background-image: none;
      box-shadow: none;
      text-shadow: none;
      //border: 1px solid $bd-gray;
      border: 0px solid $bd-gray !important;
      color: $t-dark;
    }

    &-icon:after {
      color: $t-dark;
      font-weight: normal;
    }
    &-daygrid {
      table {
        border: none;
      }
      .fc-col-header {
        &-cell {
          text-align: right;
          padding-bottom: 32px;
          font-weight: 500;
          font-size: 16px;
          &-cushion {
            color: #b5b5b5;
            cursor: default;
          }
        }
      }
      &-body {
        .active {
          .fc-daygrid-day-number {
             border: 1px solid $b-green;
          }
        }
        .fc-daygrid-day-number {
          line-height: 3rem;
          width: 3rem;
          height: 3rem;
          font-weight: bold;
          border-radius: 50%;
          font-size: 16px;
          text-align: center;
          vertical-align: middle;
          padding: 0;
          &:active,
          &:hover {
            border: 1px solid $b-green;
          }
        }
        .fc-day-today {
          background: transparent !important;
          .fc-daygrid-day-number {
            color: #fff;
            background-color: $b-green;
            box-shadow: 0 2px 8px 0 rgba(133, 226, 202, 0.6);

            &:hover,
            &:active {
              border: none;
            }
          }
        }
        .fc-day-events-indicator {
          background-color: $b-green;
          width: 8px;
          height: 8px;
          vertical-align: middle;
          border-radius: 50%;
          -webkit-border-radius: 50%;
          -moz-border-radius: 50%;
          -ms-border-radius: 50%;
          -o-border-radius: 50%;
        }
        .fc-daygrid-event-harness {
          float: right;
          margin-top: -20px;
        }
        .fc-daygrid-day-events {
          // margin: 0;
          // min-height: 0;
          .fc-event {
            background-color: transparent;
            border: none;
            margin-left: -32px;
            // padding: 0;
          }
        }
      }
    }

    &-title,
    &-time {
      font-weight: 500;
      font-size: $gl-font-size-xxs;
      color: $t-dark;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &-event {
      background-color: $theme-primary-ltr;
      border-color: $theme-primary-ltr;
      padding: 4px;
    }

    &-day-header {
      vertical-align: middle;
      border-color: $theme-light-lt;
      font-weight: normal;
      padding: $gl-spacer-xxs;
      color: $t-dark;
    }

    &-day,
    &-day-number {
      color: $t-dark;
    }

    &-unthemed,
    &-row {
      border-color: $bd-gray;
    }

    &-row {
      thead {
        tr {
          text-align: right;
        }
      }
    }

    &-day-number {
      margin: 8px;
      padding: 8px !important;

      &.fc-today {
        background: $b-green;
        display: inline-block;
        text-align: center !important;
        color: $t-light;
        border-radius: 100%;
        width: $gl-asset-size-sm;
        height: $gl-asset-size-sm;
        border: none;
        padding: 0 !important;
      }
    }

    &-day.fc-today {
      background: $theme-light-lt !important;
    }

    &-other-month {
      background: url('#{$image-path}/admin/other-month.svg') no-repeat;
      background-size: cover;
    }
  }

  // Study Plan event status
  &__status {
    margin-right: 4px;
    width: 6px;
    height: 6px;
    border-radius: 100%;
    display: inline-block;
    vertical-align: middle;

    &--incomplete {
      color: $t-red;
    }

    &--completed {
      color: $t-gray-3;
    }
  }
}

.fl {
  &-week-header {
    a {
      color: $t-dark;
      font-weight: 700;
      &:hover {
        cursor: default;
      }
    }
  }
  &-allDay {
    color: $t-gray-2;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 12px;
    text-align: left;
  }
}
