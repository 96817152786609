// About

.p-about {
  &__intro {
    background: url('#{$image-path}/pages/about/intro_bg.jpg') no-repeat
      center top;
    background-size: cover;
    height: 560px;
    // h1 {
    //   font-size: 64px;
    //   font-weight: 600;
    // }
  }
  .about{
    .img{
      height: 400px;
      position: relative;
      @include mobile {
        height: 120px;
      }
    }
    .img-h{
      position: relative;
      height: 286px;
      @include mobile {
        height: 180px;
      }
    }
  }
  &__tabs {
    background: $b-gray-2;
    // height: 56px;
  }
  .team {
    .title {
      font-size: 42px;
      margin-bottom: 40px;
      @include mobile {
        font-size: 24px;
      }
    }
  }
  &__bg {
    background: $b-gray-1;
    .img-h{
      position: relative;
      height: 286px;
      @include mobile {
        height: 180px
      }
    }
  }
  &_timeline {
    &__dot {
      width: 28px;
      height: 28px;
      position: absolute;
      left: 0;
      right: 0;
      // top: 30px;
      margin: auto;
      z-index: 1;
    }
    &__line {
      width: 2px;
      height: 100%;
      background: $bd-gray;
      margin:auto;
    }
  }
  &_team {
    background: #F9FAFA;
    p {
      max-width: 760px;
      margin: 0 auto;
    }
    .num {
      font-size: 70px;
      font-weight: bold;
      @include mobile{
        font-size: 46px;
      }
    }
    .max_w {
      max-width: 290px;
      margin: 20px auto 0;
      @include mobile{
        max-width: 100%;
        margin: 12px 0 20px;
      }
    }
    .team_bg {
      background: url('/images/pages/about/map.png') no-repeat bottom;
      background-size: contain;
      @include mobile{
        background-size: cover;
      }
    }
    .team_box {
      background: rgba(224, 244, 240, 0.46);
      border-radius: 20px;
      padding: 60px;
    }
  }
  &_careers {
    .u-mb-2xs{
      margin-bottom: 6px;
    }
    .u-pl-2xs{
      padding-left: 6px;
    }
    // List
    &__list {
      margin-left: 20px;
      li {
        list-style: disc;
        -webkit-margin-before: 0;
        line-height: 2;
        &::marker{
          color: $t-dark;
        }
      }
    }
  }
}
