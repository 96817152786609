// Task
$task-spacing: $gl-spacer-xs;
$task-icon-size: 10px;
$task-border-radius: $gl-radius-md;

.p-dashboard {
  // Header
  &__header {
    background-color: $theme-light-lt;
    padding-top: rem($gl-spacer-xl);

    @include desktop {
      background-color: $b-gray-2;
    }
  }

  // Service
  &__service {
    background-color: $theme-light-lt;
    padding-bottom: rem($gl-spacer-xxs);
    padding-top: rem($gl-spacer-l);

    @include desktop {
      background-color: $b-gray-2;
      padding-bottom: rem($gl-spacer-l);
    }
  }

  // Tasks
  &__task {
    background-color: $b-gray-2;
    padding-top: rem($gl-spacer-l);
    padding-bottom: rem($gl-spacer-l);

    &__container {
      @include desktop {
        padding: rem($gl-spacer-l);
        background-color: $theme-light-lt;
        box-shadow: $gl-box-shadow-m;
        border-radius: $gl-radius-lg;
      }
    }

    &__item {
      border-bottom: 1px solid $bd-gray;
      padding: rem($task-spacing) 0;
      display: block;
      color: $t-dark;

      @include desktop {
        padding: rem($task-spacing);
        border: 1px solid $bd-gray;
        border-radius: $task-border-radius;
        margin-bottom: rem($task-spacing);

        &:hover {
          border-color: $b-green;
        }
      }

      &--incomplete {
        font-weight: 500;
      }
    }

    &__icon {
      border: 2px solid $b-green;
      width: $task-icon-size;
      height: $task-icon-size;
      border-radius: 50%;
    }
  }
}

.content {
  width: 100%;
  position: relative;
  min-height: calc(100% - 65px);
  //min-width: $break_xs;
}

#__next {
  height: 100%
}
