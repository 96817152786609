@if (config(utilities)) {
  $prefix: 'u-';

  @each $bp in map-keys($breakpoints) {
    @include media-breakpoint-up($bp) {
      $infix: breakpoint-infix($bp, $breakpoints);

      @each $name, $size in $font-sizes {
        .u-font-size#{$infix}-#{$name} {
          font-size: rem($size) !important;
        }
      }

      @each $name, $size in $gl-header-font-sizes {
        .u-font-size#{$infix}-#{$name} {
          @include font-size($size, auto, true);
        }
      }
    }
  }
}
