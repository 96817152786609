/* ==========================================================================
   #AVATAR
   ========================================================================== */

// A profile image thumbnail
$avatar-color: $theme-light-lt;
$avatar-font-size-small: 10px;

.c-avatar {
  border-radius: 50%;
  overflow: hidden;
  position: relative;
  background-color: $b-gray-3;
  color: $t-light;
  text-align: center;
  background-size: cover;

  &__img {
    position: relative;
    z-index: 0;
    display: block;
    margin: 0;
    object-fit: cover;
    width: 100%;
    height: 100%;
  }

  &--inline {
    display: inline-block;
    vertical-align: middle;
  }

  // Avatar sizes
  @each $size, $value in $asset-size {
    &--#{$size}{
      height: rem($value);
      width: rem($value);
      line-height: rem($value);
    }
  }

  &--sm {
    font-size: rem($avatar-font-size-small);
  }
}
