// [TODO] Remove on React
.formError
  z-index: 990

  .formErrorContent
    z-index: 991

  .formErrorArrow
    z-index: 991

.formError.formErrorInsideDialog
  z-index: 5000

.formError.formErrorInsideDialog, .formErrorContent
  z-index: 5001

.formError.formErrorInsideDialog, .formErrorArrow
  z-index: 5006

.inputContainer
  position: relative
  float: left

.ajaxSubmit
  padding: 20px
  background: #55ea55
  border: 1px solid #999
  display: none

body[dir='rtl']
  .formError
    .formErrorArrow
      margin: -2px 13px 0 0

body.rtl
  .formError
    .formErrorArrow
      margin: -2px 13px 0 0

.greenPopup
  .formErrorContent
    background: #33be40

  .formErrorArrow
    div
      background: #33be40

.blackPopup
  .formErrorContent
    background: #393939
    color: #FFF

  .formErrorArrow
    div
      background: #393939
      color: #FFF

.formError
  position: absolute
  opacity: 0.87
  top: -34px
  left: 266px
  right: initial
  display: block
  .formErrorContent
    background: #FF6873
    position: relative
    color: #fff
    width: 150px
    font-size: 12px
    font-weight: 500
    -moz-box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.3)
    -webkit-box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.3)
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.3)
    -moz-border-radius: 3px
    -webkit-border-radius: 3px
    padding: 4px 10px 4px 10px
    +border-radius(3px)
  .w3
    width: 300px
  .formErrorArrowBottom
    -moz-box-shadow: "transparent"
    -webkit-box-shadow: "transparent"
    box-shadow: "transparent"
    margin: 0px 0 0 12px
    top: 50px

    div
      -moz-box-shadow: "transparent"
      -webkit-box-shadow: "transparent"
      box-shadow: "transparent"

  .formErrorArrow
    width: 15px
    margin: -2px 0 0 13px
    position: relative

    div
      -moz-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3)
      -webkit-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3)
      box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3)
      font-size: 0px
      height: 1px
      background: #FF6873
      margin: 0 auto
      line-height: 0
      display: block

    .line10
      width: 15px
      border: none

    .line9
      width: 13px
      border: none

    .line8
      width: 11px

    .line7
      width: 9px

    .line6
      width: 7px

    .line5
      width: 5px

    .line4
      width: 3px

    .line3
      width: 1px
      border: none

    .line2
      width: 0px
      border: none

    .line1
      width: 0px
      border: none

.formErrorRightTop
  left: auto !important
  right: 0
  .formErrorArrow
    margin: -2px 13px 0 auto

.formErrorLeftBottom
  top: 50px

.formErrorRightBottom
  left: auto !important
  right: 0
  top: 50px
  .formErrorArrow
    margin: -2px 13px 0 auto
