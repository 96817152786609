// Profile Card

$c-pc-degree-y-position: rem(-14px);
$c-pc-degree-width: rem(92px);
$c-pc-degree-spacing: rem(5px);
$c-pc-degree-font-size: rem($gl-font-size-xxs);
$c-pc-avatar-size: rem(72px);
$c-pc-avatar-size-desk: rem(168px);
$c-pc-padding: rem($gl-spacer-m) rem($gl-spacer-m) rem($gl-spacer-l);
$c-pc-padding-desk: rem($gl-spacer-xl) rem($gl-spacer-xl) rem($gl-spacer-2xl);

.c-profile-card {
  @extend %o-block--white, %o-block--shadow;

  text-align: center;
  position: relative;
  padding: $c-pc-padding;

  @include desktop {
    padding: $c-pc-padding-desk;
  }

  &__degree {
    position: absolute;
    left: 0;
    right: 0;
    bottom: $c-pc-degree-y-position;
    border-radius: $gl-radius-pill;
    font-size: $c-pc-degree-font-size;
    color: $t-light;
    width: $c-pc-degree-width;
    padding: $c-pc-degree-spacing;
    margin: auto;
    background: $b-green;
  }

  &__avatar {
    width: $c-pc-avatar-size;
    height: $c-pc-avatar-size;

    @include desktop {
      width: $c-pc-avatar-size-desk;
      height: $c-pc-avatar-size-desk;
    }
  }
}
