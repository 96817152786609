$path: '/images/pages/sdc-introduction';

.p-sdc-intro {
  &_intro {
    background: url('#{$path}/intro_bg_min.jpg') no-repeat center;
    background-size: cover;
    height: 560px;

    &__hero {
      height: 100%;
    }
  }

  &_team {
    .team_bg {
      position: relative;
      top: 20px;
      height: 0;

      &_img {
        height: 182px;
        position: relative;
      }
    }

    .team_img {
      height: 110px;
      position: relative;

      @include mobile {
        height: 70px
      }
    }

    .aifs {
      align-items: flex-start;

      @include mobile {
        flex-direction: column;
      }
    }

    .plr {
      padding-left: 2px;
      padding-right: 2px;
    }

    .team_text {
      line-height: 168%;
    }

    .border {
      background: $b-green;
      border-radius: 90px;
      width: 96px;
      height: 10px;

      @include mobile {
        width: 56px;
        margin: 24px 0;
      }
    }
  }

  &_info {
    background: url('#{$path}/info_bg.jpg') no-repeat center;
    background-size: cover;

    .text {
      margin-top: 4px;
      opacity: 0.9;
    }

    .img-h {
      height: 342px;
      position: relative;

      @include mobile {
        height: 200px
      }
    }

    .info_mb {
      margin-bottom: 52px;
    }
  }

  &_sdc {
    .country {
      width: 32px;
      height: 32px;

      @include mobile {
        width: 27px;
        height: 27px;
        margin-right: 0.5rem;
      }
    }

    .sdc-item {
      margin-bottom: 40px;
    }

    @include mobile {
      .contain-item {
        margin-bottom: -2.5rem;
      }

      .sdc-item {
        margin-bottom: 1.5rem;
      }
    }

    .border {
      border-right: 3px solid #dedada;

      @include mobile {
        &__mobile {
          border: none;
        }
      }
    }

    .tutor_box {
      background: $t-light;
      box-shadow: 0px 5px 12px rgba(0, 0, 0, 0.06);
      cursor: pointer;
      position: relative;

      img {
        width: 100%;
      }

      .tutor {
        height: 360px;
        position: relative;

        @include mobile {
          height: 210px;
        }
      }

      .bb {
        margin: 8px auto;
        width: 32px;
        height: 4px;
        background: $b-green;
        border-radius: 6px;
        border: none;
      }

      .active {
        position: absolute;
        top: 0;
        height: 100%;
        width: 100%;
        padding: 0 18px;
        background: rgba(0, 0, 0, 0.5);
        backdrop-filter: blur(3px);
        -moz-transition: all 0.1s ease 0s;
        -webkit-transition: all 0.1s ease 0s;
        transition: all 0.1s ease 0s;
        -o-transition: all 0.1s ease 0s;

        &_mt {
          margin-top: 60%;

          .bb {
            background: $t-light;
          }
        }
      }
    }
  }
}