///* ========================================================================
//   #STEPS
//   ======================================================================== */

$step-size: 40px;
$step-font-size: $gl-font-size-base;
$step-item-spacing: $gl-spacer-xxs;
$step-label-color: $theme-dark-lt;

// Steps joint line
$step-line-top: $gl-asset-size-lg * 0.5;

// Step dot
$step-dot-size: 6px;
$step-dot-space: $gl-spacer-xxs;


.c-steps {

  // Number Steps
  &--flex {
    text-align: center;
    display: flex;
    justify-content: space-between;
  }

  &__item {
    flex: 1;
    position: relative;

    &__count {
      border-radius: 100%;
      background: $t-gray-3;
      width: rem($step-size);
      height: rem($step-size);
      color: $t-light;
      font-size: rem($step-font-size);
      line-height: rem($step-size);
      margin: 0 auto rem($step-item-spacing);
      position: relative;
      z-index: $gl-z-index-1;
    }

    &__label {
      color: $step-label-color;

      @include desktop {
        font-size: rem($step-font-size);
      }
    }

    &:before, &:after {
      content: '';
      width: 50%;
      position: absolute;
      top: $step-line-top;
      border-top: 1px solid $bd-gray;
      z-index: $gl-z-index-0;
    }

    &:before {
      left: 0;
    }

    &:after {
      right: 0;
    }

    // No line on the left side of the first time and right side of the last item
    &:first-child {
      &:before {
        display: none;
      }
    }

    &:last-child {
      &:after {
        display: none;
      }
    }

    // Active State
    &.is-active &__count {
      background: $b-green;
    }

    &.is-active &__label {
      color: $t-dark;
    }
  }

  // Dot Steps
  &__dot {
    width: $step-dot-size;
    height: $step-dot-size;
    border-radius: 50%;
    background-color: $b-gray-2;
    display: inline-block;
    vertical-align: middle;

    & + & {
      margin-left: rem($step-dot-space);
    }

    &.is-active {
      background-color: $b-green;
    }
  }
}
