.p-home {
  &__rmp {
    background: linear-gradient(180deg, $theme-light-lt, #d7f1eb);

    &__slide {
      position: relative;
      padding: 72px $gl-spacer-m $gl-spacer-l;
      overflow: visible;

      @include desktop {
        // width: 928px;
        margin: auto;
      }

      @include mobile {
        display: flex;
        flex-wrap: wrap;
        padding: 0;
      }
    }

    &__block {
      background: rgba(248, 255, 254, 0.78);
      box-shadow: 0px 26px 32px rgba(0, 100, 86, 0.05);
      backdrop-filter: blur(4px);
      border-radius: 12px;
      padding: 2rem;
      &--left{
        @include mobile {
          margin-top: 24px;
          background: #ffe9ce;
          box-shadow: 10px 10px 20px rgba(213, 225, 224, 0.521143);
          order:2;
        }
      }

      @include desktop {
        position: absolute;
        z-index: 1;
        width: 330px;

        &--left {
          top: 183px;
          left: 80px;
          
        }

        &--right {
          top: 30px;
          right: 90px;
        }

        &--alt {
          background: #ffe9ce;
        }
      }

      @include mobile {
        flex: 0 0 100%;
        width: 100%;
      }
    }

    &__line-top {
      position: absolute;
      z-index: 2;
      top: 18px;
      left: 210px;
    }

    &__tag {
      position: absolute;
      z-index: 3;
      bottom: -40px;
      left: 500px;
    }

    &__decor {
      position: absolute;
      bottom: 10px;
      z-index: 2;
      left: 300px;
    }

    &__line-top,
    &__tag,
    &__decor {
      @include mobile {
        display: none !important;
      }
    }

    &__avatar {
      background: url('#{$image-path}/pages/rmp/avatar.svg') no-repeat top left;
      width: 90px;
      height: 90px;
      background-size: cover;
      text-align: center;
      padding: 5px;
      margin: auto;
    }
  }
}
