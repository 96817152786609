/* ==========================================================================
   #SINGLE SIDE BORDER-RADIUS
   ========================================================================== */

@mixin border-radius($radius: $gl-radius-base, $important: null) {
  @if ($important == true) {
    $important: !important;
  }

  border-radius: $radius $important;
}

@mixin border-top-radius($radius, $important: null) {
  @if ($important == true) {
    $important: !important;
  }

  border-top-left-radius: $radius $important;
  border-top-right-radius: $radius $important;
}

@mixin border-right-radius($radius, $important: null) {
  @if ($important == true) {
    $important: !important;
  }

  border-top-right-radius: $radius $important;
  border-bottom-right-radius: $radius $important;
}

@mixin border-bottom-radius($radius, $important: null) {
  @if ($important == true) {
    $important: !important;
  }

  border-bottom-right-radius: $radius $important;
  border-bottom-left-radius: $radius $important;
}

@mixin border-left-radius($radius, $important: null) {
  @if ($important == true) {
    $important: !important;
  }

  border-top-left-radius: $radius $important;
  border-bottom-left-radius: $radius $important;
}
