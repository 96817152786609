@import ../mixins/flex

body[location='request_school_pages']
    .section
        padding: 5em 0

body[location='index_qa_questions']
    .qa_ctn.mw8
        max-width: 895px

    label.blue_border
        background: #f9fdff
        font-size: 12px
        color: #2a93db
        padding: 4px 6px
        border-radius: 3px
        display: inline-block
        line-height: 1em
        border: 1px solid #d0e5f6

    .question_container
        .red_dot
            top: 3.7em

body[location='setting_pages']
    .personal_info
        .row-fluid.language
            padding-bottom: 40px

.users_top_dropdown_menu
    ul.dropdown-menu
        li
            a
                width: 100%
                display: block

.popover
    .popover-content
        .description
            word-wrap: break-word

.impersonating
    width: 300px
    height: auto
    position: fixed
    z-index: 10000
    background: black
    color: white
    bottom: 0px
    left: 0px
    padding: 10px

.footer_wrap
    .wehcat_icon
        cursor: pointer
        width: 32px
        height: 32px
        display: inline

.loading_overlay
    width: 0px
    height: 0px
    display: none

body[location='oneclass_mentor_question_pages'], body[location='dashboard_easyke_mentors'], body[location='settings_easyke_mentors']
    @media screen and (max-width: $break-small)
        .content
            padding-bottom: 3em

        .footer_wrap
            display: none

//Index loading documents
body.loading[location='index_documents'], body.loading[location='dashboard_users'], body.loading[location='index_qa_questions']
    .loading_overlay
        width: 100%
        height: 100%
        display: block
        background-color: #fff
        opacity: 0.8
        position: absolute
        z-index: 3000
        top: 0px
        position: fixed

        .load_spinner
            background: image-url("#{$image-path}/document/spinner.gif")
            width: 153px
            height: 114px
            margin-left: 50%
            top: 50%
            position: fixed

//index documents
body[location='index_documents']
    min-height: 760px

    .doc-hero
        background: linear-gradient(101deg, #ade0ef, #98b1e7)

        .switch_semesters
            .disabled
                display: none

        .mw10
            @media screen and (max-width: $break-small)
                background: white
                padding: 2rem

    //.terms_list
    //  li
    //    display: inline-block
    //    margin-right: 3rem
    //    a
    //      color: $t-gray-2
    //      &:hover
    //        color: $dark-gray
    //  li.active
    //    a
    //      color: $dark-gray
    //  @media screen and (max-width: $break-small)
    //    li
    //      padding: 0 1.5rem
    //      margin: 0
    .available_courses_list
        //ul
        //  li.show
        //    word-wrap: break-word
        //    @media screen and (min-width: $break-medium)
        //      min-height: 45px
        .tab-content
            overflow: hidden

    //.content
    //  margin-bottom: 0
    //  background: white
    .wd-25-m
        .course-nav
            ul
                li.show
                    width: 100%
                    word-wrap: break-word
                    cursor: pointer

                li:hover
                    background: #ecf7fa

                a
                    color: $text_color

            @media screen and (max-width: 767px)
                padding: 0 1.5rem 0.75rem
                border-bottom: 16px solid #f7f7f7

                li
                    border-bottom: 1px solid $bd-gray
                    background-size: 12px 6px

                ul:last-child
                    li:last-child
                        border-bottom: none

            @media screen and (max-width: $break-small)
                h6
                    font-size: 13px
                    color: $t-gray-1
                    font-weight: normal

        .link-block
            border-radius: 4px

            &:hover
                box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.06)

    .wd-75-m
        @media screen and (max-width: 767px)
            padding: 0 1.5rem

            .wd-40-ns
                p
                    font-size: 14px

        @media screen and (max-width: 767px)
            .f5
                font-size: 13px

        .title:hover
            color: $text_color
            text-decoration: underline !important

        .course_list
            .course_link:hover
                h4
                    text-decoration: underline !important

            .note_item
                @media screen and (min-width: $break-medium)
                    transition: 0.2s
                    max-width: 172px
                    width: 21.6%
                    min-width: 140px
                    border: 1px solid $bd-gray
                    border-radius: 3px

                    &:hover
                        box-shadow: 0 4px 20px rgba(0, 0, 0, 0.08)

                    &:first-child
                        margin-left: 0

                @media screen and (max-width: 767px)
                    padding: 1.5em 0

                &:last-child
                    border: none

                @media screen and (max-width: 932px) and (min-width: 768px)
                    &:nth-child(3)
                        display: none

            h4.title
                @media screen and (max-width: 767px)
                    margin-left: 1em
                    color: #888

            .doc-show-all
                background: #9dc3e9
                display: table

                &:hover
                    color: white

            @media screen and (max-width: 767px)
                padding: 2rem 0 1rem
                border-bottom: 1px solid $bd-gray

        .course:first-child
            @media screen and (max-width: 767px)
                .course_list
                    padding-top: 0

        hr
            margin: 3em 0

            @media screen and (max-width: 767px)
                display: none

    .pa_page
        width: 100%
        padding: 50px
        border: 1px solid $bd-gray
        border-radius: 3px

        span
            color: $text_color
            display: inline-block
            width: 78%
            padding-left: 1em

        .icon_chat
            opacity: 0.8
            width: 20%
            max-width: 60px

        &:hover
            span
                color: $text_color
                text-decoration: underline

        @media screen and (max-width: 767px)
            padding: 1.5rem
            margin-bottom: 1rem

    .doc-panel
        .video_panel
            padding: 0px !important

            .video_container
                background: #4a445d
                padding: 40px 40px 60px
                position: relative

                a.back
                    color: #a9a5b8

                    &:hover
                        color: white
                        text-decoration: underline !important

                #video_player
                    height: 600px

                .label-next
                    color: white
                    background: #847f96
                    position: absolute
                    bottom: -12px
                    padding: 6px 10px
                    border-radius: 30px
                    left: 0
                    right: 0
                    width: 115px
                    margin: auto

        @media screen and (max-width: 767px)
            .documents.listing
                margin-top: 1.5rem
                padding: 0 1.5rem

//Document show
div[location='show_documents']
  background: white !important
  .hide
    display: none !important
  .content
    background: white !important
  .show_documents
    p.description
      color: #666
      font-size: 12px
    h1
      text-transform: capitalize
    .btn.download_desktop_button
      padding: 4px 15px
      width: 100px
      margin: auto
      border-radius: 3px
      @media screen and (max-width: $break-device)
        display: none
  .rate-doc
    .submit_rating
      border-radius: 3px
    .rate-tags
      .rate-tag
        color: #94bce4
        border: 1px solid #94bce4
        padding: 4px 8px
        cursor: pointer
        margin-right: 5px
      .rate-tag.active
        color: white
        background: #94bce4
  .easyke_doc
    height: 780px
    @media screen and (max-width: $break-small)
      height: 78vh

// Scholarships
body[location='index_scholarships']
    .content
        padding-top: 0 !important

    .scholarship-hero
        background: linear-gradient(277deg, #a9e6dd, #c1ceec)

        .flex__item:first-child
            border-right: 3px solid #aedfe2

        .f1-m
            @media screen and (min-width: $break-medium)
                font-size: 2rem

            @media screen and (max-width: $break-medium)
                font-weight: 500

    .nav-tabs
        li a
            font-weight: normal

        li.active a
            border-color: #66d7b4

        @media screen and (max-width: $break-small)
            background: none

    .scholarship_match_link
        box-shadow: 0 1px 4px 0 rgba(220, 217, 217, 0.5)

        .scholarship_amount
            color: #66d7b4

            @media screen and (max-width: $break-small)
                margin-bottom: 8px

        .btn
            border-color: #66d7b4
            color: #66d7b4

body[location='show_scholarships']
    .content
        padding-top: 0 !important

    .scholarship-hero
        background: linear-gradient(277deg, #a9e6dd, #c1ceec)

    .scholarship_match_details
        box-shadow: 0 1px 4px 0 rgba(220, 217, 217, 0.5)

        @media screen and (max-width: $break-small)
            .btn
                font-size: 14px
                padding: 0.6em 1.3em

body.logged_in
    .content
        margin: 0
        text-render: optimizeLegibility
        -webkit-font-smoothing: antialiased

body[location='dashboard_users'] [class^="span"],
body.logged_in [class^="span"]
    @media screen and (max-width: 780px)
        width: 100%
        float: none
        margin-left: 0

//Dashboard
body[location='dashboard_users']
  .content
    background: white
  .dashboard
    @media screen and (min-width: $break-small)
      background: $page_background
      .todo-inner
        border-radius: 6px
        border-color: $bd-gray
        &:hover
          border-color: $green
    @media screen and (max-width: $break-small)
      .mw10
        +flexbox
        +flex-direction(column)
        .to_do_list
          +order(2)
          background: $page_background
          border-radius: 0
          padding: 2rem 1.5rem
        .services_section
          +order(1)
        .services_section, .header_section
          padding: 0 1.5rem

  .header_section
    h2
      margin-bottom: 8px
  .to_do_list
    .to_do
      //margin-right: -4px
      .complete, .incomplete
        margin-right: 8px
      .incomplete
        border: 2px solid $green
        width: 10px
        height: 10px
      .todo-inner
        +flex-direction(column)
        +justify-content(center)
      .todo--content
        .dib
          color: $text-color
      .dib.silver
        text-decoration: line-through

    //Calendar in _widget.sass line 671

    //.services_section
    //  margin-top: 36px
    //  .service-block
    //    margin-right: -4px
    //    width: (100% / 3)
    //    .service
    //      height: 0
    //      padding: 100% 0 0 0
    //      transition: 0.2s
    //      color: $mid-gray-alt
    //      &:hover
    //        box-shadow: 0 4px 20px rgba(0,0,0,0.14)
    //      .service--content
    //        max-width: 100%
    //        max-height: 100%
    //        .red_dot
    //          right: .75rem
    //          top: .75rem
    //          position: absolute
    //          width: 7px
    //          height: 7px
    //          background: #FF4362
    //          border-radius: 50%
    //        .inline-flex
    //          +inline-flex
    //          +flex-direction(column)
    //          +justify-content(center)
    //          +align-items(center)
    //        .service-img
    //          width: 28px
    //          height: 28px
    //          margin-bottom: 8px
    //    @media screen and (min-width: 640px)
    //      width: 25%
    //      .service-img
    //        width: 36px !important
    //        height: 36px !important
    //    @media screen and (min-width: 780px)
    //      width: 20%
    //      .service-img
    //        margin-bottom: 20px !important
    //    @media screen and (min-width: 960px)
    //      width: (100% / 6)
    //  @media screen and (max-width: $break-small)
    //    .row
    //      margin-left: -10px !important
    //      margin-right: -10px !important
    //      .service-block
    //        padding: 10px !important
    //.event
    //  .today
    //    width: 134px
    //    height: 22px
    //    font-size: 16px
    //    font-weight: 500
    //    letter-spacing: 1px
    //    color: #b5b5b5
    //  .tasks
    //    .empty
    //      border-radius: 6px
    //.empty
    //  .add_new_task
    //    font-size: 16px
    //    max-width: 252px
    //.assignment.task
    //  background: #EFFAF6
    //.exam.task
    //  background: #F5F4F9
    //.exam.task
    //.task
    //  padding: 12px
    //  border-radius: 6px
    //  margin-bottom: 8px
    //.time
    //  font-size: 12px
    //  max-width: 120px
    //  @media screen and (max-width: $break-small)
    //    font-size: 11px
    //    line-height: 20px
    //  .delete_task
    //    cursor: pointer
    //.add_new_task--gray
    //  padding: 12px
    //  background: #fafafa
    //  border-radius: 6px
    //  cursor: pointer
    //  &:hover
    //    background: #f7f7f7
    .modal.fade
        display: none

    .modal.add_task
        .date_select
            input
                background: white
                width: 100%

        .kind
            line-height: 25px

        .date_label
            line-height: 46px

        #start_date
            margin-bottom: 0.75rem

        #start_date, #finish_date
            -webkit-appearance: none

        textarea
            -webkit-appearance: none
            min-width: 0

    .overview, .careers, .report
        table
            width: 100%

            td.title
                width: 25%

            td
                vertical-align: top

            hr
                margin: 2.5em 0

            tr:last-child
                td
                    padding-bottom: 1em

                    hr
                        display: none

    .overview
        margin-top: 3em

        .requirements
            h5
                margin-bottom: 1em

            li
                line-height: 1.6em
                padding: 1em 0
                width: 100%

                a
                    color: $text_color

                    &:hover
                        text-decoration: underline !important

            li.grey_text
                cursor: pointer

                &:hover
                    text-decoration: underline

            .btn
                margin-top: 2em

        .major-intro
            h3
                margin-bottom: 2em

            p
                font-size: 16px
                line-height: 1.8em

            .credits, .gallup
                h6
                    margin: 20px 0 10px
                    color: $text_color

    .careers
        .alert
            font-size: 13px

    @media screen and (max-width: $break-device)
        .overview
            position: relative
            margin-top: 1em

            .span3.requirements
                margin: 0 0 1em
                background: white
                padding: 1.75em
                border-radius: 2px
                box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1)

                h5
                    font-size: 1.15em
                    margin: 0

                .muted
                    line-height: 1em
                    font-size: 13px

                li
                    border-bottom: 1px solid $bd-gray
                    font-size: 15px
                    margin: 0

                    &:last-child
                        border: none

                .btn
                    margin-top: 1em

            .span9
                .description
                    h3
                        margin-bottom: 1em

                    td.title
                        padding-bottom: 0

                    td
                        display: block
                        width: 100%

                        h5
                            margin: 0 0 1em

                        p
                            line-height: 1.6em

            .white-block
                padding: 1.75em

    //Highschool Dashboard
    .highschool
        .white-block
            padding: 4em 3em
            transition: 0.2s

            &:hover
                box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2)

            p
                font-size: 15px
                color: $text-color

div[location='course_select_courses']
    .content
        background: white

    .course-top
        .mw8
            max-width: 896px

            .flex
                margin-bottom: 8px

                &:last-child
                    margin-bottom: 0

            .wd-60-ns
                .flex__item.mid-gray.f7
                    @media screen and (min-width: $break-small)
                        font-weight: 500

                .flex
                    @media screen and (max-width: $break-small)
                        padding: 4px 0

        .contact_form
            .btn
                width: 416px

    .terms_list
        li
            display: inline-block

            a
                color: $t-gray-1

                &:hover
                    color: $t-dark

        li.active
            a
                color: $t-dark

        @media screen and (max-width: $break-small)
            li
                padding: 0 1.5rem

    .rating_chart
        .liveGraph
            .bar
                .barval
                    max-height: 125px !important

    .wd-25-m
        .course-nav
            ul
                li.show
                    width: 100%
                    word-wrap: break-word
                    cursor: pointer

                li.active
                    @media screen and (max-width: $break-medium)
                        background: #EBF5F0

            @media screen and (max-width: $break-medium)
                padding: 0 1.5rem 0.75rem
                border-bottom: 16px solid #f7f7f7

                li
                    border-bottom: 1px solid $bd-gray
                    background: image-url("#{$image-path}/icons/caret-light.svg") no-repeat center right
                    background-size: 12px 6px

                ul:last-child
                    li:last-child
                        border-bottom: none

            @media screen and (max-width: $break-small)
                h6
                    font-size: 13px

                    //color: $t-gray-2
                    //font-weight: normal

        .link-block
            border-radius: 4px

            &:hover
                box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.06)

    .wd-75-m
        @media screen and (max-width: $break-medium)
            padding: 0 1.5rem

            .wd-40-ns
                p
                    font-size: 14px

    .courses_list
        .course_item
            ul.comments
                .starbox.review, .starbox.easiness
                    display: inline-block
                    margin-left: 0px

                .easiness
                    margin:
                        right: 0px !important
                        left: 0px !important

    .qa_page
        a
            text-decoration: underline

            &:hover
                text-decoration: underline !important

body[location='course_report_courses']
    .inner-hero
        margin: 0 0 3em

        a.translation
            color: rgba(255, 255, 255, 0.6)

            &:hover
                color: white
                text-decoration: underline !important

        span
            color: rgba(255, 255, 255, 0.4)

        @media screen and (max-width: $break-device)
            margin: 0

    .inner-hero, .block-footer
        .btn
            padding: 10px 30px

    .white-block
        padding: 60px

        @media screen and (max-width: $break-device)
            padding: 1em 1em 2em

    .block-footer
        background: white
        border-top: 1px solid $bd-gray
        padding: 40px
        box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1)

    table.personal_info
        width: 100%

        tr
            border-bottom: 1px solid $bd-gray

            td
                padding: 1em

                @media screen and (max-width: $break-device)
                    display: block
                    width: 100%
                    padding: 0.75em 0

                    p.large_text
                        font-size: 1.2em !important

                @media screen and (min-width: $break-device)
                    border-right: 1px solid $bd-gray

                    &:last-child
                        border: none

                    &:first-child
                        padding-left: 0

            @media screen and (min-width: $break-device)
                border-top: 1px solid $bd-gray

    .text
        margin-top: 40px

        .full-course-list
            margin-bottom: 60px
            border-bottom: 1px solid $bd-gray
            width: 100%

            h3
                margin: 30px 0

            p.course-name
                margin: 30px 0 10px

            p.course-description
                font-size: 14px
                color: #777
                line-height: 1.6em

        .full-course-list.three_columns
            td
                width: 33%
                border-right: 1px solid $bd-gray
                padding-left: 5px
                padding-right: 5px

                &:first-child
                    border-left: 1px solid $bd-gray

        p
            font-size: 16px
            line-height: 1.9em
            color: #333

        .we_choose
            background: #F6F9FF
            padding: 30px

body[location='course_select_pages'], div[location='course_select_courses'], body[location='change_helper_pages'], body[location='course_report_courses']
    .inner-hero, .course-top
        background: linear-gradient(-45deg, #93d8cf 0%, #c8e8d5 100%)

        hr
            border-top: 1px solid rgba(255, 255, 255, 0.1)
            border-left: none
            border-right: none
            margin: 2.5em 0

            @media screen and (max-width: $break-device)
                display: none

    .course_detail
        .star
            width: 32px !important
            height: 32px !important

        .notes
            //.documents
            //  @media screen and (min-width: 767px)
            //    margin: 2em 0 1em
            //.document
            //  //:padding 1.5em 0
            //  @media screen and (min-width: 767px)
            //    margin: 0 3% 0 0
            //    border: 1px solid #e0e0e0
            //    max-width: 172px
            //    width: 21.6%
            //    min-width: 140px
            //    &:hover
            //      box-shadow: 0 4px 20px rgba(0, 0, 0, 0.08)

        #pie_container
            .highcharts-container
                width: 100%

        .ratings
            .rating
                border-bottom: 1px solid $bd-gray
                padding-bottom: 3rem
                margin-bottom: 3rem

                &:last-child
                    margin-bottom: 0
                    padding-bottom: 0
                    border-bottom: none

                .icon_prof
                    @media screen and (max-width: $break-small)
                        width: 32px
                        height: 32px
                        background-size: 32px

            .prof
                .tooltip
                    font-size: 16px !important

            .comments
                background: #f6f7fa

                li
                    list-style: none

                ul.comments_full
                    li
                        list-style: none
                        padding: 0 0 16px

                ul.first_comment
                    padding: 0 0 16px

        #gpa_graph
            width: 95%
            height: 190px

            .highcharts-legend-item
                display: none

            .highcharts-yaxis-title
                display: none

            .highcharts-axis-labels
                display: none

        .liveGraph
            .bar
                width: 32px !important

            .barval, .barlabel
                width: 32px !important

            .barval
                background-color: $b-green !important

            @media screen and (max-width: $break-small)
                height: 100px !important

            .bar
                span
                    color: white
                    padding-top: 5px

            .barlabel
                font-size: 14px
                bottom: (-25px) !important
                color: $t-gray-1
                font-weight: fw5

//Email helper
body[location='email_helper_pages']
    .white-block
        padding: 50px 150px
        margin: 40px auto
        text-render: optimizeLegibility
        -webkit-font-smoothing: antialiased

        p
            font-size: 20px

        a
            font-size: 24px
            color: $text_color

//Settings
body
    .content
        @media screen and (max-width: $break-device)
            input, select, .pull-left
                width: 100% !important

            .pull-left
                float: none

    #password
        width: 0px
        left: -99999px
        position: fixed

    .profile_setting, .notification
        .white_container
            box-shadow: none
            border: 1px solid #e8e8e8

        .container_body
            padding: 40px 50px

            input[type='checkbox']
                margin-top: 4px

            input[type='text'], input[type='password']
                height: 42px
                width: 270px

            label
                cursor: default

            span.orange
                color: #FF5624

            @media screen and (max-width: $break-device)
                padding: 2em 1em

        .gallup_test_menu
            display: none

            @media screen and (max-width: $break-device)
                display: -webkit-box

        .container_footer
            border-top: 1px solid #e8e8e8

        .logout_button
            display: none

            @media screen and (max-width: $break-device)
                display: inline-table

    .profile_setting
        min-height: 600px

//Mentors
.logged_in.mentors_mentor_view
    background: $page_background !important

    .content
        margin-bottom: 0

    .mentor
        margin-top: 40px

        .mentor_container
            img
                width: 180px
                height: 180px

            .qr_code
                width: 140px
                height: 140px

        .mentor_head
            border-bottom: 1px solid $bd-gray
            padding: 40px

        .mentor_details
            .span6
                padding: 40px

            .span6.contact
                border-left: 1px solid $bd-gray

        .inner-hero
            //margin: 40px 0 0
            padding: 50px 0

            .faq
                p
                    font-size: 13px

            h6
                color: #666

            img
                width: 100px
                height: 100px

//Guides
body[location='guide_index'], body[location='show_guides']
    .content
        margin: 0

    .guide-ctn
        position: relative

    .guide-panel
        background: white
        padding: 2em 2em 4em
        margin-left: 200px
        min-height: 100vh

        a
            color: #4fb4a8

        .fluid-wrap
            max-width: 1456px

        @media screen and (max-width: $break-device)
            padding-bottom: 50px

    .guide_nav
        width: 200px
        background: $b-gray-2
        border-right: 1px solid #e8e8e8
        position: absolute
        left: 0
        height: 100%

        .item.category_link
            color: $text_color
            font-weight: bold
            font-size: 16px
            padding: 1.25em 1.5em
            border-bottom: 1px solid #e8e8e8
            cursor: pointer

        .category_link.active
            color: $text_color

        .guide_link
            padding: 0.75em 1.75em
            color: #777
            cursor: pointer
            font-size: 14px
            border-bottom: 1px solid #e8e8e8

            &:hover
                color: $text_color

        .guide_link.active
            color: white
            background: $b-green
            cursor: default

        .sub_menu
            overflow: hidden

    .guides
        margin-top: 40px !important

    @media screen and (min-width: $break-device)
        .mobile-toggle
            display: none

        .guide_nav
            display: block !important

    @media screen and (max-width: $break-device)
        .guide-panel
            padding: 2em 0em

        .desktop-toggle
            display: none

        .mobile-toggle
            postition: absolute
            left: 0
            right: 0
            width: 100%
            height: 40px
            background-color: $b-gray-2
            border-bottom: 1px solid #e8e8e8
            line-height: 40px
            cursor: pointer

        .guide_nav
            background-color: $b-gray-2
            z-index: 1000
            display: none
            height: 100vh
            width: 100%
            text-align: center
            overflow-y: auto

            a
                display: block
                padding: 0.75em 0 !important
                font-size: 1.25em !important
                color: white !important
                font-weight: normal

            .guide_link.active
                color: #777
                background: none

            .guide_link, .category_link
                padding: 1em !important

            .category_link
                font-size: 16px !important

        .guide-panel
            margin-left: 0

        .sub_menu.hide
            display: block !important

//Textbooks
body[location='index_textbook']
    .textbook-hero
        background: linear-gradient(277deg, #b3e6d6, #acd4de)

        .textbook-search
            input
                background: #fff
                border: none
                border-radius: 4px
                height: 46px
                padding-right: 3rem

            .btn
                background: transparent

                svg
                    fill: #62636b

            select
                border: none

    .textbook.item
        padding: 1.5rem 0
        cursor: pointer
        transition: 0.3s
        border-bottom: 1px solid #e8e8e8

        h3
            a:hover, a:focus, a:active
                color: $t-green-dark !important
                text-decoration: none

        .thumbnail
            border: none
            padding: 0
            box-shadow: none

        img
            width: 120px
            height: 172px

        @media screen and (max-width: $break-mid)
            img
                width: 64px
                height: auto

            .details
                h3
                    font-size: 15px !important
                    margin-bottom: 15px !important

            .btn
                display: none

    .panel_footer
        @media screen and (max-width: $break-mid)
            padding: 0 1em

//textbook_show
body[location='show_textbooks']
    .textbookshow
        @media screen and (max-width: $break-small)
            .image_div
                width: 20%

            .flex.mb3
                margin-bottom: 2rem !important

    .book_wrap
        .price:last-child
            border: none

        .price
            span.fract
                margin-left: -6px

            @media screen and (max-width: $break-medium)
                border-bottom: 1px solid $bd-gray

                .textbook_content
                    padding-right: 4px

                .btn
                    padding: 5px 12px
                    font-size: 13px

        .grey_text_primer
            max-width: 595px

        .fetching
            text-align: center

        .loader
            display: block
            margin: 50px auto 10px
            width: 300px
            height: 25px
            border:
                radius: 14px
                bottom: 1px solid #fff
                top: 1px solid #999
            background: #ccc
            overflow: hidden
            position: relative

        .progress-bar
            height: inherit
            width: 0%
            border-radius: inherit
            position: relative
            overflow: hidden
            background: #2ecc71

        .progress-stripes
            width: inherit
            height: inherit
            font-size: 180px
            font-weight: bold
            margin-top: -50px
            letter-spacing: -14px
            color: #27ae60

        .percentage
            position: absolute
            top: 4px
            right: 5px
            font-weight: bold
            font-size: 16px
            color: #fff

body[location='show_guides'], body[location='guide_view_guides']
    .accordion-inner
        ol, ul
            list-style-type: disc

//Payment
body[location='payment_page']
    background: white

    .content
        background: white

.users_payment_page
    .content
        background: white

        @media screen and (max-width: $break-device)
            margin-bottom: 3em !important

    .demo-bar
        display: none !important

    .conditions
        h1
            margin-bottom: 0

        .methods
            margin-top: 3em

            @media screen and (max-width: $break-device)
                padding: 0 1em
                margin-top: 2em

            a
                display: inline-block
                color: white !important
                vertical-align: middle
                transition: 0.2s
                width: 100%
                margin-bottom: 1em

                &:hover
                    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2)

                &:hover, &:focuse, &:active
                    color: white !important

                @media screen and (min-width: $break-device)
                    line-height: 200px
                    width: 200px
                    height: 200px
                    margin: 0

            .price-ctn
                font-size: 24px

                @media screen and (max-width: $break-device)
                    font-size: 18px
                    padding: 1em

            .bg-green
                background: $b-green

            .bg-blue
                background: $b-green
    .payment
        .select_package, .package_info
            .inner-hero
                background: linear-gradient(-45deg, #7DB9DB 0%, #AAE1DB 100%)
                padding: 3em 0 0

                @media screen and (min-width: $break-device)
                    padding: 5.5em 0 0

                h1, p
                    color: white

                @media screen and (max-width: $break-device)
                    p.top
                        display: none

        .packages_tab_content
            .wd-40-ns, .wd-60-ns
                padding: 0 2em

                @media screen and (max-width: $break-device)
                    padding: 0 1em

            .wd-40-ns
                hr
                    border-top: 1px solid #e5e5e5

                .description
                    margin: 1.5em 0

                    @media screen and (max-width: $break-device)
                        margin: 1em 0
                        font-size: 14px

                .price
                    font-size: 3em
                    margin-bottom: 0.5em

                    sup
                        font-size: 0.5em

                select
                    width: 100%

                    @media screen and (max-width: $break-device)
                        margin: 0
                        background-color: white !important

                .price, .btn, hr
                    @media screen and (max-width: $break-device)
                        display: none

            .wd-60-ns
                table
                    background: white
                    width: 100%

                    td
                        border: 1px solid $bd-gray
                        padding: 1em 2em
                        line-height: 30px
                        width: 50%

                        @media screen and (max-width: $break-device)
                            padding: 0.75em 1em
                            width: 75%

                .mobile-show
                    .price
                        width: 100%
                        background: white
                        border: 1px solid $bd-gray
                        margin: 0.5em 0
                        padding: 0.75em
                        font-size: 21px
                        color: $t-green-dark

                    @media screen and (min-width: $break-device)
                        display: none

        .packages_tabs
            .qa_tab
                background: transparent

        .package_info
            .inner-hero
                padding: 5em 0

                a
                    color: white
                    cursor: pointer

                    &:hover
                        text-decoration: underline !important

                @media screen and (max-width: $break-device)
                    padding: 1.5em 0
                    margin-bottom: 0

                    .price, .title
                        display: none

                    h3
                        display: block
                        color: white
                        margin: 0

                    .change_package
                        font-size: 12px

        .choose_payment
            padding: 0 8em

            h2
                @media screen and (min-width: $break-device)
                    display: block
                    font-size: 30px
                    margin-bottom: 1em

            .wd-50-ns
                select
                    width: 100%
                    font-size: 16px

            @media screen and (max-width: $break-device)
                padding: 0

                .wd-50-ns
                    width: 100%
                    padding: 3em 1.5em
                    background: white
                    border-bottom: 1px solid $bd-gray
                    margin: 0.5em 0 0
                    text-align: center

                    h3
                        font-weight: bold
                        margin: 1em 0 0.15em

                    br
                        display: none

                    img
                        width: 36px
                        height: 36px

                    p.small_text
                        max-width: 280px
                        margin: auto

                    a
                        color: #6bc236

//Parents Dashboard
body[location='parent_dashbaord_pages']
    .chart-container.doc_views
        .highcharts-series
            rect
                cursor: pointer

.parents_parent_dashboard
    .content
        padding: 20px 0 60px
        background: #E8EAF0 !important

    .date_section
        small
            margin: 0 5px

            &:first-child
                margin-left: 0

        input
            height: 40px

//Parents Questions
body[location='questions_index_parents'], body[location='questions_show_parents']
    background: white

    .content
        background: white !important

body[location='questions_index_parents'], .parent_dashboard
    .wrap.double_margin_top
        @media screen and (max-width: $break-device)
            margin: 0 !important

    .questions-ctn
        -webkit-font-smoothing: antialiased
        text-rendering: optimizeLegibility

    .qa_question_link, .qa_question_expanded
        padding: 1.5em 0
        border-bottom: 1px solid $bd-gray

        label
            background: #F0F5FB
            font-size: 13px
            color: #6A8FB7
            padding: 4px 6px
            border-radius: 3px
            display: inline-block
            line-height: 1em

    .qa_question_link
        p
            margin: 10px 0

            &:hover
                text-decoration: underline !important

    .qa_question_link.new
        background: #f3f8fe

    .qa_attachments_list
        .qa_attachment_entry.media
            margin_bottom: 10px

//Parents Login
body[location='edit_passwords'], body[location='create_passwords'], body[location='update_passwords']
    .forget_div
        margin: 0px auto
        margin-top: 50px
        width: 500px
        text-align: center

        form
            width: 100% !important

body[location='edit_passwords'], body[location='new_passwords'], body[location='login_pages'] .parents_login_page, body[location='create_passwords'], body[location='update_passwords']
    .info_container
        form
            margin: auto

    .forget_div
        margin-bottom: 30px !important

body[location='documents_views_parents']
    table
        width: 100%

        th, td
            border: 1px solid $bd-gray
            padding: 1em

        th
            background: #F7FAFC

// radio
body[location='index_live_tutorial_bookings'],
body[location='show_tutors'],
body[location='bookings_users'],
body[location='index_payments']
    input[type=radio]
        margin-top: -5px

    .radios
        label
            margin-left: 10px
            margin-right: 18px
            min-width: 78px

        input[type='radio']:checked + label
            font-weight: bold

    .radios.urgent-request
        label
            min-width: 70px
