// Service
$service-margin-y: $gl-spacer-m;
$service-radius: $gl-radius-lg;
$service-shadow: $gl-box-shadow-m;
$service-shadow-hover: 0 4px 20px rgba(0,0,0,0.14);

// Title
$service-title-margin-top: $gl-spacer-xs;
$service-title-font-size-mobile: $gl-font-size-xs;

// Text
$service-text-font-size: $gl-font-size-xs;
$service-text-margin-top: 4px;

.c-service {
  position: relative;
  overflow: hidden;
  text-align: center;
  display: block;

  &--block {
    margin-bottom: rem($service-margin-y);
    background: white;
    border-radius: $service-radius;
    box-shadow: $service-shadow;
    height: 0;
    padding: 100% 0 0 0;

    &:hover {
      box-shadow: $service-shadow-hover;
    }
  }

  &__inner {
    max-width: 100%;
    max-height: 100%;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    align-items: center
  }

  &__title {
    margin-top: rem($service-title-margin-top);
    color: $t-dark;
    font-weight: 500;

    @include mobile {
      font-size: $service-title-font-size-mobile;
    }
  }

  &__text {
    color: $t-gray-1;
    margin-top: rem($service-text-margin-top);
    font-size: $service-text-font-size;
  }

  &__icon {
    @include mobile {
      width: $gl-asset-size-sm;
      height: $gl-asset-size-sm;
    }
  }
}
