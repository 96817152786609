.p-cajob {
  &__intro {
    &-banner {
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      height: 560px;
      &-dots {
        bottom: 20px !important;
        li.slick-active {
          width: 64px !important;
          button {
            width: 64px !important;
            background: $b-green !important;
          }
        }
        li button {
          width: 8px !important;
          height: 8px !important;
          background: $b-green !important;
          border-radius: 4px !important;
          &::before {
            display: none;
          }
        }
      }
    }
    &-center {
      align-self: center;
      @include mobile {
        padding-top: 12px;
      }
    }
  }
  &__suit {
    .items {
      color: #222222;
      background: #fafafa;
      border-radius: 6px;
      padding: 18px 18px;
      margin-top: 46px;
      @include mobile {
        margin-top: 20px;
        margin-left: 0;
        margin-right: 0;

      }
    }
    .img {
      border-radius: 12px !important;
      -webkit-border-radius: 12px !important;
      -moz-border-radius: 12px !important;
      -ms-border-radius: 12px !important;
      -o-border-radius: 12px !important;
    }
  }
  &__content {
    &-intro {
      background-color: #fcfcfc;
      background-position: right bottom;
      background-image: url('#{$image-path}/cajob/content-bg.png');
      background-size: contain;
      background-repeat: no-repeat;
    }
    &-right {
      background-color: #fcfcfc;
      background-position: right bottom;
      background-image: url('#{$image-path}/cajob/content-right-bg.png');
      background-size: contain;
      background-repeat: no-repeat;
    }
  }
  &__jobs {
    &-title {
      color: #222222;
      font-weight: 500;
      line-height: 1.5;
      margin: 34px 0 16px;
    }
    &-img {
      // height: 240px;
      border-radius: 12px;
    }
  }
  &__plan {
    background: #00af98;
    @include mobile {
      padding-bottom: 2rem !important;
    }
    &-item {
      padding: 20px;
      background: rgba(255, 255, 255, 0.4);
      box-shadow: 12px 28px 90px rgba(0, 0, 0, 0.07);
      border-radius: 12px;
    }
  }
  &__qrcode {
    position: absolute;
    background: #ffffff;
    box-shadow: 0px 4px 26px rgba(0, 0, 0, 0.05);
    border-radius: 24px;
    padding: 32px 48px;
    text-align: center;
    left: 0;
    z-index: 2;
  }
  &__project {
    background: #f9fafa;
    .left_center {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      @include mobile{
        align-items: start;
      }
    }
    .pl {
      margin-left: 30%;
      @include mobile {
        margin-left: 0;
      }
    }
    .box {
      padding: 20px 28px;
      width: 368px;
      background: rgba(255, 255, 255, 0.9);
      border: 1.2px solid #ffffff;
      box-sizing: border-box;
      box-shadow: 0px 68px 120px rgba(45, 71, 71, 0.06);
      backdrop-filter: blur(12px);
      border-radius: 12px;
      margin-bottom: 40px;
      @include mobile{
        width:100%;
      }
    }
    .icon {
      filter: drop-shadow(0px 13px 16px rgba(49, 0, 190, 0.12));
    }
  }
  &__plan_pra {
    background: #3e4746;
    color: #ffffff;
    .box {
      padding: 20px;
      background: rgba(255, 255, 255, 0.8);
      border: 1.2px solid #ffffff;
      box-sizing: border-box;
      box-shadow: 0px 32px 80px rgba(45, 71, 71, 0.06);
      backdrop-filter: blur(12px);
      border-radius: 12px;
    }
    .hr {
      border-top: 1px solid rgba(255, 255, 255, 0.2);
    }
    .rc_bg{
      margin-top: 40px;
      position: relative;
      z-index: 0;
    }
    .cz{
      position: relative;
      z-index: 1;
      top:-60px;
    }
    .m_box {
      padding: 16px 24px 15px;
      background: linear-gradient(
        180deg,
        rgba(255, 255, 255, 0.36) -20.97%,
        rgba(255, 255, 255, 0.18) 100%
      );
      border: 1.5px solid rgba(255, 255, 255, 0.52);
      box-sizing: border-box;
      box-shadow: 0px 56px 68px rgba(25, 43, 40, 0.1);
      backdrop-filter: blur(8px);
      border-radius: 12px;
      img{
        margin-bottom: 10px;
      }
    }
  }
}

.pc-nowrap {
  @include desktop {
    white-space: nowrap;
  }
}
