// Task
$task-spacing: $gl-spacer-xs;
$task-icon-size: 10px;
$task-border-radius: $gl-radius-md;

.p-highshcool-tutoring {
  .player_box {
    margin: 0 auto;
    width: 80%;
    height: 547px;
    @include mobile {
      width: 100%;
      height: 200px;
    }
  }

  // Header
  .lh45 {
    line-height: 2.8125rem;
    @include mobile {
      line-height: 1.5;
    }
  }
  &__intro {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    height: 560px;
    &__ib {
      background-image: url('#{$image-path}/pages/high-school-academic/ib_banner_bg.jpg');
    }
    &__ap {
      background-image: url('#{$image-path}/pages/high-school-academic/ap_banner_bg.jpg');
    }
    &__na {
      background-image: url('#{$image-path}/pages/high-school-academic/na_banner_bg.jpg');
    }
    &__hero {
      position: relative;
      height: 100%;
      // height: rem($section-hero-height-mobile);

      // @include desktop {
      //   height: rem($section-hero-height);
      // }
    }
  }

  &__info {
    h2 {
      font-weight: 500;
      //font-size: 2.625rem;
      line-height: 150%;
    }
    .text {
      font-weight: 500;
      @include mobile {
        font-weight: 400;
        padding: 0;
      }
    }
    .item-border {
      @include mobile {
        border: 2px solid $b-green;
        border-radius: 0.75rem;
        flex: 0 0 47%;
        margin-bottom: 1.5rem;
        padding-top: 1rem;
      }
    }
    .item {
      background: url('#{$image-path}/pages/high-school-academic/item.svg')
        no-repeat center;
      height: 21.875rem;
      @include mobile {
        background: none;
        height: 11rem;
      }
      .img {
        display: block;
        margin: 1.5rem auto 0;
        // width:183px;
        height: 183px;
        position: relative;
        @include mobile {
          height: 72px;
          margin-top: 0;
        }
      }
    }

    .item-opposite {
      background: url('#{$image-path}/pages/high-school-academic/item-opposite.svg')
        no-repeat center;
      @include mobile {
        background: none;
        height: 11rem;
      }
      .img {
        display: block;
        // width:183px;
        height: 183px;
        position: relative;
        @include mobile {
          height: 72px
        }
      }
    }
  }

  &__subject {
    background: $b-gray-1;
    .flex24 {
      flex: 0 0 24% !important;
    }

    .flex18 {
      flex: 0 0 18% !important;
    }

    .item {
      flex: 0 0 20%;
      font-size: 1.5rem;
      margin-bottom: 4rem;
      @include mobile {
        flex: 0 0 50% !important;
        padding-top: 1rem;
        margin-bottom: 1rem;
      }
      h3 {
        font-weight: 500;
        @include mobile {
          font-weight: 400;
        }
      }

      span {
        font-family: PingFang SC;
        font-size: 1rem;
      }
    }
  }

  // Service
  &__tutors {
    .border-r12 {
      border-radius: 0.75rem;
      @include mobile {
        padding: 1.5rem 1rem 2rem;
      }
    }

    background: url('#{$image-path}/pages/homework/world-bg.svg') no-repeat
      center;

    img {
      border: 1rem solid rgba(18, 204, 179, 0.06) !important;
      border-radius: 50%;
      @include mobile {
        border: 0.5rem solid rgba(18, 204, 179, 0.06) !important;
      }
    }
    .tutors__avatar {
      height: 10.5rem;
      width: 10.5rem;
      position: relative;
      margin: 0 auto;
      @include mobile {
        width: 6rem;
        height: 6rem;
      }
    }

    &__line {
      width: 3rem;
      background: $t-green;
      height: 6px;
      border-radius: 3px;
      margin: 1rem auto;
      @include mobile {
        width: 1.5rem;
        height: 3px;
        margin: 0.5rem auto;
      }
    }
    .center-text {
      @include desktop {
        margin: 8rem 0;
      }
    }
  }

  &__essay {
    color: white;
    background-color: $b-green-dark;
    background-image: url('#{$image-path}/pages/famous/recruit-bg.png');
    background-size: 100% 100%;
    @include mobile {
      background-image: none;
    }
  }

  &__sample {
    background: url('#{$image-path}/pages/high-school-academic/half-ring.png')
      no-repeat right bottom;
    @include mobile {
      background: none;
    }
    .essay-img {
      width: 422px;
      height: 422px;
      @include mobile {
        width: 260px;
        height: 260px;
      }
    }
    .left-ring {
      display: inline-block;
      box-shadow: 0 0 0 3.5rem $b-green, 0 0 0 7rem rgba(18, 204, 179, 0.16);
      width: 15rem;
      height: 15rem;
      border-radius: 50%;
      font-size: 1.75rem;
      color: $t-green-dark;
      text-align: center;

      @include mobile {
        width: 12rem;
        height: 12rem;
        box-shadow: 0 0 0 2.5rem $b-green, 0 0 0 5rem rgba(18, 204, 179, 0.16);
        margin: 0 auto;
        margin-top: 0 !important;
        font-size: 1.125rem;
      }
    }
    .line {
      @include mobile {
        display: none;
      }
    }
    .list {
      @include mobile {
        margin-top: 11rem;
      }
    }
    .mt-4 {
      margin-top: -4rem;
    }

    &__point {
      width: 6px;
      height: 6px;
      background: $b-green;
      border-radius: 50%;
      margin-right: 0.2rem;
      margin-bottom: 2px;
    }

    .step {
      flex: 0 0 100%;
      height: 10rem;
      border: 1px solid $b-green;
      border-radius: 0.875rem;
      margin-bottom: 1rem;
      padding: 2rem;

      .item {
        margin-top: 1.5rem;
        justify-content: space-between;
        font-size: 1rem;
        padding-right: 4rem;
        @include mobile {
          padding-right: 0;
        }
      }

      span {
        display: inline-block;
        font-size: 1.5rem;
        font-weight: 500;
        margin-left: -3.5rem;
      }
    }
  }

  &__extended {
    background: $b-gray-1;

    .icon {
      height: 44px;
      margin-bottom: 1em;
      position: relative;
    }
  }

  &__extended-tutor {
    background: $b-orange-2;

    .tutor_img{
      width: 119px;
      height: 119px;
      position: relative;
      margin:0 auto;
      img {
        border-radius: 50%;
      }
      @include mobile {
        height: 64px;
        width: 64px;
      }
    }


    .item {
      background: white;
      padding: 2rem 1.5rem;
      border-radius: 10px;
      @include mobile {
        padding: 2rem 0.5rem;
      }
      h4 {
        font-weight: 500;
        @include mobile {
          font-weight: 400;
        }
      }
    }

    .text {
      color: $t-gray-1;
      font-size: 1rem;
    }
  }
  &__ap {
    &__weakness {
      background-image: url('#{$image-path}/pages/high-school-academic/ap-weakness.png');
      background-color: $b-orange-2;
      background-size: cover;
      @include mobile {
        background: $b-orange-2;
      }
    }
  }

  &__na {
    &__tutor {
      background: url('#{$image-path}/pages/high-school-academic/world.png');
      background-size: cover;
      img {
        border-radius: 50%;
      }

      .item {
        background: white;
        padding: 2rem 1.5rem;
        border-radius: 10px;
      }

      .text {
        color: $t-gray-1;
        font-size: 1rem;
      }
    }
    &__sample {
      background: $b-gray-1;
      p {
        font-weight: 500;
        @include mobile {
          font-weight: 400;
        }
      }
      .img-h{
        height: 520px;
        position: relative;
        @include mobile {
          height: 380px;
        }
        img {
          border-radius: 1rem !important;
          -webkit-border-radius: 1rem !important;
          -moz-border-radius: 1rem !important;
          -ms-border-radius: 1rem !important;
          -o-border-radius: 1rem !important;
        }
      }
    }
    &__extended {
      .c-slider {
        .slick-dots {
          button:before {
            font-size: rem($slider-dot-size);
          }

          .slick-active button:before {
            color: $t-green;
          }
        }

        .slick-active {
          &:hover {
            transform: scale(1.1) !important;
          }
          &.slick-current {
            > div {
              opacity: 1;
              margin-left: $gl-spacer-m;
              margin-right: $gl-spacer-m;
              transform: scale(1) !important;
              // box-shadow: 0 4px 30px 0 rgba(0, 0, 0, 0.20);
            }
          }
          > div {
            opacity: 0.8;
            margin-left: $gl-spacer-m;
            margin-right: $gl-spacer-m;
          }
        }
        .slick-next:before,
        .slick-prev:before {
          color: $slider-dot-color;
        }
        .swiper-button-next:before,
        .swiper-button-prev:before {
          color: $slider-dot-color;
        }
        .swiper-button-prev {
          &:after {
            content: url('#{$image-path}/pages/iss/chevron-left.svg');
          }
        }
        .swiper-button-next {
          &:after {
            content: url('#{$image-path}/pages/iss/chevron-right.svg');
          }
        }
        &--lt {
          .slick-prev {
            &:before {
              content: url('#{$image-path}/pages/high-school-academic/chevron-left.svg');
            }
          }

          .slick-next {
            &:before {
              content: url('#{$image-path}/pages/high-school-academic/chevron-right.svg');
            }
          }
        }

        &__item {
          &--center {
            opacity: 0.8;
            margin-left: $gl-spacer-m;
            margin-right: $gl-spacer-m;

            &.slick-current {
              transform: scale(1.3);
              opacity: 1;
              // box-shadow: 0 4px 30px 0 rgba(0, 0, 0, 0.20);
            }
          }
        }

        @include mobile {
          zoom: 0.5;
        }
      }
      .n-card {
        background-color: rgba(0, 0, 0, 0.5) !important;
        margin-top: -23rem;
      }
      .mobile-card-c {
        overflow: auto;
        white-space: nowrap;
        &::-webkit-scrollbar {
          display: none;
          width: 0 !important
        }
        -ms-overflow-style: none;
        overflow: -moz-scrollbars-none;
      }
      .card {
        height: 20rem;
        margin-bottom: 3rem;
        padding: 33% $gl-spacer-m;
        text-align: center;
        background: $theme-light-lt;
        border-radius: 16px;
        box-sizing: border-box;
        @include mobile {
          width: 250px;
          margin-right: 2rem;
        }
        .avatar {
          margin: auto;
          width: 2rem;
          height: 2rem;
          margin-bottom: $gl-spacer-s;
        }
        &__en {
          font-size: 11px;
        }

        &__title {
          font-size: 11px;
        }
      }
    }
    &__gdc {
      background: $b-gray-1;
      .item {
        width: 33.3333%;
        img {
          width: 100%;
        }
        p {
          position: relative;
          text-align: left;
          line-height: 3rem;
          padding-left: 2px;
          margin-top: -3.375rem;
          background: black;
          color: white;
        }
      }
    }

    &__service-subjects {
      background: $b-gray-1;
      &__line {
        width: 6px;
        background: $b-green;
        height: 1.2rem;
        border-radius: 3px;
      }
      .biology {
        background-image: url('#{$image-path}/pages/high-school-academic/service-subjects/biology.png');
        @include mobile {
          background-image: none;
        }
      }
      .business {
        background-image: url('#{$image-path}/pages/high-school-academic/service-subjects/business.png');
        @include mobile {
          background-image: none;
        }
      }
      .physics {
        background-image: url('#{$image-path}/pages/high-school-academic/service-subjects/physics.png');
        @include mobile {
          background-image: none;
        }
      }
      .english {
        background-image: url('#{$image-path}/pages/high-school-academic/service-subjects/english.png');
        @include mobile {
          background-image: none;
        }
      }
      .mathematics {
        background-image: url('#{$image-path}/pages/high-school-academic/service-subjects/math.png');
        @include mobile {
          background-image: none;
        }
      }
      .chemistry {
        background-image: url('#{$image-path}/pages/high-school-academic/service-subjects/chemistry.png');
        @include mobile {
          background-image: none;
        }
      }
      .history {
        background-image: url('#{$image-path}/pages/high-school-academic/service-subjects/history.png');
        @include mobile {
          background-image: none;
        }
      }
      .esl {
        background-image: url('#{$image-path}/pages/high-school-academic/service-subjects/esl.png');
        @include mobile {
          background-image: none;
        }
      }
      .social {
        background-image: url('#{$image-path}/pages/high-school-academic/service-subjects/social.png');
        @include mobile {
          background-image: none;
        }
      }
      .economic {
        background-image: url('#{$image-path}/pages/high-school-academic/service-subjects/economic.png');
        @include mobile {
          background-image: none;
        }
      }
      .geography {
        background-image: url('#{$image-path}/pages/high-school-academic/service-subjects/geography.png');
        @include mobile {
          background-image: none;
        }
      }
      .item {
        background-position: bottom right;
        background-repeat: no-repeat;
        height: 100%;
        // min-height: 14rem;
        margin-bottom: 1rem;
        background-color:$t-light;
        border-radius: 0.875rem;
        box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.06);
        @include mobile {
          min-height: auto;
          margin-bottom: 0;
          background-color: rgba(0, 0, 0, 0);
          box-shadow: none;
        }
        h3 {
          font-size: 1.125rem;
          font-weight: 500;
          margin: 0 6px;
        }
        span {
          font-family: 'PingFang SC';
          font-size: 0.75rem;
        }
        .n-item {
          padding-left: 2px;
          padding-right: 2px;
          h3 {
            margin: 0;
            font-size: 1rem;
            font-weight: 400;
          }
          span {
            color: $t-gray-1;
          }
        }
      }
    }
  }
}
