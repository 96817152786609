// Page section with background color
.c-lt-section {
  padding: rem($gl-spacer-m) 0;

  @include desktop {
    padding: rem($gl-spacer-xl) 0;
  }

  &--dk {
    background: tint($b-gray-2, 80%);
  }

  // Desktop only
  &--dk-md {
    @include desktop {
      background: tint($b-gray-2, 80%);
    }
  }

  &--dkr {
    background: $b-gray-2;
  }
}
