/* ==========================================================================
   #FORM MIXINS
   ========================================================================== */
// [TODO] Add docs
@mixin form-field-variant($color, $bg-color, $placeholder-color, $border-color, $focus-border-color) {
  color: $color;
  background-color: $bg-color;
  border-color: $border-color;

  &::placeholder {
    color: $placeholder-color;
  }

  @if $focus-border-color != null {
    &:focus {
      border-color: $focus-border-color;
      outline: 0;
      box-shadow: none;
    }
  }
}
