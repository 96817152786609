// Timeline

$c-timeline-line-width: 2px;
$c-timeline-dot-size: rem(12px);

.c-timeline {
  position: relative;

  &__line {
    width: $c-timeline-line-width;
    height: 100%;
    background: $bd-gray;
    margin: auto;
  }

  &__dot {
    width: $c-timeline-dot-size;
    height: $c-timeline-dot-size;
    background-color: $b-green;
    border-radius: 100%;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    z-index: $gl-z-index-1;
  }
}
