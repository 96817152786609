/* ==========================================================================
   #LIST
   ========================================================================== */

%list-inline {
  display: inline-block;

  &:after {
    content: '\00B7';
    padding: 0 4px;
    font-size: $gl-font-size-base;
  }

  &:last-child {
    &:after {
      content: none;
    }
  }  
}

.c-list {
  @include list-unstyled();
  
  //Horizontal List
  &__item {
    &--inline {
      display: inline-block;

      &:after {
        content: '\00B7';
        padding: 0 4px;
        font-size: $gl-font-size-base;
        color: $theme-dark-lt;
      }

      &-space {
        &:after {
          content: ' ';
          padding: 0 2px;
          font-size: $gl-font-size-base;
          color: $theme-dark-lt;
        }
      }

      &:last-child {
        &:after {
          content: none;
        }
      }
    }
  }
}