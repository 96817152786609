.positioner
  position: relative
  display: inline-block
  line-height: 0

.starbox
  .colorbar
    z-index: 0
    position: absolute
    top: 0
    bottom: 0
    left: 0
.starbox
  .ghost
    z-index: 0
    position: absolute
    top: 0
    bottom: 0
    left: 0

.starbox
  .stars
    display: inline-block

.starbox
  .stars
    .star_holder
      position: relative
      z-index: 1


.starbox
  .stars
    .star_holder
      .star
        display: inline-block
        vertical-align: baseline
        background-repeat: no-repeat


/* Override with your own image and size */
.starbox
  .stars
    .star_holder
      .star
        background-image: url('#{$image-path}/icons/jstar.svg')
        width: 24px
        height: 24px
        background-size: contain

/* Override with your own colours */
.starbox
  .stars
    background: #cccccc
.starbox
  .colorbar
    background: $b-green
.starbox
  .rated
    .colorbar
      background: $b-green
.starbox
  .ghost
    background: #a1a1a1

//Course helper
body[location='change_helper_pages']
  .star
    background-image: url('#{$image-path}/icons/jstar.svg') !important
    width: 32px !important
    height: 32px !important
