// If you need to make a quick fix, you can do it here.
// Later when you have proper time, you can move the
// fix into the proper structure/organization
// See https://goo.gl/uABajm

// the intercom button should be positioned above the mobile toolbar
@include mobile {
  #intercom-container .intercom-launcher-frame,
  #intercom-container .intercom-launcher-discovery-frame,
  #intercom-container .intercom-messenger-frame {
    margin-bottom: 64px;
  }
}

#intercom-container {
  z-index: 99998 !important;
}

// Prevent Wechat browser text resize
body {
  @include mobile {
    -webkit-text-size-adjust: 100% !important;
  }
  // Black and white effects
  //-webkit-filter: grayscale(1);
  //-webkit-filter: grayscale(100%);
  //-moz-filter: grayscale(100%);
  //-ms-filter: grayscale(100%);
  //-o-filter: grayscale(100%);
  //filter: grayscale(100%);
}

// Have only one breakpoint sizes, desktop and tablet view.
// If larger than tablet, then set min-width to 1140px
.c-navbar,
.l-container-fixed,
.c-footer {
  @include media('>=md') {
    min-width: 1104px;
  }
}

// Admin Panel body container
.l-container-fixed-lg {
  min-width: 1200px;
  height: 100%;
  overflow-y: hidden;
}

// Flex body container
.l-container-flex {
  height: calc(100% - 65px);
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

// Grey background on login and signup pages
.sessions_new,
.users_signup {
  background: $b-gray-2;
}

// Grey background on payment steps pages
.users_step1,
.users_step2,
.users_step3,
.users_step4 {
  background: $b-gray-2;
}

// Login form should not be scrollable
.sessions_new
.tab-content {
  overflow: visible;
}

// Hide navbar on mentor login page
.easyke_mentors_home {
  .js-fixed {
    display: none;
  }
}

// Is Hidden
.is-hidden, .hide {
  display: none;
}

// [RESET] hide navbar and footer on mobile app parents updates
.pages_app_faq,
.pages_parent_updates {
  .intercom-launcher-frame,
  #intercom-container,
  .c-sbar,
  .c-bar,
  .c-navbar,
  .c-footer,
  .js-fixed {
    display: none;
  }
}

// Need to update lists
li {
  list-style: none;
}

// Hack: hide footer on booking show;
body[location='show_bookings'],
body[location='choose_subject_bookings'],
body[location='choose_date_bookings'],
body[location='add_details_bookings'],
body[location='confirm_bookings'] {
  .c-footer {
    display: none;
  }
}

// Hide intercom on lt booking show
body[location='show_bookings'] {
  .intercom-launcher-frame,
  #intercom-container {
    display: none;
  }
}

// Fix admin user updates line break
.updates__content {
  p,
  .media-heading,
  .comment-text,
  .update_content {
      word-break: break-word;
  }
}
