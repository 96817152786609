// 定校解析
$img-path: '/images/pages/offer-interpretation';

.p-offer {
  &__intro {
    background: url('#{$img-path}/intro_bg_new.jpg') no-repeat center;
    background-size: cover;
    height: 560px;
    &__hero {
      height: 100%;
    }
    &__ft {
      margin-top: 24px;
      font-size: 32px;
      line-height: 150%;
      @include mobile {
        font-size: 24px;
      }
    }
    &__ft42 {
      font-size: 42px;
    }
  }
  &__info {
    p {
      max-width: 760px;
    }
    &__bg {
      height: 560px;
      @include mobile {
        display: none;
      }
    }
    &__box {
      background: url('#{$img-path}/box-bg_new.png') #{$b-green} no-repeat;
      box-shadow: 0px 32px 40px rgba(37, 130, 117, 0.14);
      border-radius: 12px;
      height: 480px;
      position: relative;
      left: 100px;
      z-index: 3;
      top: 40px;
      padding: 50px;
      @media screen and (max-width: 640px) {
        position: relative;
        left: 0px;
        z-index: 3;
        top: 0px;
        height: auto;
      }
    }
    &_mb {
      margin-bottom: 40px;
    }
  }
  &__individuation {
    background: url('#{$img-path}/indiv_bg.png') #{$b-orange-2} no-repeat;
    background-size: cover;
    &_box_m {
      background:$t-light;
      border-radius: 12px;
      margin-bottom: 24px;
      padding: 0;
      .active {
        // padding-left: 32px;
        height: 100%;
        background: url('#{$img-path}/active_bg.png') no-repeat $b-green;
        background-size: cover;
        border-radius: 12px;
      }
    }
    &_box {
      //width: 1200px;
      background: $t-light;
      box-shadow: 0px 32px 40px rgba(37, 130, 117, 0.08);
      border-radius: 12px;
      @include mobile {
        background: rgba(0, 0, 0, 0);
        box-shadow: none;
      }
      .box_m {
        background: $t-light;
        border-radius: 8px;
        padding: 24px 16px;
        height: 250px;
      }
      .border_m {
        margin: 10px 0;
        width: 20px;
        height: 3px;
        background: $t-dark;
        border-radius: 4px;
      }
      .pd0 {
        padding: 0;
      }
      &_layout {
        padding: 100px 0;
        .border {
          border-right: 1px solid rgba(0, 0, 0, 0.12);
          &:nth-child(3) {
            border: none;
          }
        }
        .num {
          margin: 56px 0 32px;
          font-size: 42px;
          color: $t-green-dark;
        }
        .title {
          font-weight: 600;
          font-size: 20px;
          line-height: 150%;
          color: $t-dark;
        }
      }
      &_bg {
        .active {
          // padding-left: 32px;
          height: 100%;
          background: url('#{$img-path}/active_bg.png') no-repeat $b-green;
          background-size: cover;
          border-radius: 12px;
          @include mobile {
            margin: 0 0.5rem;
            background: $t-light;
          }
          .p-offer__individuation_box_layout {
            padding: 60px 32px;
            color: $t-light;
            @include mobile {
              padding: 1.5rem 1rem;
              color: black;
            }
            .border {
              border: none;
            }
            .num {
              margin: 32px 0 16px;
              font-size: 42px;
              color: $t-light;
              @include mobile {
                color: black;
              }
            }
            .title {
              margin-bottom: 20px;
              font-weight: 600;
              font-size: 20px;
              line-height: 150%;
              color: $t-light;
              @include mobile {
                font-size: 15px;
                margin-top: 0.5rem;
                color: black;
              }
            }
            .text {
              font-size: 16px;
              line-height: 150%;
              color: $t-light;
              @include mobile {
                font-size: 12px;
                color: black;
              }
            }
          }
        }
        .list {
          @include mobile {
            margin-top: 0.75rem;
          }
        }
      }
    }
  }

  &_report {
    // height: 620px;
    position: relative;
    &_bg {
      //background: url('#{$img-path}/report-bg.png') no-repeat left;
      // background-size: contain;
      position: relative;
      @media screen and (max-width: 640px) {
        display: none;
      }
    }
    &_report {
      position: absolute;
      top: 80px;
      left: 110px;
      width: 664px;
      @media screen and (max-width: 640px) {
        position: relative;
        top: 0px;
        left: 0px;
        margin-bottom: 20px;
      }
    }
    &_line {
      position: absolute;
      right: -111px;
      top: 17px;
      @media screen and (max-width: 640px) {
        display: none;
      }
    }
    &_box {
      padding: 24px;
      background: $t-light;
      border: 2px solid #eaeaea;
      box-shadow: -41px 41px 36px rgba(0, 0, 0, 0.0545509);
      border-radius: 6px;
      margin-bottom: 40px;
      .title {
        display: flex;
        &_text {
          font-size: 18px;
          line-height: 150%;
          color: $t-dark;
        }
        .icon {
          background: $b-green;
          width: 6px;
          height: 20px;
          border-radius: 4px;
          margin-right: 10px;
        }
      }

      p {
        margin-top: 20px;
        font-size: 14px;
        line-height: 150%;
        color: $t-gray-1;
        margin-bottom: 0;
      }
    }
  }
}
