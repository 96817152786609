/* @group Base */


%chosen-field {
    background-color: white;
    border: 1px solid $bd-gray-dark;
    display: block;
    overflow: hidden;
    white-space: nowrap;
    position: relative;
    padding: 0.75em;
    color: $t-dark;
    text-decoration: none;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    transition: 0.1s;
}

.chosen-container {
  position: relative;
  display: inline-block;
  zoom: 1;
  *display: inline;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  vertical-align: middle;

  .chosen-drop {
    background: #fff;
    border-top: 0;
    position: absolute;
    border: 1px solid $bd-gray-dark;
    border-top: 0;
    top: 100%;
    left: -9999px;
    -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
    -moz-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
    z-index: 99999;
  }
}

.chosen-with-drop {
  .chosen-drop {
    left: 0;
  }
}

.chosen-hidden {
  display: none !important;
}

/* @end */

/* @group Single Chosen */

.chosen-container-single {
  .chosen-single {
    @extend %chosen-field;
  }

  .chosen-default {
    color: $t-dark;
  }

  .chosen-single {
    span {
      margin: {
        right: 15px;
      };
      font-size: 14px;
      font-weight: 400;
      display: block;
      overflow: hidden;
      white-space: nowrap;
      -o-text-overflow: ellipsis;
      -ms-text-overflow: ellipsis;
      text-overflow: ellipsis;
    }
  }

  &.chosen-disabled .chosen-single abbr:hover {
    background-position: (-42px) -10px;
  }

  .chosen-single div {
    position: absolute;
    right: 0.75em;
    top: 0;
    display: block;
    height: 100%;
    width: 10px;

    b {
      background: url(#{$image-path}/icons/caret.svg) no-repeat center;
      width: 10px;
      height: 100%;
      background-size: 16px;
      display: block;
      text-align: right;
    }
  }

  .chosen-search {
    padding: 3px 4px;
    position: relative;
    margin: 0;
    white-space: nowrap;
    z-index: 1010;

    input {
      border: 1px solid #DDDEE0;
      width: 100% !important;
      padding: $gl-input-sm-padding-y;
      height: 40px;
      border-radius: 0;
      box-sizing: border-box;
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;

      &:focus {
        outline: none;
        border-color: $b-green;
        box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(82,168,236,.6);
        -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(82,168,236,.6);
        -moz-box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(82,168,236,.6);
      }
    }
  }

  .chosen-drop {
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
    width: 100% !important;
  }

  .chosen-results {
    font-size: 14px;
  }
}

.chosen-container-single.pa {
  .chosen-single {
    padding: 14px 16px
  }
}
/* @end */

.chosen-container-single-nosearch .chosen-search input {
  position: absolute;
  left: -9000px;
}

/* @group Multi Chosen */

.chosen-container-multi .chosen-choices {
  @extend %chosen-field;

  cursor: text;
  overflow: hidden;
  position: relative;

  li {
    display: inline-block;
    list-style: none;
  }

  .search-field {
    white-space: nowrap;
    margin: 0;
    padding: 0;

    input {
      color: #555658;
      background: transparent !important;
      border: 0 !important;
      font-family: sans-serif;
      font-size: 100%;
      height: 15px;
      padding: 5px;
      margin: 1px 0;
      outline: 0;
      -webkit-box-shadow: none;
      -moz-box-shadow: none;
      box-shadow: none;
    }

    .default {
      color: #999;
    }
  }

  .search-choice {
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    border-radius: 2px;
    background: #fff;
    border: 1px solid $bd-gray-dark;
    color: #555658;
    line-height: 13px;
    padding: 3px 20px 3px 5px;
    margin: 3px 0 3px 5px;
    position: relative;
    cursor: default;
  }

  .search-choice-focus {
    background: #D9DADC;
  }

  .search-choice .search-choice-close {
    display: block;
    position: absolute;
    right: 3px;
    top: 4px;
    width: 12px;
    height: 12px;
    background-size: 12px !important;
    background: url(#{$image-path}/icons/x.svg) no-repeat center;
  }
}

/* @end */

/* @group Results */

.chosen-container .chosen-results {
  margin: 0 0 4px;
  padding: 0;
  position: relative;
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

.chosen-container-multi .chosen-results {
  margin: (-1px) 0 0;
  padding: 0;
}

.chosen-container .chosen-results {
  li {
    display: none;
    line-height: 1.6em;
    padding: 8px 15px;
    margin: 0;
    list-style: none;
  }

  .active-result {
    cursor: pointer;
    display: list-item;
    color: #555658;
  }

  .highlighted {
    background: $theme-primary-ltr;
  }

  li em {
    background: #F2F3F5;
    font-style: normal;
  }

  .highlighted em {
    background: transparent;
  }

  .no-results {
    background: #F4F5F7;
    display: list-item;
    color: #88898B;
  }

  .group-result {
    cursor: default;
    color: #88898B;
    font-weight: bold;
  }

  .group-option {
    padding-left: 15px;
  }
}

.chosen-container-multi .chosen-drop .result-selected {
  display: none;
}

.chosen-container {
  .chosen-results-scroll {
    background: white;
    margin: 0 4px;
    position: absolute;
    text-align: center;
    width: 321px;

    /* This should by dynamic with js */
    z-index: 1;

    span {
      display: inline-block;
      height: 17px;
      text-indent: -5000px;
      width: 9px;
    }
  }

  .chosen-results-scroll-down {
    bottom: 0;

    span {
      background: url("#{$image-path}/caret.svg") no-repeat top right -6px;
    }
  }

  .chosen-results-scroll-up span {
    background: url("#{$image-path}/icons/caret-tri.png") no-repeat -22px -3px;
  }
}

/* @end */

/* @group Active */

.chosen-container-active {
  .chosen-single {
    border: 1px solid #DDDEE0;
  }

  .chosen-single-with-drop {
    background: #fff;
    -webkit-border-bottom-left-radius: 0;
    -webkit-border-bottom-right-radius: 0;
    -moz-border-radius-bottomleft: 0;
    -moz-border-radius-bottomright: 0;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  .chosen-choices {
    border: 1px solid #DDDEE0;

    .search-field input {
      color: #111 !important;
    }
  }
}

/* @end */

/* @group Disabled Support */

.chosen-disabled {
  cursor: default;

  .chosen-single {
    opacity: $gl-disabled-opacity;

    b {
      background: url(#{$image-path}/icons/caret-light.svg) no-repeat center !important;
      background-size: 9px 6px !important;
    }

    &:hover {
      border-color: #D9DADC;

      b {
        background: url(#{$image-path}/icons/caret-light.svg) no-repeat center !important;
        background-size: 9px 6px !important;
      }
    }
  }

  .chosen-single, .chosen-choices .search-choice .search-choice-close {
    cursor: default;
  }
}

/* @group Right to Left */

.chosen-rtl {
  text-align: right;

  .chosen-single {
    padding: 0 8px 0 0;
    overflow: visible;

    span {
      margin-left: 26px;
      margin-right: 0;
      direction: rtl;
    }

    div {
      left: 3px;
      right: auto;
    }

    abbr {
      left: 26px;
      right: auto;
    }
  }

  .chosen-choices {
    .search-field input {
      direction: rtl;
    }

    li {
      float: right;
    }

    .search-choice {
      padding: 3px 5px 3px 19px;
      margin: 3px 5px 3px 0;

      .search-choice-close {
        left: 4px;
        right: auto;
      }
    }
  }

  &.chosen-container-single .chosen-results {
    margin: 0 0 3px 3px;
    padding: 0 4px 0 0;
  }

  .chosen-results .group-option {
    padding-left: 0;
    padding-right: 15px;
  }

  &.chosen-container-active .chosen-single-with-drop div {
    border-right: none;
  }

  .chosen-search input {
    background: white url("#{$image-path}/icons/caret.svg") no-repeat top right -6px;
    background: url("#{$image-path}/icons/caret.svg") no-repeat top right -6px, -webkit-gradient(linear, 0 0, 0 100%, color-stop(1%, #EEEFF1), color-stop(15%, white));
    background: url("#{$image-path}/icons/caret.svg") no-repeat top right -6px, -webkit-linear-gradient(top, #EEEFF1 1%, white 15%);
    background: url("#{$image-path}/icons/caret.svg") no-repeat top right -6px, -moz-linear-gradient(top, #EEEFF1 1%, white 15%);
    background: url("#{$image-path}/icons/caret.svg") no-repeat top right -6px, -o-linear-gradient(top, #EEEFF1 1%, white 15%);
    background: url("#{$image-path}/icons/caret.svg") no-repeat top right -6px, linear-gradient(#EEEFF1 1%, white 15%);
    padding: 4px 5px 4px 20px;
    direction: rtl;
  }
}

.chosen-container-single.chosen-rtl {
  .chosen-single div b {
    background-position: 6px 2px;
  }

  .chosen-single-with-drop div b {
    background-position: (-13px) 3px;
  }
}


.ll-position {
  position: absolute;
  z-index: $gl-z-index-3;
  background: #fff;
  &--max-height {
    max-height: 260px;
    overflow: auto;
  }
}
