textarea,input[type="text"],input[type="password"],input[type="datetime"],input[type="datetime-local"],input[type="date"],input[type="month"],input[type="time"],input[type="week"],input[type="number"],input[type="email"],input[type="url"],input[type="search"],input[type="tel"],input[type="color"],.uneditable-input, select
  border: 1px solid #ddd
  box-shadow: none
  background: #fff
  //padding: 0.75rem
  border-radius: 4px
  font-family: $body_fonts
label
  color: $text_color
  padding: 0.75rem 0
  cursor: default

select
  height: 46px
  color: $text_color
  line-height: 46px
  padding: 0 0.75rem

select, .text-input, text_field
  @media screen and (max-width: $break-small)
    -webkit-appearance: none
    border: 1px solid #ddd
    box-shadow: none
    background-color: #fff !important
select
  @media screen and (max-width: $break-small)
    background: url('#{$image-path}/icons/caret.svg') center right 0.75rem no-repeat
    background-size: 10px 9px

.select-small
  height: 38px
@-moz-document url-prefix()
  .select-small
    height: 45px
.inner-hero
  select
    border: 1px solid #d4d4d4
    color: #444
  label
    width: 90px

input[type="checkbox"]
  margin:
    top: -2px
    right: 2px

.qa_body
  .qa_comment_form, .qa_comment_reply_form, .qa_comment_decline_form,.qa_comment_post_comment_form
    textarea
      padding: 8px
      background: white
      resize: none

.form-search
  .input-append
    position: relative
    .btn
      position: absolute
      right: 0
      border-radius: 0 3px 3px 0
      height: 46px

//QA Form
.question_section
  padding-top: 3rem
  padding-bottom: 3rem
  @media screen and (max-width: $break-small)
    padding: 0
    background: white
    .disclaimer
      padding: 24px
      background: white
      .ba
        padding: 24px
  .mw8
    max-width: 895px
//.ask_ctn
//  @media screen and (min-width: $break-small)
//    +box-shadow(0 2px 4px 0 rgba(0, 0, 0, 0.07))
.ask_ctn > select,
.qa_subject_select select
  background: white
  background-image: image-url('#{$image-path}/misc/down-caret@3x.png')
  width: 100%
  box-sizing: border-box
  font-size: 14px
  font-weight: bold
  line-height: 1
  border: 0
  padding: 18px 24px
  border-radius: 6px !important
  -webkit-appearance: none
  margin: 0
  border-bottom: solid 1px #e8e8e8
  border-bottom-left-radius: 0 !important
  border-bottom-right-radius: 0 !important
  background-repeat: no-repeat
  background-size: 14px
  background-position: 95% center
  background-position: center right 24px
  height: 52px
  @media screen and (max-width: $break-device)
    font-size: 15px
    padding: 0 10px
.qa_question_specialization_ids
  background: white
  border-radius: 0
  border-bottom: solid 1px #e8e8e8
.user_form,.qa_comment_form, .ask_ctn
  border-radius: 6px
  width: 100%

  .content_div
    padding: 12px 16px
    label
      font-size: 18px
      color: #aaa !important
  .qa_question_specialization_ids
    padding: 4px 24px
    .tag
      border-radius: 3px
      padding: 0.5em
      background: #f7f7f7
      display: inline-block
      margin: 0.25em
      cursor: pointer
      color: #666666
      .question_tag
        margin:
          top: -4px
          right: 5px

  .question
    height: 0px
    display: none
    resize: none
    border: none
    background: transparent
    width: 100%
    font-size: 16px
    border-bottom: 1px solid #e8e8e8
  .mce-btn-group
    border: 0 !important
  #mceu_20, .mce-container-body.mce-stack-layout, .mce-toolbar-grp
    border-radius: 0
  .mce-panel
    border: none !important
    background-color: #fff
    .mce-btn
      background-color: #fff
      &:hover
        background-color: #f0f0f0
    .mce-ico
      color: $text_color
  .mce-content-body, .mce-reset
    color: $text_color
    font-size: 18px
  .form_footer
    width: 100%
    position: relative
    padding: 0 1.5rem 1.5rem
    background: #fff
    border-radius: 0 0 6px 6px
    border: 0
    .attach_file
      margin-top: 15px
      margin-right: 16px
      cursor: pointer
      img
        vertical-align: middle
      span
        font-size: 13px
        color: $t-gray-1
        font-weight: bold
      &:hover
        span
          color: $text_color
    .attachment_name
      margin: 8px 4px 0
    .attachments_list
      margin-top: 6px
    @media screen and (max-width: $break-device)
      .btn
        font-size: 16px
      .submit_question
        width: 100%
        margin-top: 1em

.mce-container
  .btn:hover, .btn:focus, button:hover, button:focus
    background: inherit
.mce-content-body
  font-size: 18px
  line-height: 1.33

.qa_comment_expanded
  .mce-panel, .mce-btn
    background: white
    border: 0
  .mce-btn-group
    border: 0 !important

.time_created_at
  font-size: 13px
.experts_qa_subject_link
  margin: 0 !important

///* Mentor dashboard square filters */
//.mentor-questions-ctn
//  #need_reviews, #reviewed
//    .filter, .sort
//      font-size: 13px
//      border: solid 1px #eeeeee
//      height: 36px
//      width: 148px
//      line-height: 35px
//      border-radius: 0 !important
//      -webkit-appearance: none
//      background: image-url('icons/caret-gray.svg') center right 0.75rem no-repeat
//      background-color: white
//      background-size: 9px 6px
//  #need_reviews
//    .filter
//      border-right: 0
//    .sort
//      margin-left: -3px
