/* ==========================================================================
   #FOOTER
   ========================================================================== */

$footer-bg: $theme-light-lt;
$footer-padding-y: 64px;
$footer-font-size: $gl-font-size-s;
// Nav
$footer-mobile-padding-x: $gl-spacer-m;
$footer-mobile-padding-y: $gl-spacer-l;

.c-footer {
  background-color: $footer-bg;
  font-size: $footer-font-size;
  color: $t-gray-1;
  padding: rem($footer-padding-y) 0;
  border-top: $gl-border-width-base solid $bd-gray;
  display: block;

  @include media('<=md') {
    padding: rem($footer-mobile-padding-y) rem($footer-mobile-padding-x) rem($footer-mobile-padding-y * 2.5);
  }

  &__nav {
    @include list-unstyled();

    &__item {
      @include media('<=md') {
        margin-bottom: rem($gl-spacer-m);
        padding-bottom: rem($gl-spacer-m);
        border-bottom: 1px solid $bd-gray;
        text-align: center;
      }
    }
  }

  &__link {
    color: $t-gray-1;
    text-decoration: none;
    cursor: pointer;

    &:focus,
    &:hover,
    &.is-active {
      color: $t-dark-active;
    }
  }

  &__list {
    @include list-unstyled();

    &__item + &__item {
      margin-top: rem($gl-spacer-xs);
    }

    @include media('<=md') {
      text-align: center;
    }
  }

  &__title {
    margin-bottom: rem($gl-spacer-s);
    font-weight: 500;
  }

  &__wechat {
    background: url(#{$image-path}/footer/wechat.svg) no-repeat center;
    background-size: contain;
  }

  &__weibo {
    background: url(#{$image-path}/footer/weibo.svg) no-repeat center;
    background-size: contain;
  }
}
