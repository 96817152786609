// Document thumbnail mixins

@mixin doc-thumb-size($var) {
  width: map-get($var, width);
  height: map-get($var, height);

  @if map-has-key($var, font-size) {
    font-size: map-get($var, font-size);
  }

  @if map-has-key($var, border-radius) {
    &,
    &__link {
      border-radius: map-get($var, border-radius);
    }
  }
}
