//
// Custom Select
// --------------------------------------------------

.c-form-select {
  width: 100% !important;
  display: block;
  padding: 0;
  font-family: $gl-input-font-family;
  line-height: $gl-input-line-height;

  .chosen-container {
    width: 100% !important;
  }

  .chosen-single,
  .chosen-choices {
    padding: rem($gl-input-sm-padding-y) rem($gl-input-sm-padding-x);
    font-size: rem($gl-input-sm-font-size);

    div b {
      background: url(#{$image-path}/icons/caret.svg) no-repeat center;
      background-size: 14px;
      width: 10px;
      height: 100%;
      display: block;
      text-align: right;
    }
  }

  @each $variant, $opts in $gl-input-variants {
    &--#{$variant} {
      font-size: map-get($opts, font-size);

      .chosen-single,
      .chosen-choices {
        border-color: map-get($opts, border-color);
      }

      &:not(.chosen-disabled) .chosen-default span {
        color: map-get($opts, placeholder-color);
      }
    }
  }

  @each $size, $opts in $gl-input-sizes {
    &--#{$size} .chosen-single, .chosen-choices {
      padding: map-get($opts, padding-y) map-get($opts, padding-x);
      font-size: map-get($opts, font-size);

      span {
        font-size: map-get($opts, font-size);
      }
    }
  }

  .chosen-choices {
    margin-bottom: 0;
    //padding: 0.3rem;
  }

  .chosen-drop {
    z-index: $gl-z-index-pre-max - 1;
    width: 100%;
  }

  &.chosen-disabled .chosen-single {
    cursor: not-allowed;

    @include hover-focus {
      outline: none;
    }

    div b {
      background-image: url(#{$image-path}/icons/caret-light.svg) !important;
    }
  }
}
