// Star Rating

.c-rating {
  display: inline-flex;
  align-items: center;

  &__item {
    & + & {
      margin-left: 5px;
    }
  }
}
