$lt-notice-padding: rem($gl-spacer-l);
$lt-notice-padding-mobile: rem($gl-spacer-m);

// Notice banner
.c-lt-notice {
  border-radius: $gl-radius-lg;
  border: 1px solid $bd-gray;

  &__inner {
    padding: $lt-notice-padding;
    text-align: center;

    @include mobile {
      padding: $lt-notice-padding-mobile;
    }
  }

  &__title {
    font-weight: 600;
    font-size: rem($gl-font-size-h3g);
    margin-bottom: rem($gl-spacer-xs);
  }

  &__col {
    border-top: 1px solid $bd-gray;

    &__inner {
      padding: $lt-notice-padding;
      text-align: left;

      @include mobile {
        padding: $lt-notice-padding-mobile;
      }
    }
  }

  &__col + &__col {
    @include desktop {
      border-left: 1px solid $bd-gray;
    }
  }

  &__footer {
    border-top: 1px solid $bd-gray;
    text-align: center;
    padding: rem($gl-spacer-s);
  }
}
