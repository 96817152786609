
// TODO: move plugin css to the top from haml
.c-clr {
  padding-top: 20px;

  position: relative;

  &__loader {
    top: 0;
    left: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba($theme-light-lt, 0.7);
    z-index: $gl-z-index-3;
  }


}

div.c-clr .c-clr__readonly {
  background: $b-orange-2;
  border-color: transparent;
  color: $t-gray-1;
  cursor: not-allowed;
}

.c-clr-popper {
  background: $theme-light-lt;
  padding: $gl-spacer-l $gl-spacer-m $gl-spacer-m;
  border-radius: $gl-radius-base;
  box-shadow: $gl-box-shadow-base;
  width: 300px;
  z-index: $gl-z-index-modal;
  display: none;
  position: relative;

  &.active {
    display: block;
  }

  &__close {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
  }
}

// =============

// button.fc-button {
//   &:focus {
//     outline: 0;
//     box-shadow: none;
//   }


//   &.fc-button-primary {
//     @include button-variant(
//       $theme-primary,
//       $theme-light-lt
//     );

//     &:not(:disabled).fc-button-active,
//     &:not(:disabled):active {
//       @include button-variant(
//         $bd-gray,
//         $t-dark
//       );
//     }
//   }
// }

// fc-prev-button fc-button fc-button-primary

.form-row {
  & + & {
    margin-top: 16px;
  }
}

div.pika-single {
  z-index: 100001;
}

.fc-content {
  margin-right: 22px;
}

.c-clr-remove-event {
  position: absolute;
  top: 0;
  right: 4px
}

.ui-timepicker-container.ui-timepicker-standard {

  &,
  * {
    box-sizing: border-box;
  }

  z-index: 100000!important;
  background: $theme-light-lt;
  border-radius: 4px;
  box-shadow: $gl-box-shadow-sm;
  border: none;

  .ui-widget-content {
    border: none;
    background: none;
  }


  a {
    padding: $gl-spacer-xxs $gl-spacer-m;
    border: none;
  }

  .ui-state-hover,
  .ui-widget-content .ui-state-hover,
  .ui-widget-header .ui-state-hover,
  .ui-state-focus,
  .ui-widget-content .ui-state-focus,
  .ui-widget-header .ui-state-focus {
    background: none;
    border: none;

    line-height: inherit;
    display: block;
    width: 100%;
    text-align: inherit;
    font-weight: inherit;
    margin: 0;
    color: $t-dark-active;
    cursor: pointer;

    &,
    &:hover {
      color: $t-dark-active;
      text-decoration: none;
      background: $b-gray-2;
      border: none;
    }
  }



}

div.ui-timepicker-wrapper {
  z-index: $gl-z-index-modal;
}
