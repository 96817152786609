.video-js {
  background-color: transparent;
  border-radius: 12px;
  .vjs-tech {
    border-radius: 12px;
  }
}
.vjs-poster{
  border-radius: 12px;
  background-size: cover;
  background-color: transparent;
}
