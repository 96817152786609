/* ==========================================================================
   #Tutor, Moderator, Mentor Dashboard
   ========================================================================== */

// Default Variables

// Dashboard
$tdb-header-padding-top: $gl-spacer-l;
$tdb-shadow: $gl-box-shadow-m;
$tdb-block-spacing: $gl-spacer-m;
$tdb-block-inner-padding-y: $gl-spacer-xl;
$tdb-block-inner-padding-x: $gl-spacer-l;
$tdb-min-width: 576px;
$tdb-select-padding: 6px;

// Tutor, Moderator, Mentor Dashboard

.p-tdb {
  min-height: 100vh;

  &__header {
    box-shadow: $tdb-shadow;
    padding-top: rem($tdb-header-padding-top);
  }

  &__avatar {
    background-size: cover;
    background-color: $b-gray-2;

    @include mobile {
      width: $gl-asset-size-lg;
      height: $gl-asset-size-lg;
    }
  }

  // Stats
  &__stats {
    &__num {
      font-size: rem($gl-font-size-base);
      font-weight: 600;
    }

    &__title {
      font-size: rem($gl-font-size-xs);
      color: $t-gray-1;
    }
  }

  // Tabs
  &__tabs {
    @include mobile {
      overflow-x: scroll;
      overflow-y: hidden;
    }

    &__inner {
      border-bottom: none;
      min-width: rem($tdb-min-width);
    }
  }

  // Tab Content
  &__content {
    overflow: visible;
  }

  // Select
  &__field {
    font-size: rem($gl-font-size-xs) !important;
    padding: $tdb-select-padding !important;
  }
}
.p-ltt {
  &__title {
    @extend %truncate;

    font-size: rem($gl-font-size-h3);
    margin-top: 0;
    margin-bottom: $gl-spacer-xxs;
    font-weight: 600;
  }

  &__block {
    &__header {
      padding: $gl-spacer-m;
      border-bottom: 1px solid $bd-gray;
      font-size: rem($gl-font-size-sub-header);
      margin: 0;
      font-weight: 600;
    }
  }
}
