$image-path: "/images"

/* web screen size */
$break-wide: 40em
$break-mid: 960px
$break-device: 780px
$break-small: 568px
$break-xs: 320px
$container-max-widths-xl: 1080px

/* web screen size 1032=64.5 1040=65 */
$break-large: 65em
/* Ipad Vertical and smaller 768px */
$break-medium: 48em
/* Iphone 6 Plus and smaller 480px */
$break-small: 30em
/* Iphone 5 and smaller 320 px*/
$break-xs: 20em

/* Iphone 6, Iphone 6s, Nexus5X, Nexu6P, GalaxyS5 */
$breakpoint-not-small: "screen and (min-width: #{$break-small})" !default

/* Ipad Vertical to medium desktop screen */
$breakpoint-medium: "screen and (min-width: #{$break-medium})" !default

/* Ipad Horizontal to large desktop screen */
$breakpoint-large: "screen and (min-width: #{$break-large})" !default

/* Desktop */
$breakpoint-desktop: "screen and (min-width: #{$break-medium})" !default

/* Mobile */
$breakpoint-mobile: "screen and (max-width: #{$break-small})" !default

/* --------------- sizes --------------- */
$commonMarginSize: 10px

$gl-font-size-base: 16px

/* --------------- fonts --------------- */

$body_fonts: 'Source Han Sans CN', 'Roboto','"Barlow"','Helvetica Neue',"PingFang SC","Hiragino Sans GB","Microsoft YaHei","STHeiti","WenQuanYi Micro Hei",SimSun,sans-serif
$heading_fonts: 'Source Han Sans CN', 'Roboto','"Barlow"','Helvetica Neue',"PingFang SC","Hiragino Sans GB","Microsoft YaHei","STHeiti","WenQuanYi Micro Hei",SimSun,sans-serif

/* --------------- Measurements --------------- */

$padding_value: 10px
$margin_value: 10px

/* measurements for nav */
$avatar_width : 50px
$header_height: 55px
$drop_width   : 165px
$drop_item    : $drop_width


/* legacy */

$extra : 640px
$top   : 95px

$z_datetime: 2000
$z_content_wrap_extra: 1
$z_extra: 2
$z_popup: 2001
$z_overlay: 200
$z_control_panel: 200
$z_header_wrap: 6
$z_content: 4
$z_extra_wrap: 3
$z_extra: 5
$results: 2
$z_panel_heading: 4
$z_attending: 5
