/* ==========================================================================
   #COUNTRY SELECT
   ========================================================================== */
$country-icon-size: $gl-asset-size-md;

.c-country-select {
  &__item {
    text-align: center;
    color: $theme-dark-lt;
    font-size: $gl-font-size-xxs;
    cursor: pointer;
    padding: 12px 0;
    &:hover,
    &.is-active {
      color: $t-dark;
    }
  }

  &__icon {
    margin: 0 auto rem($gl-spacer-xxs);
    background-size: $country-icon-size;
    width: $country-icon-size;
    height: $country-icon-size;

    // US
    &--us {
      background: url(#{$image-path}/country-select/us-g.svg)
    }

    // CA
    &--ca {
      background: url(#{$image-path}/country-select/ca-g.svg)
    }

    // AU
    &--au {
      background: url(#{$image-path}/country-select/au-g.svg)
    }

    // UK
    &--uk {
      background: url(#{$image-path}/country-select/uk-g.jpg)
    }
  }

  // Hover and active states
  @at-root {
    &__item:hover > &,
    &__item.is-active > & {
      &__icon {
        // US
        &--us {
          background: url(#{$image-path}/country-select/us.svg)
        }

        // CA
        &--ca {
          background: url(#{$image-path}/country-select/ca.svg)
        }

        // AU
        &--au {
          background: url(#{$image-path}/country-select/au.svg)
        }

        // UK
        &--uk {
          background: url(#{$image-path}/country-select/uk.svg)
        }
      }
    }
  }
}
