/* ==========================================================================
   #PAGE BREAK
   ========================================================================== */

// Page Break

.c-pg-break {
  height: rem($gl-spacer-xs);
  background-color: $b-gray-2;
  width: 100%;
  margin-top: rem($gl-spacer-l);
  margin-bottom: rem($gl-spacer-l);
}
