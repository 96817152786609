@use "sass:list"

[class^="icon_"], [class*=" icon_"]
  background-repeat: no-repeat
  background-position: left

//home page icons
.icon_play_vid
  background: url('#{$image-path}/home/play.svg')
  width: 100px
  height: 100px
  background-size: 100px

//footer icons
.icon_weichat_login
  background: url('#{$image-path}/icons/wechat-gray.svg') no-repeat
  background-size: 23px
.icon_weichat_login
  height: 23px
  width: 23px
  margin-left: 3px
.icon_weibo_footer
  background: url('#{$image-path}/icons/weibo.svg') no-repeat
  background-size: 32px
.icon_weixin_footer
  background: url('#{$image-path}/icons/wechat.svg') no-repeat
  background-size: 32px
.icon_weibo_footer, .icon_weixin_footer
  height: 32px
  width: 32px
  margin: 0.5em

.icon_caret
  border: none
  background: url('#{$image-path}/icons/caret.png') no-repeat
  width: 10px
  height: 7px
  display: inline-block

//dashboard icons, sidebar icons
.dashboard-tour
  li
    a
      background-repeat: no-repeat
      padding-top: 40px
      background-position: center top
      display: block
  .course
    a
      background: url('#{$image-path}/icons/course.png')
      background-size: 30px
  .guide
    a
      background: url('#{$image-path}/icons/guides.png')
      background-size: 25px 30px
  .life
    a
      background: url('#{$image-path}/icons/ask.png')
      background-size: 30px 28px
  .essay
    a
      background: url('#{$image-path}/icons/essay.png')
      background-size: 30px
  .qa
    a
      background: url('#{$image-path}/icons/qa.png')
      background-size: 30px 28px
  .notes
    a
      background: url('#{$image-path}/icons/notes.png')
      background-size: 28px 30px
  .ppt
    a
      background: url('#{$image-path}/icons/ppt.png')
      background-size: 30px
  .money
    a
      background: url('#{$image-path}/icons/money.png')
      background-size: 20px 30px
  .book
    a
      background: url('#{$image-path}/icons/book.png')
      background-size: 23px 30px
  .email
    a
      background: url('#{$image-path}/icons/email.png')
      background-size: 30px 28px
.arrow_container
  background: url('#{$image-path}/misc/scrollup.png')
  cursor: pointer
  width: 41px
  height: 41px
  background-size: 41px 41px
  position: fixed
  z-index: 100
  bottom: 105px
  right: 23px

.icon_remove
  background: url('#{$image-path}/icons/remove.png')
  width: 12px
  height: 12px
  cursor: pointer
  margin-top: 15px
  &:hover
    opacity: 0.8

.icon_print
  background: url('#{$image-path}/icons/print.png')
  width: 17px
  height: 16px
  display: inline-block
  margin:
    top: -4px
    right: 5px
  vertical-align: middle

//Q&A Icons
.attach_icon,.calender_icon,.clock_icon
  display: inline-block
  vertical-align: middle
.attach_icon
  cursor: pointer
  .fa-paperclip
    vertical-align: middle
.calender_icon
  background: url('#{$image-path}/experts/date.png') center center no-repeat
  height: 20px
  width: 20px
.clock_icon
  background: url('#{$image-path}/experts/time.png') center center no-repeat
  height: 20px
  width: 20px
.star_icon
  float: left
  width: 16px
  height: 20px
  background: url('#{$image-path}/experts/star_full.png') center center no-repeat
.empty_star_icon
  float: left
  width: 16px
  height: 20px
  background: url('#{$image-path}/experts/star_empty.png') center center no-repeat

.check_icon
  width:  40px
  height: 40px
  background: url('#{$image-path}/experts/answered.png') center center no-repeat

.icon_search
  +background-image-retina(list.slash(/images, icons, search-w), png, 32px, 32px)
  height: 32px
  width: 32px

.icon_check
  background: url('#{$image-path}/misc/check.svg') center center no-repeat
  height: 24px
  width: 24px
  background-size: 24px

.course-select-tut
  +background-image-retina(list.slash(/images, icons, arrow-w), png, 8px, 12px)
  background-repeat: no-repeat
  background-position: center right 30px

.icon_arrow_w
  +background-image-retina(list.slash(/images, icons, arrow-w), png, 8px, 12px)
  width: 8px
  height: 12px
  display: inline-block

.background_div
  background: #f6f7fa
  box-shadow: inset 0 1px 3px rgba(0,0,0,0.05)
  border-radius: 100px
  width: 100%
  height: 14px
.front_div
  height: 14px
  background: $b-green
  border-radius: 100px

.attachment_name
  .close
    width: 10px
    height: 10px
    opacity: 0.5
    background: url('#{$image-path}/icons/close.png') center center no-repeat
    background-size: 12px 12px !important
    display: inline-block
    vertical-align: middle
    &:hover
      opacity: 1

//Blog
.video_img
  width: 26px
  height: 27px
  +background-image-retina(list.slash(/images, blog, video), png, 26px, 27px)
  position: absolute
  bottom: 10%
  left: 10%
  @media screen and (max-width: 710px)
    position: relative !important
    margin-top: 10px
    left: 0

.icon_star
  width: 18px
  height: 18px
  +background-image-retina(list.slash(/images, icons, star), png, 18px, 18px)
  display: inline-block
  margin-top: 2px
.icon_nextpage
  +background-image-retina(list.slash(/images, icons, arrow-right), png, 7px, 12px)
  opacity: 0.5
  width: 7px
  height: 12px
  vertical-align: middle
  display: inline-block
  margin-top: -2px

//Paid tour
.paid-tour4, .pay-process2
  .checked, .check-g, .fail
    width: 27px
    height: 21px
  .process, .process-dark
    width: 30px
    height: 32px
    margin-top: 7px
  .checked, .check-g, .fail, .process, .process-dark
    @media screen and (max-width: $break-device)
      zoom: 0.7
  .process4
    width: 30px
    height: 32px
    margin-right: 27px
    float: left
    vertical-align: middle
    margin-top: -160px
  .fail
    +background-image-retina(list.slash(/images, tour, fail), png, 27px, 21px)
  .process
    background: url('#{$image-path}/tour/loading.gif') center no-repeat
  .check-g
    +background-image-retina(list.slash(/images, tour, check-g), png, 27px, 21px)
  .process-dark
    background: url('#{$image-path}/tour/loading-dark.gif')
  .process-dark-fail
    margin-top: -170px


.bar
  height: 20px
.wait_2_bar
  background: url('#{$image-path}/tour/wait3.png')
.wait_3_bar
  background: url('#{$image-path}/tour/wait4.png')
.wait_4_bar
  background: url('#{$image-path}/tour/wait5.png')
.wait_5_bar
  background: url('#{$image-path}/tour/progress-full.png')

.flow-arrow-down
  background: url('#{$image-path}/icons/flow-arrow.png') center center no-repeat
  width: 13px
  height: 44px
  margin: 8px auto

.icon_chat
  background: url('#{$image-path}/icons/qa-chat.svg') no-repeat
  height: 37px
  width: 44px
  background-size: 44px 37px

//Videos
.icon_vid_ctn_play
  +background-image-retina(list.slash(/images, icons, vid-ctn-play), png, 47px, 47px)
  height: 47px
  width: 47px
  position: absolute
  z-index: 100
  margin: auto
  left: 0
  right: 0
  top: 32px
.vid-ctn
  +background-image-retina(list.slash(/images, icons, vid-ctn), png, 159px, 114px)
  background-repeat: no-repeat
  height: 114px
  width: 159px
  position: relative
  img
    width: 159px
    height: 107px
    border: 1px solid #E2E3E7
.vid_thumbnail
  .icon_vid_ctn_play
    height: 37px
    width: 37px
    background-size: 37px 37px
    top: 21px
//Event
.icon_time
  +background-image-retina(list.slash(/images, icons, time), png, 16px, 16px)
.icon_location
  +background-image-retina(list.slash(/images, icons, location), png, 16px, 16px)
.icon_time, .icon_location
  height: 16px
  width: 16px
  display: inline-block
  vertical-align: middle

//Home Partners Sprite
.intro, .hero
  .new-oriental
    +background-image-retina(list.slash(/images, home, xdfw), png, 49px, 21px)
    width: 49px
    height: 21px
  .qide
    height: 24px
    width: 79px

.qiantu
  a
    background: url(/images/home/partners-sprite.png) no-repeat -10px -283px
    width: 151px
    height: 71px
    &:hover
      background: url(/images/home/partners-sprite.png) no-repeat -352px -192px

.tiandao
  a
    background: url(/images/home/partners-sprite.png) no-repeat -352px -101px
    width: 151px
    height: 71px
    &:hover
      background: url(/images/home/partners-sprite.png) no-repeat -352px -10px

.qide
  a
    background: url(/images/home/partners-sprite.png) no-repeat -181px -101px
    width: 151px
    height: 71px
    &:hover
      background: url(/images/home/partners-sprite.png) no-repeat -10px -101px

.huaying
  a
    background: url(/images/home/partners-sprite.png) no-repeat -181px -10px
    width: 151px
    height: 71px
    &:hover
      background: url(/images/home/partners-sprite.png) no-repeat -10px -10px

.shandong
  a
    background: url(/images/home/partners-sprite.png) no-repeat -181px -192px
    width: 151px
    height: 71px
    &:hover
      background: url(/images/home/partners-sprite.png) no-repeat -10px -192px

//Course icons
.icon_happiness, .icon_popularity, .icon_bookuse, .icon_difficulty
  width: 48px
  height: 46px
  margin-top: 7px
.icon_bookuse
  +background-image-retina(list.slash(/images, icons, book-usage), png, 48px, 46px)
.icon_prof
  width: 48px
  height: 48px
  +background-image-retina(list.slash(/images, icons, prof), png, 48px, 48px)
  background-size: 48px

//Course landing
body[location='xuanke_pages']
  .tips-ctn
    .icon-tipcheck
      +background-image-retina(list.slash(/images, pages, course, check), png, 14px, 11px)
      width: 24px
      height: 24px
      float: left
      background-repeat: no-repeat
      background-position: top 3px left
      vertical-align: middle

//Moderator Review
.review_questions
  .qualify, .disqualify
    width: 24px
    height: 24px
    background-repeat: no-repeat
    background-position: center
  .qualify
    background-color: $b-green
    background-image: url('#{$image-path}/icons/check-w.svg')
    background-size: 14px
  .disqualify
    background-image: url('#{$image-path}/icons/x-w.svg')
    background-size: 14px
.nav-tabs
  .icon-question, .icon-reviews
    margin:
      top: -1px
      right: 2px
  .icon-question
    background: url('/images/icons/icon-chat-gray.svg') no-repeat center
    width: 16px
    height: 16px
    background-size: 16px
  .icon-reviews
    background: url('/images/icons/icon-clipboard-gray.svg') no-repeat center
    width: 16px
    height: 16px
    background-size: 13px 16px
  li:hover, li.active
    .icon-question
      background: url(/images/icons/icon-chat-green.svg) no-repeat center
      background-size: 16px
    .icon-reviews
      background: url(/images/icons/icon-clipboard-green.svg) no-repeat center
      background-size: 13px 16px

//Doc rating
.icon-rate
  margin-bottom: 6px
  width: 42px
  height: 42px
  background-size: 42px
.icon-rate__good
  background: url(/images/icons/good.svg) no-repeat center
  &:hover
    background: url(/images/icons/good-hover.svg) no-repeat center
.active
  .icon-rate__good
    background: url(/images/icons/good-hover.svg) no-repeat center
.icon-rate__normal
  background: url(/images/icons/normal.svg) no-repeat center
  &:hover
    background: url(/images/icons/normal-hover.svg) no-repeat center
.active
  .icon-rate__normal
    background: url(/images/icons/normal-hover.svg) no-repeat center
.icon-rate__bad
  background: url(/images/icons/bad.svg) no-repeat center
  &:hover
    background: url(/images/icons/bad-hover.svg) no-repeat center
.active
  .icon-rate__bad
    background: url(/images/icons/bad-hover.svg) no-repeat center

//Admin
.icon-admin-edit
  margin-top: 0
  width: 26px
  height: 26px
  stroke: $t-gray-3
  &:hover
    stroke: $t-green
