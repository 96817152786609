// Animation.css presets

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

.collapse {
  max-height: 0;
  overflow: hidden;
  -webkit-transition: max-height .35s ease;
  -moz-transition: max-height .35s ease;
  -o-transition: max-height .35s ease;
  transition: max-height .35s ease;
}