// ISS

.p-iss {
  // Intro
  &__intro {
    background: url('#{$image-path}/pages/index/iss.png') no-repeat center;
    background-size: cover;
    padding: 80px 0;

    @include mobile {
      padding: $gl-spacer-xl 0;
    }
    &_slider {
      @include mobile {
        zoom: 0.5;
      }
    }
  }

  &__ti {
    margin: 32px 0;
  }
  // Card
  &__card {
    padding: $gl-spacer-l $gl-spacer-m;
    text-align: center;
    background: $theme-light-lt;
    border-radius: 16px;
    box-sizing: border-box;

    &__avatar {
      margin: auto;
      width: 102px;
      height: 102px;
      margin-bottom: $gl-spacer-s;
    }

    &__name {
      font-size: 12px;
      margin-bottom: 2px;
    }

    &__en {
      font-size: 11px;
      @include mobile{
        white-space: break-spaces;
      }
    }

    &__school {
      font-size: $gl-font-size-s;
      font-weight: 500;
      margin-bottom: 4px;
    }

    &__title {
      font-size: 11px;
    }

    &__shadow {
      margin-top: $gl-spacer-xxs;
    }
  }

  // Qa
  &__qa {
    &__item {
      border-radius: $gl-radius-lg;
      height: 100%;
    }
  }

  // Schedule
  &__schedule {
    &__item {
      font-size: $gl-font-size-base;

      @include hover {
        box-shadow: $gl-box-shadow-m;
        transition: 0.2s;
      }

      &--faded {
        opacity: 0.45;

        @include hover {
          box-shadow: none;
        }
      }

      & + & {
        margin-top: $gl-spacer-m;
      }
    }
  }

  // Form
  &__form {
    background: $theme-gradient-warm;
  }
}
