$lt-book-sb-width: 464px;

// Book
.p-lt-book {
  min-height: 100vh;

  &__bg {
    @include desktop {
      background-image: linear-gradient(90deg, #fff 50%, #f9f9f9 50%);
    }
  }

  &__confirm {
    background: #f9f9f9;
  }

  // Left sidebar
  &__sb {
    padding: $gl-spacer-m $gl-spacer-m $gl-spacer-l;

    @include media('>960px') {
      padding: $gl-spacer-l $gl-spacer-2xl;
      position: fixed;
      top: 65px;
      left: 0;
      bottom: 0;
      background: $theme-light-lt url('#{$image-path}/lte/icons/bottom-circles.svg') no-repeat bottom left;
      width: $lt-book-sb-width;
    }

    &__inner {
      @include media('>960px') {
        display: table;
        height: 100%;
      }
    }

    &__msg {
      background: url('#{$image-path}/pages/lt/left-bg.png') no-repeat bottom left;
      background-attachment: fixed;
      background-size: 16%;
    }

    &__body {
      position: fixed;
      width: 41.6%;
      max-width: 486px;

      &-school {
        background: rgba(61, 137, 137, 0.03);
        border-radius: 8px;
        padding: 8px;
        margin-top: 16px;
        width: 100%;
      }
    }

    &__title {
      font-weight: 600;
      font-size: rem($gl-font-size-h2);
      margin: $gl-spacer-m 0 $gl-spacer-xs;
    }
  }

  // Main
  &__main {
    padding: $gl-spacer-l 0;
    min-height: 100vh;
    background: #f9f9f9;

    &__title {
      font-size: rem($gl-font-size-h5);
      font-weight: 500;
      margin: 0 0 $gl-spacer-m;
    }

    &__sub {
      font-size: rem($gl-font-size-s);
      margin: 0 0 $gl-spacer-s;
      color: $t-dark;
      font-weight: 500;
    }

    &__desc {
      margin-bottom: $gl-spacer-s;
    }

    &__input {
      background: #EEEEEE;
      border-radius: 3px;
    }
  }

  // Form
  &__form {
    &__group {
      margin-bottom: $gl-spacer-l;

      &__option {
        background: #FFFFFF;
        border-radius: 8px;
      }
    }

    &__border {
      border-bottom: 1px rgba(0, 0, 0, 0.06) solid;
    }
  }

  // Block
  &__block {
    background: $theme-light-lt;
    border-radius: $gl-radius-lg;
    padding: $gl-spacer-m;
    margin-bottom: $gl-spacer-m;

    &--link {
      cursor: pointer;

      @include hover {
        box-shadow: $gl-box-shadow-l;
      }

      &.is-active {
        border: 2px solid $t-green;
      }

      .tutor_mark {
        width: 6px;
        height: 6px;
        border-radius: 100%;
        -webkit-border-radius: 100%;
        -moz-border-radius: 100%;
        -ms-border-radius: 100%;
        -o-border-radius: 100%;
        display: inline-block;
        vertical-align: middle;
        margin-right: 2px;
      }
    }

    &__title {
      font-size: rem($gl-font-size-base);
      font-weight: 500;
    }

    .ws_normal {
      white-space: normal;
    }
  }

  // Time
  &__time {
    &.is-active {
      @include button-variant($t-green, $t-light);
    }
  }

  &__modal {
    width: 600px !important;
    max-height: 588px;
    word-break: break-word;

    @include mobile {
      width: 100%;
    }

    .c-modal__header {
      display: none;
    }

    .c-modal__body {
      padding: 0;
    }

    &__header {
      background: #F3F3F3;
      border: 1px solid #F7F7F7;
    }

    &__content {
      background: rgba(247, 247, 247, 0.9);
    }

    &__radio {
      .ant-checkbox-inner {
        border-radius: 50%;
      }
    }
  }

  &__example-modal {
    .c-modal__body {
      padding: 0;
    }

    h4 {
      margin-top: -10px;
    }

    .tip {
      background: #F1F9F8;
      border: 1px solid #52B4A8;
      border-radius: 6px;
    }
  }

  &__capitalize {
    text-transform: capitalize;
  }
}

.c-expandable__inner {
  overflow: hidden;
  max-height: 4.5em;
  word-break: break-word;
  text-overflow: ellipsis;
}

.c-expandable__inner.is-active {
  max-height: unset;
  display: inline;
}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

::-webkit-scrollbar-thumb {
  border-radius: 1em;
  background-color: rgba(50, 50, 50, .3);
}

::-webkit-scrollbar-track {
  border-radius: 1em;
  background-color: rgba(50, 50, 50, .1);
}