/* ==========================================================================
   #REACT NAVBAR
   ========================================================================== */
// [TODO] Update React component:
// Add list__inner wrapper
// add navbar__inner

// Top header navbar

// Container
$navbar-bg: $theme-light-lt;
$navbar-border-color: rgba(0,0,0,0.07);
$navbar-menu-font-size: $gl-font-size-s;
$navbar-menu-padding-x: $gl-spacer-xs;
$navbar-menu-margin-top-mobile: $gl-spacer-m;
$navbar-padding-x: $gl-spacer-m;
$navbar-padding-mobile: $gl-spacer-s;
$navbar-padding-y: 20px;
$navbar-title-font-size: $gl-font-size-base;


// Right content (toolbar)
$navbar-dd-spacing: 21px; // Additional spacing between toggle and dropdown list
$navbar-toolbar-spacing: 24px; // spacing between toolbar buttons
$navbar-toolbar-pad-right: 12px;
$navbar-toolbar-xl-min-width: 455px; // min width for large screens
$navbar-button-padding-x: 16px;

// Mobile Navbar
$navbar-mobile-font-weight: 500;
$navbar-mobile-font-size: $gl-font-size-base;
$navbar-mobile-height: 57px;
$navbar-mobile-line-height: $navbar-mobile-height - $gl-spacer-l;

// [TODO] Remove Utility classes from markup

%navbar-btn {
  color: $t-dark;
  text-decoration: none;
  cursor: pointer;

  @include hover {
    color: $t-gray-1;
    text-decoration: none;
  }
}
.c-height{
  height: 65px;
  @include mobile{
    height: 57px;
  }
}
.c-navbar {
  top: 0;
  left: 0;
  width: 100%;

  &__inner {
    background: $navbar-bg;
    border-bottom: 1px solid $navbar-border-color;
    padding: rem($navbar-padding-y) 0;
    position: relative;
    z-index: $gl-z-index-3;

    @include media('<=md') {
      padding: rem($navbar-padding-mobile) 0;
    }

    // Smaller padding, no border (admin panel)
    &--sm {
      border-bottom: none;
      padding: $gl-spacer-xs 0;
    }
  }

  // LOGO
  &__logo {
    display: inline-block;

    &__link {
      display: block;
    }
  }

  //dropdown
  &__dd {

    // [TODO] Remove after Dropdown refactoring

    &__list {
      padding-top: $navbar-dd-spacing;
    }

    &__toggle {
      @extend %navbar-btn;

      font-size: rem($navbar-menu-font-size);
      white-space: nowrap;
    }
  }

  // Dropdown Popup
  $tab-color: $theme-dark-lt;
  $tab-hover-bg: $theme-primary-ltr;
  $popup-width: 400px;
  $popup-height: 480px;

  $popup-tabs-padding-y: rem($gl-spacer-s);
  $popup-btn-font-size: rem($gl-font-size-s);
  $popup-font-size: rem($gl-font-size-xs);
  $popup-footer-padding-y: rem($gl-spacer-s);
  $popup-item-padding-x: rem($gl-spacer-m);
  $popup-item-padding-y: rem($gl-spacer-xs);
  $popup-min-height: 2em;

  &__popup {
    width: $popup-width;
    height: $popup-height;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: space-between;


    @media (min-width: 380px) and (max-width: 480px) {
      width: 365px;
    }

    &__tabs {
      padding: $popup-tabs-padding-y;
      border-bottom: 1px solid $navbar-border-color;
    }

    &__tab {
      text-align: center;
      line-height: $gl-line-height-xs;
      font-size: $popup-btn-font-size;
      font-weight: 500;

      & + & {
        border-left: 1px solid $bd-gray;
      }

      &__link {
        @extend %navbar-btn;

        padding: 8px 24px;
        transition: all $gl-duration-base;
        text-decoration: none;
        white-space: nowrap;
        color: $tab-color;

        &.is-active {
          color: $t-dark;

          .#{$icon-class}__svg {
            stroke: $t-green-dark;
          }
        }

        @include hover-focus {
          color: $t-dark;
          text-decoration: none;
        }
      }
    }

    &__content {
      display: none;
      font-size: $popup-font-size;
      width: 100%;
      overflow-y: auto;

      &.is-active {
        display: block;
      }
    }

    &__item {
      padding: $popup-item-padding-y $popup-item-padding-x;

      & + & {
        border-top: 1px solid $navbar-border-color;
      }

      &:hover {
        background: $tab-hover-bg;
      }
    }

    &__footer {
      border-top: 1px solid $navbar-border-color;
      text-align: center;
      padding: $popup-footer-padding-y 0;
      font-size: $popup-btn-font-size;
    }
  }

  // Menu
  &__menu {
    flex-grow: unset;
    padding: 0 rem($navbar-menu-padding-x);

    &--stacked {
      @include mobile {
        margin-top: rem($navbar-menu-margin-top-mobile);
        width: 100%;
        display: none;
      }
    }

    &__toggle {
      cursor: pointer;
    }
  }

  // Button Toolbar
  &__toolbar {
    padding-right: rem($navbar-toolbar-pad-right);

    &__item {
      text-align: left;

      @at-root {
        & + & {
          margin-left: $navbar-toolbar-spacing;
        }
      }
    }
  }

  &__link {
    @extend %navbar-btn;
  }

  // Mobile
  &__mobile {
    &__title {
      text-align: center;
      font-weight: $navbar-mobile-font-weight;
      font-size: rem($navbar-mobile-font-size);
      line-height: $navbar-mobile-line-height;
    }
  }

  // Title
  &__title {
    font-size: $navbar-title-font-size;
    color: $t-dark;
    vertical-align: middle;

    &--bold {
      font-weight: 500;
    }
  }

  // Tag
  &__tag {
    background: $b-red-1 !important;
    color: $t-light;
    display: inline-block;
    margin: 0;
  }
}
