$img-path: '/images/pages/prep-course';

.p-prep {
  &__intro {
    background: url('#{$img-path}/intro_bg.jpg') no-repeat center;
    background-size: cover;
    height: 560px;
    &__hero {
      height: 100%;
    }
  }
  &__info {
    .bor {
      margin: 32px auto;
      width: 38px;
      height: 4px;
      background: $t-dark;
      border-radius: 20px;
      @include mobile {
        margin: 0.5rem auto;
      }
    }
  }
  &__mb {
    background: #f5f5f5;
    height: 10px;
  }
  &__speech {
    background: #fff;
    &_info {
      background: $b-gray-1;
      position: relative;
      padding-bottom: 6px;
      @include mobile {
        margin: 0 12px;
        border-radius: 12px;
      }
      .bg {
        z-index: 1;
        position: absolute;
        width: 100%;
        bottom: 0;
        .img-left {
          align-self: flex-end;
        }
      }
      .info-box {
        position: relative;
        z-index: 2;
      }
      .speech_title {
        margin-bottom: 72px;
        padding-top: 72px;
        font-weight: 600;
        font-size: 32px;
        line-height: 150%;
        @include mobile {
          font-size: 1.25rem;
          margin-bottom: 2rem;
          padding-top: 2rem;
        }
      }
      .box {
        padding: 112px 0px 54px;
        background: $t-light;
        box-shadow: -41px 41px 36px rgba(0, 0, 0, 0.0545509);
        border-radius: 12px;
        margin-bottom: 86px;
        @include mobile {
          padding: 1.5rem;
          background: rgba(0, 0, 0, 0);
          box-shadow: none;
          margin-bottom: 1rem;
        }
        .mt {
          margin-top: 10px;
          @include mobile {
            margin-top: 0;
          }
        }
        .quotes {
          margin-top: -8px;
          margin-right: -8px;
        }
        .text {
          font-weight: 500;
          font-size: 24px;
          line-height: 150%;
          color: $t-dark;
          margin-bottom: 60px;
          @include mobile {
            font-size: 16px;
            margin-bottom: 1rem;
            font-weight: 400;
          }
        }
      }

      .course-box {
        .ch {
          margin-bottom: 20px;
          @include mobile {
            margin-bottom: 0.5rem;
          }
        }
        .img-l {
          margin-left: 30px;
          @include mobile {
            margin-left: 0;
          }
        }
        .img-r {
          margin-right: 30px;
        }
        &_mt {
          margin-top: 55px;
          @include mobile {
            margin-top: 0.5rem;
          }
        }
        &_mb {
          margin-bottom: 116px;
          @include mobile {
            margin-bottom: 2rem;
          }
        }
        &_mr {
          margin-right: -12px;
          @include mobile {
            margin-right: 0;
          }
        }
        &_mt1{
          margin-top:-11px
        }
        &_ml {
          margin-left: -70px;
          @include mobile {
            margin-left: 0;
          }
        }
      }
    }
  }
  .p-test {
    .mt {
      margin-top: 112px;
      @include mobile {
        margin-top: 64px;
      }
    }
    .pre-img-w{
      height: 130px;
      position: relative;
      @include mobile {
        float: left;
      }
    }
    .left-content {
      border-top: 2px solid #eaeaea;
      border-left: 2px solid #eaeaea;
      border-bottom: 2px solid #eaeaea;
      box-shadow: -41px 41px 36px rgba(0, 0, 0, 0.0545509);
      border-bottom-left-radius: 20px;
      padding: 3.25rem;
      @include mobile {
        padding: 1.5rem 1rem 1rem;
        border: none;
        box-shadow: none;
      }
      .name {
        @include mobile {
          float: left;
          margin-left: 1rem;
        }
      }
    }
    .info {
      background: $b-gray-1;
      border-radius: 0.75rem;
      h5 {
        // font-size: 1.75rem;
        color: $t-green;
        margin-bottom: 4px;
        @include mobile {
          font-size: 1.25rem;
        }
      }
      p {
        line-height: 150%;
      }
    }
    .list-content {
      width: calc(100% - 22px);
      line-height: 150%;
    }
    .line {
      width: 3.75rem;
      height: 0.5rem;
      background: $b-green;
      border-radius: 0.75rem;
      margin: 1.5rem 0;
    }
    .right-content {
      background-color: #00c6ab;
      background-image: url('#{$image-path}/test/right-bg.png');
      background-position: top center;
      border-radius: 1.25rem;
      background-repeat: no-repeat;
    }
    .p-right {
      margin-bottom: 1.25rem;
    }
    .wh-auto {
      margin: 0 auto;
    }
    .mobile-tab {
      border: $b-green 1px solid;
      border-radius: 4px;
      margin: 0 2rem 1rem;
      color: $t-green;
      .active {
        color: white;
        background: $b-green;
      }
    }
  }
  &__course {
    background-size: cover;
    background: url('#{$img-path}/map.png') no-repeat center;
    .p_r {
      .text {
        margin-right: 0px;
      }
      .cp {
        margin-right: 0px;
      }
    }
    .pr40 {
      // margin-right: 40px !important;
    }
    &_mb {
      margin: 30px 0 110px;
      .text {
        margin-bottom: 12px;
        margin-right: 40px;
      }
      .cp {
        margin-right: 40px;
      }
    }
    .box-container {
      height: 100%;
      padding: 42px;
      background: $t-light;
      border: 2px solid #eaeaea;
      box-shadow: -41px 41px 36px rgba(0, 0, 0, 0.0545509);
      border-radius: 12px;
      @include mobile {
        padding: 1.5rem;
      }
      .title {
        margin-bottom: 32px;
        .icon {
          margin-right: 26px;
          margin-top: 5px;
          width: 6px;
          height: 26px;
          background: $b-green;
          border-radius: 8px;
        }
      }
      .tool_cr {
        margin-bottom: 40px;
        .aic {
          align-items: center;
          display: flex;
        }
        .tl {
          margin-bottom: 16px;
        }
        img {
          margin-right: 12px;
        }
        @include mobile {
          margin-bottom: 1.5rem;
        }
      }
      .bg {
        background: $b-orange-2;
        border-radius: 12px;
        padding: 24px;
        height: 100%;
        text-align: center;
        .text-mg {
          margin: 12px 0 16px;
          @include mobile {
            margin: 0;
          }
        }
        @include mobile {
          .next-img {
            width: 50%;
            margin: 0 auto;
          }
        }
      }
    }
  }
}
