//Slider
.slideout-menu
    position: fixed
    left: 0
    top: 0
    bottom: 0
    right: 0
    z-index: 0
    width: 256px
    overflow-y: auto
    -webkit-overflow-scrolling: touch
    display: none

.slideout-panel
    position: relative
    z-index: 1
    will-change: transform

.slideout-open
    overflow: hidden

    body, .slideout-panel
        overflow: hidden

    .slideout-menu
        display: block

.fixed
    backface-visibility: hidden
    position: fixed
    z-index: 2
    transition: transform 300ms ease

.fixed-open
    transform: translate3d(256px, 0px, 0px)

//@media screen and (min-width: 780px)
//  .slideout-panel
//    margin-left: 256px
//  .slideout-menu
//    display: block
//  .btn-hamburger
//    display: none

//Popover
.popover
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25)
    border-color: #ddd
    padding: 0

.popover-title
    color: $t-gray-1
    font-weight: 700
    border-radius: 3px 3px 0 0
    border: 0
    background-color: #f5f5f5
    padding: 15px 14px

//Popups
.modal-backdrop, .modal-backdrop.fade.in
    opacity: 0.3

.modal-content
    box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.07)
    border: 1px solid rgba(0, 0, 0, 0.2)
    background: #fff
    border-radius: $modal-content-radius
    position: relative

    @media screen and (max-width: $break-small)
        border-radius: 0

        //min-height: 100vh

@media screen and (max-width: $break-small)
    img.modal-content
        min-height: initial !important

@media screen and (max-width: $break-small)
    //#wechatloginModal
    //  .modal-dialog
    //    top: 20%
    //#wechat_container
    //  width: initial !important
    //  height: initial !important
    //  min-height: initial !important

.modal-header
    padding: 4rem 3rem 0
    text-align: center
    border-bottom: none

    h4
        font-size: 20px
        font-weight: bold
        color: $text_color
        margin: 0

    @media screen and (max-width: $break-small)
        padding: 4rem 3rem 0

        h4
            font-size: 18px

    .close
        margin: 0
        font-weight: normal
        font-size: 30px
        line-height: 30px
        position: absolute
        right: 1.5rem
        top: 1.25rem

        span
            vertical-margin: middle

        &:hover
            background: none

.modal
    //z-index: 9000000
    min-width: 320px

.modal-body
    padding: 2em 3em 6em

    @media screen and (min-width: $break-device)
        padding: 2em 4em 6em

        label
            float: left
            width: 70px

    @media screen and (max-width: $break-small)
        padding: 2em

.modal-dialog
    @media screen and (min-width: 768px)
        margin: 8em auto

    @media screen and (max-width: $break-small)
        margin: 0

.modal-footer
    padding: 15px
    text-align: right
    border-top: 1px solid #edeeee

.modal#Testi, .modal#Testi_top
    z-index: -100
    padding-top: 0%

    .modal-content, .modal-dialog, .modal-body
        width: 800px !important
        height: 450px
        max-height: 540px !important
        padding: 0

    #wistia_r3rmikm9jp, iframe, #wistia_embed, #wistia_embed_top
        width: 800px
        height: 450px

#scrollUp
    bottom: 20px
    right: 20px
    padding: 10px 20px
    background: #555
    color: #fff

//Phone verification popup
.modal.fade#phoneModal
    @media screen and (max-width: $break-small)
        .text-input
            width: 100%

        .demo-code
            margin: 10px 0 0 !important
            padding: 0 !important

    @media screen and (max-width: 640px)
        top: 2.5%
        width: 95%
        left: 0
        right: 0
        height: 95%
        margin: auto

    .modal-body
        @media screen and (max-width: 640px)
            padding: 5%

        small
            padding: 15px 0

        .phone_number
            padding: 14px 0

        .countdown
            display: none
            float: left
            font-size: 13px
            color: #999999 !important
            width: 200px
            margin:
                left: 70px

            @media screen and (max-width: $break-small)
                float: none
                margin: 10px 0

        #resend.disabled
            color: #999999 !important

            &:hover
                color: #999999 !important
                text-decoration: none !important

        #resend
            float: left
            margin-left: 70px
            font-size: 13px

            @media screen and (max-width: 640px)
                float: none
                margin: 0

//Demo popup
.modal#demo_user_modal
    .btn.medium_text
        @media screen and (max-width: $break-device)
            font-size: 14px !important

//Question Time Limit Popup
.modal#questions_time_limit
    .modal-header
        background: white

    .modal-body
        width: 100%

        p
            margin: 0 0 1.5em
            font-size: 18px

//Course select popup
.modal.fade#programModal
    label
        width: 120px
        text-align: right
        padding-right: 20px

//Add Course popup
#courseModal
    form.course_add
        label
            width: auto
            float: none
            display: inline-block
            padding: 0
            line-height: 46px

        .wd-30 label
            @media screen and (min-width: $break-small)
                font-size: 16px

        .wd-70 input
            background: white

        .cannot_find_course
            cursor: pointer

//Guides
.guides
    .ui-widget
        font-family: $body_fonts

        .ui-accordion-header
            border: none
            border-bottom: 1px solid $bd-gray
            border-radius: 0
            background: white
            font-weight: normal
            padding: 1em !important
            margin-top: 0 !important
            font-size: 17px

            &:hover, &:focus
                text-decoration: none

                .ui-accordion-header-icon.ui-icon-triangle-1-s
                    opacity: 1

            .ui-accordion-header-icon
                margin-top: 0 !important
                top: 41% !important

            @media screen and (min-width: $break-device)
                font-size: 21px

    .accordion-inner
        background: white
        border: none
        border-radius: 0
        height: auto
        padding: 40px 0 !important
        border-bottom: 1px solid #eee

        @media screen and (max-width: $break-device)
            padding: 1.5em 0 !important

        .guide_content
            margin: auto
            word-wrap: break-word
            padding: 0 1em

            @media screen and (min-width: $break-device)
                width: 80%
                padding: 0

    .ui-accordion-header-icon.ui-icon-triangle-1-s
        opacity: 0.5
        width: 18px
        height: 12px
        right: 1em !important
        left: auto !important

    .accordion-toggle, .accordion-toggle.collapsed
        .ui-accordion-header-icon.ui-icon-triangle-1-s
            background: image-url("#{$image-path}/icons/down-dark.svg") no-repeat
            background-size: 18px 12px

    .accordion-toggle
        .ui-accordion-header-icon.ui-icon-triangle-1-s
            background: image-url("#{$image-path}/icons/up-dark.svg") no-repeat
            background-size: 18px 12px

    @media screen and (max-width: $break-device)
        padding: 0
        margin: 0 !important

        .ui-accordion-header-icon
            background-size: 15px 10px !important
            width: 15px

.bounce
    margin-top: -25px
    -webkit-animation: bounce 1s infinite

//Date Picker
.ui-datepicker
    background: white !important
    border-color: #d8dadf !important

    th
        color: $t-gray-1 !important

    .ui-widget-header
        border: none !important
        background: #f5f6fa !important
        color: $t-gray-1 !important
        padding: 10px !important

        .mtz-monthpicker-year
            margin: 0
            width: 100% !important

    .ui-state-default
        color: $t-gray-1 !important
        background: white !important
        border-color: #d8dadf !important

    .ui-state-highlight, .ui-state-hover
        background: #f5f6fa !important
        border-color: #d8dadf !important
        color: #222 !important

    .ui-icon
        margin-top: (-10) !important
        background-image: image-url("#{$image-path}/jquery-ui/ui-icons_222222_256x240.png") !important

    .ui-datepicker-prev, .ui-datepicker-next
        top: 10px !important

@-webkit-keyframes bounce
    0%
        bottom: 5px

    25%, 75%
        bottom: 15px

    50%
        bottom: 20px

    100%
        bottom: 0

//Major popup
.modal-body.major-info
    padding: 30px

    ul
        line-height: 1.6em
        margin: 0.5em 0 1em 0

//Parents Dashboard
.parents_parent_dashboard
    .content
        margin-bottom: 0

    .top_section
        @media screen and (max-width: $break-device)
            .date_section, .login
                float: none

            .login
                margin-top: 0.5em

                h4
                    font-size: 13px

    .chart-container
        border-radius: 6px
        border: 1px solid #d8dadf
        margin: 20px 0
        box-shadow: 0 2px 2px rgba(0, 0, 0, 0.03)

        .chart-title
            background: #f5f6fa
            border-bottom: 1px solid #E1E4EA
            border-radius: 4px 4px 0 0
            padding: 15px

            @media screen and (max-width: $break-device)
                padding: 0.5em

        .btn
            @media screen and (max-width: $break-device)
                padding: 0.75em !important
                font-size: 16px !important

        .highchart-wrap
            background: white
            padding: 20px 10px 10px
            border-radius: 0 0 4px 4px

            .highcharts-container
                margin: auto

                .highcharts-axis-labels, .highcharts-legend-item
                    text
                        color: $t-gray-1 !important
                        fill: $t-gray-1 !important

                .highcharts-yaxis-labels
                    text
                        font-weight: bold !important

                .highcharts-grid
                    path
                        stroke: #E4E5E8
                        stroke-dasharray: 3px 1px

                        &:first-child
                            stroke: #E1E4EA
                            stroke-dasharray: 0
                            stroke-width: 2px

//Events
body[location='event_pages']
    .modal
        z-index: 50 !important

    .modal.fade.in
        z-index: 1050 !important

//QA rating
.modal#ratingModal
    .modal-body
        padding: 30px

        textarea
            width: 70%

    #qa_comment_rating
        visibility: hidden
        height: 0px
        width: 0px
        padding-top: 1px
        margin: 0
        border: 0
        padding-bottom: 1px

    .star
        background-image: image-url("#{$image-path}/jstar.svg") !important
        width: 32px !important
        height: 32px !important

//First QA
.modal#qa_first_question_asked
    padding-right: 0 !important

//FAQ
div[location='faq_pages']
    .modal
        .modal-dialog
            width: 800px
            margin: 3em auto !important

        .modal-content
            width: 800px
            height: 540px

            .modal-body, #wistia_embed_top
                width: 100%
                height: 100%
                padding: 0

        @media screen and (max-width: $break-device)
            .modal-dialog, .modal-content, #wistia_embed_top
                width: 100% !important

    .modal-backdrop
        opactiy: 0.8

    #demo_user_modal.modal
        .modal-content
            height: initial

        .modal-body
            padding: 2em 4em 6em

body[location='grades_pages']
    .modal-content
        top: 0
        bottom: 0
        position: absolute
        left: 0
        right: 0
        margin: auto

//Dashboard Calendar
//body[location='dashboard_users']
//  .calendar
//    .mw10
//      padding-top: 76px
//      @media screen and (min-width: $break-small)
//        padding-top: 138px
//  .monthly
//    background-color: white
//    position: static
//  .monthly-header
//    position: absolute
//    left: 0
//    right: 0
//    width: 100%
//    top: 0
//    height: 56px
//    margin: auto
//    padding: 0.5rem 0 0
//    @media screen and (min-width: $break-small)
//      border-bottom: 1px solid $bd-gray
//      height: 74px
//      padding: 0.5rem 0 2rem
//  @media screen and (max-width: 568px)
//    .monthly-day .monthly-event-indicator
//      width: 5px
//      height: 5px
//      margin-top: 6px
//      background-color: #48E0CA
//    .monthly-indicator-wrap
//      padding-top: 0
//      margin-top: -15px
//
//  .monthly-day-event > .monthly-day-number
//    line-height: 48px
//    @media screen and (max-width: 568px)
//      line-height: 38px
//  .monthly-day-event
//    @media screen and (min-width: 25em)
//      padding-bottom: 0.3rem
//      padding-top: 1rem
//  .monthly-today .monthly-day-number
//    width: 48px
//    height: 48px
//    background-color: $green
//    box-shadow: 0 2px 8px 0 rgba(133, 226, 202, 0.6)
//    border-radius: 50%
//    color: white
//    font-weight: bold
//    @media screen and (max-width: 568px)
//      width: 38px
//      height: 38px
//
//  .monthly-prev, .monthly-next
//    height: 44px !important
//    opacity: 0.3 !important
//    &:after
//      border-color: $text_color !important
//      @media screen and (min-width: $break-small)
//        height: 0.75rem !important
//        width: 0.75rem !important
//    &:hover
//      opacity: 1 !important
//    @media screen and (min-width: $break-small)
//      height: 56px !important
//  .monthly-prev:after
//    border-width: 0 0 2px 2px !important
//  .monthly-next:after
//    border-width: 2px 2px 0 0 !important
//
//  .monthly-header-title a:link
//    background-color: transparent !important
//    border: 0 !important
//    font-size: 18px !important
//    letter-spacing: 3px
//    font-weight: 500 !important
//    @media screen and (max-width: $break-small)
//      font-size: 16px !important
//
//  .monthly-day-title-wrap
//    font-size: 16px
//    font-weight: 500
//    color: $silver
//    padding-bottom: 4%
//    @media screen and (max-width: $break-small)
//      padding-bottom: 1.25rem
//      font-size: 12px
//      font-weight: 600
//
//  .monthly-day-wrap
//    font-size: 16px
//  .monthly-day-blank
//    background: white !important
//  .monthly-day:hover,.monthly-day-event.active
//    cursor: pointer
//    .monthly-day-number
//      border-radius: 50%
//      border: 1px solid $green
//      width: 48px
//      height: 48px
//      @media screen and (max-width: 568px)
//        width: 38px
//        height: 38px
//  .monthly-day
//    @media screen and (max-width: $break-small)
//      padding-top: 0.25rem !important
//      padding-bottom: 0.5rem
//  .monthly-today:hover .monthly-day-number
//    border: none !important

//Timetable
body[location='user_timetable']
    #calendar
        .fc-toolbar
            margin: 0

            .fc-agendaWeek-button
                display: none

        .fc-axis.fc-time
            color: $t-gray-1
            text-transform: uppercase
            font-weight: 500
            font-size: 12px
            text-align: left

        .fc-event
            cursor: pointer
            background-color: #f3f1fd
            border: 0
            color: #4a4a4a
            font-size: 1rem
            padding: 5px 10px
            border-radius: 4px

            .fc-time
                padding-bottom: 0
                font-size: 11px

            .fc-title, .fc-time
                padding: 0

            .fc-title
                font-size: 14px
                font-weight: 500

        .fc-widget-content
            border-color: #E8E8E8

.timepicker
    a[data-action='incrementHours'], a[data-action='incrementMinutes']
        background: white !important
        padding: 5px !important

        &:after
            content: "▲"
            color: $t-dark-active

    a[data-action='decrementHours'], a[data-action='decrementMinutes']
        background: white !important
        padding: 5px !important

        &:after
            content: "▼"
            color: $t-dark-active

    .table-condensed
        border: 1px solid #eee

        td
            padding: 4px 10px !important

//Service
body[location='dashboard_users']
    .service-block
        .tooltip
            font-size: 14px
            font-weight: bold
            line-height: 1.5em

.logged_in
    @media screen and (max-width: $break-small)
        #intercom-container
            display: none

//Progress Bar
.loading-ctn
    width: 100%

    .bar
        border-radius: 30px
        box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1)
        background: $b-gray-2
        border: 1px solid #fafafa
        overflow: visible

        @media screen and (max-width: $break-small)
            border-radius: 5px

        .ui-progressbar-value
            background: $b-green
            box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.1)
            border-radius: 30px
            border: none

            @media screen and (max-width: $break-small)
                border-radius: 5px
