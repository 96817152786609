///* ========================================================================
//   #Section
//   ======================================================================== */

// The section element represents a generic section of a document or application.
// A section, in this context, is a thematic grouping of content.
// The theme of each section should be identified, typically by including a heading
// (h1-h6 element) as a child of the section element. In most cases, a <section> tag
// should be used for markup.

// Padding
$section-mobile-padding-y: $gl-spacer-2xl;
$section-padding-y: $gl-spacer-2xl;
$section-padding-y-lg: $gl-spacer-3xl;
$section-padding-y-xl: $gl-spacer-4xl;

// Background
$section-background-white: $theme-light-lt;
$section-background-light-ltr: $theme-primary-ltst;
$section-hero-height: 368px;
$section-hero-height-mobile: 170px;

// Header
$section-header-font-weight: 500;
$section-header-font-size-3xl: 64px; // 60px
$section-header-font-size-2xl: $gl-font-size-h1; // 48px
$section-header-font-size-1xl: 42px; // 48px
$section-header-font-size-xl: $gl-font-size-h2; // 40px
$section-header-font-size-l: $gl-font-size-h3; // 28px
$section-header-font-size-m: $gl-font-size-h4; // 24px
$section-header-font-size-s: $gl-font-size-h5; // 20px
$section-header-font-size-xs: $gl-font-size-sub-header; // 18px

// Font Size
$section-text-font-size-base: $gl-font-size-base;
$section-text-font-size-m: $gl-font-size-h3g;

.o-section {
  //padding-top: rem($section-mobile-padding-y);
  padding-top: 7rem !important;
  padding-bottom: 7rem !important;

  @include desktop {
    padding-top: rem($section-padding-y);
    padding-bottom: rem($section-padding-y);
  }

  @include mobile {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }

  // Hero Banner
  &__hero {
    display: table;
    height: rem($section-hero-height-mobile);

    @include desktop {
      height: rem($section-hero-height);
    }

    &__inner {
      display: table-cell;
      vertical-align: middle;
    }
  }

  // Backgrounds
  &--white {
    background: $section-background-white;
  }
  &__gr {
    background: $b-green-dark;
  }
  &__bottom {
    &__title {
      font-weight: 600;
      font-size: 42px;
      line-height: 150%;
      color: $t-light;
    }
    &__f_title {
      font-size: 18px;
      line-height: 150%;
      color: $t-light;
    }
  }

  &--lt {
    background: $b-gray-2;
  }
  &__pd {
    padding: 97px 0;
  }

  &__bt__bg {
    background: url('/images/pages/index/bottom_bg.png') center;
    background-size: cover;
  }
  &--ltr {
    background: $section-background-light-ltr;
  }

  &--dk {
    background: $b-gray-2;
  }

  &--lg {
    @include desktop {
      padding-top: $section-padding-y-lg;
      padding-bottom: $section-padding-y-lg;
    }
  }

  &--xl {
    @include desktop {
      padding-top: $section-padding-y-xl;
      padding-bottom: $section-padding-y-xl;
    }
  }

  // Gradients
  @each $name, $gradient in $theme-gradients {
    &--#{$name} {
      background-image: $gradient;
    }
  }

  // Header
  &__header {
    margin-top: 0;
    font-weight: $section-header-font-weight;

    &--3xl {
      font-size: rem($section-header-font-size-1xl);
      font-weight: normal;
      letter-spacing: 3px;

      @include desktop {
        font-size: rem($section-header-font-size-3xl);
      }
    }

    &--2xl {
      font-size: rem($section-header-font-size-l);
      margin: 0;

      @include desktop {
        font-size: rem($section-header-font-size-2xl);
      }
    }

    &--xl {
      font-size: rem($section-header-font-size-l);
      margin-bottom: rem($gl-spacer-l);

      @include desktop {
        font-size: rem($section-header-font-size-xl);
      }
    }

    &--l {
      font-size: rem($section-header-font-size-s);
      margin-bottom: rem($gl-spacer-l);

      @include desktop {
        font-size: rem($section-header-font-size-l);
      }
    }

    &--m {
      font-size: 15px;

      @include desktop {
        font-size: rem($section-header-font-size-m);
      }
    }

    &--s {
      font-size: rem($section-text-font-size-base);

      @include desktop {
        font-size: rem($section-header-font-size-s);
      }
    }

    &--xs {
      font-size: rem($section-text-font-size-base);
      @include desktop {
        font-size: rem($section-header-font-size-xs);
      }
    }
  }

  // Text
  &__text {
    font-size: rem($gl-font-size-base);
    @include mobile {
      font-size: 15px;
    }
    &--m {
      font-size: rem($section-text-font-size-base);

      @include desktop {
        font-size: rem($section-header-font-size-s);
      }
    }
  }

  // Paragraph
  &__paragraph {
    line-height: 2;
    font-size: rem($gl-font-size-base);

    &--sm {
      font-size: rem($gl-font-size-s);
    }
  }
}
