/* ==========================================================================
   #DOCUMENT INDEX
   ========================================================================== */
$p-docs-intro-padding: rem($gl-spacer-3xl) 0;
$p-docs-intro-padding-mobile: rem($gl-spacer-l) 0;
$p-docs-header-font-size: rem($gl-font-size-h3);
$p-docs-header-font-size-mobile: rem($gl-font-size-h4);
$p-docs-text-font-size: rem($gl-font-size-base);
$p-docs-course-title-font-size: rem($gl-font-size-h4);
$p-docs-course-title-font-size-mobile: rem($gl-font-size-h6);

.p-docs {
  // Intro
  &__intro {
    padding: $p-docs-intro-padding;
    background: url('#{$image-path}/pages/sg-banner.svg') no-repeat center top;
    background-size: cover;

    @include mobile {
      padding: $p-docs-intro-padding-mobile;
    }

    &__header {
      font-weight: 600;
      font-size: $p-docs-header-font-size;
      margin-bottom: $gl-spacer-s;
      margin-top: 0;

      @include mobile {
        font-size: $p-docs-header-font-size-mobile;
      }
    }

    &__text {
      color: $t-gray-1;
      margin-bottom: 0;

      @include desktop {
        font-size: $p-docs-text-font-size;
      }
    }

    &__inner {
      @include mobile {
        background: $theme-light-lt;
        padding: rem($gl-spacer-l);
      }
    }
  }

  // Tabs
  &__tabs {
    padding: rem($gl-spacer-s) 0;
    background: $theme-primary-ltst;

    &__item {
      margin-right: rem($gl-spacer-xl);
      display: inline-block;

      &.active {
        .p-docs__tabs__link {
          color: $t-dark;
        }
      }
    }

    &__link {
      color: $theme-dark-lt;
      font-weight: 500;
    }
  }

  // Block
  &__block {
    border: 1px solid $bd-gray;
    padding: rem($gl-spacer-m);
    display: block;

    &:hover {
      box-shadow: $gl-box-shadow-sm;
    }
  }

  // Sidebar
  &__sb {
    &__list {
      margin: 0;

      &__link {
        padding: rem($gl-spacer-xs) rem($gl-spacer-s);
        font-weight: 500;
        color: $t-dark;
        display: block;

        &:hover,
        &.active {
          background: $theme-primary-ltr;
        }

        @include mobile {
          border-bottom: 1px solid $bd-gray;
          padding: rem($gl-spacer-xs) 0;
        }
      }

      .show:last-child {
        margin-bottom: rem($gl-spacer-m);
      }
    }

    @include mobile {
      border-bottom: 16px solid $bd-gray;
      margin-bottom: rem($gl-spacer-m);
    }
  }

  // Main
  &__main {
    @include mobile {
      margin: 0;
    }
  }

  // Course
  &__course {
    &__title {
      font-weight: 600;
      font-size: $p-docs-course-title-font-size;
      display: inline-block;
      margin-right: rem($gl-spacer-xxs);
      margin-bottom: 0;
      vertical-align: middle;
      // color: #018383;
      @include mobile {
        font-size: $p-docs-course-title-font-size-mobile;
      }
    }

    &__item {
      overflow-x: auto;
      flex-wrap: nowrap;
      @include mobile{
        flex-wrap: wrap;
        &>div{
          margin-bottom: 10px;
        }
      }
    }
  }

  //Document
  &__list__item {
    padding: 24px 0;
    border-bottom: 1px solid $bd-gray;

    &:last-child {
      border-bottom: none;
    }

    &:hover {
      .c-document__title {
        text-decoration: underline;
      }
    }
  }

  // Primer Thumbnail
  &__primer {
    opacity: 0.5;
  }
}
