// Task
$task-spacing: $gl-spacer-xs;
$task-icon-size: 10px;
$task-border-radius: $gl-radius-md;

.p-famous {
  &__na {
    &__intro {
      background: url('#{$image-path}/pages/famous/na-intro-bg.jpg') no-repeat
        center;
      background-size: cover;
      height: 560px;
    }
    &__info {
      h2 {
        font-size: 2.625rem;
        color: $t-green;
      }
    }
    &__position {
      background-image: url('#{$image-path}/pages/famous/na-tutor-position-new.png');
      background-color: rgba(0, 0, 0, 0.04);
      background-size: cover;
      background-position: top center;
      .usa {
        background-image: url('#{$image-path}/pages/famous/usa.png');
        background-color: white;
        background-size: cover;
        box-shadow: 0px 34px 40px rgba(0, 0, 0, 0.05);
        border: 1px solid rgba(0, 0, 0, 0.05);
        border-radius: 0.875rem;
      }
      .canada {
        background-image: url('#{$image-path}/pages/famous/canada.png');
        background-color: $t-light;
        background-size: cover;
        box-shadow: 0px 34px 40px rgba(0, 0, 0, 0.05);
        border: 1px solid rgba(0, 0, 0, 0.05);
        border-radius: 0.875rem;
      }
      .school {
        margin: 2rem 1.5rem 2rem 0;
        border: 1px solid $b-green;
        border-radius: 0.5rem;
        span {
          font-size: 0.75rem;
          color: $t-gray-1;
          margin-bottom: 0.2rem;
        }
      }
    }
    &__tutors {
      .img {
        background: $t-light;
        border: 0.5px solid rgba(151, 151, 151, 0.3) !important;
      }
      h6 {
        margin: 0.4rem 0;
      }
    }
  }
  &__anu {
    &__intro {
      background: url('#{$image-path}/pages/famous/anu-bg.jpg') no-repeat center;
      background-size: cover;
      height: 560px;
    }
    &__info {
      h2 {
        font-size: 2rem;
        color: $t-green;
      }
    }
    &__position {
      background-image: url('#{$image-path}/pages/famous/na-tutor-position-new.png');
      background-color: rgba(0, 0, 0, 0.04);
      background-size: cover;
      .usa {
        background-image: url('#{$image-path}/pages/famous/aus-bg.png');
        background-color: white;
        background-size: cover;
        box-shadow: 0px 34px 40px rgba(0, 0, 0, 0.05);
        border: 1px solid rgba(0, 0, 0, 0.05);
        border-radius: 0.875rem;
      }
      .canada {
        background-image: url('#{$image-path}/pages/famous/england-bg.png');
        background-color: white;
        background-size: cover;
        box-shadow: 0px 34px 40px rgba(0, 0, 0, 0.05);
        border: 1px solid rgba(0, 0, 0, 0.05);
        border-radius: 0.875rem;
      }
      .school {
        margin: 2rem 1.5rem 2rem 0;
        border: 1px solid $b-green;
        border-radius: 0.5rem;
        span {
          font-size: 0.75rem;
          color: $t-gray-1;
          margin-bottom: 0.2rem;
        }
      }
    }
    &__tutors {
      h6 {
        margin: 0.4rem 0;
      }
    }
  }
  &__system {
    &__intro {
      background: url('#{$image-path}/pages/famous/ecological-system-bg.jpg')
        no-repeat center;
      background-size: cover;
      height: 560px;
      // @include mobile{
      //   background: url('#{$image-path}/pages/famous/ecological-system-bg.jpg')
      //   no-repeat 30px center;
      // }
    }
    &__essay {
      background-color: $b-green-dark;
      background-image: url('#{$image-path}/pages/famous/recruit-bg.png');
      background-size: cover;
      color: white;
      @include mobile {
        img {
          float: left;
        }
        h5 {
          margin: 0 !important;
          padding-bottom: 12px;
          // padding-top: 0.5rem;
        }
        .item {
          margin-top: 2rem;
        }
      }
    }
    &__platform {
      background: $b-gray-1;
      .num__mv {
        margin: 0px calc(10% - 1.25rem);
      }
      .num {
        width: 2.5rem;
        height: 2.5rem;
        line-height: 2.3rem;
        border: 2px solid rgba(0, 0, 0, 0.05);
        text-align: center;
        border-radius: 50%;
      }
      .line_m{
        height: 72px;
        border: 0.5px solid rgba(0, 0, 0, 0.1);
        width: 0;
        margin:12px auto;
      }
      .line {
        width: 100%;
        height: 0;
        border: 0.5px solid rgba(0, 0, 0, 0.1);
        margin: auto 2rem;
        @include mobile {
          display: none;
        }
      }
      @include mobile {
        .item {
          flex: 0 0 100%;
        }
        .none {
          display: none;
        }
      }
    }
    &__mentor {
      .item {
        text-align: center;
        margin: auto 0;
      }
      a {
        cursor: default !important;
      }
      .mt-2 {
        margin-top: -2rem;
      }
      .rings_img{
        height: 422px;
        position: relative;
      }

      .rings {
        height: 422px;
        background: url('#{$image-path}/pages/famous/system/rings.png');
        background-size: cover;
      }

      .mobile-mentor {
        display: none;
        @include mobile {
          display: block;
          .img-w{
            width: 132px;
          }
          .img {
            height: 132px;
            position: relative;
          }
          .text {
            margin: auto 0 auto 1.5rem;
          }
          .arrow {
            margin:16px 0;
            height: 28px;
            position: relative;
          }
        }
      }
      @include mobile {
        .none {
          display: none;
        }
      }
    }
  }
}
