/* ==========================================================================
   #BUTTON MIXINS
   ========================================================================== */

// Button sizes
@mixin button-size($pv, $ph, $font-size, $line-height) {
  padding: $pv $ph;
  font-size: $font-size;
  line-height: $line-height;
}

// Button variants
@mixin button-variant(
  $background,
  $color,
  $important: null
) {
  @include hover-focus {
    background: tint($background, 20%) $important;
    border-color: tint($background, 20%) $important;
    color: tint($color, 20%) $important;
  }

  &,
  &.is-disabled,
  &:disabled {
    background-color: $background $important;
    border-color: $background $important;
    color: $color $important;
  }
}

// Outline button variants
@mixin button-outline-variant(
  $background,
  $color,
  $border-color,
  $important: null
) {
  @include hover-focus {
    background: $background;
    border-color: tint($border-color, 20%) $important;
    color: tint($color, 20%) $important;
  }

  &,
  &.is-disabled,
  &:disabled {
    background: $background;
    border-color: $border-color;
    color: $color;
  }
}
