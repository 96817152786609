// LT Booking
%truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.c-lt-booking {
  padding: rem($gl-spacer-m) rem($gl-spacer-s) rem($gl-spacer-m) 0;
  display: block;
  color: $t-dark-active;

  @include desktop {
    padding: rem($gl-spacer-m) rem($gl-spacer-m) rem($gl-spacer-m) 0;
  }

  @include hover {
    background: tint($b-gray-2, 80%);
  }

  & + & {
    border-top: 1px solid $b-gray-2;
  }
.green{
  color: #018989;
}
  // Status
  &__status {
    font-size: rem($gl-font-size-xxs);
    font-weight: 500;

    @include desktop {
      font-size: rem($gl-font-size-base);
    }

    &--primary {
      color: #52b4a8;
    }

    &--accent {
      color: $theme-accent;
    }

    &--alert {
      color: $t-red;
    }

    &--alt {
      color: $t-gray-1;
    }
  }

  // Time
  &__time {
    font-size: rem($gl-font-size-xxs);
    font-weight: 500;
    display: inline-block;
    @include desktop {
      font-size: rem($gl-font-size-base);
    }

    &--end {
      @include mobile {
        color: $t-gray-1;
      }
    }
  }

  // Title
  &__title {
    @extend %truncate;

    font-size: rem($gl-font-size-base);
    font-weight: 500;

    @include mobile {
      margin-bottom: rem($gl-spacer-xxs);
    }
  }

  // Name
  &__name {
    @extend %truncate;

    display: inline-block;
    vertical-align: middle;

    @include mobile {
      font-size: rem($gl-font-size-xxs);
      color: $t-gray-1;
    }

    &--md {
      font-size: $gl-font-size-base;
    }
  }

  // Avatar
  &__avatar {
    display: inline-block;
    vertical-align: middle;
    width: 20px;
    height: 20px;
    margin-right: 4px;

    @include desktop {
      width: rem($gl-asset-size-md);
      height: rem($gl-asset-size-md);
      margin-right: rem($gl-spacer-xxs);
    }

    &--md {
      width: 56px;
      height: 56px;
      margin: 0;

      @include desktop {
        width: 56px;
        height: 56px;
        margin: 0;
      }
    }
  }

  // Rate
  &__rate {
    @include desktop {
      text-align: center;
    }
  }
  &__scroll {
    position: absolute;
    top: -65px;
    visibility: hidden;
    height: 0;
  }
}

.booking-receipt.tooltip > .tooltip-inner {
  max-width: 300px;
  /* If max-width does not work, try using width instead */
  width: 300px;
  font-size: 12px;
}
.booking-receipt.tooltip > .tooltip-inner .l-row {
  padding: 2px 0;
}
.completed-filter .radios input {
  margin-top: -5px;
}
.completed-filter .radios label {
  margin-left: 5px;
  margin-right: 20px;
  padding: 5px 0;
  min-width: 80px;
}
