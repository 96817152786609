// Elite tutors
// ===== Tab ====
$elite-tabs-margin-bottom: $gl-spacer-m;

// ===== Highlights ====
$p-elite-map-height: 920px;

// ===== Gallery ====
$gallery-height: 164px;
$gallery-height-desktop: 475px;

// ===== Info ====
$p-elite-topic-bg-hover: $theme-primary-ltr;
$p-elite-topic-padding: $gl-spacer-s;

// ===== Profile ====
$p-elite-profile-height: 430px;
$p-elite-profile-width: 150px;
$p-elite-slide-height: 821px;
$p-elite-slide-width: 173px;
$p-elite-slide-image-height: 400px;
$p-elite-tutor-height: 360px;
$p-elite-profile-bg: #000;
$p-elite-profile-info-height: 76px;

// ===== Media ====
$p-elite-media-padding-x: 32px;
$p-elite-media-padding-x-desktop: 112px;
$p-elite-item-width: 144px;
$p-elite-item-height: 59px;

.p-elite {
  // Intro
  &__intro {
    background: url("#{$image-path}/pages/elite/intro.jpg") no-repeat center top;
    background-size: cover;

    &__inner {
      display: table-cell;
      vertical-align: bottom;
    }
  }

  // Tab Content
  &__tab-content {
    overflow: visible;
  }

  // Highlights
  &__highlights {
    position: relative;
    .c-slider {
      .slick-dots {
        button:before {
          font-size: rem($slider-dot-size);
        }

        .slick-active button:before {
          color: $t-green;
        }
      }

      .slick-active {
        &:hover {
          transform: scale(1.1) !important;
        }
        &.slick-current {
          > div {
            opacity: 1;
            margin-left: $gl-spacer-m;
            margin-right: $gl-spacer-m;
            transform: scale(1) !important;
            // box-shadow: 0 4px 30px 0 rgba(0, 0, 0, 0.20);
          }
        }
        > div {
          opacity: 0.8;
          margin-left: $gl-spacer-m;
          margin-right: $gl-spacer-m;
        }
      }
      .slick-next:before,
      .slick-prev:before {
        color: $slider-dot-color;
      }
      .swiper-button-next:before,
      .swiper-button-prev:before {
        color: $slider-dot-color;
      }
      .swiper-button-prev {
        &:after {
          content: url('#{$image-path}/pages/iss/chevron-left.svg');
        }
      }
      .swiper-button-next {
        &:after {
          content: url('#{$image-path}/pages/iss/chevron-right.svg');
        }
      }
      &--lt {
        .slick-prev {
          &:before {
            content: url('#{$image-path}/pages/high-school-academic/chevron-left.svg');
          }
        }

        .slick-next {
          &:before {
            content: url('#{$image-path}/pages/high-school-academic/chevron-right.svg');
          }
        }
      }

      &__item {
        &--center {
          opacity: 0.8;
          margin-left: $gl-spacer-m;
          margin-right: $gl-spacer-m;

          &.slick-current {
            transform: scale(1.3);
            opacity: 1;
            // box-shadow: 0 4px 30px 0 rgba(0, 0, 0, 0.20);
          }
        }
      }
    }
    // @include desktop {
    //   height: rem($p-elite-map-height);
    // }
  }

  &__stats {
    border: 2px solid $b-green;
    padding: rem($gl-spacer-s);
    border-radius: 100px;
    text-align: center;
    margin-bottom: rem($gl-spacer-l);

    &__item {
      border-right: 2px solid rgba(0, 0, 0, 0.07);

      &:last-child {
        border-right: none;
      }

      @include mobile {
        zoom: 0.8;
      }
    }
  }

  &__tabs {
    margin-bottom: rem($elite-tabs-margin-bottom);

    &__item {
      @include mobile {
        margin-left: 0 !important;
        margin-right: rem($gl-spacer-xs);
      }
    }
  }

  // Gallery
  &__gallery {
    width: 100%;
    box-shadow: $gl-box-shadow-m;

    @include desktop {
      height: rem($gallery-height-desktop);
    }
  }

  // Info
  &__topic {
    padding: rem($p-elite-topic-padding) 0;

    &:hover {
      background: $p-elite-topic-bg-hover;
    }
  }

  // Profile
  &__profile {
    background: $p-elite-profile-bg;

    &__text {
      color: rgba(255,255,255,0.7);
      margin-bottom: rem($gl-spacer-xl);
    }

    &__tutor {
      height: rem($p-elite-profile-height);
      padding: rem($gl-spacer-s);
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;

      @include desktop {
        transition: all 0.4s;

        &:hover {
          transform: scale(1.1);
        }
      }

      &__inner {
        height: rem($p-elite-profile-info-height);
      }

      &--tina {
        background: url("#{$image-path}/pages/elite/tina.jpg") top center no-repeat;
        background-size: cover;
      }

      &--phillip {
        background: url("#{$image-path}/pages/elite/phillip.jpg") top center no-repeat;
        background-size: cover;
      }

      &--zachary {
        background: url("#{$image-path}/pages/elite/zachary.jpg") top center no-repeat;
        background-size: cover;
      }

      &--nathan {
        background: url("#{$image-path}/pages/elite/nathan.jpg") top center no-repeat;
        background-size: cover;
      }

      &--melanie {
        background: url("#{$image-path}/pages/elite/melanie.jpg") top center no-repeat;
        background-size: cover;
      }

      &--jonathan {
        background: url("#{$image-path}/pages/elite/jonathan.jpg") top center no-repeat;
        background-size: cover;
      }

      &--dean {
        background: url("#{$image-path}/pages/elite/dean.jpg") top center no-repeat;
        background-size: cover;
      }

      &--last {
        background-color: #5f6a6e;

        @include desktop {
          display: none;
        }
      }

      &__name {
        font-weight: bold;
        text-transform: uppercase;
        font-size: $gl-font-size-xs;
        color: $t-light;
      }

      &__info {
        color: $t-light;
        font-size: $gl-font-size-xs;
      }

      &.active {
        border-bottom: 4px solid $b-green;
      }
    }
  }

  // Slides
  &__slides {
    border-bottom: 2px solid $bd-gray;
    position: relative;
    overflow-y: hidden;

    @include desktop {
      height: rem($p-elite-slide-height);
    }
  }

  &__slide {
    @include desktop {
      background-image: linear-gradient(to right, $theme-light-lt 50%, $p-elite-profile-bg 50%);
      background-color: $p-elite-profile-bg;
    }

    &__block {
      background-color: $theme-light-lt;
      padding: rem($gl-spacer-xl) rem($gl-spacer-m);

      @include desktop {
        height: rem($p-elite-slide-height);
        padding: rem($gl-spacer-3xl) 0;
      }
    }

    &__list {
      @include list-unstyled();

      font-weight: 500;
      font-size: rem($gl-font-size-base);

      li + li {
        margin-top: rem($gl-spacer-xs);
      }
    }

    &__bg {
      background: url("#{$image-path}/pages/elitetutors/slanted-bg.svg") top -1px left -1px no-repeat;
      background-size: cover;
      width: rem($p-elite-slide-width);
      height: rem($p-elite-slide-height);
    }

    &__image {
      @include mobile {
        height: rem($p-elite-slide-image-height);
        background-position: top center !important;
      }

      &--jean {
        background: $p-elite-profile-bg url("#{$image-path}/pages/elitetutors/jean.jpg") right -4rem bottom no-repeat;
      }

      &--jay {
        background: $p-elite-profile-bg url("#{$image-path}/pages/elitetutors/jay.jpg") right -2rem bottom no-repeat;
      }

      &--sophia {
        background: $p-elite-profile-bg url("#{$image-path}/pages/elitetutors/sophia.jpg") right -5rem bottom no-repeat;
      }

      &--scott {
        background: $p-elite-profile-bg url("#{$image-path}/pages/elitetutors/scott.jpg") right bottom no-repeat;
      }

      &--janice {
        background: $p-elite-profile-bg url("#{$image-path}/pages/elitetutors/janice.jpg") right -4rem bottom no-repeat;
      }

      &--teresa {
        background: $p-elite-profile-bg url("#{$image-path}/pages/elitetutors/teresa.jpg") right bottom no-repeat;
      }
    }
  }

  //Media
  &__media {
    border-top: 2px solid $bd-gray;

    &__block {
      @include desktop {
        padding-right: rem($p-elite-media-padding-x-desktop);
        border-right: 1px solid $b-green;
      }
    }

    &__item {
      width: $p-elite-item-width;
      height: $p-elite-item-height;
      margin-bottom: rem($gl-spacer-l);

      @include mobile {
        zoom: 0.6;
      }

      &--sn {
        background: url("#{$image-path}/pages/elitetutors/css_sprites.png") no-repeat -10px -168px;
      }

      &--ne {
        background: url("#{$image-path}/pages/elitetutors/css_sprites.png") no-repeat -174px -10px;
      }

      &--ch {
        background: url("#{$image-path}/pages/elitetutors/css_sprites.png") no-repeat -10px -10px;
      }

      &--qq {
        background: url("#{$image-path}/pages/elitetutors/css_sprites.png") no-repeat -174px -89px;
      }

      &--sh {
        background: url("#{$image-path}/pages/elitetutors/css_sprites.png") no-repeat -174px -168px;
      }

      &--if {
        background: url("#{$image-path}/pages/elitetutors/css_sprites.png") no-repeat -10px -89px;
      }
    }
  }

  // Line break
  &__line-break {
    border-top: 2px solid $b-green;
  }
}
