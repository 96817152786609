body[location='carry_forward_report_pages'] {
  background: $theme-light-lt;
  -webkit-print-color-adjust: exact;
  print-color-adjust: exact;
  height: 100%;

  .c-navbar,
  .navbar,
  .admin-sidenav,
  .navbar-height-offset,
  .c-footer,
  .footer_wrap {
    display: none;
  }

  @media print {
    h1:not(:first-of-type) {
      page-break-before: always;
    }
  }
}

.report {
  height: 29.7cm;
  width: 21cm;
  margin: auto;
  page-break-after: always;

  &__title {
    font-size: $gl-font-size-h4;
    font-weight: bold;
  }

  &__divider {
    width: 80px;
    border-bottom: 4px solid $b-green;
    margin-bottom: $gl-spacer-l;
  }

  &__footer {
    position: fixed;
    text-align: center;
    bottom: 0;
    left: 0;
    right: 0;

    img {
      zoom: 0.6;
      opacity: 0.4;
      -webkit-filter: grayscale(100%);
      filter: grayscale(100%);
    }
  }

  &__sc {
    position: relative;

    &--outlined {
      border: 1px solid $bd-gray;
    }

    &__text {
      position: absolute;
      z-index: $gl-z-index-1;
    }

    &__name {
      top: 56px;
      left: 100px;
    }

    &__email {
      top: 31px;
      left: 52px;
      background-color: #d5eaff;
      border-radius: 2px;
      padding: 2px 4px;
      font-size: 8px;
    }

    &__email-body {
      top: 113px;
      left: 19px;
      font-size: 10px;
    }

    &__date {
      color: $t-gray-1;
      top: 12px;
      right: 20px;
      text-align: right;
      font-size: 8px;
    }

    &__bg {
      display: block;
    }
  }
}
