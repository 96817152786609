// Live Tutorial
.p-live {
  padding-top: rem($gl-spacer-l);

  &__block {
    margin-bottom: rem($gl-spacer-xs);

    &__inner {
      border-radius: $gl-radius-md;
      border: 2px solid $bd-gray;
      display: block;
      font-weight: 500;
      padding: rem($gl-spacer-xs);
      color: $t-dark;
      height: 100%;

      @include desktop {
        font-size: $gl-font-size-base;
        text-align: center;
      }

      &.is-active {
        border-color: $b-green;
      }
    }

    &__img {
      @include desktop {
        margin: 0 auto rem($gl-spacer-xxs);
      }
    }
  }

  &__date {
    font-size: rem($gl-font-size-xs);
  }

  &__time {
    margin-bottom: rem($gl-spacer-s);
    font-size: rem($gl-font-size-base);
  }
}
