// Life QA Landing Page

// Intro
$p-lifeqa-intro-height: 60vh;
$p-lifeqa-intro-height-desk: rem(635px);
$p-lifeqa-intro-img-right: -15rem;
$p-lifeqa-intro-img-bottom-mobile: -2.4rem;
$p-lifeqa-intro-img-width: rem(1018px);
$p-lifeqa-intro-img-height: rem(688px);

// Info
$p-lifeqa-info-padding-y: $gl-spacer-m;
$p-lifeqa-info-padding-y-desk: $gl-spacer-2xl;
$p-lifeqa-num-font-size: $gl-font-size-h2;
$p-lifeqa-num-font-size-desk: 72px;
$p-lifeqa-num-font-weight: 600;

// Stages

.p-lifeqa {
  overflow-x: hidden;

  // Intro
  &__intro {
    background: $theme-gradient-warm;

    &__hero {
      height: $p-lifeqa-intro-height;
      position: relative;

      @include desktop {
        height: $p-lifeqa-intro-height-desk;
      }
    }

    &__inner {
      width: 100%;
      position: relative;
      z-index: $gl-z-index-1;

      @include mobile {
        display: block;
        text-align: center;
        padding-top: $gl-spacer-3xl;
      }
    }

    &__img-mobile {
      position: absolute;
      left: 0;
      right: 0;
      margin: auto;
      width: 100%;
      bottom: $p-lifeqa-intro-img-bottom-mobile;
    }

    &__img-desktop {
      position: absolute;
      right: $p-lifeqa-intro-img-right;
      left: auto;
      bottom: auto;
      top: 0;
      width: $p-lifeqa-intro-img-width;
      height: $p-lifeqa-intro-img-height;
    }
  }

  &__subheader {
    letter-spacing: 2px;
    opacity: 0.4;

    @include desktop {
      font-size: rem($gl-font-size-base);
    }
  }

  // Info
  &__info {
    &__num {
      font-weight: $p-lifeqa-num-font-weight;
      color: $t-gray-3;
      line-height: $gl-line-height-xs;
      font-size: rem($p-lifeqa-num-font-size);
      margin-bottom: $gl-spacer-m;

      @include desktop {
        font-size: rem($p-lifeqa-num-font-size-desk);
      }
    }
  }
}
