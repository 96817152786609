// Task
$task-spacing: $gl-spacer-xs;
$task-icon-size: 10px;
$task-border-radius: $gl-radius-md;

.p-university-tutoring {
  .lh150 {
    line-height: 150%;
  }
  .ml-14per {
    @include desktop {
      margin-left: 14%;
    }
  }
  .mr-14per {
    @include desktop {
      margin-right: 14%;
    }
  }
  .pl5per {
    padding-left: 5%;
    @include mobile {
      padding-left: 0;
    }
  }
  .pr5per {
    padding-right: 5%;
    @include mobile {
      padding-right: 0;
    }
  }
  .pl10per {
    padding-left: 10.5%;
  }
  .mt-8 {
    margin-top: -8rem;
  }
  .mt-11 {
    margin-top: -11.5rem;
  }
  .mt-4 {
    margin-top: -4rem;
  }
  .mh-auto {
    margin: 0 auto;
  }
  .border-r12 {
    border-radius: 0.75rem;
  }
  &__planning {
    &__intro {
      background: url('#{$image-path}/pages/university-tutoring/onboard-planning/intro_bg.jpg')
        no-repeat center;
      background-size: cover;
      height: 560px;
    }
    &__info {
      .item {
        border-radius: 0.75rem;
        background-color: $t-light;
        background-image: url('#{$image-path}/pages/university-tutoring/onboard-planning/challege-blur-bg.png');
        background-size: 100% 100%;
        border: 1px solid rgba(0, 0, 0, 0.1);
        padding: 6.5rem 0.5rem;
        -moz-transition: all 0.1s ease 0s;
        -webkit-transition: all 0.1s ease 0s;
        transition: all 0.1s ease 0s;
        -o-transition: all 0.1s ease 0s;
        @include mobile {
          padding: 0.5rem;
          margin-top: 1rem;
        }
        .t-image {
          height: 3.5rem;
          margin: 0 auto;
          background-repeat: no-repeat;
          @include desktop {
            width: 3.5rem;
          }
        }
        .t-image0 {
          background-image: url('#{$image-path}/pages/university-tutoring/onboard-planning/star.png');
          @include mobile {
            background-position: right;
            background-size: auto 90%;
          }
        }
        .t-image1 {
          background-image: url('#{$image-path}/pages/university-tutoring/onboard-planning/pen.png');
          @include mobile {
            background-position: center;
            background-size: auto 90%;
          }
        }
        .t-image2 {
          background-image: url('#{$image-path}/pages/university-tutoring/onboard-planning/language.png');
          @include mobile {
            background-position: center;
            background-size: auto 90%;
          }
        }
        .line {
          width: 2.375rem;
          background: black;
          height: 0.25rem;
          border-radius: 1.25rem;
          margin: 1.2rem auto;
        }
        &__active {
          color: $t-light;
          background-color: $b-green;
          background-image: url('#{$image-path}/pages/university-tutoring/onboard-planning/challege-blur-hover-bg.png');
          -moz-transition: all 0.1s ease 0s;
          -webkit-transition: all 0.1s ease 0s;
          transition: all 0.1s ease 0s;
          -o-transition: all 0.1s ease 0s;
          .line {
            background-color: white;
          }
          .t-image0 {
            background-image: url('#{$image-path}/pages/university-tutoring/onboard-planning/star-hover.png');
          }
          .t-image1 {
            background-image: url('#{$image-path}/pages/university-tutoring/onboard-planning/pen-hover.png');
          }
          .t-image2 {
            background-image: url('#{$image-path}/pages/university-tutoring/onboard-planning/language-hover.png');
          }
          .mid-gray {
            color: $t-light;
          }
        }
      }
    }
    &__time {
      background-color: $b-orange-2;
      background-size: cover;
      .list-content {
        height: 31rem;
        background-image: url('#{$image-path}/pages/university-tutoring/onboard-planning/best-time-left.png'),
          url('#{$image-path}/pages/university-tutoring/onboard-planning/best-time-bg.png');
        background-position: left top, right top;
        background-size: 100% 100%, 50% 100%;
        background-repeat: no-repeat, no-repeat;
        background-color: #00af98;
        @include mobile {
          background-image: none;
          background-color: rgba(0, 0, 0, 0);
        }
        .item {
          flex: 0 0 100%;
          height: 56px;
          .right-title {
            color: white;
            font-size: 1.75rem;
            line-height: 56px;
          }
          &__hover {
            zoom: 1.14;
            .right-title {
              color: $t-green-dark;
              padding-left: 10%;
              padding-right: 12%;
              background: url('#{$image-path}/pages/university-tutoring/onboard-planning/union.png')
                no-repeat;
            }
          }
        }
        .right-dom {
          background-color: white;
          background-image: url('#{$image-path}/pages/university-tutoring/onboard-planning/best-time-content-bg.png');
          background-size: 100% 100%;
          padding-left: 4.75rem;
          border-radius: 0.75rem;
          height: 16.75rem;
          .line {
            width: 5.375rem;
            height: 0.5rem;
            background: $b-green;
            border-radius: 1.25rem;
            @include mobile {
              width: 2rem;
            }
          }
          @include mobile {
            height: 14rem;
            background-image: none;
          }
        }
      }
    }
  }
  &__essay {
    &__intro {
      background: url('#{$image-path}/pages/university-tutoring/essay-guidance/intro_bg.jpg')
        no-repeat center;
      background-size: cover;
      height: 560px;
    }
    &__info {
      background: url('#{$image-path}/pages/university-tutoring/essay-guidance/request-bg.png');
      background-position: center;
      @include mobile {
        background: white;
      }
      .ms {
        position: relative;
        color: $t-light;
        margin-bottom: -6rem;
        margin-left: 1.5rem;
        top: 30px;
        z-index: 3;
      }
    }
    &__essay {
      background-color: $b-orange-2;
      span {
        color: $t-green;
      }
      .img {
        width: 80%;
        margin: 0 auto;
        height: 240px;
        position: relative;
      }
      .mobile-content {
        @include mobile {
          text-align: left;
        }
      }
    }
    &__security {
      .bc {
        background-color: $b-green;
        background-position: bottom;
        background-repeat: no-repeat;
        height: 14.75rem;
        margin-top: 11.25rem;
        border-radius: 0.75rem;
        @include mobile {
          height: auto;
          margin-top: 7rem;
        }
      }
      span {
        color: $t-green;
      }
      .img_h {
        height: 445px;
        width: 360px;
        @include desktop {
          height: 445px;
        }
      }
      .icai {
        background-image: url('#{$image-path}/pages/university-tutoring/essay-guidance/icai-bg.png');
      }
      .turnitin {
        background-image: url('#{$image-path}/pages/university-tutoring/essay-guidance/turnitin-bg.png');
      }
    }
    &__modify {
      background-color: $b-gray-1;
      background-size: cover;
      span {
        color: $t-green;
      }
      .point {
        width: 6px;
        height: 6px;
        background: #c0c0c0;
        border-radius: 50%;
      }
      .item-dom {
        width: 6rem;
      }
      .item {
        flex: 0 0 20%;
      }
      .mobile-img {
        width: 146px;
      }
      .mobile-o {
        width: calc(100% - 146px);
      }
      .mobile-item1 {
        margin-left: -4rem;
      }
      .mobile-item2 {
        margin-top: 2.8rem;
        margin-left: 1rem;
      }
      .mobile-item3 {
        margin-top: 2.6rem;
        margin-left: -4rem;
      }
      .mobile-item4 {
        margin-top: 2.8rem;
        margin-left: 1rem;
      }
      .mobile-item5 {
        margin-top: 2.2rem;
        margin-left: -4rem;
      }
    }
    &__example {
      .ph9per {
        @include desktop {
          padding-left: 9%;
          padding-right: 9%;
        }
      }
      span {
        color: $t-green;
      }
      .point {
        width: 1.5rem;
        height: 1.5rem;
        background: #c0c0c0;
        border-radius: 50%;
      }
      .next {
        width: 3rem;
        height: 3rem;
        border-radius: 50%;
        background-image: url('#{$image-path}/pages/university-tutoring/essay-guidance/next.png');
        background-repeat: no-repeat;
        background-position: center;
        background-color: $b-green;
        box-shadow: 0px 0px 15px rgba(0, 188, 163, 0.16);
        &:hover {
          background-color: $b-green-lt;
        }
      }
      .line {
        width: calc(100% - 4.5rem);
        height: 0;
        border: 0.5px solid rgba(0, 0, 0, 0.2);
      }
      .mt10-negative {
        @include desktop {
          margin-top: -10rem;
        }
      }
      .top-img {
        position: relative;
        z-index: 2;
        @include mobile {
          display: none;
        }
      }
      .animation-in {
        animation: fadeOut 0.5s;
      }
    }
  }
  &__major {
    &__intro {
      background: url('#{$image-path}/pages/university-tutoring/major-consulting/intro_bg.jpg')
        no-repeat center;
      background-size: cover;
      height: 560px;
    }
    &__info {
      background-image: url('#{$image-path}/pages/university-tutoring/major-consulting/info-bg.png');
      background-size: 100% 70%;
      background-repeat: no-repeat;
      background-position: bottom;
      .item {
        background-color: white;
        border-radius: 0.75rem;
        box-shadow: 0px 6px 40px rgba(0, 114, 99, 0.14);
        .img {
          top: -3.125rem;
          height: 60px;
          div {
            box-shadow: 0px 32px 40px rgba(37, 130, 117, 0.16);
            border-radius: 50%;
          }
          @include mobile {
            width: 42%;
            margin: 0 auto;
            height: 30px;
          }
        }
        @include mobile {
          .min-height-title {
            min-height: 5.25rem;
          }
        }
      }
      @include mobile {
        .mobile-c {
          margin: 0;
        }
      }
    }
    &__educators {
      .line {
        width: 2.875rem;
        height: 0.375rem;
        background: $b-green;
        border-radius: 3px;
        margin-top: 0.5rem;
        margin-bottom: 0.5rem;
        @include desktop {
          margin: 1rem auto;
        }
      }
      .h-100 {
        // height: 525px;
      }
      .player-video {
        margin: 0 auto;
        width: 100%;
        height: 525px;
        @include mobile {
          height: 260px;
        }
      }
      .item {
        box-shadow: 0px 6px 40px rgba(0, 114, 99, 0.14);
        padding-left: 0.75rem;
        padding-right: 0.75rem;
        @include mobile {
          box-shadow: none;
          padding-left: 0;
          padding-right: 0;
        }
        img {
          border: 1rem solid rgba(18, 204, 179, 0.06) !important;
          border-radius: 50%;
        }
        .text_align {
          @include mobile {
            text-align: left;
          }
        }
        .item-btn {
          width: 2.5rem;
          height: 2.5rem;
          border-radius: 50%;
          background-repeat: no-repeat;
          background-position: center;
          background-color: $b-green;
          box-shadow: 0px 0px 15px rgba(0, 188, 163, 0.16);
          &:hover {
            background-color: $b-green-lt;
          }
        }
        .point {
          width: 0.75rem;
          height: 0.75rem;
          border-radius: 50%;
          background: $bd-gray;
          &__select {
            background: $b-green;
          }
        }
        .pre {
          background-image: url('#{$image-path}/pages/university-tutoring/major-consulting/arrow-left.png');
          overflow: auto;
        }
        .next {
          background-image: url('#{$image-path}/pages/university-tutoring/major-consulting/arrow-right.png');
        }
      }
    }
    &__experience {
      background: $b-gray-1;
      .line {
        width: 4rem;
        height: 0.375rem;
        border-radius: 0.75rem;
        background: $b-dark;
      }
      .plh {
        line-height: 1.5;
      }
      .icon-minh {
        min-height: 61px;
      }
      .align_left {
        text-align: left;
        @include mobile {
          text-align: center;
        }
      }
      .title-fs {
        font-size: 1.75rem;
        @include mobile {
          font-size: 1.25rem;
        }
      }
    }
    &__mentor {
      .item {
        text-align: center;
        margin: auto 0;
      }
      a {
        cursor: default !important;
      }
      .mt-2 {
        margin-top: -2rem;
      }

      .rings {
        height: 422px;
        background: url('#{$image-path}/pages/famous/system/rings.png');
        background-size: cover;
      }

      .mobile-mentor {
        display: none;
        @include mobile {
          display: block;
          img {
            width: 12rem;
          }
          .text {
            margin: auto 1.5rem;
          }
          .arrow {
            height: 3rem;
          }
        }
      }
      @include mobile {
        .none {
          display: none;
        }
      }
    }
  }
}
