.tutor-applicant
  .o-section
    // padding-top: 7.5rem!important
    // padding-bottom: 7.5rem!important
  .p-bat__us
    padding-top: 0rem
    padding-bottom: 0px !important
  .bg-grey
    background-color:#F4F8F7
  .bg-dark
    background-color: #202423
  .new_bg.new
  .l-container
    .o-section__text
      @media (min-width: 768px)
        padding-right: 5rem
    .l-col-1-5
      flex: 0 0 20%
      max-width: 20%
      margin-bottom: 3rem
    .c-btn.white
      background-color:$b-green-dark
      border-color:$b-green-dark
    .us
      width: 100%
      text-align: center
      .controls i
        display: inline-block
        width: 20px
        height: 20px
        background:  url('/images/pages/tutor-applicants/play.png') no-repeat
        background-size: 100%

    .play
      width: 84px
      height: 84px
      margin-top: 150px
    .c-nav-tabs--underlined
      padding-left: 15%
      padding-right: 15%
      border-bottom: 1px solid #ededed
    .step
      width: 38px
      height: 38px
      border-radius: 38px
      background-color: #cff6f1
      line-height: 38px
      font-family: 'Roboto'
      font-weight: 600
      font-size: 18px
      margin: 0 auto
      margin-bottom: 1rem
    .step1
      width: 38px
      height: 38px
      border-radius: 38px
      line-height: 38px
      font-family: 'Roboto'
      font-weight: 600
      font-size: 18px
      background: rgba(239, 129, 82, 0.2)
      color: #EF8152
      margin: 0 auto
      margin-bottom: 1rem
    .step-info
      font-size: 1rem
    .step-header
      text-align: center
    .steps-header
      margin-top: 3rem
      margin-bottom: 2rem
      font-size: 20px
    .step-content
      padding-left: 7%
    .vector
      line-height: 38px
    .js-nav-tab-link
      min-height: 60px
      p
        float: right
        line-height: 40px
        padding-left: 10px
    .tab-content
      overflow: hidden
      .tab-pane
        margin-bottom: 4rem
    .text-left
      text-align: left
    .story
      display: none
      .c-testimonials.js-testimonials.stories-testimonials
        margin: 64px 0 24px
        text-align: left
        background: none
        box-shadow: none
        padding-bottom: 0rem
    .left
      background-color: white
      box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.07)
    .align-right
      text-align: right
    .story.active
      display: flex

.application-process
  .title
    // width: 162px
    height: 21px
    font-family: Roboto
    font-size: 18px
    font-weight: 500
    font-stretch: normal
    font-style: normal
    line-height: 1.17
    letter-spacing: normal
    color: #000000
  .hr
    margin-top: 0px
  .num
    width: 24px
    height: 16px
    font-family: Roboto
    font-size: 20px
    font-weight: bold
    font-stretch: normal
    font-style: italic
    line-height: 0.8
    letter-spacing: normal
    color: #12ccb3
    margin-right: 0px
  .desc
    margin-left: 0px
    width: 135px
    height: 16px
    font-family: Roboto
    font-size: 16px
    font-weight: normal
    font-stretch: normal
    font-style: normal
    line-height: 1
    letter-spacing: normal
    color: #000000
  .content
    width: 724px
    font-family: Roboto
    font-size: 14px
    font-weight: normal
    font-stretch: normal
    font-style: normal
    line-height: 1.5
    letter-spacing: normal
    color: #000000
.js-country-scope-select
  width: 100%
  height: 180px
  padding: 12px
  border: 2px solid #eee
  cursor: pointer
  border-radius: 12px
  &.is-active
    border: 2px solid #42d8c2
    border-radius: 12px
  &.na
    background:  url('/images/pages/tutor-applicants/mask-na.svg')
  &.au
    background:  url('/images/pages/tutor-applicants/mask-au.svg')
  p
    &.blank
      width: 33px
      border: 1px solid #000
    line-height: 1