$lt-color-primary: rgba($t-green, 0.14);
$lt-color-accent: rgba($theme-accent, 0.14);
$lt-cal-event-width: 136px;
$lt-cal-event-height: 80px;

.c-lt-cal {
  // With shadow
  &--shadowed {
    box-shadow: $gl-box-shadow-l;
  }

  // Header
  &__header {
    padding: rem($gl-spacer-m);
    border-bottom: 1px solid $bd-gray;
  }

  // Select
  &__select {
    // TODO: need to the following two lines to OneClass later;
    @extend %form-field;
    @extend %form-select;

    width: auto;
    font-size: rem($gl-font-size-h5);
    font-weight: 500;
    border: 1px solid $bd-gray-dark;
    padding: 4px 24px 4px 12px;
  }

  // Status dot
  &__dot {
    border-radius: 50%;
    width: 8px;
    height: 8px;
    display: inline-block;
    vertical-align: middle;
    margin-right: 2px;
    border: 1px solid transparent;

    &--primary {
      background: $b-green;
      border-color: $b-green;
    }

    &--primary-lt {
      background: $lt-color-primary;
      border-color: $lt-color-primary;
    }

    &--accent {
      background: $theme-accent;
      border-color: $theme-accent;
    }

    &--accent-lt {
      background: $lt-color-accent;
      border-color: $lt-color-accent;
    }

    &--alt {
      background: $b-orange-2;
      border-color: $b-orange-2;
    }

    &--lg {
      width: 12px;
      height: 12px;
    }
  }

  // Body
  &__body {
    padding: rem($gl-spacer-l) rem($gl-spacer-m);
    &__mobile {
      @include mobile {
        margin-top: -5rem;
      }
    }
  }

  // Table
  &__table {
    width: 100%;
  }

  // Table Header
  &__th {
    background: $clr-white;
    padding-bottom: $gl-spacer-m;
    top: 65px;
  }

  // Page flip
  &__page {
    border-radius: 50%;
    border: 1px solid $bd-gray-dark;
    width: $gl-asset-size-md;
    height: $gl-asset-size-md;
    text-align: center;
    position: absolute;
    cursor: pointer;
    padding: 3px 0;

    @include hover {
      opacity: 0.54;
    }

    &--prev {
      left: 0;
      bottom: $gl-spacer-m;
    }

    &--next {
      right: 0;
      bottom: $gl-spacer-m;
    }
  }

  // Week day
  &__weekday {
    color: $t-gray-1;
    text-transform: uppercase;
    font-weight: 500;
    margin-bottom: 4px;
    text-align: center;
  }

  // Day
  &__day {
    $lt-cal-day-size-md: 40px;
    $lt-cal-day-size-lg: 56px;

    font-size: rem($gl-font-size-base);
    margin: auto;
    border-radius: 50%;
    text-align: center;

    @include desktop {
      font-size: rem($gl-font-size-h3g);
    }

    &--md {
      line-height: $lt-cal-day-size-md;
      height: $lt-cal-day-size-md;
      width: $lt-cal-day-size-md;
    }

    &--lg {
      line-height: $lt-cal-day-size-md;
      height: $lt-cal-day-size-md;
      width: $lt-cal-day-size-md;

      @include desktop {
        line-height: $lt-cal-day-size-lg;
        height: $lt-cal-day-size-lg;
        width: $lt-cal-day-size-lg;
        margin-top: 4px;
        margin-bottom: 4px;
      }
    }

    &.is-disabled {
      color: $theme-dark-lt;
      cursor: not-allowed;
    }

    // Highlighted
    &--highlight {

      &.is-active {
        background: $b-green;
        color: $theme-light-lt;
      }

      &--primary {
        background: $lt-color-primary;
      }

      &--accent {
        background: $lt-color-accent;
      }
    }
  }

  // Clickable
  &__link {
    cursor: pointer;
  }

  // Time
  &__time {
    width: 56px;
    font-weight: normal;
    text-align: right;
    font-size: rem($gl-font-size-xs);
    padding-right: $gl-spacer-xxs;
    vertical-align: top;
  }

  // td
  &__td {
    border: 1px solid $bd-gray;
    position: relative;
    vertical-align: top;
  }

  // Event
  &__event {
    width: $lt-cal-event-width;
    height: $lt-cal-event-height;
    cursor: pointer;
    padding: $gl-spacer-xxs;
    box-sizing: border-box;
    position: relative;
    -webkit-user-select: none;
    -moz-user-select:none;
    -o-user-select:none;
    -ms-user-select:none;

    @include hover-focus {
      background: $b-gray-2;
    }

    &.is-updated {
      background: $theme-primary-ltr;
      color: $t-gray-2;
    }

    // Confirmed
    &--primary {
      background: $lt-color-primary;
      border-left: 2px solid $t-green;
      color: $b-dark;
    }

    // Pending
    &--accent {
      background: $lt-color-accent;
      border-left: 2px solid $theme-accent;
      color: $b-dark;
    }

    // Free
    &--alt {
      background: $theme-primary-ltr;
      color: $t-gray-1;
    }

    // Unavailable
    &.is-disabled {
      background: $b-gray-2;
      cursor: not-allowed;
    }

    &__icon {
      position: absolute;
      right: 8px;
      top: 8px;
    }

    &__title {
      @extend %truncate;

      font-weight: 500;
      font-size: rem($gl-font-size-xs);
      color: $b-dark;
    }

    &__time {
      font-size: rem($gl-font-size-xxs);
      color: $b-dark;
    }
  }

  // Popup
  &__popup {
    width: 400px;

    &__inner {
      padding: 0;
      box-shadow: $gl-box-shadow-l;
      border: 1px solid $bd-gray;

      &:after {
        content:'';
        display:block;
        width:0;
        height:0;
        position:absolute;
        margin: auto;
        left: 0;
        right: 0;
        border-top: 12px solid $theme-light-lt;
        border-left: 12px solid transparent;
        border-right: 12px solid transparent;
        bottom: -12px;
      }
    }

    &__header {
      padding: $gl-spacer-m;
      border-bottom: 1px solid $bd-gray;
    }

    &__title {
      font-size: $gl-font-size-h3g;
      font-weight: 500;
      margin: 0;
    }

    &__icon {
      opacity: 84%;
      cursor: pointer;

      @include hover {
        opacity: 1;
      }
    }

    &__body {
      padding: $gl-spacer-m;
    }

    &__footer {
      padding: $gl-spacer-s $gl-spacer-m;
      border-top: 1px solid $bd-gray;
    }
  }

  // Action Bottom Banner
  &__banner {
    position: fixed;
    z-index: $gl-z-index-3;
    left: 0;
    right: 0;
    bottom: 0;
    padding: $gl-spacer-l;
    background: $theme-light-lt;
    text-align: center;
    box-shadow: $gl-box-shadow-l;
    border-top: 1px solid $bd-gray;
  }
}
