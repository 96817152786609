// Essay Landing Page

// Intro
$p-essay-intro-height: 64vh;
$p-essay-intro-height-desk: rem(635px);
$p-essay-intro-img-right: -15rem;
$p-essay-intro-img-width: rem(1068px);
$p-essay-intro-img-height: rem(635px);

// Info
$p-essay-info-expand-right: rem($gl-spacer-s);
$p-essay-info-expand-bottom: 0.7rem;
$p-essay-info-expand-right-desk: 0;
$p-essay-info-expand-bottom-desk: 0.4rem;
$p-essay-tooltip-size: rem(320px);

// Stages
$p-essay-stages-color: $theme-alert-lt;

.p-essay {
  overflow-x: hidden;

  // Intro
  &__intro {
    background: $theme-gradient-warm;

    &__hero {
      height: $p-essay-intro-height;
      position: relative;

      @include desktop {
        height: $p-essay-intro-height-desk;
      }
    }

    &__inner {
      width: 100%;
      position: relative;
      z-index: $gl-z-index-1;

      @include mobile {
        display: block;
        text-align: center;
        padding-top: $gl-spacer-3xl;
      }
    }

    &__img-mobile {
      position: absolute;
      left: 0;
      right: 0;
      margin: auto;
      width: 100%;
      bottom: 0;
    }

    &__img-desktop {
      position: absolute;
      right: $p-essay-intro-img-right;
      left: auto;
      bottom: auto;
      top: 0;
      width: $p-essay-intro-img-width;
      height: $p-essay-intro-img-height;
    }
  }

  &__subheader {
    letter-spacing: 2px;
    opacity: 0.4;

    @include desktop {
      font-size: rem($gl-font-size-base);
    }
  }

  // Info
  &__info {
    position: relative;

    &__inner {
      position: relative;
      z-index: $gl-z-index-1;
    }

    &__expand {
      position: absolute;
      z-index: $gl-z-index-2;
      right: $p-essay-info-expand-right;
      bottom: $p-essay-info-expand-bottom;

      @include desktop {
        right: $p-essay-info-expand-right-desk;
        bottom: $p-essay-info-expand-bottom-desk;
      }
    }

    &__img {

      @include desktop {
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
        margin-top: auto;
        margin-bottom: auto;
        z-index: $gl-z-index-0;
      }
    }

    &__tooltip {
      width: $p-essay-tooltip-size;
    }
  }

  // Stages
  &__stages {
    &__stage {
      border-left: 2px solid transparent;
      margin-left: -1px;

      & + & {
        margin-top: $gl-spacer-xl;

        @include desktop {
          margin-top: $gl-spacer-2xl;
        }
      }

      &--ltst {
        border-color: darken($p-essay-stages-color, 10%);
      }

      &--ltr {
        border-color: darken($p-essay-stages-color, 20%);
      }

      &--lt {
        border-color: darken($p-essay-stages-color, 30%);
      }

      &--dk {
        border-color: darken($p-essay-stages-color, 40%);
      }

      &--dkr {
        border-color: darken($p-essay-stages-color, 50%);
      }
    }
  }

  // Tutors
  &__tutors {
    background: $theme-primary-ltst url("#{$image-path}/pages/homework/world-bg.svg") no-repeat center;

    &__intro {

      @include desktop {
        text-align: center;
      }
    }
  }

  // Sample
  &__sample {
    &__block {
      position: relative;
    }

    &__pointer {

      @include desktop {
        position: absolute;
        z-index: $gl-z-index-1;
        top: 9rem;

        &--right {
          right: $gl-spacer-xs;
        }

        &--left {
          left: $gl-spacer-xs;
        }
      }
    }
  }
}
