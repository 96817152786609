///* ========================================================================
//   #Pagination
//   ======================================================================== */

// A Bootstrap pagination component

// Pagination is built with list HTML elements so screen readers can
// announce the number of available links.
// Use a wrapping <nav> element to identify it as a navigation section
// to screen readers and other assistive technologies.
//
// In addition, as pages likely have more than one such navigation section,
// it’s advisable to provide a descriptive aria-label for the <nav> to
// reflect its purpose.
//
// For example, if the pagination component is used to navigate between
// a set of search results, an appropriate label could be
// aria-label="Search results pages"

// You can change the alignment of pagination components with flexbox utilities (layouts.grid)

// <nav class="c-pagination" aria-label="Page navigation example">
//   <ul class="c-pagination__list">
//     <li class="c-pagination__item">
//      <span class="c-pagination__link  c-pagination__arrow c-pagination__arrow--left is-disabled">
//        = c_ico 'chevron-left', cl: 'c-pagination__icon',  svg_class: 'c-pagination__svg'
//      </span>
//     </li>
//     <li class="c-pagination__item"><span class="c-pagination__link c-pagination__current is-disabled">2</span></li>
//     <li class="c-pagination__item"><a class="c-pagination__link" href="#">2</a></li>
//     <li class="c-pagination__item c-pagination__ellipsis is-disabled"></li>
//     <li class="c-pagination__item">
//      <a class="c-pagination__link  c-pagination__arrow c-pagination__arrow--right" href="#">
//        = c_ico 'chevron-right', cl: 'c-pagination__icon',  svg_class: 'c-pagination__svg'
//      </a>
//     </li>
//   </ul>
// </nav>

// ====== Default vars ======
// Font
$pagination-font-size: $gl-font-size-base !default;
// Spacing
$pagination-spacing: 4px !default;
$pagination-padding-x: 8px !default;
$pagination-padding-y: 8px !default;
$pagination-item-width: 32px !default;
$pagination-icon-size: 16px !default;
// Border
$pagination-border-radius: $gl-radius-base !default;
$pagination-border-width: $gl-border-width-base !default;
// States
$pagination-transition: $gl-transition-base !default;

$pagination-breakpoint: 'md';
$pagination-item-bg: $theme-light-lt;

.pagination {
  &__list {
    display: inline-block;
    *display: inline;
    *zoom: 1;
    margin-left: 0;
    margin-bottom: 0;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
    -moz-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
    li {
      display: inline;
      list-style: none;
      line-height: 20px;
      a {
        color: $t-green-dark;
        float: left;
        padding: 4px 12px;
        line-height: 20px;
        text-decoration: none;
        background-color: $t-light;
        border: 1px solid $bd-gray-dark;
        border-left-width: 0;
        &:hover {
          background: #f5f5f5;
          color: #000;
        }
      }
      &:first-child a {
        border-left-width: 1px;
        -webkit-border-top-left-radius: 4px;
        -moz-border-radius-topleft: 4px;
        border-top-left-radius: 4px;
        -webkit-border-bottom-left-radius: 4px;
        -moz-border-radius-bottomleft: 4px;
        border-bottom-left-radius: 4px;
      }
      &:last-child a {
        -webkit-border-top-right-radius: 4px;
        -moz-border-radius-topright: 4px;
        border-top-right-radius: 4px;
        -webkit-border-bottom-right-radius: 4px;
        -moz-border-radius-bottomright: 4px;
        border-bottom-right-radius: 4px;
      }
    }
    &__disabled, &__disabled:hover{
      a {
        color: #999999 !important;
        background-color: transparent !important;
        cursor: default !important;
      }
    }
  }
}
