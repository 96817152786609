/* ==========================================================================
   #TAGS
   ========================================================================== */
$tag-border-width : $gl-border-width-base;
$tag-line-height  : $gl-line-height-xs;
$tag-radius       : $gl-radius-base;
$tag-margin-bottom: 4px;
$tag-border-width : 1px;
$tag-hover-opacity: 0.7;

$tag-variants: (
  primary: (
    background: $theme-primary-ltr,
    color: $t-green-dark,
  ),
  alt: (
    background: $b-gray-2,
    color: $t-gray-1,
  ),
  accent: (
    background: $theme-accent-lt,
    color: $theme-accent-dk,
  ),
  alert: (
    background: $theme-alert-lt,
    color: $t-red,
  )
);

$tag-sizes: (
  sm: (
    padding-x: 4px,
    padding-y: 2px,
    font-size: $gl-font-size-xxs,
    font-weight: 500,
  ),
  md: (
    padding-x: 10px,
    padding-y: 5px,
    font-size: $gl-font-size-xs,
    font-weight: normal,
  )
);


.c-tag {
  $defaultSize: map-get($btn-sizes, sm);

  display: inline-block;
  vertical-align: middle;
  border-radius: $tag-radius;
  line-height: $tag-line-height;
  text-decoration: none;
  white-space: nowrap;
  box-sizing: border-box;
  background: transparent;
  margin-bottom: $tag-margin-bottom;
  border: $tag-border-width solid transparent;
  margin-right:2px;

  @include tag-size(
    map-get($defaultSize, padding-y),
    map-get($defaultSize, padding-x),
    map-get($defaultSize, font-size),
    map-get($defaultSize, font-weight)
  );

  // Share hover and focus styles
  @include hover-focus {
    text-decoration: none;
    opacity: $tag-hover-opacity;

    // TODO REMOVE AFTER REFACTORING AND USE with-close instead
    &__close {
      display: inline-block;
    }
  }

  &__close {
    display: none;
  }

  // Show close icon only on hover
  &--with-close {
    .#{$icon-class} {
      display: none;
    }

    @include hover-focus {
      .#{$icon-class} {
        display: inline-block;
      }
    }
  }

  &--pointer {
    cursor: pointer;
  }

  // Sizes

  @each $size, $opts in $tag-sizes {
    &--#{$size} {
      @include tag-size(
        map-get($opts, padding-y),
        map-get($opts, padding-x),
        map-get($opts, font-size),
        map-get($opts, font-weight)
      );
    }
  }

  // Variants

  @each $variant, $opts in $tag-variants {
    &--#{$variant} {
      @include tag-variant(
        map-get($opts, background),
        map-get($opts, color)
      );
    }
  }

  // Outline

  &--outline {
    border-color: $bd-gray-dark;
    color: $t-gray-1;

    &:hover {
      color: $t-green-dark;
    }

    @at-root {
      .c-tag-checkbox:checked + & {
        border-color: $b-green;
        background-color: $b-green;
        color: white;
      }
    }
  }
}
