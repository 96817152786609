.btn, button
    color: #fff
    -webkit-border-radius: 200px
    -moz-border-radius: 200px
    -o-border-radius: 200px
    border-radius: 200px
    font-size: 14px
    padding: 0.6em 1.3em
    border: none
    text-shadow: none
    box-shadow: none
    -webkit-box-shadow: none
    -moz-box-shadow: none
    cursor: pointer
    background: $b-green
    text-decoration: none
    -webkit-font-smoothing: antialiased
    text-rendering: optimizeLegibility
    font-weight: 500
    display: inline-block
    margin-bottom: 0
    text-align: center
    vertical-align: middle

    &:hover, &:focus
        background: rgba($b-green, 0.8)
        color: #fff
        -webkit-transition: 0.1s
        -moz-transition: 0.1s
        -o-transition: 0.1s
        transition: 0.1s
        text-decoration: none

    &:active
        box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.15) !important

.disable_button, .btn.disabled
    color: #aaa

    &:hover
        background-color: #e6e6e6
        color: #aaa

//button size
.btn-large
    font-size: 16px
    padding: 0.75em 2em

.btn-xl
    font-size: 18px
    padding: 0.6em 2em

.btn-small
    padding: 5px 12px
    font-size: 13px

//button types
.btn-gray
    background: transparent !important
    color: $text_color !important
    border: 1px solid #d4d4d4

    &:hover
        color: #333 !important
        border: 1px solid #bbb

//.btn-skip
//  background: #03A9F4 !important
//  color: white
.btn-admin
    background: $b-green

    &:hover
        background: #75dcc6

.btn-round
    border-radius: 30px !important

.btn-clear
    background: rgba(0, 0, 0, 0.07)
    border: 2px solid white

    &:hover
        background: none

.btn-round.btn-small
    padding: 3px 16px

.btn-clear.btn-large
    padding: 0.56em 2em

.btn-link
    background: none
    padding: 0
    border: none
    color: $t-green-dark

    &:hover
        text-decoration: underline
        color: $t-green-dark

.btn.add_admin
    background: #ddd
    border-radius: 100%
    padding: 0
    line-height: 22px
    font-size: 18px
    font-weight: normal

    &:hover
        background: $bd-gray

.btn-new
    width: 320px
    padding: 18px
.btn-new-p18
    padding-top: 18px
    padding-bottom: 18px
.btn-new-pv
    padding-top: 20px
    padding-bottom: 20px

.btn-white
    background: $t-light
    box-shadow: 0px 16px 20px rgba(0, 188, 163, 0.16)
    border-radius: 200px
    font-weight: 600
    font-size: 18px
    padding: 21px 0
    color: $b-green

//gray inner-hero button
.btn-hero
    background: #d5d8dd
    border: 1px solid #CCD2D8
    color: #555

    &:hover
        background: #C8CBD1
        color: #555
