$path: '/images/pages/case';
.p-case {
  &_intro {
    background: url('#{$path}/intro_bg_min.jpg') no-repeat center;
    background-size: cover;
    height: 560px;
    &__hero {
      height: 100%;
    }
  }

  &_info {
    background: url('#{$path}/info_bg.png') no-repeat center;
    background-size: cover;
    padding-bottom: 160px;
    @include mobile {
      padding-bottom: 80px
    }
    // height: 560px;
    .title {
      padding: 94px 0 76px;
      font-size: 46px;
      line-height: 150%;
      @include mobile {
        font-size: 16px;
        padding: 2rem 0 1rem;
      }
    }
    &_box {
      position: relative;
      top: -110px;
      .bg {
        background: url('#{$path}/info_box_bg.png') no-repeat center;
        background-size: cover;
        padding: 64px 0;
        @include mobile {
          padding: 20px 10px;
          border-radius: 8px;
        }
      }
      .border {
        border-right: 1px dashed $t-light;
      }

      .num {
        font-weight: 500;
        font-size: 56px;
        line-height: 120%;
        color: $t-light;
        @include mobile {
          font-size: 20px;
        }
      }
      .text {
        font-size: 20px;
        line-height: 120%;
        color: $t-light;
        @include mobile {
          font-size: 12px;
        }
      }
    }
  }
  &_timeline {
    .img-h{
      height: 187px;
      position: relative;
      @include mobile {
        height: 90px;
      }
    }
    .dot_line{
      width:16px;
    }
    .year {
      cursor: pointer;
      &_active {
        margin-top: -15px;
        font-weight: 600;
        font-size: 28px;
        line-height: 150%;
        color: $t-dark;
        @include mobile {
          font-size: 20px;
        }
      }
    }
    .line {
      width: 1px;
      height: 100%;
      background: #e8e8e8;
      margin: 0 auto;
      &_active{
        height: 700px;
        @include mobile {
          display: none;
        }
      }
      @include mobile {
        display: none;
      }
    }
    .dot {
      width: 12px;
      height: 12px;
      background-color: #dadada;
      border-radius: 50%;
      margin: 0 auto;
      &_active {
        box-shadow: 0 0 0 4px rgb(18 204 179 / 30%);
        width: 8px;
        height: 8px;
        background-color: $b-green;
        border-radius: 50%;
      }
    }
  }
}
