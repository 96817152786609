// Chat

.c-chat {
  border-left: 1px solid $bd-gray;
  //height: 100%;
  background: $theme-light-lt;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  flex-grow: 1;
  box-sizing: content-box;
  overflow: hidden;
  @include desktop {
    position: fixed;
    width: 336px;
    height: calc(100vh - 65px);
    right: 0;
  }
  // Header
  &__header {
    padding: rem($gl-spacer-s) rem($gl-spacer-m);
    border-bottom: 1px solid $bd-gray;

    &__title {
      font-size: $gl-font-size-base;
      font-weight: 600;
      margin: 0;
      line-height: $gl-line-height-base;
    }
  }

  // Inner
  &__inner {
    background: tint($b-gray-2, 40%);
    flex: 1;
    overflow: hidden;
    position: relative;
  }

  &__panel {
    position: absolute;
    padding: rem($gl-spacer-m);
    box-sizing: border-box;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    height: 100%;
    transform: translateZ(0);
    overflow-y: auto;
  }

  // Chat item
  &__item {
    margin-bottom: rem($gl-spacer-s);

    &--primary {
      justify-content: flex-end;
      text-align: right;
    }

    // Chat bubble
    &__bubble {
      padding: rem($gl-spacer-xs);
      word-break: break-word;
      text-align: left;
      display: inline-block;

      // From self
      &--primary {
        background: $b-orange-2;
        border-radius: 16px 16px 0 16px;
      }

      // Not from self
      &--alt {
        background: $theme-light-lt;
        border-radius: 16px 16px 16px 0;
      }

      // From system
      &--dk {
        background: $bd-gray;
        border-radius: 16px;
        width: 100%;
      }
    }

    // Notice
    //&__notice {
    //  background: $bd-gray;
    //  padding: $gl-spacer-xxs $gl-spacer-s;
    //  border-radius: $gl-radius-pill;
    //}

    &__time {
      font-size: $gl-font-size-xxs;
      color: $theme-dark-lt;
    }

    &__avatar {
      width: 45px;
      height: 45px;
      line-height: 45px;
    }
  }

  // Footer
  &__footer {
    border-top: 1px solid $bd-gray;
  }

  // Textarea
  &__textarea {
    width: 100%;
    -webkit-appearance: none;
    border: none;
    box-sizing: border-box;
    resize: none;
    padding: rem($gl-spacer-m);
    font-size: rem($gl-font-size-base);
    color: $t-dark-active;
    overflow: hidden auto;

    &:active,
    &:focus {
      box-shadow: none;
    }
  }

  &__btn {
    border-radius: 50%;
    width: $gl-asset-size-md;
    height: $gl-asset-size-md;
    padding: 0;
    line-height: 27px;

    @include mobile {
      width: $gl-asset-size-lg;
      height: $gl-asset-size-lg;
      line-height: 42px;

      .c-icon__svg {
        width: $gl-asset-size-xs;
        height: $gl-asset-size-xs;
      }
    }
  }

  // Toggle
  &__toggle {
    &.is-active {
      .c-icon__svg {
        stroke: $green;
      }
    }
  }
}
