.ekus
    .l28
        line-height: 28px

    .f16
        font-size: 16px

    .banner
        margin: 0 auto
        background: url("#{$image-path}/pages/ekus/banner@2x.jpg") no-repeat center
        background-size: 100%

        @media(max-width: 767px)
            background: url("#{$image-path}/pages/ekus/banner_mobile@2x.jpg") no-repeat center
            background-size: 100%

        background-size: cover
        height: 510px

        h2
            @media(max-width: 767px)
                font-size: 36px
                text-align: center
                padding-top: 0
                padding-left: 0
                display: none

            font-size: 56px
            line-height: 70px
            padding-top: 227px

    .title
        font-size: 30px
        font-weight: 500

        @media(max-width: 767px)
            font-size: 24px

    .text
        p
            line-height: 36px

            @media(max-width: 767px)
                line-height: 28px

    .course-banner
        background: url("#{$image-path}/pages/ekus/course_banner@2x.png") no-repeat center
        background-size: cover
        height: 280px

        h2
            color: $t-dark-active
            font-size: 30px
            font-weight: 600
            margin-top: 90px

        p
            font-size: 20px
            color: $t-dark-active
            font-weight: 500

    .intro
        img
            width: 56px
            height: 56px

        h4
            height: 24px
            margin-top: -3px
            line-height: 24px
            margin-bottom: 10px
            color: #000
            font-size: 18px
            overflow: hidden
            text-overflow: ellipsis
            display: -webkit-box
            -webkit-box-orient: vertical
            -webkit-line-clamp: 1
            max-width: 80%

            @media(max-width: 767px)
                height: auto
                margin-top: 0

        p
            line-height: 24px
            height: 48px
            color: $t-gray-1
            font-size: 14px
            overflow: hidden
            text-overflow: ellipsis
            display: -webkit-box
            -webkit-box-orient: vertical
            -webkit-line-clamp: 2

        @media(max-width: 767px)
            img
                width: 32px
                height: 32px

            h4
                font-size: 18px
                line-height: 18px

            .l-col-6
                padding-right: 0px
                padding-left: 0px
                padding-bottom: 24px

    .course-count
        font-size: 14px
        color: $t-dark-active
        height: 20px
        font-weight: normal

    .back-to-ekus
        color: #c49b69
        font-size: 16px

    .no-course
        font-size: 16px
        font-weight: 500
        color: $t-dark-active

    .courses
        background-color: #fafafa

        .course
            background-color: #fff
            padding-left: 16px
            padding-bottom: 30px
            padding-right: 24px
            border-radius: 16px
            margin-bottom: 80px
            margin-right: 18px
            display: block

            @media(max-width: 767px)
                margin-bottom: 100px

            .cn-name
                color: #000
                font-size: 20px
                font-weight: 500
                overflow: hidden
                text-overflow: ellipsis
                display: -webkit-box
                -webkit-box-orient: vertical
                -webkit-line-clamp: 1

            .name
                color: $t-gray-1
                font-size: 16px
                overflow: hidden
                text-overflow: ellipsis
                display: -webkit-box
                -webkit-box-orient: vertical
                -webkit-line-clamp: 2

        img.book
            width: 136px
            height: 190px
            margin-top: -46px

            @media(max-width: 767px)
                width: 114px
                height: 160px

        .professor
            width: 60%

            img
                width: 38px
                height: 38px
                border-radius: 19px

            .professor_name
                display: inline-block
                height: 38px
                line-height: 38px
                overflow: hidden
                text-overflow: ellipsis
                max-width: 150px
                font-size: 16px
                padding-left: 12px
                color: #000
                display: -webkit-box
                -webkit-box-orient: vertical
                -webkit-line-clamp: 1

                @media(max-width: 767px)
                    max-width: 120px

        .flex__item
            padding-top: 54px

            @media(max-width: 767px)
                padding-top: 36px

            h4
                margin-bottom: 10px
                height: 36px
                font-size: 20px

        span.school
            width: 36px
            height: 18px
            line-height: 20px
            text-align: center
            font-size: 13px
            color: #fff
            background-color: #c49b69
            margin-right: 10px

        .price
            width: 40%
            text-align: right

            span
                line-height: 38px
                width: 100%
                height: 22px
                font-size: 24px
                font-wight: 500
                color: #000

    .steps.mobile
        @media(max-width: 767px)
            display: block

        display: none

    .steps.pc
        @media(max-width: 767px)
            display: none

        display: block

    .steps
        h4
            font-size: 24px
            font-weight: 500
            margin-bottom: 16px

            @media(max-width: 767px)
                font-size: 18px

        p
            font-size: 16px

        .arrow
            width: 12.5%
            flex-basis: 12.5%
            max-width: 12.5%
            padding-top: 49.6px

        .arrow-mobile
            width: 100%
            text-align: center
            height: 48px
            margin: 24px 0

            img
                height: 48px

        .bottom-arrow
            padding-right: 12.5%
            text-align: right

            img
                margin-right: -5px

        .tips
            font-size: 14px
            color: $t-gray-1

        .img
            width: 33%
            float: left
            padding-right: 16px

        .text
            width: 66%
            float: left

    .schools
        .fa-search
            font-size: 20px

        h5
            margin-bottom: 6px
            font-weight: 500
            font-size: 16px

        p
            color: $t-gray-1
            font-size: 14px
            line-height: 24px
            overflow: hidden
            text-overflow: ellipsis
            display: -webkit-box
            -webkit-box-orient: vertical
            -webkit-line-clamp: 2

        .all-school-btn
            background: #c49b69
            padding-right: 45px
            padding-left: 45px
            color: #fff
            border-radius: 4px

        button
            z-index: 3
            background: transparent
            left: 0

            .fa
                color: #888

        .search
            border-radius: 4px
            background: #fff
            border: 1px solid #ddd
            height: 50px
            width: 100%
            padding-left: 50px

    .why
        .c-btn--primary
            background: #c49b69
            border: 1px solid #c49b69

    .carts
        margin-top: 48px

        h2
            font-size: 34px
            font-weight: 500

            @media(max-width: 767px)
                font-size: 20px

        .pl3
            @media(max-width: 767px)
                padding-left: 0 !important

        .back
            a
                font-size: 16px
                color: $t-gray-1

        .cart
            border: 1px solid #eaeaea
            padding: 35px 70px

            @media(max-width: 767px)
                padding: 12px 8px

            .img
                float: left

                img
                    height: 190px
                    width: 136px

                    @media(max-width: 767px)
                        height: 136px
                        width: 100px

            .course-price.mobile
                display: none
                clear: left

                @media(max-width: 767px)
                    display: block

            .course-price.pc
                display: block

                @media(max-width: 767px)
                    display: none

            .course-info
                margin-left: 200px

                @media(max-width: 767px)
                    margin-left: 120px

                .course-type
                    span
                        color: #c49b69
                        font-size: 14px
                        border-radius: 2px
                        border: 1px solid #c49b69
                        padding: 3px 8px
                        margin-right: 34px

                        @media(max-width: 767px)
                            display: block

            .del
                margin-top: -50px
                text-align: right

                a
                    color: $t-gray-1

        .cart:last-child
            border-bottom: 1px solid #eaeaea

        .charge
            margin: 50px 0px
            background: #fafafa
            padding: 21px 71px
            display: flex

            @media(max-width: 767px)
                padding: 12px 24px

            .total-price
                float: left
                width: 50%
                line-height: 32px

                .amount
                    color: #000
                    font-weight: 500
                    font-size: 20px

            .charge-btn
                float: left
                line-height: 32px
                width: 50%
                text-align: right
                margin: auto 0

                button
                    background: #c49b69

        .user-info
            padding: 36px 70px
            border: 1px solid #eaeaea

            @media(max-width: 767px)
                padding: 20px 12px

            h4
                font-size: 20px

                @media(max-width: 767px)
                    font-size: 16px

        .payment-type
            padding: 35px 70px
            border: 1px solid #eaeaea
            border-top: none
            display: block

            @media(max-width: 767px)
                padding: 20px 12px

            .payment
                width: 300px
                float: left

                p
                    font-size: 18px
                    display: flex
                    margin-right: 100px
                    line-height: 34px

                    img
                        margin-right: 12px

                input
                    margin: auto 20px
                    height: 34px

                label
                    padding: 0px
                    margin: auto 0px

            .payment-now
                .text
                    margin-right: 50px
                    color: $t-gray-1
                    font-size: 20px

                    @media(max-width: 767px)
                        font-size: 16px

                .amount
                    font-weight: 500
                    font-size: 20px

                a
                    background: #c49b69
                    padding: 8px 40px
                    color: #fff
                    border-radius: 2px

    .payment_result
        margin-top: 75px
        width: 700px
        margin-left: auto
        margin-right: auto
        padding: 60px 120px
        background: #fff

        a
            color: #c49b69

        .wechat
            vertical-align: middle

    .qrcode
        img
            margin: 0 auto

.user-upgrade
    .c-btn
        font-size: 18px
        color: #fff
        background: #c49b69
        border-radius: 4px

    p
        a
            color: #c49b69
            font-size: 12px
