/* ==========================================================================
 #FONT SIZE
 ========================================================================== */
// Generate a font-size and baseline-compatible line-height.
@use "sass:math";

@mixin font-size($font-size, $line-height: auto, $important: false) {
  @if (type-of($font-size) == number) {
    @if (unit($font-size) != "px") {
      @error "`#{$font-size}` needs to be a pixel value.";
    }
  }

  @else {
    @error "`#{$font-size}` needs to be a number.";
  }

  @if ($important == true) {
    $important: !important;
  }

  @else if ($important == false) {
    $important: null;
  }

  @else {
    @error "`#{$important}` needs to be `true` or `false`.";
  }

  // We provide a `px` fallback for old IEs not supporting `rem` values.
  // font-size: $font-size $important; 
  font-size: math.div($font-size, $gl-font-size-base) * 1rem $important;

  @if ($line-height == 'auto') {
    line-height: ceil(math.div($font-size, $gl-line-height-base)) * math.div($gl-line-height-base, $font-size) $important;
  }

  @else {
    @if (type-of($line-height) == number or $line-height == 'inherit' or $line-height == 'normal') {
      line-height: $line-height $important;
    }

    @else if ($line-height != 'none' and $line-height != false) {
      @error "D’oh! `#{$line-height}` is not a valid value for `$line-height`.";
    }
  }
}
