/* ==========================================================================
   #TEXT EDITOR PLUGIN
   ========================================================================== */
// Default vars
$te-padding: $gl-spacer-xs;
$te-font-size: $gl-font-size-h3g;
$te-bg: $theme-light-lt;

// Select
$te-select-height: $gl-asset-size-lg;
$te-select-padding: $gl-spacer-m;

// Inner
$te-inner-padding: $gl-spacer-s;
$te-inner-label-color: $theme-dark-lt;
$te-inner-height: 280px;

// Footer
$te-footer-padding: $gl-spacer-m;

// Tags
$te-tags-padding-x: $gl-spacer-m;
$te-tags-padding-y: $gl-spacer-xxs;

.c-text-editor {
  width: 100%;
  background-color: $te-bg;
  border-radius: $gl-radius-md;

  &--shadow {
    @include desktop {
      box-shadow: $gl-box-shadow-sm;
    }
  }

  &--outline {
    border: 1px solid $bd-gray;

    .mce-panel {
      padding: rem($te-padding);
    }
  }

  // Select
  &__select {
    background: url('#{$image-path}/icons/caret.svg') center right $gl-spacer-xxs no-repeat;
    background-size: 16px;
    width: 100%;
    box-sizing: border-box;
    font-weight: 600;
    -webkit-appearance: none;
    padding: 0 rem($gl-spacer-s);
    border: none;
    border-bottom: solid 1px $bd-gray;
    border-top-left-radius: $gl-radius-md;
    border-top-right-radius: $gl-radius-md;
    height: rem($te-select-height);
    line-height: rem($te-select-height);
  }

  // Tags
  &__tags {
    padding: rem($te-tags-padding-y) rem($te-tags-padding-x);
    border-bottom: 1px solid $bd-gray;
  }

  // Inner
  &__inner {
    padding: rem($te-inner-padding);
    height: Rem($te-inner-height);

    label {
      font-size: rem($te-font-size);
      color: $te-inner-label-color !important;
    }
  }

  // Footer
  &__footer {
    padding: 0 rem($te-footer-padding) rem($te-footer-padding);
  }

  // Text Editor Plugin
  .mce {
    &-panel {
      border: none !important;
      background-color: $te-bg;
    }

    &-btn {
      background-color: $te-bg;
      border: none;

      @include hover-focus {
        background-color: $b-gray-2;
      }

      &-group {
        border: none !important;
      }
    }

    &-ico {
      color: $t-dark;
    }

    &-content-body,
    &-reset {
      color: $t-dark;
      font-size: $te-font-size;
      line-height: $gl-line-height-base;
    }

    &-stack-layout,
    &-toolbar-grp {
      border-radius: 0;
    }
  }
}
