// LT Landing Page

// Intro
$p-lt-intro-height: 64vh;
$p-lt-intro-height-desk: rem(635px);
$p-lt-intro-img-right: -11rem;
$p-lt-intro-img-bottom: rem(-103px);
$p-lt-intro-img-width: rem(866px);
$p-lt-intro-img-height: rem(551px);

.p-lt {
  overflow-x: hidden;

  // Block
  &__block {
    @extend %o-block--white, %o-block--shadow;

    border-radius: $gl-radius-lg;
    padding: $gl-spacer-m;
    @include mobile{
      box-shadow: none;
    }
  }

  // Select
  &__select {
    box-shadow: $gl-box-shadow-l;
    border-radius: $gl-radius-pill;
  }

  // Intro
  &__intro {
    background: $theme-gradient-warm;

    &__hero {
      height: $p-lt-intro-height;
      position: relative;

      @include desktop {
        height: $p-lt-intro-height-desk;
      }
    }

    &__inner {
      width: 100%;
      position: relative;
      z-index: $gl-z-index-1;

      @include mobile {
        display: block;
        text-align: center;
        padding-top: $gl-spacer-3xl;
      }
    }

    &__img-mobile {
      position: absolute;
      left: 0;
      right: 0;
      margin: auto;
      width: 100%;
      bottom: -4rem;
    }

    &__img-desktop {
      position: absolute;
      right: $p-lt-intro-img-right;
      left: auto;
      bottom: $p-lt-intro-img-bottom;
      width: $p-lt-intro-img-width;
      height: $p-lt-intro-img-height;
    }
  }

  &__subheader {
    letter-spacing: 2px;
    opacity: 0.4;

    @include desktop {
      font-size: rem($gl-font-size-base);
    }
  }

  // Challenges
  &__challenges {
    text-align: center;

    &__text {
      color: $t-light;
      font-weight: 500;
      font-size: rem($gl-font-size-base);
      margin: $gl-spacer-xs 0 0;

      @include desktop {
        margin: $gl-spacer-xl 0 0;
      }
    }

    &__item {

      & + & {
        @include mobile {
          margin-top: $gl-spacer-m;
        }
      }

      &__inner {
        border-radius: $gl-radius-lg;
        padding: $gl-spacer-l $gl-spacer-m;
        height: 100%;
        width: 100%;

        @include desktop {
          padding: $gl-spacer-3xl $gl-spacer-m;
        }

        &--plan {
          background: url("#{$image-path}/pages/lt/plan.jpg") no-repeat bottom center;
          background-size: cover;
        }

        &--student {
          background: url("#{$image-path}/pages/lt/student.jpg") no-repeat bottom center;
          background-size: cover;
        }

        &--hour {
          background: url("#{$image-path}/pages/lt/hour.jpg") no-repeat bottom center;
          background-size: cover;
        }

        &--write {
          background: url("#{$image-path}/pages/lt/write.jpg") no-repeat bottom center;
          background-size: cover;
        }

        &--book {
          background: url("#{$image-path}/pages/lt/book.jpg") no-repeat bottom center;
          background-size: cover;
        }
      }
    }
  }

  // Info
  &__info {
    &__container {
      width: 100%;
      margin-right: auto;
      margin-left: auto;
      @include make-container-max-widths();
    }
    &__slide {
      position: relative;

      @include mobile {
        display: flex;
        flex-wrap: wrap;
      }
    }

    &__block {
      @include desktop {
        position: absolute;
        z-index: 1;
        width: 300px;

        &--top {
          top: $gl-spacer-3xl;
          left: $gl-spacer-s;
        }

        &--btm {
          bottom: 160px;
          right: $gl-spacer-s;
        }
      }

      @include mobile {
        flex: 0 0 100%;
      }
    }


    &__img {
      @include desktop {
        max-width: 820px;
        margin: auto;
      }
      .img-h{
        height: 545px;
        @include mobile {
          height: 250px;
        }
      }
      @include mobile {
        order: 3;
        flex: 0 0 100%;
      }
    }
  }

  // Subject
  &__subjects {
    &__list {
      &__item {
        font-size: rem($gl-font-size-base);
        padding: 0 $gl-spacer-xs;

        & + & {
          margin-top: $gl-spacer-xs;
        }

        @include desktop {
          font-size: rem($gl-font-size-h3g);
        }
      }
    }
  }

  // Tutors
  &__tutors {
    &__map {
      background: url("#{$image-path}/pages/homework/world-bg.svg") no-repeat center top;
      background-size: cover;

      @include desktop {
        padding-top: $gl-spacer-3xl;
      }
    }
  }

  // How
  &__how {
    text-align: center;

    &__item + &__item {
      @include mobile {
        margin-top: rem($gl-spacer-m);
      }
    }

    &__step {
      font-size: rem($gl-font-size-h3);
      font-weight: bold;
      color: $t-gray-3;
      margin-bottom: rem($gl-spacer-xxs);
    }
  }
}
