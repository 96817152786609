/* ==========================================================================
   #FORM GROUPS
   ========================================================================== */

// Default Vars
$form-group-spacing: $gl-spacer-xs !default;
$form-text-margin-top: $gl-spacer-xs !default;

// Designed to help with the organization and spacing of vertical forms. For
// horizontal forms, use the predefined grid classes.

.o-form-group {
  margin-bottom: $form-group-spacing;
  position: relative;

  // remove on React
  &,
  * {
    box-sizing: border-box;
  }

  // Prevent double border radius when fields are next to each other
  &__field {
    &:first-of-type {
      @include border-right-radius(0, true);
    }

    &:last-of-type {
      @include border-left-radius(0, true);
    }        
  }
}

// Block-level help text in forms can be created using .o-form-text

// Associating help text with form controls
//   Help text should be explicitly associated with the form control
//   it relates to using the aria-describedby attribute. This will
//   ensure that assistive technologies—such as screen readers—will
//   announce this help text when the user focuses or enters the control.
.o-form-text {
  display: block;
  margin-top: $form-text-margin-top;
}
