// stylelint-disable declaration-no-important

// Margin and Padding

@if (config(utilities)) {
  $prefix: 'u-';

  @each $bp in map-keys($breakpoints) {
    @include media-breakpoint-up($bp) {
      $infix: breakpoint-infix($bp, $breakpoints);

      @each $prop, $abbrev in (margin: m, padding: p) {
        @each $size, $length in $spacers {

          .#{$prefix}#{$abbrev}-#{$size}#{$infix} { #{$prop}: rem($length) !important; }

          .#{$prefix}#{$abbrev}t-#{$size}#{$infix},
          .#{$prefix}#{$abbrev}y-#{$size}#{$infix} {
            #{$prop}-top: rem($length) !important;
          }

          .#{$prefix}#{$abbrev}r-#{$size}#{$infix},
          .#{$prefix}#{$abbrev}x-#{$size}#{$infix} {
            #{$prop}-right: rem($length) !important;
          }

          .#{$prefix}#{$abbrev}b-#{$size}#{$infix},
          .#{$prefix}#{$abbrev}y-#{$size}#{$infix} {
            #{$prop}-bottom: rem($length) !important;
          }

          .#{$prefix}#{$abbrev}l-#{$size}#{$infix},
          .#{$prefix}#{$abbrev}x-#{$size}#{$infix} {
            #{$prop}-left: rem($length) !important;
          }
        }
      }

      // Some special margin utils
      .#{$prefix}m-0#{$infix} { margin: 0 !important; }
      .#{$prefix}m-auto#{$infix} { margin: auto !important; }

      .#{$prefix}mt-auto#{$infix},
      .#{$prefix}my-auto#{$infix} {
        margin-top: auto !important;
      }

      .#{$prefix}mr-auto#{$infix},
      .#{$prefix}mx-auto#{$infix} {
        margin-right: auto !important;
      }

      .#{$prefix}mb-auto#{$infix},
      .#{$prefix}my-auto#{$infix} {
        margin-bottom: auto !important;
      }

      .#{$prefix}ml-auto#{$infix},
      .#{$prefix}mx-auto#{$infix} {
        margin-left: auto !important;
      }

      @each $prop, $abbrev in (margin: m, padding: p) {
        .#{$prefix}#{$abbrev}t-0#{$infix},
        .#{$prefix}#{$abbrev}y-0#{$infix} { #{$prop}-top: 0 !important; }

        .#{$prefix}#{$abbrev}b-0#{$infix},
        .#{$prefix}#{$abbrev}y-0#{$infix} { #{$prop}-bottom: 0 !important; }

        .#{$prefix}#{$abbrev}l-0#{$infix},
        .#{$prefix}#{$abbrev}x-0#{$infix} { #{$prop}-left: 0 !important; }

        .#{$prefix}#{$abbrev}r-0#{$infix},
        .#{$prefix}#{$abbrev}x-0#{$infix} { #{$prop}-right: 0 !important; }
      }

      // Some special padding spacing
      .#{$prefix}p-0#{$infix} { padding: 0 !important; }

    }
  }
}

