///* ========================================================================
//   #Tooltip
//   ======================================================================== */

%tooltipPopup {
  border-radius: $gl-radius-base;
  padding: $gl-spacer-xxs $gl-spacer-xs;
  box-shadow: $gl-box-shadow-sm;
  text-transform: none;
}

.c-tooltip {
  $popupSpacer: $gl-spacer-xxs;

  position: relative;

  &--animated {
    transition: opacity $gl-duration-base;
  }

  &__popup {
    position: absolute;
    opacity: 0;
    visibility: hidden;
    z-index: $gl-z-index-max;
    font-size: $gl-font-size-s;
    // display: none;

    &.is-active {
      // display: block;
      opacity: 1;
      visibility: visible;
      // transition-delay: 0s;
    }

    > * {
      margin-bottom: 0;
    }

    &__inner {
      position: relative;

      &--dark {
        @extend %tooltipPopup;

        background-color: $b-dark;
        color: $t-light;
      }

      &--light {
        @extend %tooltipPopup;

        background-color: $theme-light-lt;
        color: $t-gray-1;
      }

      &--nowrap {
        white-space: nowrap;
      }
    }

    @each $bp in map-keys($breakpoints) {
      @include media-breakpoint-up($bp) {
        $infix: breakpoint-infix($bp, $breakpoints);

        &#{$infix}--tl,
        &#{$infix}--tr {
          bottom: 100%;
          left: 0;
          right: auto;
          top: auto;
          padding-top: $popupSpacer;
        }

        &#{$infix}--bl,
        &#{$infix}--br {
          top: 100%;
          left: 0;
          right: auto;
          bottom: auto;
          padding-top: $popupSpacer;
        }

        &#{$infix}--br,
        &#{$infix}--tr {
          left: auto;
          right: 0;
        }

        &#{$infix}--tc,
        &#{$infix}--bc {
          bottom: 100%;
          right: 50%;
          left: auto;
          top: auto;
          padding-bottom: $popupSpacer;
          transform: translate(50%, 0);
        }

        &#{$infix}--bc {
          top: 100%;
          bottom: auto;
        }

        &#{$infix}--rc {
          left: 100%;
          top: 18%;
        }
      }
    }
  }

  &__arrow {
    $size: 30px;

    width: $size;
    height: $size * 0.5;
    position: absolute;
    top: 100%;
    right: 20px;
    overflow: hidden;

    &::after {
      content: '';
      position: absolute;
      background: white;
      transform: translateX(-50%) translateY(-50%) rotate(45deg);
      top: 0;
      left: 50%;
      box-shadow: $gl-box-shadow-sm;

      @include size(0.4 * $size);
    }
  }
}

