// ==========================================================================
// #REACT DROPDOWN
// ==========================================================================
// [TODO] Update React component:
// add new dropdown container

// List Container
$dd-list-min-width: 240px;
$dd-list-bg: $theme-light-lt;
$dd-list-font-size: $gl-font-size-s;
$dd-list-padding-x: 24px;
$dd-list-padding-y: 16px;
$dd-list-spacing: 2px; // spacing between toggle and dropdown list
$dd-list-border-radius: $gl-radius-base;
$dd-list-shadow: $gl-box-shadow-m;
$dd-list-z-index: $gl-z-index-3;
$dd-list-wide-padding: $gl-spacer-m;
$dd-list-wide-position-x: -$gl-spacer-s;
$dd-duration: 0.1s; // dropdown animation duration

// List Item
$dd-list-item-spacing: 16px; // spacing between list items
$dd-list-item-hover-bg: $theme-primary-ltr;

// List Menu
$dd-menu-padding: $gl-spacer-m;

// Admin Categories Dropdown
$dd-categories-max-width: 400px;
$dd-categories-padding-y: $gl-spacer-xs;
$dd-categories-padding-x: $gl-spacer-m;

//https://stackoverflow.com/questions/42101723/unable-to-preventdefault-inside-passive-event-listener
.js-dropdown__toggle {
  touch-action: none;
}

.c-dropdown {
  position: relative;
  display: inline-flex;

  &--wide {
    position: static;
  }

  &__popup {
    position: absolute;
    top: 100%;
    left: 0;
    opacity: 0;
    visibility: hidden;
    //
    padding-top: $dd-list-spacing;
    transition: all $dd-duration;
    z-index: $dd-list-z-index;
    font-size: $dd-list-font-size;

    &--right {
      left: auto;
      right: 0;
    }

    &__inner {
      background: $dd-list-bg;
      border-radius: $dd-list-border-radius;
      box-shadow: $dd-list-shadow;

      &--wide {
        border-radius: 0;
        text-align: center;
        padding: $dd-list-wide-padding;
      }
    }

    &.is-active {
      opacity: 1;
      visibility: visible;
      transition-delay: 0s;
    }

    &--wide {
      right: 0;
      padding-top: 1px !important;
    }
  }

  &__list {
    min-width: rem($dd-list-min-width);
    padding: rem($dd-list-padding-y) 0;
  }

  &__menu {
    padding: rem($dd-menu-padding);
  }

  &__link-logout {
    display: block;
    padding: rem($dd-list-item-spacing * 0.5) rem($dd-list-padding-x);
    color: $t-dark;
    &:hover {
      color: $t-dark;
      text-decoration: none;
      background: $dd-list-item-hover-bg;
    }

    // A link with round square background on hover (ie. logged in navbar dropdown links)
    &--lt {
      padding: rem($dd-list-item-spacing) rem($dd-list-padding-x);

      &:hover {
        background: rgba(0,0,0,0.05);
        border-radius: $dd-list-border-radius;
      }
    }

    // A link with underline (ie. admin categories dropdown)
    &--bm {
      border-bottom: 1px solid $bd-gray;

      &:hover {
        background: transparent;
        color: $t-green-dark;
      }
    }
  }

  &__link {
    display: block;
    padding: rem($dd-list-item-spacing * 0.5) rem($dd-list-padding-x);
    color: $t-dark;
    &:hover {
      color: $t-gray-1;
      text-decoration: none;
      background: $dd-list-item-hover-bg;
    }

    // A link with round square background on hover (ie. logged in navbar dropdown links)
    &--lt {
      padding: rem($dd-list-item-spacing) rem($dd-list-padding-x);

      &:hover {
        background: rgba(0,0,0,0.05);
        border-radius: $dd-list-border-radius;
      }
    }

    // A link with underline (ie. admin categories dropdown)
    &--bm {
      border-bottom: 1px solid $bd-gray;

      &:hover {
        background: transparent;
        color: $t-green-dark;
      }
    }
  }

  // Admin categories dropdown
  &__categories {
    width: rem($dd-categories-max-width) !important;
    padding: rem($dd-categories-padding-y) rem($dd-categories-padding-x);
  }
}
