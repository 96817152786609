// Live Tutorial User Dashbaord
$primer-height: 270px;
$primer-height-mobile: 327px;

.p-ltu {
  padding: rem($gl-spacer-xl) 0;
  background: url('#{$image-path}/lte/icons/dash-bg.svg') no-repeat top right;

  // Col
  &__col {
    & + & {
      @include desktop {
        border-left: 1px solid $bd-gray;
      }
    }
  }

  // Section
  &__section {
    margin-bottom: rem($gl-spacer-xl);

    @include desktop {
      margin-bottom: rem($gl-spacer-2xl);
    }

    // Title
    &__title {
      font-size: rem($gl-font-size-h5);
      font-weight: 500;
      margin: 0 0 4px;
    }

    // Sub text
    &__sub {
      color: $t-gray-1;
      margin-bottom: rem($gl-spacer-m);

      @include mobile {
        margin-bottom: rem($gl-spacer-s);
      }
    }
  }

  // Block
  &__block {
    text-align: center;
    padding: rem($gl-spacer-l);
    background: $theme-primary-ltst;
  }

  // How
  &__how {
    &__item + &__item {
      @include mobile {
        margin-top: rem($gl-spacer-m);
      }
    }

    &__step {
      font-size: rem($gl-font-size-h3);
      font-weight: bold;
      color: #ddd;
      margin-bottom: rem($gl-spacer-xxs);
    }
  }

  &__primer {
    height: $primer-height-mobile;
    @include desktop {
      height: $primer-height;
    }
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: $gl-spacer-s 0;
    background-image: url('#{$image-path}/lte/pages/user_dashboard/primer-mobile@3x.jpg');
    @include desktop {
      background-image: url('#{$image-path}/lte/pages/user_dashboard/primer@3x.jpg');
    }
    background-size: cover;
    background-position-y: 24%;
    @include desktop {
      background-position: 50% 50%;
    }
    background-repeat: no-repeat;

    &__text {
      // TODO: update theme colors and font sizes
      color: #292929;
      font-size: 18px;
      line-height: 1.33;
      letter-spacing: normal;
      @include desktop {
        font-size: 22px;
      }
      font-weight: normal;
    }
    &__link {
      font-weight: 750;
    }
  }

  // Slider
  &__slider {
    margin-left: rem(-$gl-spacer-xl);

    &__item {
      margin-right: rem($gl-spacer-m);
    }

    .slick-list {
      padding: rem($gl-spacer-s) rem($gl-spacer-xl) rem($gl-spacer-xl);

      @include desktop {
        padding: rem($gl-spacer-m) rem($gl-spacer-xl) rem($gl-spacer-xl);
      }
    }
  }

  // Stats
  &__stats {
    @include mobile {
      display: none;
    }
  }

  &__timezone {
    @include desktop {
      margin-bottom: -36px;
    }
  }
}
