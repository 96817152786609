/* ==========================================================================
   #TESTIMONIAL
   ========================================================================== */

$c-tstm-bg: $theme-light-lt;
$c-tstm-shadow: 0 0 25px 0 rgba(0, 0, 0, 0.07);
$c-tstm-spacing: $gl-spacer-l;
$c-tstm-spacing-desk: $gl-spacer-2xl;
$c-tstm-max-width: 896px;
$c-tstm-radius: 13px;

.c-testimonials {
  $duration: 0.5s;

  background: $c-tstm-bg;
  box-shadow: $c-tstm-shadow;
  border-radius: $c-tstm-radius;
  position: relative;
  max-width: rem($c-tstm-max-width);
  // padding: rem($c-tstm-spacing);
  // margin-top: rem($c-tstm-spacing);
  
  @include desktop {
    // padding: rem($c-tstm-spacing-desk);    
    margin: rem($c-tstm-spacing-desk) 0;
  }

  &__inner {
    position: relative;
    transition: height $duration;
  }

  &__item {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    visibility: hidden;
    opacity: 0;
    transition: opacity $duration, visibility $duration;

    &.is-active {
      visibility: visible;
      position: relative;
      opacity: 1;
    }
  }

  &__quote {
    font-size: rem($gl-font-size-base);
    font-family: 'Roboto';
    color: #222222;
  }

  &__name {
    font-weight: 600;
    font-size: rem($gl-font-size-base);
  }

  &__img {
    position: absolute;

    &--tc {
      left: 0;
      right: 0;
      margin: auto;
      top: rem(-$gl-spacer-xs);
      height: rem($gl-asset-size-xs);

      @include desktop {
        left: rem(-$gl-spacer-m);
        top: rem(-$gl-spacer-s);
        right: auto;
        height: rem($gl-asset-size-lg);
      }
    }

    &--br {
      bottom: rem($gl-spacer-l);
      right: rem(-$gl-spacer-xl);
    }
  }  
}