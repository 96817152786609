// ==========================================================================
// #BUTTON
// ==========================================================================
// Default vars
$btn-disabled-opacity      : $gl-disabled-opacity;
$btn-border-width          : $gl-border-width-base;
$btn-line-height           : $gl-line-height-xs;
$btn-transition            : $gl-transition-base;
$btn-transition-properties : color, background-color, border-color, opacity;
$btn-border-radius         : $gl-radius-base;
$btn-hover-opacity         : 0.7;

// Default Button Size
$btn-padding-x   : 24px;
$btn-padding-y   : 10px;
$btn-font-size   : $gl-font-size-s;
$btn-line-height : $gl-line-height-xs;

// [TODO] Update React component (add an xs size)

$btn-variants: (
  primary: (
    background      : $b-green,
    color           : $t-light,
  ),
  alt: (
    background      : $bd-gray,
    color           : $t-dark,
  ),
  lt: (
    background      : $theme-light-lt,
    color           : $t-dark,
  )
);

$btn-outline-variants: (
  primary: (
    background      : $theme-light-lt,
    color           : $t-green-dark,
    border-color    : $t-green-dark,
  ),
  alt: (
    background      : $theme-light-lt,
    color           : $t-dark,
    border-color    : $bd-gray-dark,
  )
);

$btn-sizes: (
  sm: (
    padding-x  : 12px,
    padding-y  : 6px,
    font-size  : $gl-font-size-xs,
    line-height: $gl-line-height-xs
  ),
  md: (
    padding-x  : $btn-padding-x,
    padding-y  : $btn-padding-y,
    font-size  : $btn-font-size,
    line-height: $btn-line-height,
    mobile: 'sm'
  ),
  lg: (
    padding-x  : 64px,
    padding-y  : 14px,
    font-size  : 18px,
    line-height: $gl-line-height-xs
  )
);

.c-btn {
  $defaultSize: map-get($btn-sizes, md);

  display: inline-block;
  text-decoration: none;
  cursor: pointer;
  border: $btn-border-width solid transparent;
  background: transparent;
  border-radius: $btn-border-radius;
  transition: $btn-transition;
  transition-property: $btn-transition-properties;
  white-space: nowrap;
  text-align: center;
  box-sizing: border-box;
  font-weight: 500;

  @include button-size(
    map-get($defaultSize, padding-y),
    map-get($defaultSize, padding-x),
    map-get($defaultSize, font-size),
    map-get($defaultSize, line-height)
  );

  // Share hover and focus styles
  @include hover-focus {
    text-decoration: none;
  }

  // Disabled comes first so active can properly restyle
  &.is-disabled,
  &:disabled {
    opacity: $btn-disabled-opacity;
    box-shadow: none;
    cursor: not-allowed;

    @include hover-focus {
      opacity: $btn-disabled-opacity;
    }
  }

  // Sizes

  @each $size, $opts in $btn-sizes {
    &--#{$size} {
      @include button-size(
        map-get($opts, padding-y),
        map-get($opts, padding-x),
        map-get($opts, font-size),
        map-get($opts, line-height)
      );

      @if (map-has-key($opts, icon-padding-x)) {
        &__icon {
          margin-right: map-get($opts, icon-padding-x) * 0.5;
        }
      }

      //@if (map-has-key($opts, mobile)) {
      //  @include phone {
      //    $mobileSize: map-get($btn-sizes, map-get($opts, mobile));

      //    @include button-size(
      //      map-get($mobileSize, padding-y),
      //      map-get($mobileSize, padding-x),
      //      map-get($mobileSize, font-size),
      //      map-get($mobileSize, line-height)
      //    );
      //  }
      //}
    }

    &--with-icon--#{$size} {
      padding-left: map-get($opts, icon-padding-x);
    }
  }

  // [TODO] Refactor Active state classes (merge button-variant and button-outline-variant)
  @each $variant, $opts in $btn-variants {
    &--#{$variant} {
      @include button-variant(
        map-get($opts, background),
        map-get($opts, color)
      );
    }

    @at-root {
      #{&}-toggle--#{$variant}.is-active {
        @include button-variant(
          map-get($opts, background),
          map-get($opts, color)
        );
      }
    }
  }

  @each $variant, $opts in $btn-outline-variants {
    &--outline--#{$variant} {
      @include button-outline-variant(
        map-get($opts, background),
        map-get($opts, color),
        map-get($opts, border-color)
      );
    }

    @at-root {
      #{&}-toggle--outline--#{$variant}.is-active {
        @include button-outline-variant(
          map-get($opts, background),
          map-get($opts, color),
          map-get($opts, border-color)
        );
      }
    }
  }

  // Pill Button
  &--round {
    border-radius: 999px;
  }

  // Full Width
  &--w100 {
    width: 100%;
    padding-left: $btn-padding-x;
    padding-right: $btn-padding-x;
  }

  // With Shadow
  &--shadowed {
    box-shadow: $gl-box-shadow-m;
  }

  // Checkbox Button
  @at-root {
    &--checkbox:checked + & {
      border-color: $b-green;
      background-color: $b-green;
      color: $t-light;
    }
  }
}

// Future-proof disabling of clicks on `<a>` elements
a.c-btn.disabled,
fieldset[disabled] a.c-btn {
  pointer-events: none;
  border-color: $theme-dark-lt;
  background-color: $theme-dark-lt;
}

.c-btn.disabled {
  pointer-events: none;
  border-color: $theme-dark-lt;
  background-color: $theme-dark-lt;
}
