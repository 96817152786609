$us-img: '/images/pages/internship/us';
.p--internship {
  &__us {
    &__intro {
      background: url('#{$us-img}/banner_bg.jpg') no-repeat center;
      background-size: cover;
      height: 560px;
      .title {
        font-weight: 600;
        font-size: 64px;
        line-height: 120%;
        color: #222222;
        @include mobile {
          font-size: 40px;
        }
      }
    }
    .plan_box {
      position: relative;
      border-radius: 12px;
      background-color: #f7f7f7;
      .plan_t {
        position: absolute;
        z-index: 2;
        height: 75px;
        top: 25px;
        width: 100%;
        background: url('#{$us-img}/plan_title.svg') no-repeat center center;
        background-size: contain;
      }
      .left {
        padding: 82px 52px;
        position: relative;
        z-index: 2;
        @include mobile {
          padding: 40px 20px;
        }
      }
      .plan_bg {
        height: 100%;
        background: url('#{$us-img}/plan_bg.jpg') no-repeat center right;
        background-size: cover;
        border-radius: 12px;
        -webkit-border-radius: 12px;
        -moz-border-radius: 12px;
        -ms-border-radius: 12px;
        -o-border-radius: 12px;
      }
    }
    &__project {
      background-color: #fafafa;
      background-image: url('#{$us-img}/project/bg.png');
      background-repeat: no-repeat;
      background-size: 462px 450px;
      background-position: right 60px bottom;
      .card {
        height: 20rem;
        padding: 33% 24px;
        text-align: center;
        border-radius: 12px;
        background-size: cover;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        -webkit-border-radius: 12px;
        -moz-border-radius: 12px;
        -ms-border-radius: 12px;
        -o-border-radius: 12px;
        @include mobile {
          height: 15rem;
          padding: 33% 10px;
        }
      }
    }
    &__info {
      .left_bg {
        height: 432px;
        background: url('#{$us-img}/info/left_bg.jpg') no-repeat;
        background-size: cover;
        border-radius: 12px;
        -webkit-border-radius: 12px;
        -moz-border-radius: 12px;
        -ms-border-radius: 12px;
        -o-border-radius: 12px;
      }
    }
    &__flow {
      background-color: #ecf9f7;
      // img {
      //   margin-left: -18px;
      // }
      .flow_num {
        width: 42px;
        height: 42px;
        background: #12ccb3;
        box-shadow: 2px 5px 20px rgba(0, 150, 130, 0.14);
        border-radius: 100%;
        color: #fff;
        font-family: 'Barlow';
        font-size: 22px;
        display: flex;
        align-items: center;
        justify-content: center;
        -webkit-border-radius: 100%;
        -moz-border-radius: 100%;
        -ms-border-radius: 100%;
        -o-border-radius: 100%;
      }
      .flow_1{
        margin-bottom: -40px;
    margin-left: 25px;
      }
      .flow_2{
        margin-bottom: -40px;
    margin-left: 25px;
      }
      .flow_mt3 {
        margin-top: -35px;
      }
      .fm1 {
        margin: 10px 0 0 27px;
      }
      .fm2 {
        margin-left: -10px;
      }
      .fm3 {
        margin-top: 15px;
        margin-left: 25px;
      }
      .f2t {
        margin-top: -20px;
      }
      .f2m0 {
        margin-top: -25px;
      }
      .f2m1 {
        margin-top: 10px;
      }
      .f2m2 {
        margin-top: -22px;
       
      }
      .f2m3 {
        margin-top: -18px;
        text-align: center;
        margin-left: -25px;
      }
      .c_max_w {
        max-width: 300px;
      }
      .flow_m_box {
        .line{
          width: 1px;
    height: 100%;
    margin: -16px auto 0;
    border-left: 1.2px dashed #B5C5C2;
        }
        .u-h-70{
          height: 70%;
        }
        // height: 808px;
        // .item_box {
        //   margin-bottom: 68px;
        // }
        // .flow_m_l {
        //   margin-left: -40px;
        // }
      }
    }
    &__job {
      background: #ffffff;
      box-shadow: 12px 28px 90px rgba(0, 0, 0, 0.07);
      border-radius: 12px;
      padding: 48px 60px;
      margin-bottom: 48px;
      @include mobile {
        padding: 20px;
      }
      .job_item {
        background: #222222;
        box-shadow: 2px 2px 12px rgba(79, 98, 95, 0.32);
        border-radius: 2px;
        transform: rotate(45deg);
        width: 8px;
        height: 8px;
      }
      .jobml {
        margin-left: 5px;
      }
    }
  }
}
