// Upcoming Banner
.c-lt-upcoming {
  border-radius: $gl-radius-lg;
  box-shadow: $gl-box-shadow-l;
  padding: $gl-spacer-xs;
  background: $theme-light-lt;
  cursor: pointer;
  &__avatar {
    width: 56px;
    height: 56px;
    box-shadow: $gl-box-shadow-m;

    @include mobile {
      width: $gl-asset-size-lg;
      height: $gl-asset-size-lg;
    }

    &--bordered {
      border: 2px solid $t-green;

      &:before {
        content: '';
        position: absolute;
        z-index: 1;
        top: 0px;
        left: 0px;
        right: 0px;
        bottom: 0px;
        border: 2px solid $theme-light-lt;
        border-radius: 50%;
      }
    }
  }

  &__title {
    font-weight: 600;
    font-size: rem($gl-font-size-base);
    margin: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    @include desktop {
      font-size: rem($gl-font-size-h3g);
    }
  }

  &__sub {
    font-size: rem($gl-font-size-xs);
    color: $t-gray-1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  @include desktop {
    font-size: rem($gl-font-size-xxs);
    padding: rem($gl-spacer-s) rem($gl-spacer-m);
 }
}
