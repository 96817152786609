.p-lt-book {
  .c-slider {
    .slick-arrow {
      @include mobile {
        display: none !important;
      }
    }
    &--lt {
      .slick-prev,
      .slick-next {
        width: 36px;
        height: 36px;
      }
      .slick-prev.slick-disabled:before,
      .slick-next.slick-disabled:before {
        opacity: 0.35;
      }
      .slick-prev {
        left: -50px;
        &:before {
          width: 36px;
          height: 36px;
          content: url('/images/user/learning-center/modules/left.svg');
          @include mobile {
            content: ' ';
          }
        }
      }
      .slick-track {
        margin-left: 0;
        margin-right: 0;
      }
      .slick-next {
        right: -50px;
        &:before {
          width: 36px;
          height: 36px;
          content: url('/images/user/learning-center/modules/right.svg');
          @include mobile {
            content: ' ';
          }
        }
      }
    }

    .slick-active {
      > div {
        margin-left: 0;
        margin-right: 0;
      }
      &:hover {
        transform: scale(1) !important;
        -webkit-transform: scale(1) !important;
        -moz-transform: scale(1) !important;
        -ms-transform: scale(1) !important;
        -o-transform: scale(1) !important;
      }
      &.slick-current {
        > div {
          margin-left: 0;
          margin-right: 0;
          transform: scale(1) !important;
          //box-shadow: 0 4px 30px 0 rgba(0, 0, 0, 0.20);
          -webkit-transform: scale(1) !important;
          -moz-transform: scale(1) !important;
          -ms-transform: scale(1) !important;
          -o-transform: scale(1) !important;
        }
        .card-box {
          background: rgba(18, 204, 179, 0.06);
          border: 1px solid #12ccb3;
        }
      }
      &.slick-default {
        .card-box {
          background: #ffffff;
          border: none;
        }
      }
      
    }

    &__item {
      &--center {
        &.slick-current {
          transform: scale(1);
          opacity: 1;
          //box-shadow: 0 4px 30px 0 rgba(0, 0, 0, 0.20);
          -webkit-transform: scale(1);
          -moz-transform: scale(1);
          -ms-transform: scale(1);
          -o-transform: scale(1);
        }
      }
    }
  }
  .tutor-box {
    padding: 32px 24px;
    cursor: pointer;
    .card-box {
      background: #ffffff;
      box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.07);
      border-radius: 8px;
      padding: 26px 16px;
      .avatar {
        width: 48px;
        height: 48px;
        border-radius: 100%;
        -webkit-border-radius: 100%;
        -moz-border-radius: 100%;
        -ms-border-radius: 100%;
        -o-border-radius: 100%;
      }
    }
  }
  .tab_bottom {
    border-bottom: 1px solid #eee;
    .date_mt {
      margin-top: -10px;
    }
    .app_order2 {
      @include mobile {
        order: 2;
      }
    }
    .app_order1 {
      @include mobile {
        order: 1;
      }
    }
  }
}
