///* ========================================================================
//   #BLOCK
//   ======================================================================== */

// Simple Block Container

$block-border-radius : $gl-radius-base !default;
$block-padding       : $gl-spacer-l !default;
$block-background    : $theme-light-lt !default;
$block-margin        : $gl-spacer-l !default;
$block-shadow        : $gl-box-shadow-m !default;

%o-block {
  border-radius: $block-border-radius;
  padding: rem($block-padding);
  display: block;

  & + & {
    margin-top: rem($block-margin);
  }
}

%o-block--white {
  background-color: $block-background;
}

%o-block--lt {
  background-color: $b-gray-2;
}

%o-block--primary-ltr {
  background-color: $theme-primary-ltr;
}

%o-block--outlined {
  border: 1px solid $bd-gray;
  border-radius: $gl-radius-md;
}

%o-block--shadow {
  box-shadow: $block-shadow;
}

.o-block {
  @extend %o-block;

  &--white {
    @extend %o-block--white;
  }

  &--lt {
    @extend %o-block--lt;
  }

  &--primary-ltr {
    @extend %o-block--primary-ltr;
  }

  &--outlined {
    @extend %o-block--outlined;
  }

  &--shadow {
    @extend %o-block--shadow;
  }

  &--primary {
    @extend %o-block--white, %o-block--shadow;
  }
}
