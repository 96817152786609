body
  font-size: 14px

.mid_gray
  color: $t-gray-1 !important

//a
//  color: $link_color_default
//  text-decoration: none
//  &:hover
//    color: $link_color_default
//    text-decoration: underline

.uppercase
  text-transform: uppercase

h1, h2, h3, h4, h5, h6
  text-render: optimizeLegibility
  -webkit-font-smoothing: antialiased

h1, h2, h3
  margin: 1.75em 0 .5em 0
  line-height: 1.25em
h4 ,h5, h6
  line-height: 1.8em
h1, h2, h3, h4
  font-weight: normal

p
  font-size: 100%
  line-height: 1.6

h1
  font-size: 240%
  line-height: 1.3em
  @media screen and (min-width: $break-small)
    font-size: 280%
  @media screen and (min-width: 1024px)
    font-size: 300%

h2
  font-size: 180%
  line-height: 1.3em
  @media screen and (min-width: $break-small)
    font-size: 220%
  @media screen and (min-width: 1024px)
    font-size: 240%

h3
  font-size: 160%
  @media screen and (min-width: $break-small)
    font-size: 200%
  @media screen and (min-width: 1024px)
    font-size: 220%

h4
  font-size: 140%
  margin: 0 0 0.3em
  @media screen and (min-width: $break-small)
    font-size: 160%

h5
  font-size: 100%
  font-weight: 600
  @media screen and (min-width: $break-small)
    font-size: 110%

h6
  font-size: 100%
  font-weight: 600

small
  font-size: 90%

//.logged_in
//  h1
//    font-size: 2em
//  h2
//    font-size: 24px
//  h3
//    font-size: 21px
//  h4
//    font-size: 18px
//  h5
//    font-size: 16px
//  @media screen and (max-width: $break-device)
//    p
//      font-size: 15px
//    h1
//      font-size: 1.75em
//    h2
//      font-size: 1.5em
//    h3
//      font-size: 1.15em
//    h4
//      font-size: 1.1em
//    h5
//      font-size: 1em
