// Subject QA Landing Page

// Intro
$p-hw-intro-height: 64vh;
$p-hw-intro-height-desk: rem(635px);
$p-hw-intro-img-right: -15rem;
$p-hw-intro-img-width: rem(1099px);
$p-hw-intro-img-height: rem(702px);

// Info
$p-hw-line-y-position: rem(-6px);
$p-hw-line-x-position: rem($gl-spacer-l);
$p-hw-line-x-position-lg: rem($gl-spacer-2xl);

// Flow
$p-hw-flow-header-top: 10rem;

.p-homework {
  &__intro_new {
    background: url("#{$image-path}/pages/homework/intro_bg.jpg") no-repeat center;
    background-size: cover;
    height: 560px;
    &__hero {
      height: 100%;
    }
  }
  overflow-x: hidden;

  // Block
  &__block {
    position: relative;
  }

  // Intro
  &__intro {
    background: $theme-gradient-warm;

    &__hero {
      height: $p-hw-intro-height;
      position: relative;

      @include desktop {
        height: $p-hw-intro-height-desk;
      }
    }

    &__inner {
      width: 100%;
      position: relative;
      z-index: $gl-z-index-1;

      @include mobile {
        display: block;
        text-align: center;
        padding-top: $gl-spacer-3xl;
      }
    }

    &__img-mobile {
      position: absolute;
      left: 0;
      right: 0;
      margin: auto;
      width: 100%;
      bottom: 0;
    }

    &__img-desktop {
      position: absolute;
      right: $p-hw-intro-img-right;
      left: auto;
      bottom: auto;
      top: 0;
      width: $p-hw-intro-img-width;
      height: $p-hw-intro-img-height;
    }
  }

  &__subheader {
    letter-spacing: 2px;
    opacity: 0.4;

    @include desktop {
      font-size: rem($gl-font-size-base);
    }
  }

  // Info
  &__info {
    &__line {
      position: absolute;
      z-index: $gl-z-index-1;

      &--xs {
        right: $p-hw-line-x-position-lg;
        // bottom: $p-hw-line-y-position;
        bottom: -0.7rem;
      }

      &--s {
        left: $p-hw-line-x-position;
        // top: $p-hw-line-y-position;
        top:-0.5rem;
      }

      &--m {
        right: $p-hw-line-x-position;
        // bottom: $p-hw-line-y-position;
        bottom: -0.7rem;
      }

      &--l {
        left: $p-hw-line-x-position;
        // bottom: $p-hw-line-y-position;
        bottom: -0.7rem;
      }
    }

    &__tooltip {
      text-decoration: underline $bd-gray-dark;
    }
  }

  // Tutors
  &__tutors {
    background: url("#{$image-path}/pages/homework/world-bg.svg") no-repeat center;

    &__intro {

      @include desktop {
        text-align: center;
      }
    }
  }

  // Flow
  &__flow {
    position: relative;

    &__header {

      @include desktop {
        position: absolute;
        top: $p-hw-flow-header-top;
      }
    }
  }
}
