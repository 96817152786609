// Team
$p-team-founder-spacing: rem($gl-spacer-s);
$p-team-founder-z-index: $gl-z-index-1;
$p-team-founder-bg: rgba($b-gray-2, 0.95);

.p-team {
  &__mgt {
    position: relative;

    &__img {
      width: 100%;
      display: block;
      height: 350px;
      position: relative;
      @include mobile {
        height: 180px;
      }
      &_f{
        height: 618px;
        @include mobile {
          height: 350px;
        }
      }
    }

    &__info {
      background: $p-team-founder-bg;
      padding: $p-team-founder-spacing;

      &--overlay {
        z-index: $p-team-founder-z-index;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
      }
    }
  }
}
