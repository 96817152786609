// SDC
.p-sdc {
  &__intro {
    background: url('#{$image-path}/pages/sdc/intro_bg.jpg') no-repeat center;
    background-size: cover;
    height: 560px;
    &__hero {
      height: 100%;
    }
  }
  &_bottom_img{
    height: 384px;
    position: relative;
    @include mobile{
      height: 120px;
    }
  }
  &_img{
    height: 1471px;
    position: relative;
    @include mobile{
      height: 600px;
      margin-left:-24px;
      margin-right:-24px;
    }
  }
  &_flow {
    p {
      font-size: 1rem;
      line-height: 1.5;
      margin-bottom: 2.5rem;
    }
    a{
      &:hover{
        color: $t-green-dark;
      }

    }
  }
  &_target {
    background: $b-gray-1;
  }
}
.p-sdc-system {
  &__intro {
    background: url('#{$image-path}/pages/sdc-system/intro_bg.jpg') no-repeat
      center;
    background-size: cover;
    height: 560px;
    &__hero {
      height: 100%;
    }
  }
  &_info {
    .bg {
      position: relative;
      &_img {
        position: relative;
        z-index: 0;
        height: 400px;
      }
      .info_box {
        position: absolute;
        padding-top: 100px;
        z-index: 1;
        width: 100%;
      }
      .title_center {
        font-size: 46px;
        line-height: 150%;
        color: #fff;
      }
    }
    .info_title {
      margin: 30px 0 12px;
      font-size: 28px;
      line-height: 150%;
      @include mobile {
        font-size: 24px;
      }
    }
    @include mobile {
      .jiao {
        background: #e4f8f5;
        border-radius: 0.5rem;
      }
      .yu {
        background: #fff6ea;
        border-radius: 0.5rem;
      }
    }
  }
  &_flow {
    background: url('#{$image-path}/pages/sdc-system/flow_bg.jpg') no-repeat
      center;
    background-size: cover;
    .title {
      font-weight: 600;
      font-size: 28px;
      line-height: 168%;
      color: $t-light;
      margin-bottom: 56px;
      @include mobile {
        font-size: 24px;
        margin-bottom: 0.5rem
      }
    }
    .text {
      color: $t-light;
      opacity: 0.9;
    }
    .flow-box {
      background: rgba(255, 255, 255, 0.24);
      border-radius: 0.5rem;
      text-align: left;
    }
  }
  &_servers {
    background: $b-gray-1;
    .icon {
      margin: 24px 0;
      @include mobile {
        margin: 0;
      }
    }
    .title-h {
      margin-bottom: 16px;
      @include mobile {
        margin-bottom: 4px;
      }
    }
    .img-h{
      position: relative;
      height: 210px;
    }
    &_c {
      @include mobile {
        .mobile-contain {
          flex-wrap: nowrap;
          white-space: nowrap;
          overflow: auto;
          background: linear-gradient(to bottom, #F5FCFB 0%, #F5FCFB 50%, white 51%,white 100%);
          &::-webkit-scrollbar {
            display: none;
            width: 0 !important
          }
          -ms-overflow-style: none;
          overflow: -moz-scrollbars-none;
        }
        .mobile-box {
          width: 272px;
          background:$t-light;
          box-shadow: 12px 28px 36px rgba(0, 0, 0, 0.0545509);
        }
        .mobile-text {
          width: 240px;
          white-space: normal;
        }
      }
      .box{
        cursor: pointer;
      }
      .bg {
        height: 430px;
        background:rgba(236, 249, 247, 0.5);
        // opacity: 0.5;
        @include mobile {
          display: none;
        }
      }
      .num {
        margin: 24px auto 12px;
      }
      .active_box {
        background:$t-light;
        box-shadow: 12px 41px 36px rgba(0, 0, 0, 0.0545509);
        position: relative;
        z-index: 3;
        margin-top: -100px;
        .box_bg_r{
          background-position: center;
          background-size: cover;
          background-repeat: no-repeat;
          width:100%;
          height: 100%;
        }
      }
      .border {
        width: 32px;
        height: 4px;
        background: $t-dark;
        border-radius: 6px;
      }
    }
  }
}
