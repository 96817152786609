/* ==========================================================================
   #Form Control
   ========================================================================== */
//[TODO] Refactor icon classes
// Form control with validation

$icon-pos-x: 1.5 * $gl-input-icon-position-x + $gl-input-icon-size;

%field_with_icon {
  padding-right: $icon-pos-x;
}

.c-form-control {
  display: block;
  position: relative;

  &__icon {
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    right: $gl-input-icon-position-x;

    &--left {
      right: auto;
      left: $gl-input-icon-position-x;
    }
  }

  &__btn {
    padding: 0 $gl-form-group-btn-padding-x;
    display: flex;
    align-items: center;
  }

  // [TODO] there is a conflict with old styles.
  // Remove selectors and uncomment the following rule on react

  input#{&}--with-icon,
  select#{&}--with-icon,
  textarea#{&}--with-icon {
    padding-left: $icon-pos-x;
  }

  input#{&}--with-link,
  select#{&}--with-link,
  textarea#{&}--with-link {
    padding-right: $gl-spacer-2xl;
  }

  // [TODO] Refactor
  &__link {
    position: absolute;
    right: $gl-spacer-xs;
    top: 50%;
    transform: translate(0, -50%);
    font-size: $gl-font-size-xs;
    color: $theme-dark-lt;
  }

  // &--with-icon {
  //   padding-left: $icon-pos-x;
  // }

  //// Validation Variants
  //@each $name, $color in (success: $theme-success, danger: $theme-alert) {
  //  &--#{$name} .c-form-control__field {
  //    @extend %field_with_icon;
  //
  //    border-color: $color;
  //  }
  //}

}
