// Default Size
$document-width: 178px;
$document-height: 222px;
$document-font-size: 12px;
$document-border-radius: $gl-radius-base;
//xxsmall
$document-xxs-width: 60px;
$document-xxs-height: 82px;
$document-xxs-font-size: 6px;
// xsmall
$document-xs-width: 80px;
$document-xs-height: 102px;
$document-xs-font-size: 6px;
// small
// $document-sm-width: 122px;
$document-sm-width: 178px;
// $document-sm-height: 152px;
$document-sm-height: 222px;
// small
$document-m-width: 178px;
$document-m-height: 222px;
$document-m-font-size: 12px;
//Title
$document-footer-title-font-size: rem($gl-font-size-xs);

$document-sizes: (
  'xxs': (
    width: $document-xxs-width,
    height: $document-xxs-height,
    font-size: $document-xxs-font-size,
  ),
  'xs': (
    width: $document-xs-width,
    height: $document-xs-height,
    font-size: $document-xs-font-size,
  ),
  'sm': (
    width: $document-sm-width,
    height: $document-sm-height,
    mobile: 'xs',
  ),
  'm': (
    width: $document-m-width,
    height: $document-m-height,
    font-size: $document-m-font-size,
    mobile: 'sm',
  ),
);

$document-color: $theme-light-lt;
$document-padding-x: 15px;
// Document Image
$document-image-border-color: $bd-gray;


.c-lt-doc {
  padding: rem($gl-spacer-s) 0;
  border-top: 1px solid $bd-gray;

  &:last-of-type {
    border-bottom: 1px solid $bd-gray;
    margin-bottom: $gl-spacer-s;
  }

  &__menu {
    text-align: right;

    &__item {
      display: inline-block;
      position: relative;

      & + & {
        margin-left: rem($gl-spacer-m)
      }
    }
  }

  &__title {
    font-weight: 500;
    color: $t-dark;
  }

  &__time {
    font-size: $gl-font-size-xxs;
    color: $theme-dark-lt;
  }

  &__icon {
    opacity: 0.4;

    @include hover {
      opacity: 1;
    }
  }

  &__tooltip {
    width: 240px;
  }
}

.c-document {
  $self: &;
  overflow: hidden;
  font-size: 13px;
  color: $t-gray-1;
  position: relative;
  // the document component can be clickable (e.g. document banner),
  // so we need to reset default link styles (i.e. a.c-document)
  display: block;
  text-decoration: none;

  @include desktop {
    font-size: $gl-font-size-s;
  }

  &__title {
    margin-bottom: 5px;
    font-size: $gl-font-size-s;
    color: $t-dark;
    font-weight: 500;
    word-break: break-all;
    overflow-wrap: break-word;
    -webkit-hyphens: auto;
    -ms-hyphens: auto;
    -moz-hyphens: auto;
    hyphens: auto;
    line-height: $gl-line-height-sm;

    @include desktop {
      font-size: $gl-font-size-base;
    }

    &__link {
      color: inherit;
      cursor: pointer;

      @include hover {
        text-decoration: underline;
      }
    }
  }

  &__text {
    margin-bottom: 15px;
    word-break: break-word;

    > *:last-child {
      margin-bottom: 0;
    }
  }

  // Show shadow on hover
  &--shadowed {
    &:hover {
      box-shadow: $gl-box-shadow-m;
    }
  }
}

.c-doc-thumb {
  text-align: left;
  line-height: $gl-line-height-xs;
  text-transform: uppercase;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: $document-color;

  // Default Size
  @include doc-thumb-size(
    (
      width: $document-width,
      height: $document-height,
      font-size: rem($document-font-size),
      border-radius: $document-border-radius,
    )
  );

  // Size variants
  @each $size, $opts in $document-sizes {
    &--#{$size} {
      @include doc-thumb-size($opts);

      @if (map-has-key($opts, mobile)) {
        @include media('<md') {
          @include doc-thumb-size(
            map-get($document-sizes, map-get($opts, mobile))
          );
        }
      }
    }
  }

  &--bordered {
    border: $gl-border-width-base solid $document-image-border-color;
  }

  &__link {
    width: 100%;
    flex: 1;
    overflow: hidden;

    @include plain-hover-focus {
      text-decoration: none;
      color: inherit;
    }
  }

  &__footer {
    border: $gl-border-width-base solid $document-image-border-color;
    border-top: none;
    padding: rem($gl-spacer-xs);
    width: $document-width;
    overflow-wrap: break-word;
    word-wrap: break-word;
    -webkit-hyphens: auto;
    -ms-hyphens: auto;
    -moz-hyphens: auto;
    hyphens: auto;

    @include mobile {
      width: $document-sm-width;
    }

    &__title {
      font-weight: 500;
      font-size: $document-footer-title-font-size;
      color: $t-dark;

      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
    }
  }

  // [TODO] Test component on images with different aspect ratios
  &__img {
    display: block;
    min-height: 100%;
    min-width: 100%;
    width: auto;
    height: auto;
    position: relative;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  &__tag {
    position: absolute;
    top: rem($gl-spacer-xxs);
    right: rem($gl-spacer-xxs);
  }
}

.c-document-loading {
  width: 33px;
  text-align: center;
  background: #edf7f6;
  height: 100%;
  border-radius: 3px;
  vertical-align: middle;
  display: flex;
  align-items: center;
  justify-content: center;
}
