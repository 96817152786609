/* ==========================================================================
   #TABS
   ========================================================================== */

// Default Variables

// Border
$tabs-border-width       : $gl-border-width-base !default;
// Text
$tabs-font-size         : $gl-font-size-base !default;
$tabs-font-size-sm      : $gl-font-size-xs !default;
$tabs-font-size-sm-desk : $gl-font-size-s !default;
// States
$tabs-active-color   : $t-dark !default;
// Spacing
$tabs-spacing-x: 24px !default;
$tabs-padding-y: 12px !default;
$tabs-padding-y-mobile: 8px !default;
// Menu
$tabs-menu-padding: 8px !default;
// Button
$tab-btn-padding-x: $gl-spacer-m;
$tab-btn-padding-y: $gl-spacer-xxs;

// Horizontal tabs navigation
.tab-content > .tab-pane, .pill-content > .pill-pane{
  display: none
}

.tab-content > .active, .pill-content > .active{
  display: block
}

.c-nav-tabs {
  $self: &;

  border-bottom: $tabs-border-width solid transparent;
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
  font-size: $tabs-font-size;

  // @media (min-width: 380px) and (max-width: 480px) {
  //   display: block;
  // }

  &--sm {
    font-size: $tabs-font-size-sm;

    @include desktop {
      font-size: $tabs-font-size-sm-desk;
    }
  }

  @include list-unstyled();

  &--underlined {
    border-bottom-color: $bd-gray;
  }

  // Bordered button style tabs
  &--bordered {
    border: 1px solid $bd-gray;
    border-radius: $gl-radius-base;
  }

  &__item {
    margin: 0;
    padding: 0;
    margin-bottom: - $tabs-border-width;
    position: relative;
    cursor: pointer;

    @media (min-width: 600px) and (max-width: 1920px) {
      // Spacing between tabs
      & + & {
        margin-left: rem($tabs-spacing-x);
      }
    }
    @media (min-width: 380px) and (max-width: 480px) {
      marign-top: 10px;
    }


  }

  &__link {
    color: $t-gray-1;
    display: inline-block;
    font-weight: 600;
    padding: 0 0 rem($tabs-padding-y) 0;
    border-bottom: 2 * $tabs-border-width solid transparent;

    @include hover-focus {
      color: $tabs-active-color;
      text-decoration: none;
    }

    @include media('<sm') {
      padding: 0 0 rem($tabs-padding-y-mobile) 0;
    }

    // Active state
    &.is-active {
      color: $tabs-active-color;
      border-bottom-color: $b-green;
      border-bottom-width: 2px;
    }

    // Disabled state
    &.is-disabled {
      @include plain-hover-focus {
        color: $t-gray-2;
        border-bottom-color: transparent;
      }
    }
  }

  &__menu {
    text-align: center;
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
    border-bottom: solid 1px #eee;
  }

  // Bordered button style tabs
  &__btn {
    display: block;
    color: $t-dark;
    padding: rem($tab-btn-padding-y) rem($tab-btn-padding-x);
    white-space: nowrap;
    background: $theme-light-lt;

    &:hover {
      background: $b-gray-2;
    }

    &.is-active {
      color: $t-light;
      background: $b-green;
      border-radius: $gl-radius-base;

      @include hover-focus {
        background: $b-green;
      }
    }
  }

  // Number count on tab
  &__count {
    $count-bg: $theme-primary-ltr;
    $count-color: $t-green-dark;
    $count-padding-x: 10px;
    $count-padding-y: 4px;
    $count-margin: 4px;

    background-color: $count-bg;
    display: inline-block;
    border-radius: $gl-radius-pill;
    line-height: 1;
    padding: rem($count-padding-y) rem($count-padding-x);
    margin-left: rem($count-margin);
    font-weight: 600;
    font-size: $gl-font-size-3xs;
    color: $count-color;
  }

  // Full width tabs: tab elements will automatically layout as equal width columns

  &--full {
    #{$self} {
      // reset spacing between tabs
      &__item {
        flex: 1;
      }

      &__link,
      &__label {
        width: 100%;
        text-align: center;
        display: block;
      }
    }

    #{$self}__item + #{$self}__item {
      margin-left: 0;
    }
  }
}

.c-tabs-new {
  &__item {
    background-image: url("#{$image-path}/components/tab-bg.png");
    background-position: top right;
    background-size: 100% 100%;
    padding: 27px 60px 27px 44px;
    & + & {
      margin-left: -12px;
    }
    a {
      color: $t-gray-1;
      font-size: 24px;
      &:hover {
        color: rgba(0, 0, 0, 0.7)
      }
    }
    &__active {
      background-image: url("#{$image-path}/components/tab-active-bg.png");
      z-index: 2;
      a {
        color: white;
      }
    }
  }
}
