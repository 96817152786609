.c-accordion {
  $self: &;

  &__toggle {
    cursor: pointer;

    &.is-collapsed &__opened,
    &.is-opened &__collapsed {
      display: none;
    }
  }

  &__content {
    display: none;
  }
  &__active {
    display:block;
  }
}

