.tutor_signup {
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    padding: 8px 16px;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    border:1px solid #ddd;
}
}
