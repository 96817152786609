/* ==========================================================================
   #STICKY BAR
   ========================================================================== */

// Default Variables

$sbar-border-color: rgba($theme-light-lt, 0.2);
$sbar-spacing: $gl-spacer-xxs;
$sbar-radius: $gl-radius-md;
$sbar-font-size: 10px;
$sbar-color: $t-light;
$sbar-top-position: 35%;
$sbar-x-position: $gl-spacer-s;
$sbar-bottom-position: $gl-spacer-xs;
$sbar-max-width: 300px;

// Sticky Bar

.c-sbar {
  position: fixed;
  z-index: $gl-z-index-3;
  background: $b-green;
  border-radius: $sbar-radius;
  right: rem($sbar-x-position);
  .pd{
    @include mobile {
      padding-left:0.5rem;
      padding-right:0.5rem;
    }
  }

  &__item {
    text-align: center;
    font-size: rem($sbar-font-size);
    color: $sbar-color;
    padding: rem($sbar-spacing) 0;
    cursor: pointer;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    max-width: 100%;
    display: block;

    & + & {
      @include desktop {
        border-top: 1px solid $sbar-border-color;
      }

      @include mobile {
        border-left: 1px solid $sbar-border-color;
      }
    }

    @include hover-focus {
      color: $sbar-color;
    }
  }

  &__tooltip {
    white-space: nowrap;
  }

  @include mobile {
    left: 0;
    right: 0;
    bottom: rem($sbar-bottom-position);
    margin: 0 1.5rem;
    //max-width: rem($sbar-max-width);
    width: calc(100% - 3rem);
    display: flex;
  }

  @include desktop {
    top: $sbar-top-position;
    padding: 0 rem($sbar-spacing);
  }

  &__fill-icon {
    .#{$icon-class}__svg {
      fill: $sbar-color;
    }
  }
}
