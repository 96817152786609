// Retina
=background-image-retina($file, $type, $width, $height)
  background-image: image-url($file + "." + $type)

  @media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (-moz-min-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2 / 1), only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx)
    &
      background-image: image-url($file + "@2x." + $type)
      -webkit-background-size: $width $height
      -moz-background-size: $width $height
      -o-background-size: $width $height
      background-size: $width $height

  @media only screen and (-webkit-min-device-pixel-ratio: 3), only screen and (-moz-min-device-pixel-ratio: 3), only screen and (-o-min-device-pixel-ratio: 3 / 1), only screen and (min-device-pixel-ratio: 3), only screen and (min-resolution: 401dpi), only screen and (min-resolution: 2.46dppx)
    &
      background-image: image-url($file + "@3x." + $type)
      -webkit-background-size: $width $height
      -moz-background-size: $width $height
      -o-background-size: $width $height
      background-size: $width $height
