/*
  ==========================================================================
  ONECLASS
  ==========================================================================
  * CONTENTS
  *
  * SETTINGS
  * Config...............Project-level configuration and feature switches
  * Core.................OneClass’ core and setup settings
  * Global...............Project-wide variables and settings
  * Colors...............Color variables and settings
  *
  * TOOLS
  * Border Radius........Single side border-radius
  * Breakpoints..........Breakpoint viewport sizes' mixins
  * Document.............Document thumbnail mixins
  * Grid Framework.......Bootstrap 4 Framework grid generation
  * Grid.................Bootstrap 4 Grid system
  * Button...............Button variants
  * Container............Responsive container mixin
  * Clearfix.............Micro clearfix mixin
  * Em...................Unitless function to convert px to em
  * Rem..................Unitless function to convert px to rem
  * Hover................Hover/active/focus state mixins
  * Forms................Mixins for form elements: input, selects, checkboxes, etc.
  * Font Size............Generate a font-size and baseline-compatible line-height.
  * Lists................List mixins
  * Position.............Position mixins (e.g. absolute centering, etc.)
  * Size.................Sizing shortcuts
  *
  * GENERIC
  * Box-sizing...........Better default `box-sizing`
  * Normalize............@necolas’ Normalize.css
  * Reset................A tiny reset to complement Normalize.css
  * Animation............Animation presets
  * Fonts................Project's fonts initialization
  * Shared...............Sensibly and tersely share some global commonalities
  *
  * VENDORS
  * @include_media.......Media queries library (http://include-media.com/)
  * Flatpickr............Flatepickr datepicker plugin (https://chmln.github.io/flatpickr/)
  *
  * LAYOUTS
  * Grid.................Grid system based on flexboxgrid
  *
  * ELEMENTS
  * Page.................Set up document’s default typography settings
  * Phrasing.............Phrasing content that includes text and the mark-up it contains
  * Embedded.............Embedded content tags
  * Headings.............h1-h6
  * Images...............Basic image styles
  *
  * OBJECTS
  * Block................A bordered/colored block container
  * Form Groups..........Form elements vertical layouting
  * Section..............Page Section
  *
  * COMPONENTS
  * Avatar...............A profile image
  * Badge................An icon/text badge that can be attached to any element
  * Button...............A simple button with/without icon
  * Fixed................Fixed position container
  * Button Group.........Group a series of buttons together on a single line
  * Chosen...............Custom Select (Chosen) [TODO] Refactor old code
  * Datepicker...........jQuery.ui.datepicker custom theme
  * Document.............A document thumbnail
  * Dropdown.............Dropdown component
  * Footer...............Page Footer
  * Form Control.........Form elements with validation
  * Form Field...........Textual form fields
  * Form Radio...........Custom radio
  * Form Select..........Custom select
  * Form Label...........Form text label
  * Icon.................An icon with/without background
  * Pagination...........Page navigation
  * Progress.............A progress bar
  * Tabs.................Tabs navigation
  * Toolbar..............An icon toolbar with dropdowns
  * Navbar...............A top navbar (React)
  * Sidebar..............A left side navbar for admin panel
  * Message Counter......A simple notification badge
  * Message Box..........Message boxes (red, green, gray, etc)
  * Rating...............Star Rating
  * Slider...............Slider carousel (Flick)
  * Calendar.............Calendar plugin (ie. dashboard calendar, admin calendar)
  * Calendar Event.......Calendar event details
  * Service..............Services blocks (ie. dashboard, user navbar dropdown menu)
  * Board................A fixed board for admin panel
  * Table................Table
  * Question.............A QA question
  * Comment..............A QA comment
  * Tag..................A tag
  * Page Break...........A thick page break
  * Chart................Chart widgets
  * List.................Inline list with dot in between
  * Country Select.......Select country with flag icons (signup form)
  * Steps................A steps bar
  * Autocomplete.........An autocomplete plugin
  * Comparison Table.....A table for features comparison
  * Checkbox.............Batch select checkbox (ie. admin panel reassign popup)
  * Testimonial..........A testimonial container
  * Text Editor..........A text editor plugin
  * Profile Card.........A profile card for tutors and mentors (ie. essay, wenda landing page)
  * Timeline.............A timeline for landing pages
  * Info.................A info row with label and description

  *
  * UTILITIES
  * Align................Vertical alignment
  * Colors...............Colors utilities, e.g. text color, background, gradients, etc.
  * Display..............Utilities for common `display` values
  * Screen Readers.......Special utilities for screen readers
  * Size.................Width/height for icons and etc
  * Spacing..............Margin and padding utility classes
  * Flex.................Flexbox utility classes
  * Text.................Text Alignment utilities
  * Border...............Border utilities (radius, widht, color, etc)
  * Font Sizes...........Font Size utilities
  *
  * HOTFIXES
  * Shame...............Hacky code/hotfixes that should be replaced asap
  *
  * DEBUG
  * Healthchecks........Visually check BEM naming rules
  */

// [TODO:] Add Async font load
// ===== FONTS =====
//@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,400i,500,500i,700,700i');

// ===== SETTINGS =====
@import 'main.settings';
@import 'variables';

// ===== TOOLS =====
@import 'main.tools';

// ===== VENDORS =====
@import 'vendors/vendors.flatpickr.min';

// ===== LAYOUTS =====
@import 'layouts/layouts.grid';
// @import 'layouts/layouts.grid_old';
@import 'layouts/layouts.new';

// ===== OBJECTS =====
@import 'objects/objects.block';
@import 'objects/objects.form-groups';
@import 'objects/objects.section';

// ====== active_admin ======

// ====== CUSTOM ======
@import 'custom/custom';
@import 'custom/mentor';

// ====== npm包 ======
@import '~@fullcalendar/common/main.css';
@import '~@fullcalendar/daygrid/main.css';
@import '~@fullcalendar/timegrid/main.css';
@import '~swiper/swiper.scss';
@import '~swiper/swiper-bundle.min.css';
@import '~swiper/components/navigation/navigation.scss';
@import '~swiper/components/pagination/pagination.scss';
@import '~swiper/components/effect-fade/effect-fade.scss';
@import '~slick-carousel/slick/slick.css';
@import '~slick-carousel/slick/slick-theme.css';
@import '~font-awesome/css/font-awesome.min.css';


// ===== COMPONENTS =====
@import 'components/components.popup';
@import 'components/components.icon';
@import 'components/components.button';
@import 'components/components.avatar';
@import 'components/components.form-field';
@import 'components/components.dropdown';
@import 'components/components.form-radio';
@import 'components/components.form-control';
@import 'components/components.form-label';
@import 'components/components.chosen';
@import 'components/components.form-select';
@import 'components/components.tabs';
@import 'components/components.pagination';
@import 'components/components.message-counter';
@import 'components/components.navbar';
@import 'components/components.footer';
@import 'components/components.fixed';
@import 'components/components.tooltip';
@import 'components/components.rating';
@import 'components/components.slider';
@import 'components/components.alert';
@import 'components/components.service';
@import 'components/components.calendar';
@import 'components/components.table';
@import 'components/components.question';
@import 'components/components.comment';
@import 'components/components.tag';
@import 'components/components.page-break';
@import 'components/components.sticky-bar';
@import 'components/components.list';
@import 'components/components.accordion';
@import 'components/components.country-select';
@import 'components/components.steps';
@import 'components/components.text-editor';
@import 'components/components.profile-card';
@import 'components/components.timeline';
@import 'components/components.video';
@import 'components/components.document';
@import 'components/components.block';
@import 'components/components.upcoming-banner';
@import 'components/components.booking';
@import 'components/components.bookings-list';
@import 'components/components.breadcrumb';
@import 'components/components.btn-group';
@import 'components/components.chat';
@import 'components/components.class-card';
@import 'components/components.course';
@import 'components/components.fullcalendar';
@import 'components/components.header';
@import 'components/components.notice';
@import 'components/components.section';
@import 'components/components.sticky-bar';
@import 'components/components.tutor-card';
@import 'components/components.lt-calendar';
@import 'components/components.editor';
@import 'components/components.testimonial';

// ===== PAGES =====
@import 'pages/pages.home';
@import 'pages/pages.dashboard';
@import 'pages/pages.elite';
@import 'pages/pages.careers';
@import 'pages/pages.careers-show';
@import 'pages/pages.sdc';
@import 'pages/pages.sdcteam';
@import 'pages/pages.sdcservice';
//@import 'pages/pages.sdcsteps';
@import 'pages/pages.tutor-dashboard';
@import 'pages/pages.tutor-app';
@import 'pages/pages.qa-index';
@import 'pages/pages.about';
@import 'pages/pages.team';
@import 'pages/pages.why';
@import 'pages/pages.services';
@import 'pages/pages.landing-lifeqa';
@import 'pages/pages.landing-guides';
@import 'pages/pages.landing-homework';
@import 'pages/pages.landing-essay';
@import 'pages/pages.landing-study-resources';
@import 'pages/pages.landing-course';
@import 'pages/pages.landing-lt';
@import 'pages/pages.live-tutorial';
@import 'pages/pages.document-index';
@import 'pages/pages.coursetool';
@import 'pages/pages.cfreport';
@import 'pages/pages.profindex';
@import 'pages/pages.iss';
@import 'pages/pages.forms';
@import 'pages/pages.static';
@import 'pages/pages.famous';
@import 'pages/pages.index';
@import 'pages/pages.ekus';
@import "pages/pages.offer-interpretation";
@import 'pages/pages.highschool-tutoring';
@import 'pages/pages.university-tutoring';
@import 'pages/pages.live-tutorial-new';
@import 'pages/pages.p-prep';
@import 'pages/pages.tutoring-country';
@import 'pages/pages.country';
@import 'pages/pages.home_page';
@import 'pages/pages.courses';
@import 'pages/pages.case';
@import 'pages/pages.sdc-intro';
@import 'pages/pages.abroad-info';
@import 'pages/pages.news';
@import 'pages/pages.login';
@import 'pages/pages.mentor-tutor-login';
@import 'pages/tutor/pages.tutor-lt';
@import 'pages/pages.booking';
@import 'pages/pages.book';
@import 'pages/pages.internship';
@import 'pages/pages.learning-center';
@import 'pages/tutor/pages.payments';
@import 'pages/tutor/pages.tutor_signup';
@import 'pages/pages.cajob';
@import 'pages/pages.lt-dashboard';
@import 'pages/pages.lt_bookings';
@import 'pages/_pages.tutor-applicant.sass';
@import 'pages/tutor/pages.tutor-applican';
@import 'pages/pages.chasegenius';
@import 'pages/pages.democlass';

// ===== UTILITIES =====
@import 'utilities/utilities.colors';
@import 'utilities/utilities.display';
@import 'utilities/utilities.spacing';
@import 'utilities/utilities.size';
@import 'utilities/utilities.screen-readers';
@import 'utilities/utilities.flex';
@import 'utilities/utilities.texts';
@import 'utilities/utilities.border';
@import 'utilities/utilities.font-size';
@import 'utilities/utilities.validation-engine';
@import 'utilities/utilities.align';
@import 'utilities/utilities.lists';
@import 'utilities/utilities.font-weight';

// ===== HOTFIXES =====
@import 'shame';

// ===== DEBUG =====
@import 'healthchecks';

// ===== COMMON =====
@import 'mixins/flex';
@import 'common/retina';
@import 'common/labels';
@import 'common/reset';
@import 'common/widgets';
@import 'common/icons';
@import 'common/utility_classes';
@import 'common/buttons';
@import 'common/tachyons-custom';
@import 'common/jstarbox';
@import 'common/navs';
@import 'common/typography';
@import 'common/containers';
@import 'common/alerts';
@import 'common/antd';
@import 'common/telinput';
@import '~antd/dist/antd.css';

// ===== GENERIC =====
@import 'generic/generic.reset';
@import 'generic/generic.normalize';
@import 'generic/generic.animation';
@import 'generic/generic.box-sizing';
@import 'generic/generic.shared';

// ===== ELEMENTS =====
@import 'elements/elements.page';
@import 'elements/elements.phrasing';
@import 'elements/elements.headings';
@import 'elements/elements.images';
@import 'elements/elements.embedded';
