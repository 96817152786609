// Custom build of Tachyons

/* SPACING */
@use "sass:math";

$spacing-none: 0 !important;
$spacing-extra-extra-xs: .5rem !important;
$spacing-extra-extra-small: .75rem !important;
$spacing-extra-small: 1rem !important;
$spacing-gutter: 1.5rem !important;
$spacing-small: 2rem !important;
$spacing-medium: 3rem !important;
$spacing-large: 4rem !important;
$spacing-extra-large: 5rem !important;
$spacing-extra-extra-large: 6rem !important;
$spacing-extra-extra-extra-large: 7rem !important;

// tachyons
@import '~tachyons-sass';

// Customizations

// BORDERS
.b--light-gray {border-color: $bd-gray;}
.b--black-07 {border-color: $bd-gray;}
.b--white {border-color: white;}
.b--black {border-color: $t-dark;}

// FONT-WEIGHT
.fw6    { font-weight: 500; }


// BORDER RADIUS
// br0         = use this to remove exisiting radius on an element
// br1         = small radius, ie. buttons
// br2         = large radius, ie. containers
// br-100      = circles
// br-pill     = pill buttons
.br0 {border-radius: 0;}
.br1 {border-radius: .1875rem;} //3px
.br2 {border-radius: .375rem;} //6
.br5 {border-radius: .625rem; } //10

.lh15 {line-height: 150%;}
.lh18 {line-height: 180%;}
.lh2 {line-height: 200%;}
// BOX-SHADOW
// 1  = nav bar
// 2  = regular or small containers
// 3  = document thumbnails
// 4  = large containers
.shadow-0 {box-shadow: none;}
.shadow-1 {box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);}
.shadow-2 {box-shadow: 0 1px 2px rgba(0,0,0,0.1);}
.shadow-3 {box-shadow: 0 2px 4px 0 rgba(0,0,0,0.08);}
.shadow-4 {box-shadow: 0 4px 20px 0 rgba(0,0,0,0.08);}
@media #{$breakpoint-not-small}{
  .shadow-0-ns {box-shadow: none;}
  .shadow-1-ns {box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);}
  .shadow-2-ns {box-shadow: 0 1px 2px rgba(0,0,0,0.1);}
  .shadow-3-ns {box-shadow: 0 2px 4px 0 rgba(0,0,0,0.08);}
  .shadow-4-ns {box-shadow: 0 4px 20px 0 rgba(0,0,0,0.08);}
}
@media #{$breakpoint-medium}{
  .shadow-0-m {box-shadow: none;}
  .shadow-1-m {box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);}
  .shadow-2-m {box-shadow: 0 1px 2px rgba(0,0,0,0.1);}
  .shadow-3-m {box-shadow: 0 2px 4px 0 rgba(0,0,0,0.08);}
  .shadow-4-m {box-shadow: 0 4px 20px 0 rgba(0,0,0,0.08);}
}
@media #{$breakpoint-large}{
  .shadow-0-l {box-shadow: none;}
  .shadow-1-l {box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);}
  .shadow-2-l {box-shadow: 0 1px 2px rgba(0,0,0,0.1);}
  .shadow-3-l {box-shadow: 0 2px 4px 0 rgba(0,0,0,0.08);}
  .shadow-4-l {box-shadow: 0 4px 20px 0 rgba(0,0,0,0.08);}
}


/* TYPE SCALE FOR BIG HEADLINES - don't use on mobile*/
.f-headline {font-size: 6rem;} //96
.f-subheadline {font-size: 3rem;} //48

//TYPE SCALE
//Desktop base: 16px
//f1=30px f2=24px f3=20px f4=18px f5=16px f6=14px f7=13px f8=12px f15=15px f0=32px f9=10px
.f58 {font-size:3.625rem}
.f42 {font-size: 2.625rem;}
.f40 {font-size: 2.5rem;}
.f0 {font-size: 2rem;}
.f1 {font-size: 1.875rem;}
.f2 {font-size: 1.5rem;}
.f3 {font-size: 1.25rem;}
.f4 {font-size: 1.125rem;}
.f5 {font-size: 1rem;}
.f15 {font-size: .9375rem;}
.f17 {font-size: 1.0625rem;}
.f6 {font-size: .875rem;}
.f7 {font-size: .8125rem;}
.f8 {font-size: .75rem;}
.f28 {font-size: 1.75rem;}
.f38 {font-size: 2.375rem;}
.f9 {
  font-size: .75rem;
  width: 118%;
  -webkit-transform: scale(0.82) translate(-9%, 0px);
  -moz-transform: scale(0.82) translate(-9%, 0px);
  -ms-transform: scale(0.82) translate(-9%, 0px);
  -o-transform: scale(0.82) translate(-9%, 0px);
  transform: scale(0.82) translate(-9%, 0px);
}
@media #{$breakpoint-not-small}{
  .f1-ns {font-size: 1.875rem;}
  .f2-ns {font-size: 1.5rem;}
  .f3-ns {font-size: 1.25rem;}
  .f4-ns {font-size: 1.125rem;}
  .f5-ns {font-size: 1rem;}
  .f15-ns {font-size: .9375rem;}
  .f6-ns {font-size: .875rem;}
  .f7-ns {font-size: .8125rem;}
  .f8-ns {font-size: .75rem;}
}
@media #{$breakpoint-medium}{
  .f1-m {font-size: 1.875rem;}
  .f2-m {font-size: 1.5rem;}
  .f3-m {font-size: 1.25rem;}
  .f4-m {font-size: 1.125rem;}
  .f5-m {font-size: 1rem;}
  .f15-m {font-size: .9375rem;}
  .f6-m {font-size: .875rem;}
  .f7-m {font-size: .8125rem;}
  .f8-m {font-size: .75rem;}
}
@media #{$breakpoint-mobile}{
  .f0-xs {font-size: 2rem;}
  .f1-xs {font-size: 1.875rem;}
  .f2-xs {font-size: 1.5rem;}
  .f3-xs {font-size: 1.25rem;}
  .f4-xs {font-size: 1.125rem;}
  .f5-xs {font-size: 1rem;}
  .f15-xs {font-size: .9375rem;}
  .f6-xs {font-size: .875rem;}
  .f7-xs {font-size: .8125rem;}
  .f8-xs {font-size: .75rem;}
  .f28-xs {font-size: 1.75rem;}
}

/* HEIGHTS */
//h1  = 24px
//h2  = 32px
//h3  = 48px
//h4  = 64px
//h5  = 96px

.h1 {height: 1.5rem;}
.h3 {height: 3rem;}
.h4 {height: 4rem;}
.h5 {height: 6rem;}
.h6 {height: 3rem;}
.h7 {height: 6rem;}
@media #{$breakpoint-not-small}{
  .h1-ns {height: 1.5rem;}
  .h3-ns {height: 3rem;}
  .h4-ns {height: 4rem;}
  .h5-ns {height: 6rem;}
  .h6-ns {height: 3rem;}
  .h7-ns {height: 6rem;}
}
@media #{$breakpoint-medium}{
  .h1-m {height: 1.5rem;}
  .h3-m {height: 3rem;}
  .h4-m {height: 4rem;}
  .h5-m {height: 6rem;}
  .h6-m {height: 3rem;}
  .h7-m {height: 6rem;}
}
@media #{$breakpoint-large}{
  .h1-l {height: 1.5rem;}
  .h3-l {height: 3rem;}
  .h4-l {height: 4rem;}
  .h5-l {height: 6rem;}
  .h6-l {height: 3rem;}
  .h7-l {height: 6rem;}
}


//WIDTH SCALE
//w1  = 24px
//w2  = 32px
//w3  = 48px
//w4  = 64px
//w5  = 96px


.wd1 {width: 1.5rem !important;}
// .w2 class is being used on doc-show page, can't override
.wd2 {width: 2rem !important;}
.wd3 {width: 3rem !important;}
.wd4 {width: 4rem !important;}
.wd5 {width: 6rem !important;}
.wd-10 {width: 10% !important;}
.wd-20 {width: 20% !important;}
.wd-25 {width: 25% !important;}
.wd-30 {width: 30% !important;}
.wd-33 {width: 33% !important;}
.wd-40 {width: 40% !important;}
.wd-50 {width: 50% !important;}
.wd-60 {width: 60% !important;}
.wd-70 {width: 70% !important;}
.wd-75 {width: 75% !important;}
.wd-80 {width: 80% !important;}
.wd-90 {width: 90% !important;}
.wd-100 {width: 100% !important;}
.w-auto {width: auto !important;}
.w-six { width: math.div(100%, 6) !important; }
@media #{$breakpoint-not-small}{
  .wd1-ns {width: 1.5rem !important;}
  .wd2-ns {width: 2rem !important;}
  .wd3-ns {width: 3rem !important;}
  .wd4-ns {width: 4rem !important;}
  .wd5-ns {width: 6rem !important;}
  .wd-10-ns {width: 10% !important;}
  .wd-20-ns {width: 20% !important;}
  .wd-25-ns {width: 25% !important;}
  .wd-30-ns {width: 30% !important;}
  .wd-33-ns {width: 33% !important;}
  .wd-40-ns {width: 40% !important;}
  .wd-50-ns {width: 50% !important;}
  .wd-60-ns {width: 60% !important;}
  .wd-70-ns {width: 70% !important;}
  .wd-75-ns {width: 75% !important;}
  .wd-80-ns {width: 80% !important;}
  .wd-90-ns {width: 90% !important;}
  .wd-100-ns {width: 100 !important;}
  .w-auto-ns {width: auto !important;}
  .w-third-ns { width: math.div(100%, 3) !important; }
  .w-two-third-ns { width: math.div(100%, 1.5) !important; }
  .w-auto-ns { width: auto !important; }
  .w-six-ns { width: math.div(100%, 6) !important; }
}
@media #{$breakpoint-medium}{
  .wd1-m {width: 1.5rem !important;}
  .wd2-m {width: 2rem !important;}
  .wd3-m {width: 3rem !important;}
  .wd4-m {width: 4rem !important;}
  .wd5-m {width: 6rem !important;}
  .wd-10-m {width: 10% !important;}
  .wd-20-m {width: 20% !important;}
  .wd-25-m {width: 25% !important;}
  .wd-30-m {width: 30% !important;}
  .wd-33-m {width: 33% !important;}
  .wd-40-m {width: 40% !important;}
  .wd-50-m {width: 50% !important;}
  .wd-60-m {width: 60% !important;}
  .wd-70-m {width: 70% !important;}
  .wd-75-m {width: 75% !important;}
  .wd-80-m {width: 80% !important;}
  .wd-90-m {width: 90% !important;}
  .wd-100-m {width: 100% !important;}
  .w-auto-m {width: auto !important;}
  .w-third-m { width: math.div(100%, 3) !important; }
  .w-two-thirds-m { width: math.div(100%, 1.5) !important; }
  .w-auto-m {    width: auto !important; }
  .w-six-m { width: math.div(100%, 6) !important; }
}
@media #{$breakpoint-large}{
  .wd1-l {width: 1.5rem !important;}
  .wd2-l {width: 2rem !important;}
  .wd3-l {width: 3rem !important;}
  .wd4-l {width: 4rem !important;}
  .wd5-l {width: 6rem !important;}
  .wd-10-l {width: 10% !important;}
  .wd-20-l {width: 20% !important;}
  .wd-25-l {width: 25% !important;}
  .wd-30-l {width: 30% !important;}
  .wd-33-l {width: 33% !important;}
  .wd-40-l {width: 40% !important;}
  .wd-50-l {width: 50% !important;}
  .wd-60-l {width: 60% !important;}
  .wd-70-l {width: 70% !important;}
  .wd-75-l {width: 75% !important;}
  .wd-80-l {width: 80% !important;}
  .wd-90-l {width: 90% !important;}
  .wd-100-l {width: 100% !important;}
  .w-auto-l {width: auto !important;}
  .w-third-l { width: math.div(100%, 3) !important; }
  .w-two-thirds-l { width: math.div(100%, 1.5) !important; }
  .w-auto-l {    width: auto !important; }
}

.w-third { width: math.div(100%, 3) !important; }
.w-two-thirds { width: math.div(100%, 1.5) !important; }
.w-auto { width: auto !important; }
.mb0{
  margin-bottom: 0  !important;
}
.pd0{
  padding:0;
}
//SPACING
.pa05 { padding: $spacing-extra-extra-small; }
.pt05 { padding-top: $spacing-extra-extra-small; }
.pb05 { padding-bottom: $spacing-extra-extra-small; }
.pl05 { padding-left: $spacing-extra-extra-small; }
.pr05 { padding-right: $spacing-extra-extra-small; }
.pv05 {
  padding-top: $spacing-extra-extra-small;
  padding-bottom: $spacing-extra-extra-small;
}
.ph05 {
  padding-left: $spacing-extra-extra-small;
  padding-right: $spacing-extra-extra-small;
}
.pa-05 { padding: $spacing-extra-extra-xs; }
.pt-05 { padding-top: $spacing-extra-extra-xs; }
.pb-05 { padding-bottom: $spacing-extra-extra-xs; }
.pl-05 { padding-left: $spacing-extra-extra-xs; }
.pr-05 { padding-right: $spacing-extra-extra-xs; }
.pv-05 {
  padding-top: $spacing-extra-extra-xs;
  padding-bottom: $spacing-extra-extra-xs;
}
.ph-05 {
  padding-left: $spacing-extra-extra-xs;
  padding-right: $spacing-extra-extra-xs;
}
@media #{$breakpoint-not-small}{
  .pa05-ns { padding: $spacing-extra-extra-small; }
  .pt05-ns { padding-top: $spacing-extra-extra-small; }
  .pb05-ns { padding-bottom: $spacing-extra-extra-small; }
  .pl05-ns { padding-left: $spacing-extra-extra-small; }
  .pr05-ns { padding-right: $spacing-extra-extra-small; }
  .pv05-ns {
    padding-top: $spacing-extra-extra-small;
    padding-bottom: $spacing-extra-extra-small;
  }
  .ph05-ns {
    padding-left: $spacing-extra-extra-small;
    padding-right: $spacing-extra-extra-small;
  }
}
@media #{$breakpoint-medium}{
  .pa05-m { padding: $spacing-extra-extra-small; }
  .pt05-m { padding-top: $spacing-extra-extra-small; }
  .pb05-m { padding-bottom: $spacing-extra-extra-small; }
  .pl05-m { padding-left: $spacing-extra-extra-small; }
  .pr05-m { padding-right: $spacing-extra-extra-small; }
  .pv05-m {
    padding-top: $spacing-extra-extra-small;
    padding-bottom: $spacing-extra-extra-small;
  }
  .ph05-m {
    padding-left: $spacing-extra-extra-small;
    padding-right: $spacing-extra-extra-small;
  }
}
@media #{$breakpoint-large}{
  .pa05-l { padding: $spacing-extra-extra-small; }
  .pt05-l { padding-top: $spacing-extra-extra-small; }
  .pb05-l { padding-bottom: $spacing-extra-extra-small; }
  .pl05-l { padding-left: $spacing-extra-extra-small; }
  .pr05-l { padding-right: $spacing-extra-extra-small; }
  .pv05-l {
    padding-top: $spacing-extra-extra-small;
    padding-bottom: $spacing-extra-extra-small;
  }
  .ph05-l {
    padding-left: $spacing-extra-extra-small;
    padding-right: $spacing-extra-extra-small;
  }
}

.ma-05 { margin: $spacing-extra-extra-xs; }
.mt-05 { margin-top: $spacing-extra-extra-xs; }
.mb-05 { margin-bottom: $spacing-extra-extra-xs; }
.ml-05 { margin-left: $spacing-extra-extra-xs; }
.mr-05 { margin-right: $spacing-extra-extra-xs; }
.mv-05 {
  margin-top: $spacing-extra-extra-xs;
  margin-bottom: $spacing-extra-extra-xs;
}
.mh-05 {
  margin-left: $spacing-extra-extra-xs;
  margin-right: $spacing-extra-extra-xs;
}
.mh-auto {
  margin: 0 auto;
}
.mr01{margin-right: 5px;}
.ma05 { margin: $spacing-extra-extra-small; }
.mt05 { margin-top: $spacing-extra-extra-small; }
.mb05 { margin-bottom: $spacing-extra-extra-small; }
.ml05 { margin-left: $spacing-extra-extra-small; }
.mr05 { margin-right: $spacing-extra-extra-small; }
.mv05 {
  margin-top: $spacing-extra-extra-small;
  margin-bottom: $spacing-extra-extra-small;
}
.mh05 {
  margin-left: $spacing-extra-extra-small;
  margin-right: $spacing-extra-extra-small;
}
@media #{$breakpoint-not-small}{
  .ma05-ns { margin: $spacing-extra-extra-small; }
  .mt05-ns { margin-top: $spacing-extra-extra-small; }
  .mb05-ns { margin-bottom: $spacing-extra-extra-small; }
  .ml05-ns { margin-left: $spacing-extra-extra-small; }
  .mr05-ns { margin-right: $spacing-extra-extra-small; }
  .mv05-ns {
    margin-top: $spacing-extra-extra-small;
    margin-bottom: $spacing-extra-extra-small;
  }
  .mh05-ns {
    margin-left: $spacing-extra-extra-small;
    margin-right: $spacing-extra-extra-small;
  }
}
@media #{$breakpoint-medium}{
  .ma05-m { margin: $spacing-extra-extra-small; }
  .mt05-m { margin-top: $spacing-extra-extra-small; }
  .mb05-m { margin-bottom: $spacing-extra-extra-small; }
  .ml05-m { margin-left: $spacing-extra-extra-small; }
  .mr05-m { margin-right: $spacing-extra-extra-small; }
  .mv05-m {
    margin-top: $spacing-extra-extra-small;
    margin-bottom: $spacing-extra-extra-small;
  }
  .mh05-m {
    margin-left: $spacing-extra-extra-small;
    margin-right: $spacing-extra-extra-small;
  }
}
@media #{$breakpoint-large}{
  .ma05-l { margin: $spacing-extra-extra-small; }
  .mt05-l { margin-top: $spacing-extra-extra-small; }
  .mt-05-l { margin-top: $spacing-extra-extra-xs; }
  .mb05-l { margin-bottom: $spacing-extra-extra-small; }
  .ml05-l { margin-left: $spacing-extra-extra-small; }
  .mr05-l { margin-right: $spacing-extra-extra-small; }
  .mv05-l {
    margin-top: $spacing-extra-extra-small;
    margin-bottom: $spacing-extra-extra-small;
  }
  .mh05-l {
    margin-left: $spacing-extra-extra-small;
    margin-right: $spacing-extra-extra-small;
  }
}

.pa15 { padding: $spacing-gutter; }
.pt15 { padding-top: $spacing-gutter; }
.pb15 { padding-bottom: $spacing-gutter; }
.pl15 { padding-left: $spacing-gutter; }
.pr15 { padding-right: $spacing-gutter; }
.pv15 {
  padding-top: $spacing-gutter;
  padding-bottom: $spacing-gutter;
}
.ph15 {
  padding-left: $spacing-gutter;
  padding-right: $spacing-gutter;
}
@media #{$breakpoint-not-small}{
  .pa15-ns { padding: $spacing-gutter; }
  .pt15-ns { padding-top: $spacing-gutter; }
  .pb15-ns { padding-bottom: $spacing-gutter; }
  .pl15-ns { padding-left: $spacing-gutter; }
  .pr15-ns { padding-right: $spacing-gutter; }
  .pv15-ns {
    padding-top: $spacing-gutter;
    padding-bottom: $spacing-gutter;
  }
  .ph15-ns {
    padding-left: $spacing-gutter;
    padding-right: $spacing-gutter;
  }
}
@media #{$breakpoint-medium}{
  .pa15-m { padding: $spacing-gutter; }
  .pt15-m { padding-top: $spacing-gutter; }
  .pb15-m { padding-bottom: $spacing-gutter; }
  .pl15-m { padding-left: $spacing-gutter; }
  .pr15-m { padding-right: $spacing-gutter; }
  .pv15-m {
    padding-top: $spacing-gutter;
    padding-bottom: $spacing-gutter;
  }
  .ph15-m {
    padding-left: $spacing-gutter;
    padding-right: $spacing-gutter;
  }
}
@media #{$breakpoint-large}{
  .pa15-l { padding: $spacing-gutter; }
  .pt15-l { padding-top: $spacing-gutter; }
  .pb15-l { padding-bottom: $spacing-gutter; }
  .pl15-l { padding-left: $spacing-gutter; }
  .pr15-l { padding-right: $spacing-gutter; }
  .pv15-l {
    padding-top: $spacing-gutter;
    padding-bottom: $spacing-gutter;
  }
  .ph15-l {
    padding-left: $spacing-gutter;
    padding-right: $spacing-gutter;
  }
}


.ma15 { margin: $spacing-gutter; }
.mt15 { margin-top: $spacing-gutter; }
.mb15 { margin-bottom: $spacing-gutter; }
.ml15 { margin-left: $spacing-gutter; }
.mr15 { margin-right: $spacing-gutter; }
.mv15 {
  margin-top: $spacing-gutter;
  margin-bottom: $spacing-gutter;
}
.mh15 {
  margin-left: $spacing-gutter;
  margin-right: $spacing-gutter;
}
@media #{$breakpoint-not-small}{
  .ma15-ns { margin: $spacing-gutter; }
  .mt15-ns { margin-top: $spacing-gutter; }
  .mb15-ns { margin-bottom: $spacing-gutter; }
  .ml15-ns { margin-left: $spacing-gutter; }
  .mr15-ns { margin-right: $spacing-gutter; }
  .mv15-ns {
    margin-top: $spacing-gutter;
    margin-bottom: $spacing-gutter;
  }
  .mh15-ns {
    margin-left: $spacing-gutter;
    margin-right: $spacing-gutter;
  }
}
@media #{$breakpoint-medium}{
  .ma15-m { margin: $spacing-gutter; }
  .mt15-m { margin-top: $spacing-gutter; }
  .mb15-m { margin-bottom: $spacing-gutter; }
  .ml15-m { margin-left: $spacing-gutter; }
  .mr15-m { margin-right: $spacing-gutter; }
  .mv15-m {
    margin-top: $spacing-gutter;
    margin-bottom: $spacing-gutter;
  }
  .mh15-m {
    margin-left: $spacing-gutter;
    margin-right: $spacing-gutter;
  }
}
@media #{$breakpoint-large}{
  .ma15-l { margin: $spacing-gutter; }
  .mt15-l { margin-top: $spacing-gutter; }
  .mb15-l { margin-bottom: $spacing-gutter; }
  .ml15-l { margin-left: $spacing-gutter; }
  .mr15-l { margin-right: $spacing-gutter; }
  .mv15-l {
    margin-top: $spacing-gutter;
    margin-bottom: $spacing-gutter;
  }
  .mh15-l {
    margin-left: $spacing-gutter;
    margin-right: $spacing-gutter;
  }
}
.relative{
  position: relative;
}
