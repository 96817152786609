// Page header with shadow
.c-lt-header {
  padding-top: rem($gl-spacer-l);
  padding-bottom: rem($gl-spacer-s);
  position: relative;

  @include desktop {
    padding-bottom: rem($gl-spacer-2xl);    
  }

  &--shadow {
    box-shadow: 0 10px 30px rgba(0,0,0,0.03);
  }

  &__title {
    font-size: rem($gl-font-size-h3);
    font-weight: 600;
    margin: 0;
  }
}