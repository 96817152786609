// Booking Show
$booking-sidebar-width: rem(336px);
$booking-main-width: calc(100% - 336px);

.p-booking {
  display: flex;
  flex-grow: 1;

  @include desktop {
    overflow: hidden;
  }

  // Hack: show footer inside booking main container;
  .c-footer {
    display: block !important;
    min-width: 0;
  }

  // Main column
  &__main {
    padding: rem($gl-spacer-l) 0;

    @include desktop {
      position: relative;
      overflow: auto;
      flex-grow: 1;
    }
  }

  // Sidebar
  &__sb {
    position: relative;
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    flex-grow: 1;
    box-sizing: content-box;
    transition: all 0.3s ease 0s;
    //transform: translate3d(0px, 0px, 0px);
    overflow: hidden;

    @include desktop {
      max-width: $booking-sidebar-width;
      flex-basis: $booking-sidebar-width;
      visibility: visible;
    }

    @include mobile {
      display: none;
      width: 100%;
      height: calc(100% - 108px);
      position: fixed;
      z-index: 10;
    }

    &--no-toolbar {
      @include mobile {
        height: calc(100% - 58px);
      }
    }

    &.is-active {
      display: flex;
    }
  }

  // Title
  &__title {
    font-size: $gl-font-size-h2;
    font-weight: 600;
    margin: 0;

    @include mobile {
      font-size: $gl-font-size-h3;
    }
  }

  // Title
  &__sub_title {
    font-size: $gl-font-size-h3;
    font-weight: 600;
    margin: 0;

    @include mobile {
      font-size: $gl-font-size-h4;
    }
  }

  // Col
  &__col {
    @include mobile {
      &:nth-child(2) {
        padding-bottom: rem($gl-spacer-m);
        margin-top: rem($gl-spacer-m);
      }

      &:last-child {
        padding-bottom: rem($gl-spacer-xxs);
      }
    }

    & + & {
      @include mobile {
        border-top: 1px solid $bd-gray;
        padding-top: rem($gl-spacer-m);
      }

      @include desktop {
        border-left: 1px solid $bd-gray;
      }
    }
  }

  // Block
  &__block {
    border-radius: $gl-radius-lg;

    &__section {
      padding: rem($gl-spacer-m) rem($gl-spacer-m) rem($gl-spacer-xxs);

      & + & {
        border-top: 1px solid $bd-gray;
      }
      &__text {
        white-space: pre-wrap;
      }
    }

    &--outlined {
      border: 1px solid $bd-gray;
    }

    &--shadow {
      box-shadow: $gl-box-shadow-l;
    }
  }

  // Info
  &__info {
    margin-bottom: rem($gl-spacer-xl);

    &__avatar {
      width: rem($gl-spacer-xl);
      height: rem($gl-spacer-xl);
      line-height: 44px;
    }

    &__title {
      font-size: rem($gl-font-size-xs);
      color: $t-gray-1;
    }

    &__time {
      display:inline-block;

      @include mobile {
        font-size: rem($gl-font-size-base);
      }
    }
  }

  // Tab Content
  &__tab-content {
    overflow: visible;
  }

  // Forms
  &__form {
    &__title {
      font-weight: 600;
      font-size: $gl-font-size-h5;
      margin: 0 0 6px;
    }

    &__sub {
      font-weight: 600;
      font-size: $gl-font-size-sub-header;
      margin: 0 0 6px;
    }

    &__grade {
      width: 75px;
    }

    &__indent {
      padding-left: 34px;
    }
  }

  // Table
  &__table {
    &__header {
      background-color: $b-gray-2;
      padding: $gl-spacer-s;
      font-size: rem($gl-font-size-base);
      font-weight: 600;
      border: 1px solid $bd-gray;
    }

    &__cell {
      border: 1px solid $bd-gray !important;
      border-top: none !important;
      width: 100% !important;
      height: 100% !important;
      border-radius: 0 !important;
      resize: none;
      background-color: $b-gray-2;
    }
  }

  // List
  &__list {
    margin: 0;

    &__item {
      font-size: rem($gl-font-size-base);

      &--ordered {
        counter-increment: item;

        & + & {
          margin-top: $gl-spacer-xs;
        }

        &:before {
          content: counter(item);
          font-weight: 500;
          margin-right: $gl-spacer-s;
        }
      }
    }
  }

  // Timeline
  &__timeline {
   font-size: rem($gl-font-size-base);

   &__item {
    position: relative;

     &:before {
       content: '';
       width: 1px;
       height: 100%;
       border-left: 1px solid $bd-gray;
       position: absolute;
       left: 21px;
     }
   }

   &__title {
    font-weight: 600;
    margin-bottom: $gl-spacer-xxs;
   }

   &__time {
    font-weight: 500;

    &:before {
      content: '';
      width: 18px;
      height: 18px;
      display: inline-block;
      background: $b-green;
      border: 4px solid tint($b-green, 90%);
      margin-right: $gl-spacer-xs;
      vertical-align: middle;
      border-radius: 50%;
    }
   }
  }
  &__input {
    background: rgba(0,0,0,0) !important;
    border: none !important;
    &:focus {
      border: solid 1px rgba(18, 204, 179);
      outline: 0;
      -webkit-box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%), 0 0 4px rgb(18 204 179 / 80%);
      -moz-box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 4px rgba(18, 204, 179,.8);
      box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%), 0 0 4px rgb(18 204 179 / 80%);
      &:invalid {
        border: solid 1px #e9322d;
        -webkit-box-shadow: 0 0 6px #f8b9b7;
        -moz-box-shadow: 0 0 6px #f8b9b7;
        box-shadow: 0 0 6px #f8b9b7;
      }
    }
  }
}

.c-lt-sticky {
  position: fixed;
  bottom: $gl-spacer-m;
  left: 0;
  right: 0;
  margin: auto;
  z-index: $gl-z-index-2;

  @include mobile {
    bottom: $gl-spacer-xs + 50px;

    .c-lt-upcoming {
      box-shadow: 0 8px 20px rgba(#000, 0.1);

      &__title {
        font-size: rem($gl-font-size-s);
      }

      &__sub {
        display: none;
      }
    }
  }
}

.p-gsession {
  $border: 1px solid $bd-gray;

  &__topic {
    text-transform: uppercase;
    color: $t-gray-1;
    font-size: $gl-font-size-s;
    margin-bottom: 0;
    font-weight: 500;
  }

  &__title {
    font-weight: bold;
    margin: 0;
    font-size: $gl-font-size-h4;

    @include desktop {
      font-size: 30px;
    }
  }

  &__table {
    $self: &;

    border-radius: 10px;
    border: $border;

    &__title {
      color: $t-gray-1;
      font-size: $gl-font-size-xs;
      margin-bottom: 2px;
    }


    &__row {
      padding: $gl-spacer-m;

      & + & {
        border-top: $border;
      }

      &--bordered {
        #{$self}__col + #{$self}__col {
          border-top: $border;
          margin-top: $gl-spacer-m;
          padding-top: $gl-spacer-m;


        }

        @include desktop {
          #{$self}__col + #{$self}__col {
            border-top: none;
            padding-top: 0;
            margin-top: 0;
            border-left: $border;
          }
        }
      }

    }
  }

  &__avatar {
    background: #018383;
    @include size(46px);
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
  }
}
