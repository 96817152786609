.telinput{
  position: relative;

  .react-tel-input{
    &:focus{
      border-color: #12ccb3;
    }
  }
  .react-tel-input .form-control{
    border: 1px solid #ddd;
    padding: 12px 16px 12px 50px;
    font-size: 16px;
    height:auto;
    line-height: 24px;
    width:100%;
    border-radius: 4px;
    &:focus{
      border-color: #12ccb3;
      box-shadow: none;
    }
  }

  .react-tel-input .selected-flag:focus:before, .react-tel-input .selected-flag.open:before{
    border-color: #12ccb3;;
    box-shadow: none;
  }
}