// stylelint-disable declaration-no-important
// stylelint-disable block-opening-brace-space-before

/* --------------- DISPLAY --------------- */

//
// Utilities for common `display` values
//
@if (config(utilities)) {
  @each $bp in map-keys($breakpoints) {
    @include media-breakpoint-up($bp) {
      $infix: breakpoint-infix($bp, $breakpoints);

      .u-d-none#{$infix}         { display: none !important; }
      .u-d-inline#{$infix}       { display: inline !important; }
      .u-d-inline-block#{$infix} { display: inline-block !important; }
      .u-d-block#{$infix}        { display: block !important; }
      .u-d-table#{$infix}        { display: table !important; }
      .u-d-table-row#{$infix}    { display: table-row !important; }
      .u-d-table-cell#{$infix}   { display: table-cell !important; }
      .u-d-flex#{$infix}         { display: flex !important; }
      .u-d-inline-flex#{$infix}  { display: inline-flex !important; }
    }
  }

  //
  // Utilities for toggling `display` in print
  //

  .u-d-print-block {
    display: none !important;

    @media print {
      display: block !important;
    }
  }

  .u-d-print-inline {
    display: none !important;

    @media print {
      display: inline !important;
    }
  }

  .u-d-print-inline-block {
    display: none !important;

    @media print {
      display: inline-block !important;
    }
  }

  .d-print-none {
    @media print {
      display: none !important;
    }
  }
}
