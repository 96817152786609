.p-democlass {
  &__radio {
    width: 100%;
    background: #F9FAFA;
    padding: 10px 12px;
    color: #222222;
    font-size: 14px;

    .ant-radio-wrapper {
      width: 100%;
    }
  }

  &__topic {
    padding: 10px 12px;
    background: #F9F9F9;
    border: 1px solid #DDDDDD;
    border-radius: 3px;
    cursor: pointer;
  }

  &__time {
    width: 78px;
    font-weight: normal;
    text-align: right;
    font-size: 0.8125rem;
    padding-right: 8px;
    vertical-align: top;
    font-family: 'Roboto';
    font-size: 12px;
    color: #757575;
  }

  &__event {
    height: 56px;
    cursor: pointer;
    padding: 8px;
    box-sizing: border-box;
    position: relative;
    -webkit-user-select: none;
    -moz-user-select: none;
    -o-user-select: none;
    -ms-user-select: none;

    @include hover-focus {
      background: #E1F8F5;
    }
  }

  .active {
    background: #E1F8F5;
  }

  .select {
    background: #45D6C4;
  }
  .disabled {
    background: #f6f6f6;
    cursor: not-allowed;
  }
}