$path: '/images/chasegenius';
.p-chasegenius {
  background: #f7f7f7;
  &_intro {
    background: url('#{$path}/banner-bg.jpg') no-repeat top center;
    background-size: cover;
    height: 372px;
    img {
      height: 100%;
    }
    @include mobile {
      height: 215px;
    }
  }
  .qa-box {
    top: -40px;
  }
  .qa-bg {
    background-color: white;
    border-radius: 8px;
    padding: 32px 24px;
  }

  .hr {
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    border-width: 1px 0 0;
    margin: 28px auto;
  }
  &-btn {
    cursor: pointer;
    padding: 13px 10px;
    // padding: 13px 156px;
    background: #2589ff;
    border-radius: 320px;
    color: #ffffff;
    width: 100%;
    height: 48px;
    display: inline-block;
    margin-bottom: 0;
    text-align: center;
    vertical-align: middle;
    &:hover,
    &:focus {
      color: #ffffff !important;
      background: rgba(37, 137, 255, 0.9) !important;
      border-color: rgba(37, 137, 255, 0.9) !important;
    }
  }
  &-score {
    &-box {
      box-shadow: none !important;
      background: transparent;
      // width:465px;
      @include mobile {
        padding: 0 18px;
      }
      .c-modal__body {
        background: transparent;
        padding: 0 0 34px 0;
      }
      .score-title {
        position: absolute;
        top: 0;
        left: 0;
        // background:url('/images/chasegenius/score.png')
        @include mobile {
          padding: 20px;
        }
      }
    }

    position: relative;
    top: 72px;
    margin: 0 16px;
    padding: 0 24px;
    background: #fff;
    width: 432px;
    @include mobile {
      margin: 0 13px;
      width: auto;
      padding: 0 12px;
    }

    // box-shadow: none !important;
    // background: url(/images/chasegenius/score.png) center top/465px no-repeat,
    //   url(/images/chasegenius/score-b.png) center bottom/465px no-repeat;
    // border-top-left-radius: 16px;
    // border-top-right-radius: 16px;
    // @include mobile {
    //   background: url(/images/chasegenius/score.png) center top/100% no-repeat,
    //     url(/images/chasegenius/score-b.png) center bottom/100% no-repeat;
    //   width: 100% !important;
    // }

    .score {
      display: flex;
      justify-content: center;
      &-text {
        padding: 0 20px;
        font-family: 'PingFang SC';
        font-style: normal;
        font-weight: 600;
        font-size: 50px;
        line-height: 70px;
        text-align: center;
        color: #f58d13;
      }
      &-green {
        color: #3cb288;
      }
    }
    .title {
      font-family: 'PingFang SC';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #637b97;
      text-align: left;
      @include mobile {
        font-size: 14px;
      }
    }
    &-skills {
      margin: 33px 0px;
      padding: 16px 15px;
      background: #f7f7f7;
      @include mobile {
        margin: 20px 0px;
        padding: 16px 8px;
      }
      &-label {
        padding: 6px 12px;

        height: 36px;

        background: rgba(236, 152, 52, 0.07);
        border-radius: 21px;
        font-weight: 400;
        font-size: 15px;
        line-height: 24px;

        color: #f58d13;
        display: inline-flex;
        margin-bottom: 11px;
        @include mobile {
          font-size: 14px;
        }
      }
      .ml {
        margin-left: 27px;
        @include mobile {
          margin-left: 6px;
        }
      }
    }

    .skils-g {
      background: #f1f9f6;
      color: #3cb388;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
    }
    .hrs {
      border-top: 0.666667px dashed #7a96b7;
      height: 1px;
      margin: 28px 0;
    }
    &-btn {
      width: 224px;
      height: 43px;
      padding: 10px 0 11px;
    }
  }

  &-modal {
    .ant-radio-checked .ant-radio-inner::after {
      background: #2589ff;
    }
    .ant-select-selection-placeholder,
    .ant-select-selection-item,
    .ant-form-item-explain.ant-form-item-explain-error {
      text-align: left;
    }
    .c-modal__body {
      background: transparent;
    }
    .btn {
      padding: 13px 10px;
      // padding: 13px 156px;
      background: #2589ff;
      border-radius: 320px;
      width: 344px;
      height: 48px;
      &:hover {
        background-color: rgba(37, 137, 255, 0.9);
      }
    }
  }
  &-tip {
    background: url('#{$path}/undone.png') no-repeat center;
    background-size: cover;
    height: 386px;
    width: 400px;
    border-radius: 14px;
    margin: 24px;
    .c-modal__body {
      background: transparent;
    }
    &-box {
      margin-top: 200px;
    }
    &-btn {
      width: 176px;
      height: 40px;
      background: linear-gradient(180deg, #70b1ff 0%, #2589ff 100%);
      border-radius: 360px;
      color: #ffffff;
      display: inline-block;
      margin-bottom: 0;
      text-align: center;
      vertical-align: middle;
      margin-top: 26px;
      &:hover,
      &:focus {
        color: #ffffff !important;
        background: rgba(37, 137, 255, 0.9) !important;
        border-color: rgba(37, 137, 255, 0.9) !important;
      }
    }
  }

  .ant-radio-checked .ant-radio-inner::after {
    background: #2589ff;
  }
}

.ant-message {
  z-index: 999999 !important;
}
