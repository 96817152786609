/* ==========================================================================
   #Tutor, Moderator, Mentor Dashboard
   ========================================================================== */

// Default Variables

// Dashboard
$tdb-header-padding-top: $gl-spacer-l;
$tdb-shadow: $gl-box-shadow-m;
$tdb-block-spacing: $gl-spacer-m;
$tdb-block-inner-padding-y: $gl-spacer-xl;
$tdb-block-inner-padding-x: $gl-spacer-l;
$tdb-min-width: 576px;
$tdb-select-padding: 6px;

// Tutor, Moderator, Mentor Dashboard

.c-lt-cal {
  .js-hour_slot .js-remove-selection {
    display: none;
  }
  .js-hour_slot.is-updated:hover .js-remove-selection {
    display: block;
  }
  .js-hour_slot.c-lt-cal__event--alt:hover .js-remove-selection {
    display: block;
  }
}
.tutor-notification-tabs {
  border-top: 1px solid $bd-gray !important;
  border-bottom: 1px solid $bd-gray;
}
