// Button group alt theme
//TODO: Add to btn-group.scss later

.c-btn-group {
  &__btn {
    &--alt {
      background-color: $theme-light-lt;
      flex: 1;
      cursor: pointer;

      @at-root {
        &.is-active,
        &.is-selected {
          background-color: tint($b-green-dark, 95%);
        }
      }

      @include hover {
        opacity: 0.74;
      }
    }

    &--md {
      padding: $gl-spacer-s $gl-spacer-m;
      white-space: initial;
    }

    &--lg {
      padding: 20px 24px;
      white-space: initial;

      @include desktop {
        padding: 20px 56px;
      }
    }

    &--inline-radius {
      &:first-of-type {
        @include border-left-radius($gl-radius-lg);
      }

      &:last-of-type {
        @include border-right-radius($gl-radius-lg);
      }
    }

    &--vert-radius {
      text-align: left;

      &:first-of-type {
        @include border-top-radius($gl-radius-lg);
      }

      &:last-of-type {
        @include border-bottom-radius($gl-radius-lg);
      }

      & + & {
        border-top: 1px solid $b-gray-2;
      }
    }
  }

  &__title {
    font-size: rem($gl-font-size-base);
    font-weight: 500;
  }

  &__desc {
    font-size: rem($gl-font-size-xs);
    color: $t-gray-1;
    font-weight: normal;
  }

  // Vertical
  &--vert {
    flex-direction: column;
  }
}
