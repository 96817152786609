// Tutors Application - Become A Tutor

// ===== Intro ====
$p-bat-intro-height: 540px;
$p-bat-intro-height-desk: 540px;
$p-bat-logo-padding: $gl-spacer-xs;

// ===== Header ====
$p-bat-header-font-size: $gl-font-size-h5;
$p-bat-header-font-size-desk: 32px;

// ===== How ====
$p-bat-how-step-height: 133px;
$p-bat-bar-margin: $gl-spacer-xl;

// ===== Stories ====
$p-bat-stories-height: 882px;
$p-bat-stories-height-desk: 665px;

// ===== Apply ====
$p-bat-app-min-height: 800px;


.tutor_applicants_apply,
.tutor_applicants_signup {
  background: $b-gray-2;
}

.p-bat {

  // Header

  &__header {
    font-size: rem($p-bat-header-font-size);
    font-weight: 600;
    // margin-bottom: rem($gl-spacer-m);
    // letter-spacing: 4px;
    text-transform: uppercase;

    @include desktop {
      font-size: rem($p-bat-header-font-size-desk);
      margin-bottom: rem($gl-spacer-xl);
    }
  }

  // Intro

  &__intro {
    text-align: center;
    height: rem($p-bat-intro-height);
    background: url("#{$image-path}/pages/tutor-applicants/banner-bg.jpg") no-repeat top left;
    background-size: cover;

    @include desktop {
      height: rem($p-bat-intro-height-desk);
      background: url("#{$image-path}/pages/tutor-applicants/banner-bg.jpg") no-repeat top;
      background-size: cover;
    }

    .o-section__hero__inner {
      display: table-cell;
      vertical-align: bottom;
    }
  }

  &__logo {
    vertical-align: middle;
    display: inline-block;

    &+& {
      border-left: 1px solid $b-green;
      padding-left: rem($p-bat-logo-padding);
    }
  }

  &__us{
    background: url("#{$image-path}/pages/tutor-applicants/us__bg.png") no-repeat center;
    background-size: 100% 100%;
  }

  // Who
  &__who {
    &__header {
      position: relative;
      display: inline-block;
    }

    &__title {
      position: relative;
      z-index: $gl-z-index-1;
    }

    &__logo {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      margin: auto;
      z-index: 0;

      @include mobile {
        height: $gl-asset-size-md;
      }
    }

    &__oc-logo {
      height: $gl-asset-size-lg;

      @include mobile {
        height: $gl-asset-size-md;
      }
    }
  }

  // How

  &__how {
    .faq {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &__step {
      @include desktop {
        height: rem($p-bat-how-step-height);
      }
    }

    &__tag {
      cursor: default;
    }

    &__tooltip {
      width: 300px;
      text-align: left;
    }
  }

  // How Bar

  &__bar {
    position: relative;
    margin: rem($p-bat-bar-margin) 0;

    &__dot {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      margin: auto;
    }

    &__line {
      width: 100%;
      border-top: 2px solid $bd-gray;
      margin: rem($gl-spacer-xs) 0;
    }
  }

  // Stories

  &__stories {
    padding-top: 120px !important;
    padding-bottom: 120px !important;

    &__container {
      display: table;
      height: rem($p-bat-stories-height);

      @include desktop {
        height: rem($p-bat-stories-height-desk);
      }
    }

    &__btn {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 64px;
      height: 64px;

      &--green {
        background-color: #009D88;
      }

    }

    &__inner {
      display: table-cell;
      vertical-align: middle;
    }

    &__header {
      @include mobile {
        text-align: center;
        margin-bottom: 24px;
      }
    }

    &__title {
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 600;
      font-size: 38px;
      line-height: 42px;
      text-align: left;
      color: #222222;
    }
  }

  // Apply

  &__apply {
    &__info {
      color: #6F7980;
    }

    &__inner {
      min-height: rem($p-bat-app-min-height);
    }

    &__subject-count {
      @include size-text-center($message-counter-size);

      background-color: $theme-light-lt;
      border-radius: 50%;
      font-size: $message-counter-font-size;
      color: $b-green;
      display: inline-block;
      vertical-align: top;
      margin-left: $gl-spacer-xxs;
    }

    // To initialize .col position relative so that modal can fit the whole screen on mobile
    &__form {
      position: initial;
    }
  }
}

// Intel Input Plugin
.intl-tel-input {
  width: 100%;
}

.tutor-applicant {
  .is-active {
    border-bottom-width: 3px;
    color: #009D88 !important;
    border-bottom-color: #009D88 !important;
  }
}