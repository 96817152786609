/* ==========================================================================
   #HOVER
   ========================================================================== */

// Based on Bootstrap 4 _hover.scss mixin

@mixin hover {
  &:hover { @content; }
}

@mixin hover-focus {
  &:focus,
  &:hover {
    @content;
  }
}

@mixin plain-hover-focus {
  &,
  &:focus,
  &:hover {
    @content;
  }
}

@mixin hover-focus-active {
  &:focus,
  &:active,
  &:hover {
    @content;
  }
}

// Basic styles for custom links (e.g nav links)
@mixin customLink($color, $hover) {
  color: $color;
  text-decoration: none;

  &:hover,
  &:focus,
  &.is-active {
    text-decoration: none;
    color: $hover;
  }

  @content;
}
