.ant-menu-inline, .ant-menu-vertical, .ant-menu-vertical-left{
  border-right:none  !important
}

.p-w{
  max-width: 760px;
  margin: 0 auto;
  line-height: 150%;
}

.crisp-client .cc-kv6t .cc-1xry {
  a.cc-unoo {
    bottom: 30px !important;
  }
}
