// stylelint-disable declaration-no-important
// stylelint-disable block-opening-brace-space-before

// Flex variation
//
// Custom styles for additional flex alignment options.

$prefix: 'u-';
$important: !important;

@each $breakpoint in map-keys($breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $breakpoints);

    .#{$prefix}flex-grow#{$infix}-full      { flex-grow: 1 $important; }
    .#{$prefix}flex#{$infix}-row            { flex-direction: row $important; }
    .#{$prefix}flex#{$infix}-column         { flex-direction: column $important; }
    .#{$prefix}flex#{$infix}-row-reverse    { flex-direction: row-reverse $important; }
    .#{$prefix}flex#{$infix}-column-reverse { flex-direction: column-reverse $important; }

    .#{$prefix}flex#{$infix}-wrap         { flex-wrap: wrap $important; }
    .#{$prefix}flex#{$infix}-nowrap       { flex-wrap: nowrap $important; }
    .#{$prefix}flex#{$infix}-wrap-reverse { flex-wrap: wrap-reverse $important; }

    .#{$prefix}justify-content#{$infix}-start   { justify-content: flex-start $important; }
    .#{$prefix}justify-content#{$infix}-end     { justify-content: flex-end $important; }
    .#{$prefix}justify-content#{$infix}-center  { justify-content: center $important; }
    .#{$prefix}justify-content#{$infix}-between { justify-content: space-between $important; }
    .#{$prefix}justify-content#{$infix}-around  { justify-content: space-around $important; }
    .#{$prefix}justify-content#{$infix}-evenly  { justify-content: space-evenly $important; }

    .#{$prefix}align-items#{$infix}-start    { align-items: flex-start $important; }
    .#{$prefix}align-items#{$infix}-end      { align-items: flex-end $important; }
    .#{$prefix}align-items#{$infix}-center   { align-items: center $important; }
    .#{$prefix}align-items#{$infix}-baseline { align-items: baseline $important; }
    .#{$prefix}align-items#{$infix}-stretch  { align-items: stretch $important; }

    .#{$prefix}align-content#{$infix}-start   { align-content: flex-start $important; }
    .#{$prefix}align-content#{$infix}-end     { align-content: flex-end $important; }
    .#{$prefix}align-content#{$infix}-center  { align-content: center $important; }
    .#{$prefix}align-content#{$infix}-between { align-content: space-between $important; }
    .#{$prefix}align-content#{$infix}-around  { align-content: space-around $important; }
    .#{$prefix}align-content#{$infix}-stretch { align-content: stretch $important; }

    .#{$prefix}align-self#{$infix}-auto     { align-self: auto $important; }
    .#{$prefix}align-self#{$infix}-start    { align-self: flex-start $important; }
    .#{$prefix}align-self#{$infix}-end      { align-self: flex-end $important; }
    .#{$prefix}align-self#{$infix}-center   { align-self: center $important; }
    .#{$prefix}align-self#{$infix}-baseline { align-self: baseline $important; }
    .#{$prefix}align-self#{$infix}-stretch  { align-self: stretch $important; }
  }
}

.u-align-item-end{

  align-items:flex-end ;
}