// Task
$task-spacing: $gl-spacer-xs;
$task-icon-size: 10px;
$task-border-radius: $gl-radius-md;

.p--tutoring-country {
  .tabs-mobile {
    flex-wrap: nowrap;
    white-space: nowrap;
    overflow: auto;
    &::-webkit-scrollbar {
      display: none;
      width: 0 !important
    }
    -ms-overflow-style: none;
    overflow: -moz-scrollbars-none;
  }
  .c-nav-tabs__link {
    &.is-active {
      border-bottom-width: 4px;
    }
  }
  .top-line {
    width: 100%;
    height: 0;
    border: 1px solid $bd-gray;
    margin-top: -4px;
  }
  .lh150 {
    line-height: 150%;
  }

  .ml-14per {
    margin-left: 14%;
  }

  .mr-14per {
    margin-right: 14%;
  }

  .pl5per {
    padding-left: 5%;
  }

  .pr5per {
    padding-right: 5%;
  }

  .pl10per {
    padding-left: 10.5%;
  }

  .mt-8 {
    margin-top: -8rem;
  }

  .mt-11 {
    margin-top: -11.5rem;
  }

  .mt-4 {
    margin-top: -4rem;
  }

  .mh-auto {
    margin: 0 auto;
  }

  .border-r12 {
    border-radius: 0.75rem;
  }

  &__ca {
    &__intro {
      background: url('#{$image-path}/pages/tutoring-countries/ca/ca_intro_bg.jpg')
        no-repeat center;
      background-size: cover;
      height: 560px;
      p {
        color: $t-light;
      }
      .f-size {
        font-size: 76px;
        margin-top: 0.25rem;
      }
    }
    &__info {
      .pre-img-w{
        height: 130px;
        position: relative;
        @include mobile {
          float: left;
        }
      }
      @include mobile {
        padding-top: 0.5rem !important;
        padding-bottom: 4rem !important;
        .semester-mobile {
          background: $b-orange-2;
          border-radius: 4px;
          padding: 1rem 1.25rem;
          &__lt {
            // height: 100px;
            margin-bottom: 25px;
            margin-right: 0.5rem;
          }
        }
        .essay-line {
          width: 46px;
          height: 2px;
          background: $b-green;
          border-radius: 1px;
        }
        .essay-text {
          min-height: 2.25rem;
        }
        .essay-img {
          margin-top: -0.75rem;
        }
        .essay-icon {
          margin-right: -12% !important;
          margin-top: -0.75rem;
          -webkit-transform: scale(0.5);
          -moz-transform: scale(0.5);
          -ms-transform: scale(0.5);
          -o-transform: scale(0.5);
          transform: scale(0.5);
        }
        .mobile-mt125 {
          margin-top: 1.25rem;
        }
        .mobile-pr15 {
          padding-right: 1.5rem;
        }
        .mobile-tab {
          border: $b-green 1px solid;
          border-radius: 4px;
          margin: 0 2rem 1rem;
          color: $t-green;
          .active {
            color: white;
            background: $b-green;
          }
        }
        .name {
          float: left;
          margin-left: 1rem;
        }
        .mobile-align-center {
          text-align: center
        }
      }
      .div-content {
        background: url('#{$image-path}/pages/tutoring-countries/ca/ca.png')
            no-repeat top center,
          url('#{$image-path}/pages/tutoring-countries/ca/mountain.png')
            no-repeat bottom right;
        border: 2px solid $b-green;
        box-shadow: -12px 42px 36px rgba(0, 63, 54, 0.06);
        border-radius: 20px;
        padding: 3rem 5rem;
        @include mobile {
          padding: 0;
          border: none;
          background: none;
          box-shadow: none;
        }
      }
      .mh-negative-5 {
        margin-left: -5rem;
        margin-right: -5rem;
        @include mobile {
          margin-left: 0;
          margin-right: 0;
        }
      }
      .ca {
        background: url('#{$image-path}/pages/tutoring-countries/ca/ca.png')
            no-repeat top center,
          url('#{$image-path}/pages/tutoring-countries/ca/mountain.png')
            no-repeat bottom right;
        &__qa {
          background: url('#{$image-path}/pages/tutoring-countries/ca/ca.png')
              no-repeat top center,
            url('#{$image-path}/pages/tutoring-countries/ca/qa-bg.png')
              no-repeat bottom right;
        }
        @include mobile {
          background: none;
        }
      }
      .us {
        background: url('#{$image-path}/pages/tutoring-countries/ca/us.png')
            no-repeat top center,
          url('#{$image-path}/pages/tutoring-countries/ca/mountain.png')
            no-repeat bottom right;
        &__qa {
          background: url('#{$image-path}/pages/tutoring-countries/ca/us.png')
              no-repeat top center,
            url('#{$image-path}/pages/tutoring-countries/ca/qa-bg.png')
              no-repeat bottom right;
          @include mobile {
            background: none;
          }
        }
        @include mobile {
          background: none;
        }
      }
      .u-h-96 {
        height: 6rem;
      }
      .info {
        background: $b-orange-2;
        border-radius: 0.75rem;
        margin: 2.5rem 0;
        h5 {
          font-size: 1.75rem;
          color: $t-green;
          margin-bottom: 4px;
        }

        p {
          line-height: 150%;
        }
      }
      .qa {
        h5 {
          font-weight: normal;
          margin-bottom: 4px;
        }
        .circle {
          width: 11.625rem;
          height: 11.625rem;
          display: table-cell;
          vertical-align: middle;
          @include mobile {
            width: 10rem;
            height: 10rem;
          }
        }
        .qa-list {
          background: $b-orange-2;
          border-radius: 0.75rem;
        }
        .qa-line {
          width: 0;
          height: 5rem;
          border: 1px solid rgba(0, 0, 0, 0.1);
          @include mobile {
            width: 4rem;
            height: 0;
            margin: 2rem 0;
          }
        }
        .percent {
          background: url('#{$image-path}/pages/tutoring-countries/ca/percent.png')
            no-repeat center;
          background-size: cover;
          padding-right: 1rem;
          padding-top: 8px;
        }
        .homework {
          background: url('#{$image-path}/pages/tutoring-countries/ca/homework.png')
            no-repeat center;
          background-size: cover;
        }
        .qa_img-h {
          height: 64px;
          position: relative;
          margin-bottom: 5px;
        }
      }
      .list-content {
        margin-left: -1rem;
        line-height: 150%;
      }
      .line {
        width: 3.75rem;
        height: 0.5rem;
        background: $b-green;
        border-radius: 0.75rem;
        margin: 1.5rem 0;
      }

      .black-line {
        width: 2.375rem;
        height: 4px;
        background: $b-dark;
        border-radius: 1.25rem;
        margin: 1.5rem auto;
        @include mobile {
          margin: 1rem auto;
          width: 1.5rem
        }
      }

      .lt-list {
        flex: 0 0 20%;
        .content-top {
          background: url('#{$image-path}/pages/tutoring-countries/ca/union-bottom.png');
          background-size: 100% 100%;
          align-items: center;
          padding: 1rem 0 1.5rem;
        }
        .content-bottom {
          background: url('#{$image-path}/pages/tutoring-countries/ca/union-top.png');
          background-size: 100% 100%;
          align-items: center;
          padding: 1.5rem 0 1rem;
        }
      }
      .essay-content {
        background-image: url('#{$image-path}/pages/tutoring-countries/ca/essay.png');
        background-repeat: no-repeat;
        background-position: left center;
        @include mobile {
          background-size: 60% 100%;
        }
      }
      .essay-list {
        min-width: 20rem;
        text-align: center;
        background: $b-gray-1;
        padding: 0.5rem 0.5rem;
        @include mobile {
          font-size: .75rem;
          -webkit-transform: scale(0.75) translate(18%, 0px);
          -moz-transform: scale(0.75) translate(18%, 0px);
          -ms-transform: scale(0.75) translate(18%, 0px);
          -o-transform: scale(0.75) translate(18%, 0px);
          transform: scale(0.75) translate(18%, 0px);
          min-width: 20px;
          padding: 0.5rem 1rem;
        }
      }
      .essay-right {
        margin-top: 4.2rem;
      }
      .prep-course {
        .prep-btn {
          padding: 1rem 2rem;
          border-radius: 0.375rem;
          background: $b-gray-2;
          color: $t-gray-1;
          cursor: pointer;
          &__select {
            background: $b-green;
            color: $t-light;
          }
          &:hover {
            background: $b-green-lt;
            border-color: $b-green-lt;
            color: $t-light;
          }
        }
        .prep-content {
          background: $b-gray-1;
          border-radius: 0.75rem;
        }
        .prep-info {
          background: $t-light;
          border-radius: 0.75rem;
          box-shadow: 20px 20px 36px rgba(0, 0, 0, 0.06);
          h5 {
            font-size: 1.75rem;
            color: $t-green;
            margin-bottom: 4px;
            @include mobile {
              font-size: 1.125rem;
            }
          }
          p {
            line-height: 150%;
          }
        }
        .left-pt {
          padding-top: 3px;
        }
        .prep-h {
          background: $b-orange-2;
          border-radius: 0.75rem;
        }
        .list-msg {
          width: calc(100% - 22px);
          line-height: 150%;
        }
      }
      .tutor-list {
        .tutor-line {
          width: 2rem;
          height: 4px;
          background: $b-green;
          border-radius: 3px;
        }
        .vertical-line {
          width: 0.75rem;
          height: 5.625rem;
          background: $b-green;
          border-radius: 0.75rem;
          @include mobile {
            display: none;
          }
        }
        .us-text {
          font-size: 1.625rem;
        }
        .us-label {
          font-size: 1.75rem;
        }
        .margin-b4 {
          margin-bottom: 4px !important;
        }
      }
      .p-right {
        margin-bottom: 1.25rem;
      }

      .wh-auto {
        margin: 0 auto;
        height: 106px;
        width: 106px;
        position: relative;
        @include mobile {
          height: 48px;
          width: 48px;
        }
      }
      .img-h {
        height: 42px;
      }
    }

    &__tutor {
      background-image: url('#{$image-path}/pages/tutoring-countries/ca/tutor-bg.png');
      background-size: 100% 100%;
      background-color: rgba(0, 198, 172, 0.12);
      @include mobile {
        background: $b-orange-2;
      }
      .img-h {
        width: 164px;
        height: 188px;
        position: relative;
        margin-top: -4rem;
        div {
          filter: drop-shadow(0px 28px 20px rgba(0, 0, 0, 0.1));
          img {
            border-radius: 4px;
          }
        }
        @include mobile {
          width: 98px;
          height: 98px
        }
      }
      .item {
        background: white;
        border-radius: 0.5rem;
        @include mobile {
          background: rgba(0, 0, 0, 0)
        }
      }
      .tutor-line {
        width: 2rem;
        height: 4px;
        background: $b-green;
        border-radius: 3px;
      }
    }
  }
  &__us {
    &__intro {
      background: url('#{$image-path}/pages/tutoring-countries/ca/us-intro-bg.jpg')
        no-repeat center;
      background-size: cover;
      height: 560px;
      h1 {
        border-radius: 2px;
        background: linear-gradient(to right, #fff, #fff) 0% 0%/8% 2px no-repeat,
          linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0)) 12% 0%/20%
            2px no-repeat,
          linear-gradient(to left, #fff, #fff) 100% 0%/58% 2px no-repeat,
          linear-gradient(to right, #fff, #fff) 100% 100%/100% 2px no-repeat,
          linear-gradient(to right, #fff, #fff) 100% 0/2px 100% no-repeat,
          linear-gradient(to right, #fff, #fff) 0% 100%/2px 100% no-repeat;
        @include mobile {
          background: linear-gradient(to right, #fff, #fff) 0% 0%/4% 2px no-repeat,
          linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0)) 18% 0%/20%
          2px no-repeat,
          linear-gradient(to left, #fff, #fff) 100% 0%/41% 2px no-repeat,
          linear-gradient(to right, #fff, #fff) 100% 100%/100% 2px no-repeat,
          linear-gradient(to right, #fff, #fff) 100% 0/2px 100% no-repeat,
          linear-gradient(to right, #fff, #fff) 0% 100%/ 2px 100% no-repeat;
        }
      }
      p {
        color: $t-light;
      }
      h4 {
        font-size: 2.625rem;
        color: $t-light;
        text-align: left;
        margin-top: -5rem;
      }
      .point {
        display: inline-block;
        border-radius: 50%;
        background: white;
        height: 10px;
        width: 10px;
        vertical-align: middle;
      }
    }
  }
}
