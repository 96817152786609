/* ==========================================================================
   #IMAGES
   ========================================================================== */

// Responsive images
img {
  max-width: 100%;
  height: auto;
  image-rendering: -moz-crisp-edges; /* Firefox */
  image-rendering: -o-crisp-edges; /* Opera */
  image-rendering: -webkit-optimize-contrast; /* Webkit (non-standard naming) */
  image-rendering: crisp-edges;
  -ms-interpolation-mode: nearest-neighbor;
}

.img-h {
  max-width: 100%;
}
