/* ==========================================================================
   #Slick Slider
   ========================================================================== */
$slider-dot-size: 2rem;
$slider-dot-color: $theme-dark-lt;

.swiper-box {
  .swiper-button-prev:after,
  .swiper-button-next:after {
    color: $t-gray-2;
    font-size: 20px;
  }
  .swiper-button-prev {
    // left: -30px;
    &:after {
      // content: '←';
      content: url('#{$image-path}/pages/news/left.svg');
      @include mobile {
        content: ' ';
      }
    }
  }
  .swiper-button-next {
    // right: -30px;
    &:after {
      // content: '→';
      content: url('#{$image-path}/pages/news/right.svg');
      @include mobile {
        content: ' ';
      }
    }
  }
  .pagination {
    text-align: center;
    > span {
      margin: 10px;
    }
  }
}

.c-slider {
  .slick-dots {
    button:before {
      font-size: rem($slider-dot-size);
    }

    .slick-active button:before {
      color: $t-green;
    }
  }

  .slick-active {
    &.slick-current {
      > div {
        opacity: 1;
        // margin-left: $gl-spacer-m;
        // margin-right: $gl-spacer-m;
        transform: scale(1);
        //box-shadow: 0 4px 30px 0 rgba(0, 0, 0, 0.20);
        -webkit-transform: scale(1);
        -moz-transform: scale(1);
        -ms-transform: scale(1);
        -o-transform: scale(1);
      }
    }
    > div {
      opacity: 0.8;
      // margin-left: $gl-spacer-m;
      // margin-right: $gl-spacer-m;
    }
  }
  .slick-next:before,
  .slick-prev:before {
    color: $slider-dot-color;
  }
  .swiper-button-next:before,
  .swiper-button-prev:before {
    color: $slider-dot-color;
  }
  .swiper-button-prev {
    &:after {
      content: url('#{$image-path}/pages/iss/chevron-left.svg');
      @include mobile {
        content: ' ';
      }
    }
  }
  .swiper-button-next {
    &:after {
      content: url('#{$image-path}/pages/iss/chevron-right.svg');
      @include mobile {
        content: ' ';
      }
    }
  }
  &-zoom {
    @include mobile {
      zoom: 0.5;
    }
  }
  &--lt {
    .slick-prev {
      &:before {
        content: url('#{$image-path}/pages/iss/chevron-left.svg');
        @include mobile {
          content: ' ';
        }
      }
    }

    .slick-next {
      &:before {
        content: url('#{$image-path}/pages/iss/chevron-right.svg');
        @include mobile {
          content: ' ';
        }
      }
    }
  }

  &__item {
    &--center {
      opacity: 0.8;
      margin-left: $gl-spacer-m;
      margin-right: $gl-spacer-m;

      &.slick-current {
        transform: scale(1.3);
        opacity: 1;
        // box-shadow: 0 4px 30px 0 rgba(0, 0, 0, 0.20);
      }
    }
  }

  //@include mobile {
  //  zoom: 0.5;
  //}
}

.swiper-pagination-bullet {
  width: 10px !important;
  height: 10px !important;
  bottom: 0 !important;
}
.swiper-pagination-bullet-active {
  background: $b-green !important;
}
.swiper-pagination-fraction,
.swiper-pagination-custom,
.swiper-container-horizontal > .swiper-pagination-bullets {
  bottom: 0 !important;
}
