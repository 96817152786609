// ==========================================================================
//  #Size
// ==========================================================================

// Sizing shortcuts

@mixin size($width, $height: $width) {
  width: $width;
  height: $height;
}

@mixin size-text-center($width, $height: $width) {
  @include size($width, $height);

  text-align: center;
  line-height: $height;
}

// Icon sizes
@each $size, $value in $asset-size {
  // Vertical Size (default)
  .u-width-#{$size} {
    width: rem($value);
  }

  // Horizontal size
  .u-height-#{$size} {
    height: rem($value);
  }
}
