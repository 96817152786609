@use "sass:list"

body
    min-width: 320px

.white-block, .white_block_small
    background: white
    padding: 3em
    border-radius: 2px
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1)

.content
    width: 100%
    position: relative
    min-height: calc(100% - 65px)

    //min-width: $break_xs

.bb-thick
    border-bottom: 16px solid #f7f7f7

.bt-thick
    border-top: 16px solid #f7f7f7

div[location='please_upgrade_wechats']
    .content
        min-height: initial !important

.inner-screen
    margin:
        bottom: 2em

.logged_in
    .content
        margin-bottom: 3em

.fluid-wrap
    max-width: 1200px
    min-width: $break_xs
    margin: auto
    padding:
        left: 1em
        right: 1em
    position: relative

    @media screen and (min-width: 415px)
        padding:
            left: 2em
            right: 2em

    @media screen and (min-width: $break-mid)
        padding:
            left: 3em
            right: 3em

.widescreen
    padding: 0 1em

    @media screen and (min-width: 1200px)
        max-width: 1200px
        margin: auto

.wrap-small
    max-width: 960px
    margin: auto

.white_container
    background: white
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.08)
    border-radius: 2px

    .container_footer
        background: #f9fafc
        border-top: 1px solid #e8e8e8
        padding: 20px 50px
        width: 100%

        @media screen and (max-width: $break-device)
            padding: 1em

.white-block
    background: white
    padding: 40px
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1)

.bottom_hero
    background: $b-gray-1

    .btn-gray
        border-color: $text_color

    .btn_group
        width: 545px

        @media screen and (max-width: 640px)
            width: auto
            text-align: center

    .btn
        width: 250px

        @media screen and (max-width: 640px)
            margin: 0 0 10px !important

.tel
    @media screen and (max-width: 640px)
        position: fixed
        z-index: 200
        bottom: 20px
        left: 20px
        display: block
        background: $b-green
        width: 50px
        height: 50px
        border-radius: 50%
        padding: 0.9em 0
        box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.08)

        img
            width: 24px
            height: 24px

.flexbox
    +flexbox
    +justify-content(center)

    .flexbox__row
        +flexbox
        +flex-direction(row)
        +flex-wrap(wrap)

    .flexbox__column
        +flexbox
        +flex-direction(column)

        width: 100%

    .flexbox__item--50wide
        width: 50%

    .flexbox__item--100wide
        width: 100%

//Pagination
.panel_footer.light-theme
    padding: 0 0 80px

    span
        background: transparent
        box-shadow: none
        border: none
        padding: 6px 14px

    .current
        color: #333

    .page-link
        color: #999
        border-color: #eaeaea
        background: transparent
        box-shadow: none
        padding: 6px 14px
        transition: 0.3s

        &:hover
            border-color: #ddd
            color: #666

//.logged_in
//  .content
//    background: $page_background
div[location='login_pages'].logged_in, div[location='reset_pages'].logged_in, div[location='reset_edit_pages'].logged_in, div[location='update_passwords'].logged_in, div[location='new_passwords'].logged_in, div[location='edit_passwords'].logged_in, div[location='create_passwords'].logged_in
    .content
        background: #edeef0 !important

.logged_in.pages_not_available, .logged_in.pages_services, .logged_in.pages_contact, .logged_in.pages_about, .logged_in.pages_tutors, .logged_in.pages_how
    background: white

    .content
        background: white !important

    .bottom_hero
        display: none

.thin_shadow
    box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.04)

.light_container
    background: #fbfcfc
    padding: 30px

.inner-hero
    background: #69bebe
    padding: 3.5em 0
    margin:
        bottom: 40px
        top: 60px

    @media screen and (max-width: $break-device)
        padding: 2em 0

div[location='show_textbooks']
    .inner-hero
        margin-top: 0px !important

.caret
    margin-top: 7px
    border-top: 4px solid #bbb

.news-banner
    width: 100%
    background: #EAEAF3
    padding: 10px 20px
    position: relative
    border-radius: 3px
    border: 1px solid #DCDCEF

    a
        color: #695F94

        &:hover
            color: #695F94
            text-decoration: underline !important

    .close
        position: absolute
        top: 10px
        right: 20px
        font-size: 17px

.browser
    box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.1)
    width: 90%
    overflow: hidden

    @media screen and (max-width: 800px)
        margin: auto

    .browser-bar
        border-radius: 3px 3px 0 0
        height: 20px
        background-color: #e8e8ea
        font-size: 31px
        line-height: 20px
        padding-left: 1.5%
        color: #8b8b8b

    .browser-body
        background-color: white
        width: 100%
        height: auto
        border-left: 2px solid #eceef1
        border-right: 2px solid #eceef1

        img
            width: 100%

//Easyke Mentors
div[location='home_easyke_mentors']
    .mentor-landing
        height: 100%
        display: table-cell
        vertical-align: middle

    .white_block_small
        width: 310px
        border-radius: 3px

//Mentor Signup, Login
//div[location='signup_easyke_mentors'], div[location='oneclass_mentor_signup_pages'], div[location='signup_easyke_mentors'], div[location='easyke_mentor_signup_pages'], div[location='home_easyke_mentors']
//  .alert
//    .close
//      top: -6px
//      right: 0
//  .wrapper_wrap
//    padding: 0 !important
//  .content
//    height: 100vh
//    min-height: 500px
//    background: image-url('pages/mentor-signup.jpg') no-repeat top center !important
//    background-size: cover !important
//    padding: 4em 5%
//    display: table
//    min-width: 0
//    width: 100%
//    @media screen and (max-width: $break-small)
//      padding: 1em
//    .chosen-choices
//      border: 1px solid #dddddd
//      background: #F8F8F9
//      padding: 8px
//      border-radius: 2px !important
//  .navbar, #navbar-height-offset
//    display: none
//  .oneclass_mentor_signin_pages
//    display: table-cell
//    vertical-align: middle
//  .inner-ctn
//    width: 360px
//    @media screen and (max-width: 420px)
//      width: 98%
//  .field.subjects_list
//    .subject-field
//      input.subject
//        width: 20px
//  .form_wrap
//    .field
//      position: relative
//      .formError
//        left: 60px !important
//  .fields
//    select, .chzn-container-single, input.text-input, textarea, .chosen-container-multi
//      width: 100% !important
//      margin-bottom: 10px
//      font-size: 16px
//    label
//      color: $text_color
//    .subject-field
//      padding: 5px 0
//  .btn
//    font-size: 15px
//  .session-ctn
//    .pass_field
//      position: relative
//      .forget_ctn
//        position: absolute
//        right: 0.75em
//        top: 2px
//  .signup-ctn
//    .step1_div
//      width: 400px
//      @media screen and (max-width: $break-small)
//        width: 98%
//      input#easyke_mentor_avatar
//        line-height: 20px
//      a.pull-right.mid_heavy_text.small_text
//        line-height: 28px
//        @media screen and (max-width: $break-small)
//          font-size: 13px
//          line-height: 23px
//  .alert.alert-error
//    margin: 0px auto
//    width: 100%
//  .chzn-container.chzn-container-multi
//    width: 100% !important
//    input
//      width: 100% !important
//      height: 52px
//      border-color: #d6d6dd
//      background: white
//      border-radius: 3px
//      box-shadow: none
//      font-family: "Open Sans", helvetica, arial, sans-serif
//      color: #3e4252
//    .chzn-drop
//      width: 100% !important
//    ul.chzn-choices
//      li.search-choice
//        a.search-choice-close
//          background: image-url('icons/close.png') no-repeat !important

//Document Views
//.list-view
//  .document.item
//    display: block !important
//    width: 100% !important
//    height: auto
//    padding: 1.5rem 0
//    margin: 0
//    @media screen and (max-width: 767px)
//      padding: 1em 0
//      &:first-child
//        padding-top: 0
//    .media
//      overflow: visible
//      img
//        border-radius: 4px
//        border: 1px solid #e8e8e8
//      h4
//        font-weight: 500
//        text-transform: capitalize
//        transition: 0.3s
//        @media screen and (max-width: 767px)
//          font-size: 15px
//          margin: 0.5em 0 0 !important
//      p
//        @media screen and (max-width: 767px)
//          font-size: 14px !important
//      &:hover
//        h4
//          text-decoration: underline

//List Items
.chapter.item
    border-bottom: 1px solid #ebeaec

    +background-image-retina(list.slash(/assets, icons, thin-right-arrow), png, 12px, 21px)

    background-position: right 40px center
    background-repeat: no-repeat

    &:first-child
        background-position: right 40px top 35%

        a
            padding-top: 0

    a
        display: block
        padding: 40px 40px 40px 0px

        &:hover
            h3
                text-decoration: underline

    .span8
        margin-left: 40px

        h3
            color: #796086
            font-size: 21px

.subchapter.item
    padding-right: 40px

    .chapter-header
        color: #796086
        width: 100%
        background: #f3f2f5
        padding: 15px

.sub_video
    border-bottom: 1px solid #ebeaec

    a
        display: block
        padding: 40px
        font-weight: 500

        &:hover
            h5
                text-decoration: underline

        .vid_thumbnail
            width: 134px
            height: 80px
            position: relative
            border: 1px solid #ebeaec
            overflow: hidden

            img
                height: 100%
                width: 100%

//.document.item
//  margin: 0 13px 40px
//  @media screen and (max-width: 1111px)
//    margin: 0 30px 40px
//  @media screen and (min-width: 1190px)
//    margin: 0 20px 40px
//  @media screen and (max-width: $break-medium)
//    margin: 0
//.note_item
//  @media screen and (min-width: $break-medium)
//    margin-left: 3%
//.note_item, .document.item
//  width: 100%
//  //:border-bottom 1pt solid $bd-gray
//  overflow: hidden
//  //:padding 1.5em 1em
//  padding: 1.5em 0
//  @media screen and (min-width: $break-medium)
//    display: inline-block
//    width: 172px
//    height: 240px
//    border: none
//    padding: 0
//  @media screen and (max-width: $break-medium)
//    background: white
//  a
//    position: relative
//    width: 100%
//    height: 100%
//    display: block
//    .media-body
//      width: 69%
//      margin-top: -0.8em
//  .preview_image
//    width: 20%
//    height: auto
//    padding: 0.2em
//    background: white
//    max-width: 60px
//    position: relative
//    border: 0.5pt solid #ddd
//    float: left
//    @media screen and (min-width: 767px)
//      height: 217px
//      width: 100%
//      max-width: 172px
//      display: block
//      z-index: 2
//      border: none
//  .name,.premium_name
//    display: block
//    overflow: hidden
//    line-height: 1.3em
//    font-size: 13px
//    word-wrap: break-word
//    color: $text_color
//    font-weight: 500
//    text-transform: capitalize
//    @media screen and (min-width: $break-medium)
//      height: 32px
//  .stats_container
//    width: 73%
//    padding-left: 1em
//    overflow: hidden
//    float: left
//    @media screen and (min-width: $break-medium)
//      position: absolute
//      bottom: 0
//      overflow: hidden
//      width: 100%
//      border-top: 1px solid #eee
//      z-index: 2
//      padding: 15px
//      max-height: 66px
//      background: white
//    @media screen and (max-width: $break-medium)
//      padding-right: 1.5rem
//.course_list
//  .preview_image
//    @media screen and (min-width: $break-medium)
//      height: 217px
//      width: 100%
//      max-width: 172px
//      display: block
//      z-index: 2
//      border: none

//Block wrap
.block_wrap
    background-color: white
    box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.04)
    border-radius: 0 0 2px 2px

.block_wrap_header
    background-color: #eaebec
    padding: 14px 30px
    border-radius: 2px 2px 0 0
    font-size: 16px
    color: #666

//Demos
.demo-bar
    background: #525B65
    position: fixed
    bottom: 0
    left: 0
    right: 0
    width: 100%
    padding: 24px 0
    z-index: 1000
    color: white

    &:hover
        color: white
        text-decoration: none

    span
        vertical-align: middle

    .btn-clear
        border-radius: 30px
        padding: 5px 35px
        background: transparent
        border: 1px solid white

        &:hover
            background: white
            color: #8a7cc7

    @media screen and (max-width: $break-medium)
        padding: 1em
        height: auto
        bottom: 50px

        .btn-clear
            zoom: 0.6

.pages_qa_demo, .pages_email_demo, .pages_essay_demo, .pages_au_essay_demo, .pages_qa_econ_demo, .pages_qa_math_demo, .pages_qa_stats_demo, .pages_qa_email_demo
    background: white

    .content
        background: white

        .qa_tab
            .active
                border-bottom: 2px solid $b-green

        .disclaimer.bb-thick
            @media screen and (min-width: $break-small)
                border: none

        .mw8
            max-width: 895px

        .demo-top
            background: linear-gradient(-45deg, #9BD1E1 0%, #CCE7DD 100%)

.qa_demo, .email_demo, .essay_demo, .au_essay_demo, .qa_econ_demo, .qa_math_demo, .qa_stats_demo, .qa_email_demo, .qa_tab_content
    .question
        display: block
        border-bottom: 1px solid $bd-gray
        padding: 2rem 0

        p
            color: $text_color

            @media screen and (min-width: $break-device)
                font-size: 1.2em

    a
        color: $text_color

        &:hover
            color: $text_color

            //text-decoration: underline

.logged_in.easyke_us\3D false
    .life_demo
        background: image-url("#{$image-path}/demo/au-qa-life.png") no-repeat !important

div[location='textbooks_demo_pages'], div[location='book_demo_pages'], div[location='mentor_view_mentors']
    .white-block
        margin-top: 30px
        border: 1px solid $bd-gray

    .book_demo
        width: 960px
        height: 625px
        background: image-url("#{$image-path}/demo/demo-books.png") no-repeat

        @media screen and (max-width: 960px)
            width: 100%

        .book
            width: 960px
            height: 214px
            top: 0px
            transition: 0.3s

            &:hover
                border: 1px solid $bd-gray

            @media screen and (max-width: 960px)
                width: 100%

//Paid Tour
.row-fluid
    width: 100%
    *zoom: 1

    &:before
        display: table
        content: ""
        line-height: 0

    &:after
        display: table
        content: ""
        line-height: 0
        clear: both

    [class*="span"]
        display: block
        width: 100%
        min-height: 30px
        -webkit-box-sizing: border-box
        -moz-box-sizing: border-box
        box-sizing: border-box
        float: left
        margin-left: 2.12766%
        *margin-left: 2.07447%

        &:first-child
            margin-left: 0

    .controls-row [class*="span"] + [class*="span"]
        margin-left: 2.12766%

    .span12
        width: 100%
        *width: 99.94681%

    .span11
        width: 91.48936%
        *width: 91.43617%

    .span10
        width: 82.97872%
        *width: 82.92553%

    .span9
        width: 74.46809%
        *width: 74.41489%

    .span8
        width: 65.95745%
        *width: 65.90426%

    .span7
        width: 57.44681%
        *width: 57.39362%

    .span6
        width: 48.93617%
        *width: 48.88298%

    .span5
        width: 40.42553%
        *width: 40.37234%

    .span4
        width: 31.91489%
        *width: 31.8617%

    .span3
        width: 23.40426%
        *width: 23.35106%

    .span2
        width: 14.89362%
        *width: 14.84043%

    .span1
        width: 6.38298%
        *width: 6.32979%

    .offset12
        margin-left: 104.25532%
        *margin-left: 104.14894%

        &:first-child
            margin-left: 102.12766%
            *margin-left: 102.02128%

    .offset11
        margin-left: 95.74468%
        *margin-left: 95.6383%

        &:first-child
            margin-left: 93.61702%
            *margin-left: 93.51064%

    .offset10
        margin-left: 87.23404%
        *margin-left: 87.12766%

        &:first-child
            margin-left: 85.10638%
            *margin-left: 85%

    .offset9
        margin-left: 78.7234%
        *margin-left: 78.61702%

        &:first-child
            margin-left: 76.59574%
            *margin-left: 76.48936%

    .offset8
        margin-left: 70.21277%
        *margin-left: 70.10638%

        &:first-child
            margin-left: 68.08511%
            *margin-left: 67.97872%

    .offset7
        margin-left: 61.70213%
        *margin-left: 61.59574%

        &:first-child
            margin-left: 59.57447%
            *margin-left: 59.46809%

    .offset6
        margin-left: 53.19149%
        *margin-left: 53.08511%

        &:first-child
            margin-left: 51.06383%
            *margin-left: 50.95745%

    .offset5
        margin-left: 44.68085%
        *margin-left: 44.57447%

        &:first-child
            margin-left: 42.55319%
            *margin-left: 42.44681%

    .offset4
        margin-left: 36.17021%
        *margin-left: 36.06383%

        &:first-child
            margin-left: 34.04255%
            *margin-left: 33.93617%

    .offset3
        margin-left: 27.65957%
        *margin-left: 27.55319%

        &:first-child
            margin-left: 25.53191%
            *margin-left: 25.42553%

    .offset2
        margin-left: 19.14894%
        *margin-left: 19.04255%

        &:first-child
            margin-left: 17.02128%
            *margin-left: 16.91489%

    .offset1
        margin-left: 10.6383%
        *margin-left: 10.53191%

        &:first-child
            margin-left: 8.51064%
            *margin-left: 8.40426%

[class*="span"].hide, .row-fluid [class*="span"].hide
    display: none

[class*="span"].pull-right, .row-fluid [class*="span"].pull-right
    float: right

.paid-tour1, .paid-tour2, .paid-tour4
    h1
        font-size: 45px

    p.lead
        font-size: 20px
        margin: 15px auto 25px

        @media screen and (max-width: $break-device)
            font-size: 16px
            line-height: 1.5em

            br
                display: none

    .btn
        text-render: optimizeLegibility
        -webkit-font-smoothing: antialiased
        font-size: 18px
        padding: 10px 70px

    .welcome
        padding: 14vh 1em
        background: #d0e6e3

        p.lead
            width: 70%

            @media screen and (max-width: $break-small)
                width: 80%

    .gallup
        padding: 60px 5%

        .span3
            text-align: center
            margin-bottom: 2em

            @media screen and (min-width: $break-device)
                text-align: right

        .span8
            @media screen and (max-width: $break-device)
                h5
                    text-align: center

    .gallup_window
        width: 59%
        height: 513px

        @media screen and (max-width: $break-small)
            width: 100%

        #wrapAll
            width: 100% !important

    .gallup_window_error
        padding: 40px 60px
        background: white
        text-align: left
        max-width: 650px

        @media screen and (max-width: $break-device)
            padding: 2em

            p
                font-size: 15px !important

    .upload
        padding: 10vh 0 14vh

        img
            margin-top: 60px

div[location='upload_gallup_users']
    .content
        background: #d0e6e3 !important
        margin: 0

    @media screen and (min-width: $break-device)
        .widescreen
            padding: initial

div[location='change_helper_pages']
    .content
        background: #d0e6e3 !important
        padding: 60px 0 100px
        margin: 0

    .paid-tour3
        h1
            font-size: 36px

        .white_container
            box-shadow: 0 2px 2px rgba(0, 0, 0, 0.2)
            border-radius: 2px

        span.medium_text
            color: $t-green-dark

            small
                font-size: 14px

        .span4
            width: 340px

        .upload.btn
            vertical-align: top

        .star
            cursor: pointer

        .filter_course
            vertical-align: top

div[location='wait_process_users']
    .content
        background: #48c2b8 !important
        padding: 5em 0 8em
        margin: 0

        @media screen and (max-width: $break-device)
            padding: 2em 0 5em

        h1, p.lead
            color: white

    .paid-tour4
        @media screen and (max-width: $break-device)
            .cloud
                text-align: center
                height: 100px

                img
                    height: 150px
                    width: auto

            .span8
                max-width: 300px
                margin: auto

                h1
                    text-align: center

        .status-icon
            display: inline-block
            width: 10%

    .step1, .step2, .step3, .step4, .step5, .step6
        display: inline-block
        width: 88%

    p.lead
        font-size: 24px
        margin: 8px 0

        @media screen and (max-width: $break-device)
            font-size: 18px
            margin: 3px 0

    .row-fluid.analyst_info
        p, h5
            color: white

        hr
            margin: 60px 0

        .span8
            @media screen and (min-width: $break-device)
                padding-right: 60px

            @media screen and (max-width: $break-device)
                margin-top: 1em

        .analyist_header
            .img-circle
                width: 100px
                height: 100px

    .darken
        color: #239B91 !important
        padding-left: 12.5%

    .about
        h3, p
            color: white

        @media screen and (max-width: $break-device)
            p
                font-size: 14px !important
                max-width: 300px
                margin: 0 auto 2em

            br
                display: none

            .btn
                display: block
                width: 100%
                font-size: 15px
                margin: 0.5em 0 0

    .btn
        background-color: #20a79c

        &:hover
            background-color: #0F9B90
            text-decoration: none !important

    .bar
        margin: 3em 0

    .bar.wait_5_bar
        @media screen and (min-width: $break-device)
            margin: 0

    .btn.start_result
        font-size: 24px
        padding: 12px 40px

    .btn-skip
        padding: 8px 20px !important

div[location='result_gallup_test']
    .inner-hero
        margin-bottom: 40px !important

        h1
            color: white
            margin: 20px 0 !important
            font-size: 48px

        p, strong
            color: rgba(255, 255, 255, 0.9)

        p
            width: 100%

        .btn
            background-color: white
            color: #2392cc

    hr
        margin: 15px 0 0

    .span6
        height: 373px
        position: relative
        overflow: hidden

        img
            position: absolute
            top: 180px
            right: 40px

            @media screen and (max-width: $break-device)
                bottom: -50px
                top: initial
                right: 40px
                width: 35%

    .result_section
        li
            font-size: 18px
            border-bottom: 1px solid #f4f4f4
            padding: 15px 0

        ol
            li
                list-style: decimal

        .check_result
            li
                transition: 0.2s

                &:hover
                    background: #fafafa

    .btn
        border-radius: 30px

//Before Payment Process
div[location='pay_process_users']
    background: white

    .content
        margin-bottom: 0

    .pay-process2
        padding: 6em 1em

        @media screen and (max-width: $break-device)
            padding: 4em 3em

        .btn-skip
            margin-top: 5px

        h1, .percent
            color: $t-green

        h1
            font-size: 48px !important

            @media screen and (max-width: $break-device)
                font-size: 2em !important

        p.medium_text
            @media screen and (max-width: $break-device)
                font-size: 15px !important
                margin-top: 0 !important

        p.contact
            @media screen and (max-width: $break-device)
                font-size: 13px
                line-height: 1.4em
                max-width: 300px
                margin: auto

    .loading-ctn
        width: 100%

        //@media screen and (min-width: $break-device)
        //  width: 90%
        //@media screen and (max-width: $break-device)
        //  max-width: 500px
        //  margin: auto
        .bar
            border-radius: 30px
            box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1)
            background: #e1e1e1
            border: 1px solid #fafafa
            overflow: visible

            @media screen and (max-width: $break-small)
                border-radius: 5px

            .ui-progressbar-value
                background: $b-green
                box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.1)
                border-radius: 30px
                border: none

                @media screen and (max-width: $break-small)
                    border-radius: 5px

    h4.double_vertical_margin
        font-size: 21px
        line-height: 1.4em

    .analyst_info
        padding: 6em 0
        background: white
        border-top: 1px solid $bd-gray

        @media screen and (max-width: $break-device)
            padding: 4em 3em

            p
                max-width: 500px
                margin: auto

        .analyist_header
            .img-circle
                width: 100px
                height: 100px

    .paynow
        p
            line-height: 1.4em

        .btn
            width: 300px
            padding: 0.65em

//Study Resources
.document_index_only
    .content
        height: auto !important

.document_index_only
    background: white !important

//Upload Course Schedule
.deadline_block
    padding: 40px

    @media screen and (max-width: $break-device)
        padding: 1.5em 1em

    h6
        color: #5473b3

        &:hover
            text-decoration: underline

    p
        font-size: 13px
        color: #666

        @media screen and (max-width: $break-device)
            font-size: 13px !important

@-moz-document url-prefix()
    div[location='index_documents']
        .left_nav
            margin-left: 0% !important

//Question and Answer and Essays
div[location='index_qa_questions'], div[location='index_qa_essays'], div[location='index_life_questions'], div[location='qa_demo_pages'], div[location='life_demo_pages'], div[location='index_email_questions'], div[location='email_demo_pages'], div[location='index_qa_presentation'], div[location='essay_demo_pages'], div[location='life_demo_qa_questions']
    background: white

    .content
        padding-top: 0 !important
        margin-bottom: 0
        background: white !important

    // .essay_container
    //   :min-height 631px
    // .email_container
    //   :min-height 545px
    // .expert_container
    //   :min-height 610px
    .notice_container
        background: #e5e7ea
        padding: 20px

    .question_subject_handle
        color: $t-green-dark

    .qa_question_link
        position: relative
        display: block

        .answered_tag, .need-rating
            position: absolute
            right: 0
            top: 2em

        .answered_tag, .need-rating
            color: #4fb4a8

    @media screen and (min-width: $break-device)
        .question_container
            padding-right: 6em !important

    @media screen and (max-width: $break-device)
        //.qa_suggest_guide
        //  .qa_question_link
        //    display: block
        //    border-top: none
        //    margin-bottom: 0.5em
        //    background: none
        .answered_tag, .need-rating
            right: 1em
            top: 1.5em
            font-size: 12px

div[location='edit_qa_questions'], div[location='edit_qa_presentation'], div[location='edit_life_questions'], div[location='edit_email_questions'], div[location='edit_qa_essays']
    .content
        padding-top: 40px

    .existing_fields
        border: 1px solid #dde1ea

div[location='show_qa_presentation'], div[location='edit_qa_presentation'], div[location='show_qa_questions'], div[location='edit_qa_questions'], div[location='edit_qa_presentation'], div[location='show_qa_essays'], div[location='edit_qa_essays'], div[location='show_email_questions'], div[location='edit_email_questions'], div[location='show_life_questions'], div[location='edit_life_questions'], .parent_dashboard
    .qa_body
        .qa_question_expanded, .qa_answer_expanded, .qa_comment_expanded
            .question_info
                img
                    vertical-align: middle
                    width: 36px
                    height: 36px

.qa_attachment_link
    display: inline-block
    font-size: 12px
    color: $text_color
    padding: 8px 12px
    border-radius: 4px
    margin-right: 8px
    margin-bottom: 8px
    border: 1px solid $bd-gray
    background: white

    &:hover
        background: #fafafa

.qa_attachment_entry
    word-break: break-word

    img
        width: 15px
        height: 20px
        margin-right: 8px
        vertical-align: middle

div[location='show_qa_presentation'], div[location='edit_qa_presentation'], div[location='show_qa_questions'], div[location='edit_qa_questions'], div[location='edit_qa_presentation'], div[location='show_qa_essays'], div[location='edit_qa_essays'], div[location='show_email_questions'], div[location='edit_email_questions'], div[location='show_life_questions'], div[location='edit_life_questions'], .parent_dashboard, div[location='oneclass_mentor_question_pages']
    .qa_body
        .mw7
            max-width: 712px

        .question_content
            word-wrap: break-word

        .question_content.comment_content_block
            p
                font-size: 16px

        .qa_question_expanded, .qa_answer_expanded, .qa_comment_expanded
            position: relative

            //ul, ol
            // //list-style: initial
            .star_icon, .empty_star_icon
                float: right

            .question_info
                span
                    font-size: 18px !important

                    @media screen and (min-width: $break-device)
                        font-size: 20px !important

                p
                    img
                        width: initial !important
                        height: initial !important
                        vertical-align: initial !important

                    .time_created_at
                        font-size: 12px

                    .comment_name
                        font-weight: bold

            textarea
                width: 100%

            .qa_attachments_list.unstyled
                margin-top: 1.5rem

                li
                    list-style: none !important

            .comment_reply
                p
                    font-size: 14px

            .rating
                width: 200px
                height: 35px

                select
                    height: 33px

    .answer_form
        textarea
            width: 100%

    .qa_related_questions
        .question_container
            text-render: optimizeLegibility
            -webkit-font-smoothing: antialiased

            p.question_title
                font-size: 14px !important

    .qa_answer_expanded
        .answer_header
            position: absolute
            width: 100%
            left: 0
            top: 0
            right: 0
            background: #EFFCF6
            padding: 15px 30px

            h3
                font-size: 18px
                font-weight: 600
                color: #5ec6b1

        .question_info
            margin-top: 50px

div[location='show_qa_presentation'], div[location='show_qa_questions'], div[location='show_life_questions'], div[location='show_email_questions'], div[location='show_qa_essays']
    .qa_bottom
        background-color: rgb(90, 183, 132)
        padding-bottom: 25px
        padding-top: 25px
        color: white
        letter-spacing: 1.5px

        .head_line
            font-size: 30px
            font-family: PingFangSC-Regular, sans-serif

        .white
            font-size: 14px
            font-family: PingFangSC-Ultralight, sans-serif
            margin-top: 15px
            margin-bottom: 15px
            font-weight: lighter

        .phone
            border: solid 1px
            width: 230px
            margin-left: auto
            margin-right: auto
            padding-top: 5px
            padding-bottom: 5px
            font-size: 14px
            font-weight: lighter
            font-family: PingFangSC-Ultralight, sans-serif

    .content
        background: white

        .mw7
            max-width: 712px

    .qa-nav
        padding-top: 7px !important
        padding-bottom: 6px !important

    p.question_content
        word-break: break-all

    .qa_comment_expanded
        .tutor-comment-title
            border-left: 3px solid #48dfb1
            height: 14px
            line-height: 14px
            padding-left: 14px

        .life-tutor-comment-title
            line-height: 24px

            .mentor_image
                margin-right: 8px

    .qa_question_ratings
        @media screen and (min-width: $break-small)
            border-radius: 10px
            border: none

        .star
            background-image: image-url("#{$image-path}/default/5-large.png") !important
            width: 33px !important
            height: 33px !important

    .qa_question_preview
        height: 100%
        opacity: 0.2
        z-index: 10000
        position: absolute
        top: 0px
        width: 100%
        background-position: center top
        bottom: 0px
        background-image: image-url("#{$image-path}/default/preview_background.png") !important
        background-repeat: repeat-y

    .life-mentor
        .mentor-badge
            width: 16px
            bottom: -8px

div[location='index_qa_presentation'], div[location='show_qa_presentation'], div[location='index_qa_questions'], div[location='show_qa_questions'], div[location='edit_qa_questions'], div[location='edit_qa_presentation'], div[location='index_qa_essays'], div[location='show_qa_essays'], div[location='edit_qa_essays'], div[location='index_life_questions'], div[location='show_life_questions'], div[location='edit_life_questions'], div[location='index_email_questions'], div[location='edit_email_questions'], div[location='show_email_questions'], div[location='index_qa_presentation'], .mentor_dashboard_page
    #subject_id
        @media screen and (max-width: $break-device)
            width: 100%

    .qa_question_contentformError
        @media screen and (max-width: $break-device)
            left: 35vw !important

    .tutor-comment
        background-color: #f8fefc

        .question_content
            border-bottom: none

    .tutor-comment.solution
        background-color: #e4f5f0

    .qa_question_expanded, .question_container, .qa_answer_expanded, .qa_comment_expanded
        position: relative

        //ul, ol
        // //list-style: initial !important
        .red_dot
            position: absolute
            width: 7px
            height: 7px
            background: #F3465D
            right: 0
            top: 0
            bottom: 0
            margin: auto
            border-radius: 50%

        .life_dot
            top: 3.7em !important

    //.email_container
    // //.red_dot
    //   //top: initial !important
    //.qa_tab_content
    //  a
    //    &:hover
    //      text-decoration: none

div[location='index_qa_essays'], div[location='index_email_questions'], div[location='index_life_questions'], div[location='index_qa_presentation'], .mentor_dashboard_page, div[location='index_qa_questions']
    .question_container, .qa_question_link
        cursor: pointer

        &:hover
            text-decoration: none !important

            .question_subject_handle, .question_created_date
                text-decoration: none !important

            .question_title
                text-decoration: underline !important

    .question_title
        color: $text_color

div[location='index_qa_questions'], div[location='index_qa_presentation'], div[location='essay_demo_pages']
    .essay-how
        margin-top: 2em
        padding-top: 2em
        border-top: 1px solid rgba(255, 255, 255, 0.3)

        .span4
            line-height: 1.4em

            .step
                border: 1px solid #fff
                width: 30px
                height: 30px
                border-radius: 50%
                color: white
                padding-top: 5px

        @media screen and (max-width: $break-device)
            margin-top: 1em
            padding-top: 1em

            .small_text
                font-size: 12px

            .step
                width: 30px
                height: 30px
                padding-top: 5px

div[location='index_qa_questions']
    .qa_tab_content
        .sample
            font-size: 18px
            color: #888
            line-height: 4em

    //.qa_suggest_guide
    //  padding: 1.5em 1em
    //  background: #f5fdf9
    //  border: 1px solid #cee8db
    //  font-size: 16px
    //  margin-top: 20px
    //  h2
    //    font-size: 18px
    //    margin-top: 0
    //  .view_all, .name_tag
    //    font-size: 13px
    .question_tag
        .label-tag
            margin-bottom: 8px

.row-fluid
    width: 100%
    *zoom: 1

.row-fluid:before, .row-fluid:after
    display: table
    line-height: 0
    content: ""

.row-fluid:after
    clear: both

.row-fluid [class*="span"]
    display: block
    float: left
    width: 100%
    min-height: 30px
    margin-left: 2.5641%
    *margin-left: 2.51091%
    -webkit-box-sizing: border-box
    -moz-box-sizing: border-box
    box-sizing: border-box

.row-fluid [class*="span"]:first-child
    margin-left: 0

.row-fluid .span8
    width: 65.81197%
    *width: 65.75877%

.row-fluid .span4
    width: 31.62393%
    *width: 31.57074%

.img-circle
    -webkit-border-radius: 500px
    -moz-border-radius: 500px
    border-radius: 500px
