// Course recommendation tool //
.cr_tool_cr_tool {
  .content {
    // height: 100%;
    height: 800px;
  }
}

.p-crt {
  background: $theme-gradient-warm;
  padding-top: $gl-spacer-2xl;
  padding-bottom: $gl-spacer-2xl;
  height: 100%;
  .content_height{
    height: 800px;
  }

  &__hero {
    background: $theme-gradient-warm;
    padding-top: $gl-spacer-2xl;
    padding-bottom: $gl-spacer-2xl;
    margin-bottom: $gl-spacer-2xl;

    @include mobile {
      padding-top: $gl-spacer-xl;
      padding-bottom: $gl-spacer-xl;
      margin-bottom: $gl-spacer-xl;
    }
  }

  
  // Show page
  &-prof {
    &__header {
      background: $theme-gradient-warm;
      padding: $gl-spacer-l 0;

      @include desktop {
        padding: 40px 0 $gl-spacer-2xl;
      }

      &__btn-circle {
        border-radius: 50%;
        padding: 8px;
      }
    }

    &__rating {
      padding: $gl-spacer-s;
      border-radius: $gl-radius-lg;
      background-color: $theme-light-lt;
      text-align: center;
    }

    &__pie {
      position: relative;

      &__graph {
        width: 180px !important;
        height: 180px !important;
        display: block;
        background: $b-gray-2;
        border-radius: 50%;

        @include desktop {
          width: 240px !important;
          height: 240px !important;
        }
      }
    }

    &__circle {
      position: absolute;
      z-index: 1;
      width: 150px;
      height: 150px;
      padding: 32px 0;
      border-radius: 50%;
      text-align: center;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      margin: auto;
      background: $theme-light-lt;

      @include desktop {
        width: 210px;
        height: 210px;
        padding: 68px 0;
      }

      &__percent {
        font-size: $gl-font-size-h3;
        font-weight: bold;
      }

      &__desc {
        font-size: $gl-font-size-base;
        font-weight: 600;

        @include desktop {
          font-size: $gl-font-size-sub-header;
        }
      }

      &__list {
        line-height: $gl-line-height-xs;
      }
    }

    &__review {
      padding-top: $gl-spacer-m;
      padding-bottom: $gl-spacer-m;

      & + & {
        border-top: 1px solid $bd-gray;
      }
    }

    &__countdown {
      @include mobile {
        zoom: 0.7;
        margin-bottom: $gl-spacer-l;
      }

      &__number {
        background: url("#{$image-path}/pages/rmp/countdown.svg") no-repeat top left;
        width: 124px;
        height: 92px;
        display: inline-block;
        text-align: center;
        font-weight: 600;
        font-size: 48px;
        line-height: 92px;
        vertical-align: middle;
      }
    }

    &__qr {
      width: 250px;
      height: 250px;
      margin: auto;
    }

    //Gate
    &__gate {
      background: url("#{$image-path}/pages/rmp/blur-m.png") no-repeat top center;
      background-size: cover;
      height: 800px;
      width: 100%;
      padding: 0 0 $gl-spacer-xl;
      margin-bottom: 400px;

      @include desktop {
        height: 1330px;
        background: url("#{$image-path}/pages/rmp/blur.jpg") no-repeat top left;
        background-size: contain;
        padding: $gl-spacer-m 0 $gl-spacer-xl;
      }
    }

    &__block {
      @include mobile {
        border-top: 1px solid $bd-gray;
        padding: 40px 0;
      }

      @include desktop {
        border-radius: $block-border-radius;
        padding: rem($block-padding);
        display: block;
        background-color: $block-background;
        box-shadow: $block-shadow;
        margin-bottom: $gl-spacer-xl;
      }
    }

    &__chart {
      position: relative;

      &__left,
      &__right {
        position: absolute;
        font-size: $gl-font-size-h3;
        top: 0;

        @include mobile {
          font-size: $gl-font-size-h4;
          top: 6px;
        }
      }

      &__left {
        left: $gl-spacer-l;
      }

      &__right {
        right: $gl-spacer-l;
      }
    }
  }

  // Result page
  &-result {
    &__link {
      @include hover {
        border-color: $theme-dark-lt;
        h5{
          color:$t-dark;
        }
      }
    }
  }
}
