// 视频辅导
@use "sass:math";

$img-path: '/images/pages/live-tutorial';

.p-live-t {
  &__intro {
    background: url('#{$img-path}/intro_bg.jpg') no-repeat center;
    background-size: cover;
    height: 560px;
    &__hero {
      height: 100%;
    }
  }
  &_tutor {
    background: url('#{$img-path}/tutor-bg.png') #00af98 no-repeat;
    background-size: cover;
    .num {
      font-weight: 500;
      font-size: 42px;
      line-height: 150%;
      color: $t-light;
    }
    .text {
      margin-top: 16px;
      font-size: 16px;
      line-height: 150%;
      color: $t-light;
    }
  }

  &_subject {
    h1 {
      // margin-bottom: 72px !important;
      // line-height: 2;
    }
    &_box {
      flex: 0 0 math.div(100%, 7);
      margin-bottom: 40px;
      .ch {
        margin: 8px 0 2px;
        line-height: 150%;
        color: $t-dark;
      }
      .en {
        line-height: 150%;
        color: $t-gray-1;
      }
      @include mobile {
        flex: 0 0 50%;
      }
    }
  }

  &_pt {
    &_bg {
      background: url('#{$img-path}/pt_bg.png') no-repeat #{$b-orange-2} center;
      background-size: cover;
      .l-col-md-4 {
        padding: 0 20px;
      }
      .t2 {
        margin: 8px 0;
      }
    }

    &_m {
      .box {
        width: 258px !important;
        padding: 24px 20px;
        background-color: #fff;
        border-radius: 8px;
        margin-right: 24px;
        .border {
          width: 20px;
          height: 3px;
          background: $t-dark;
          border-radius: 4px;
          margin:10px 0;
        }
      }
    }
    &_top {
      margin-top: 80px;
    }
    &_box {
      margin-bottom: 80px;
      &_c {
        margin-bottom: 32px;
      }
    }
  }
  &_video {
    .video-box {
      max-width: 1040px;
      margin: 0 auto;
      .video {
        width: 1050px;
        height: 590px;
        @include mobile{
          width: 100%;
          height: 260px;
        }
        // @media screen and (max-width: 640px) {
        //   width: 600px;
        //   height: 340px;
        // }
      }
    }

    &_tab {
      max-width: 1040px;
      margin: 0 auto 80px;
      &_btn {
        padding: 20px 28px;
        background: #f4f4f4;
        // box-shadow: 0px 14px 20px rgba(0, 84, 73, 0.12);
        border-radius: 6px;
        font-weight: 600;
        font-size: 24px;
        line-height: 150%;
        text-align: center;
        color: $t-gray-1;
        cursor: pointer;
      }
      .active {
        box-shadow: 0px 14px 20px rgba(0, 84, 73, 0.12);
        background: $b-green;
        color: $t-light;
      }
    }
  }
}
