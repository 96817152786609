//
// Radio
// --------------------------------------------------
//[TODO]: add docs

// The implementation is based on https://github.com/flatlogic/awesome-bootstrap-checkbox

// Default vars
$radio-spacing: 5px !default; //spacing between checkbox and label

.c-radio {
  cursor: default;

  &__label {
    display: inline-block;
    vertical-align: middle;
    position: relative;
    padding-top: 0;
    padding-bottom: 0;
    padding-left: $radio-spacing;
    line-height: 1;
    word-break: break-word;
  }

  &__input {
    margin-left: 0;
    margin-top: 0 !important;
    cursor: pointer;
    z-index: 1;
  }
}
