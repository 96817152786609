// SDC Service
$services-border-color: $bd-gray;
$services-padding: $gl-spacer-l;

.p-sdc {
  // Services
  &__services {
    border-right: 1px solid $services-border-color;
    border-bottom: 1px solid $services-border-color;

    &__block {
      padding: rem($services-padding);
      border-left: 1px solid $services-border-color;
      border-top: 1px solid $services-border-color;

      &:hover {
        box-shadow: 0 0 20px rgba(0,0,0,0.08)
      }
    }
  }

  // List
  &__list {
    margin-left: 20px;
    margin-bottom: 0;

    li {
      list-style: disc;
      -webkit-margin-before: 0;
    }
  }
}
