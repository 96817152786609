
h1, h2, h3, h4, h5, h6 {
  color: $t-dark;
}
a {
  color: $t-green-dark;
}
a:hover {
  color: $t-green;
}
a:active {
  color: #096dd9;
}
::-moz-selection {
  color: #fff;
  background: $t-green-dark;
}
::selection {
  color: #fff;
  background: $t-green-dark;
}
html {
  --antd-wave-shadow-color: $t-green-dark;
  --scroll-bar: 0;
}
[ant-click-animating-without-extra-node='true']::after,
.ant-click-animating-node {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  border-radius: inherit;
  -webkit-box-shadow: 0 0 0 0 $t-green-dark;
          box-shadow: 0 0 0 0 $t-green-dark;
  opacity: 0.2;
  -webkit-animation: fadeEffect 2s cubic-bezier(0.08, 0.82, 0.17, 1), waveEffect 0.4s cubic-bezier(0.08, 0.82, 0.17, 1);
          animation: fadeEffect 2s cubic-bezier(0.08, 0.82, 0.17, 1), waveEffect 0.4s cubic-bezier(0.08, 0.82, 0.17, 1);
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  content: '';
  pointer-events: none;
}
@-webkit-keyframes waveEffect {
  100% {
    -webkit-box-shadow: 0 0 0 $t-green-dark;
            box-shadow: 0 0 0 $t-green-dark;
    -webkit-box-shadow: 0 0 0 6px var(--antd-wave-shadow-color);
            box-shadow: 0 0 0 6px var(--antd-wave-shadow-color);
  }
}
@keyframes waveEffect {
  100% {
    -webkit-box-shadow: 0 0 0 $t-green-dark;
            box-shadow: 0 0 0 $t-green-dark;
    -webkit-box-shadow: 0 0 0 6px var(--antd-wave-shadow-color);
            box-shadow: 0 0 0 6px var(--antd-wave-shadow-color);
  }
}

.ant-alert-success {
  background-color: #f6ffed;
  border: 1px solid #b7eb8f;
}
.ant-alert-success .ant-alert-icon {
  color: #52c41a;
}
.ant-alert-info {
  background-color: $b-orange-2;
  border: 1px solid #91d5ff;
}
.ant-alert-info .ant-alert-icon {
  color: $t-green-dark;
}
.ant-alert-warning {
  background-color: #fffbe6;
  border: 1px solid #ffe58f;
}
.ant-alert-warning .ant-alert-icon {
  color: #faad14;
}
.ant-alert-error {
  background-color: #fff2f0;
  border: 1px solid #ffccc7;
}
.ant-alert-error .ant-alert-icon {
  color: #ff4d4f;
}
.ant-alert-error .ant-alert-description > pre {
  margin: 0;
  padding: 0;
}
.ant-alert-action {
  margin-left: 8px;
}
.ant-alert-close-icon {
  margin-left: 8px;
  padding: 0;
  overflow: hidden;
  font-size: 12px;
  line-height: 12px;
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
}
.ant-anchor-ink-ball {
  border: 2px solid $t-green-dark;
}
.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-color: $bd-gray;
  border-right-width: 1px !important;
  outline: 0;
  -webkit-box-shadow: none;
          box-shadow: none;
}
.ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector {
  height: 42px;
}
.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
  border-color: $bd-gray;
  border-right-width: 1px !important;
}
.ant-select-item-option-selected:not(.ant-select-item-option-disabled) .ant-select-item-option-state {
  color: $t-green-dark;
  background: #f1f9f8;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected .ant-picker-time-panel-cell-inner {
  background: #f1f9f8;
}
.ant-badge-status-processing {
  position: relative;
  background-color: $t-green-dark;
}
.ant-badge-status-processing::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 1px solid $t-green-dark;
  border-radius: 50%;
  -webkit-animation: antStatusProcessing 1.2s infinite ease-in-out;
          animation: antStatusProcessing 1.2s infinite ease-in-out;
  content: '';
}
.ant-badge-status-default {
  background-color: #d9d9d9;
}
.ant-badge-status-error {
  background-color: #ff4d4f;
}
.ant-badge-status-warning {
  background-color: #faad14;
}
.ant-badge-status-pink {
  background: #eb2f96;
}
.ant-badge-status-magenta {
  background: #eb2f96;
}
.ant-badge-status-red {
  background: #f5222d;
}
.ant-badge-status-volcano {
  background: #fa541c;
}
.ant-badge-status-orange {
  background: #fa8c16;
}
.ant-badge-status-yellow {
  background: #fadb14;
}
.ant-badge-status-gold {
  background: #faad14;
}
.ant-badge-status-cyan {
  background: #13c2c2;
}
.ant-badge-status-lime {
  background: #a0d911;
}
.ant-badge-status-green {
  background: #52c41a;
}
.ant-badge-status-blue {
  background: $t-green-dark;
}
.ant-badge-status-geekblue {
  background: #2f54eb;
}
.ant-badge-status-purple {
  background: #722ed1;
}
.ant-ribbon {
  background-color: $t-green-dark;
}
.ant-ribbon-color-cyan {
  color: #13c2c2;
  background: #13c2c2;
}
.ant-ribbon-color-blue {
  color: $t-green-dark;
  background: $t-green-dark;
}
.ant-breadcrumb a:hover {
  color: $t-green;
}
.ant-menu-submenu-selected {
  color: $t-green-dark;
}
.ant-menu-item a:hover {
  color: $t-green-dark;
}
.ant-menu-item > .ant-badge a:hover {
  color: $t-green-dark;
}

.ant-menu-item:hover,
.ant-menu-item-active,
.ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open,
.ant-menu-submenu-active,
.ant-menu-submenu-title:hover {
  color: $t-green-dark;
}
.ant-menu-item-selected {
  color: $t-green-dark;
}
.ant-menu-item-selected a,
.ant-menu-item-selected a:hover {
  color: $t-green-dark;
}
.ant-menu-submenu:hover > .ant-menu-submenu-title > .ant-menu-submenu-expand-icon,
.ant-menu-submenu:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow {
  color: $t-green-dark;
}
.ant-menu-vertical .ant-menu-submenu-selected,
.ant-menu-vertical-left .ant-menu-submenu-selected,
.ant-menu-vertical-right .ant-menu-submenu-selected {
  color: $t-green-dark;
}
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected {
  color: $t-green-dark;
  border-bottom: 2px solid $t-green-dark;
}
.ant-menu-horizontal > .ant-menu-item a:hover {
  color: $t-green-dark;
}
.ant-menu-horizontal > .ant-menu-item a::before {
  bottom: -2px;
}
.ant-menu-horizontal > .ant-menu-item-selected a {
  color: $t-green-dark;
}
.ant-menu-vertical .ant-menu-item::after,
.ant-menu-vertical-left .ant-menu-item::after,
.ant-menu-vertical-right .ant-menu-item::after,
.ant-menu-inline .ant-menu-item::after {
  border-right: 3px solid $t-green-dark;
}
.ant-menu-dark.ant-menu-horizontal > .ant-menu-item:hover {
  background-color: $t-green-dark;
}
.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: $t-green-dark;
}
.ant-menu.ant-menu-dark .ant-menu-item-selected,
.ant-menu-submenu-popup.ant-menu-dark .ant-menu-item-selected {
  background-color: $t-green-dark;
}
.ant-tooltip-cyan .ant-tooltip-inner {
  background-color: #13c2c2;
}
.ant-tooltip-cyan .ant-tooltip-arrow-content {
  background-color: #13c2c2;
}
.ant-tooltip-blue .ant-tooltip-inner {
  background-color: $t-green-dark;
}
.ant-tooltip-blue .ant-tooltip-arrow-content {
  background-color: $t-green-dark;
}
.ant-dropdown-menu-item-selected,
.ant-dropdown-menu-submenu-title-selected,
.ant-dropdown-menu-item-selected > a,
.ant-dropdown-menu-submenu-title-selected > a {
  color: $t-green-dark;
  background-color: $b-orange-2;
}
.ant-dropdown-menu-submenu-selected .ant-dropdown-menu-submenu-title {
  color: $t-green-dark;
}
.ant-dropdown-menu-dark .ant-dropdown-menu-item-selected,
.ant-dropdown-menu-dark .ant-dropdown-menu-item-selected:hover,
.ant-dropdown-menu-dark .ant-dropdown-menu-item-selected > a {
  color: #fff;
  background: $t-green-dark;
}
.ant-btn:hover,
.ant-btn:focus {
  color: $t-green;
  background: #fff;
  border-color: $t-green;
}
.ant-btn-primary {
  color: #fff;
  background: $b-green;
  border-color: $b-green;
}
.ant-btn-primary:hover,
.ant-btn-primary:focus {
  color: #fff;
  background: $b-green-lt;
  border-color: $b-green-lt;
}
.ant-btn-group .ant-btn-primary:not(:first-child):not(:last-child) {
  border-right-color: $t-green;
  border-left-color: $t-green;
}
.ant-btn-group .ant-btn-primary:first-child:not(:last-child) {
  border-right-color: $t-green;
}
.ant-btn-group .ant-btn-primary:last-child:not(:first-child),
.ant-btn-group .ant-btn-primary + .ant-btn-primary {
  border-left-color: $t-green;
}
.ant-btn-ghost:hover,
.ant-btn-ghost:focus {
  color: $t-green;
  background: transparent;
  border-color: $t-green;
}
.ant-btn-dashed:hover,
.ant-btn-dashed:focus {
  color: $t-green;
  background: #fff;
  border-color: $t-green;
}
.ant-btn-link {
  color: $t-green-dark;
}
.ant-btn-link:hover,
.ant-btn-link:focus {
  color: $t-green;
  background: transparent;
  border-color: $t-green;
}
.ant-btn-text:hover,
.ant-btn-text:focus {
  color: $t-green;
  background: transparent;
  border-color: $t-green;
}
.ant-btn-dangerous.ant-btn-link:hover,
.ant-btn-dangerous.ant-btn-link:focus {
  color: $t-green;
  background: transparent;
  border-color: $t-green;
}
.ant-btn-dangerous.ant-btn-text:hover,
.ant-btn-dangerous.ant-btn-text:focus {
  color: $t-green;
  background: transparent;
  border-color: $t-green;
}
.ant-btn-background-ghost.ant-btn-primary {
  color: $t-green-dark;
  background: transparent;
  border-color: $t-green-dark;
  text-shadow: none;
}
.ant-btn-background-ghost.ant-btn-primary:hover,
.ant-btn-background-ghost.ant-btn-primary:focus {
  color: $t-green;
  background: transparent;
  border-color: $t-green;
}
.ant-btn-group-rtl.ant-btn-group .ant-btn-primary:last-child:not(:first-child),
.ant-btn-group-rtl.ant-btn-group .ant-btn-primary + .ant-btn-primary {
  border-right-color: $t-green;
  border-left-color: #d9d9d9;
}
.ant-btn-group-rtl.ant-btn-group .ant-btn-primary:last-child:not(:first-child)[disabled],
.ant-btn-group-rtl.ant-btn-group .ant-btn-primary + .ant-btn-primary[disabled] {
  border-right-color: #d9d9d9;
  border-left-color: $t-green;
}
.ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected .ant-picker-calendar-date .ant-picker-calendar-date-value,
.ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected:hover .ant-picker-calendar-date .ant-picker-calendar-date-value,
.ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected .ant-picker-calendar-date-today .ant-picker-calendar-date-value,
.ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected:hover .ant-picker-calendar-date-today .ant-picker-calendar-date-value {
  color: $t-green-dark;
}
.ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date-today {
  border-color: $t-green-dark;
}
.ant-radio-wrapper:hover .ant-radio,
.ant-radio:hover .ant-radio-inner,
.ant-radio-input:focus + .ant-radio-inner {
  border-color: $t-green-dark;
}
.ant-radio-checked::after {
  border: 1px solid $t-green-dark;
}
.ant-radio-inner::after {
  background-color: $t-green-dark;
}
.ant-radio-checked .ant-radio-inner {
  border-color: $t-green-dark;
}
.ant-radio-button-wrapper:hover {
  position: relative;
  color: $t-green-dark;
}
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  z-index: 1;
  color: $t-green-dark;
  background: #fff;
  border-color: $t-green-dark;
}
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
  background-color: $t-green-dark;
}
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):first-child {
  border-color: $t-green-dark;
}
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
  color: $t-green;
  border-color: $t-green;
}
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover::before {
  background-color: $t-green;
}
.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  color: #fff;
  background: $t-green-dark;
  border-color: $t-green-dark;
}
.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
  color: #fff;
  background: $t-green;
  border-color: $t-green;
}
.ant-radio-button-wrapper-checked:not([class*=' ant-radio-button-wrapper-disabled']).ant-radio-button-wrapper:first-child {
  border-right-color: $t-green;
}
.ant-picker:hover,
.ant-picker-focused {
  border-color: $bd-gray;
  border-right-width: 1px !important;
}
.ant-picker-focused {
  border-color: $bd-gray;
  border-right-width: 1px !important;
  outline: 0;
  -webkit-box-shadow: none;
          box-shadow: none;
}
.ant-picker-input > input:hover {
  border-color: #fff;
  border-right-width: 1px !important;
}
.ant-picker-range .ant-picker-active-bar {
  background: $t-green-dark;
}
.ant-picker-ranges .ant-picker-preset > .ant-tag-blue {
  color: $t-green-dark;
  background: $b-orange-2;
  border-color: #91d5ff;
  cursor: pointer;
}
.ant-picker-panel-focused {
  border-color: $t-green-dark;
}
.ant-picker-header-view button:hover {
  color: $t-green-dark;
}
.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
  border: 1px solid $bd-gray;
}
.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
  color: #fff;
  background: $b-green;
}
.ant-picker-today-btn {
  color: $t-green-dark;
}
.ant-picker-today-btn:hover {
  color: $t-green;
}
.ant-picker-week-panel-row-selected td,
.ant-picker-week-panel-row-selected:hover td {
  background: $t-green-dark;
}
.ant-tag-checkable:not(.ant-tag-checkable-checked):hover {
  color: $t-green-dark;
}
.ant-tag-checkable-checked {
  background-color: $t-green-dark;
}
.ant-tag-cyan {
  color: #13c2c2;
  background: #e6fffb;
  border-color: #87e8de;
}
.ant-tag-cyan-inverse {
  color: #fff;
  background: #13c2c2;
  border-color: #13c2c2;
}
.ant-tag-blue {
  color: $t-green-dark;
  background: $b-orange-2;
  border-color: #91d5ff;
}
.ant-tag-blue-inverse {
  color: #fff;
  background: $t-green-dark;
  border-color: $t-green-dark;
}
.ant-tag-processing {
  color: $t-green-dark;
  background: $b-orange-2;
  border-color: #91d5ff;
}
.ant-card-actions > li > span:hover {
  color: $t-green-dark;
  -webkit-transition: color 0.3s;
  transition: color 0.3s;
}
.ant-card-actions > li > span a:not(.ant-btn):hover,
.ant-card-actions > li > span > .anticon:hover {
  color: $t-green-dark;
}
.ant-card-actions > li > span > .anticon {
  font-size: 16px;
  line-height: 22px;
}
.ant-tabs-card > .ant-tabs-nav .ant-tabs-tab-active,
.ant-tabs-card > div > .ant-tabs-nav .ant-tabs-tab-active {
  color: $t-green-dark;
  background: #fff;
}
.ant-tabs > .ant-tabs-nav .ant-tabs-nav-add:hover,
.ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-add:hover {
  color: $t-green;
}
.ant-tabs-ink-bar {
  position: absolute;
  background: $t-green-dark;
  pointer-events: none;
}
.ant-tabs-tab:hover {
  color: $t-green;
}
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: $t-green-dark;
  font-weight: 500;
}
.ant-cascader-picker:focus .ant-cascader-input {
  border-color: $t-green;
  border-right-width: 1px !important;
  outline: 0;
  -webkit-box-shadow: 0 0 0 2px rgba(18, 204, 179, 0.2);
          box-shadow: 0 0 0 2px rgba(18, 204, 179, 0.2);
}
.ant-cascader-picker-label:hover + .ant-cascader-input:not(.ant-cascader-picker-disabled .ant-cascader-picker-label:hover + .ant-cascader-input) {
  border-color: $t-green;
  border-right-width: 1px !important;
}
.ant-input-affix-wrapper:hover {
  border-color: $t-green;
  border-right-width: 1px !important;
}
.ant-input-affix-wrapper:focus,
.ant-input-affix-wrapper-focused {
  border-color: $t-green;
  border-right-width: 1px !important;
  outline: 0;
  -webkit-box-shadow: 0 0 0 2px rgba(18, 204, 179, 0.2);
          box-shadow: 0 0 0 2px rgba(18, 204, 179, 0.2);
}
.ant-input-affix-wrapper:hover {
  border-color: $t-green;
  border-right-width: 1px !important;
  z-index: 1;
}
.ant-input:hover {
  border-color: $t-green;
  border-right-width: 1px !important;
}
.ant-input-rtl .ant-input:hover {
  border-right-width: 0;
  border-left-width: 1px !important;
}
.ant-input:focus,
.ant-input-focused {
  border-color: $t-green;
  border-right-width: 1px !important;
  outline: 0;
  -webkit-box-shadow: 0 0 0 2px rgba(18, 204, 179, 0.2);
          box-shadow: 0 0 0 2px rgba(18, 204, 179, 0.2);
}
.ant-input-rtl .ant-input:focus,
.ant-input-rtl .ant-input-focused {
  border-right-width: 0;
  border-left-width: 1px !important;
}
.ant-input-group-addon .ant-select-open .ant-select-selector,
.ant-input-group-addon .ant-select-focused .ant-select-selector {
  color: $t-green-dark;
}
.ant-input-search .ant-input:hover,
.ant-input-search .ant-input:focus {
  border-color: $t-green;
}
.ant-input-search .ant-input:hover + .ant-input-group-addon .ant-input-search-button:not(.ant-btn-primary),
.ant-input-search .ant-input:focus + .ant-input-group-addon .ant-input-search-button:not(.ant-btn-primary) {
  border-left-color: $t-green;
}
.ant-input-search-rtl .ant-input:hover + .ant-input-group-addon .ant-input-search-button:not(.ant-btn-primary),
.ant-input-search-rtl .ant-input:focus + .ant-input-group-addon .ant-input-search-button:not(.ant-btn-primary) {
  border-right-color: $t-green;
  border-left-color: #d9d9d9;
}
.ant-input-search-rtl > .ant-input-group > .ant-input-affix-wrapper:hover,
.ant-input-search-rtl > .ant-input-group > .ant-input-affix-wrapper-focused {
  border-right-color: $t-green;
}
.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus + .ant-checkbox-inner {
  border-color: $t-green-dark;
}
.ant-checkbox-checked::after {
  border: 1px solid $t-green-dark;
}
.ant-checkbox-checked .ant-checkbox-inner {
  background-color: $t-green-dark;
  border-color: $t-green-dark;
}
.ant-checkbox-indeterminate .ant-checkbox-inner::after {
  background-color: $t-green-dark;
}
.ant-form-item-has-error .ant-transfer-list-search:not([disabled]):hover {
  border-color: $t-green;
  border-right-width: 1px !important;
}
.ant-form-item-has-error .ant-transfer-list-search:not([disabled]):focus {
  border-color: $t-green;
  border-right-width: 1px !important;
  outline: 0;
  -webkit-box-shadow: 0 0 0 2px rgba(18, 204, 179, 0.2);
          box-shadow: 0 0 0 2px rgba(18, 204, 179, 0.2);
}
.ant-form-item-is-validating.ant-form-item-has-feedback .ant-form-item-children-icon {
  display: inline-block;
  color: $t-green-dark;
}
.ant-input-number:hover {
  border-color: $t-green;
  border-right-width: 1px !important;
}
.ant-input-number:focus,
.ant-input-number-focused {
  border-color: $t-green;
  border-right-width: 1px !important;
  outline: 0;
  -webkit-box-shadow: 0 0 0 2px rgba(18, 204, 179, 0.2);
          box-shadow: 0 0 0 2px rgba(18, 204, 179, 0.2);
}
.ant-input-number-handler:hover .ant-input-number-handler-up-inner,
.ant-input-number-handler:hover .ant-input-number-handler-down-inner {
  color: $t-green;
}
.ant-input-number:hover {
  border-color: $t-green;
  border-right-width: 1px !important;
}
.ant-input-number-focused {
  border-color: $t-green;
  border-right-width: 1px !important;
  outline: 0;
  -webkit-box-shadow: 0 0 0 2px rgba(18, 204, 179, 0.2);
          box-shadow: 0 0 0 2px rgba(18, 204, 179, 0.2);
}
.ant-list-item-meta-title > a:hover {
  color: $t-green-dark;
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-spin {
  color: $t-green-dark;
}
.ant-spin-dot-item {
  background-color: $t-green-dark;
}
.ant-pagination-item:focus,
.ant-pagination-item:hover {
  border-color: $t-green-dark;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
.ant-pagination-item:focus a,
.ant-pagination-item:hover a {
  color: $t-green-dark;
}
.ant-pagination-item-active {
  font-weight: 500;
  background: #fff;
  border-color: $t-green-dark;
}
.ant-pagination-item-active a {
  color: $t-green-dark;
}
.ant-pagination-item-active:focus,
.ant-pagination-item-active:hover {
  border-color: $t-green;
}
.ant-pagination-item-active:focus a,
.ant-pagination-item-active:hover a {
  color: $t-green;
}
.ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-link-icon,
.ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-link-icon {
  color: $t-green-dark;
}
.ant-pagination-prev:hover button,
.ant-pagination-next:hover button {
  border-color: $t-green;
}
.ant-pagination-prev:focus .ant-pagination-item-link,
.ant-pagination-next:focus .ant-pagination-item-link,
.ant-pagination-prev:hover .ant-pagination-item-link,
.ant-pagination-next:hover .ant-pagination-item-link {
  color: $t-green-dark;
  border-color: $t-green-dark;
}
.ant-pagination-disabled,
.ant-pagination-disabled:hover,
.ant-pagination-disabled:focus {
  cursor: not-allowed;
}
.ant-pagination-options-quick-jumper input:hover {
  border-color: $t-green;
  border-right-width: 1px !important;
}
.ant-pagination-options-quick-jumper input:focus,
.ant-pagination-options-quick-jumper input-focused {
  border-color: $t-green;
  border-right-width: 1px !important;
  outline: 0;
  -webkit-box-shadow: 0 0 0 2px rgba(18, 204, 179, 0.2);
          box-shadow: 0 0 0 2px rgba(18, 204, 179, 0.2);
}
.ant-pagination-simple .ant-pagination-simple-pager input:hover {
  border-color: $t-green-dark;
}
.ant-mentions:hover {
  border-color: $t-green;
  border-right-width: 1px !important;
}
.ant-mentions:focus,
.ant-mentions-focused {
  border-color: $t-green;
  border-right-width: 1px !important;
  outline: 0;
  -webkit-box-shadow: 0 0 0 2px rgba(18, 204, 179, 0.2);
          box-shadow: 0 0 0 2px rgba(18, 204, 179, 0.2);
}
.ant-mentions-focused {
  border-color: $t-green;
  border-right-width: 1px !important;
  outline: 0;
  -webkit-box-shadow: 0 0 0 2px rgba(18, 204, 179, 0.2);
          box-shadow: 0 0 0 2px rgba(18, 204, 179, 0.2);
}
.ant-message-info .anticon,
.ant-message-loading .anticon {
  color: $t-green-dark;
}
.ant-modal-confirm-info .ant-modal-confirm-body > .anticon {
  color: $t-green-dark;
}
.anticon.ant-notification-notice-icon-info {
  color: $t-green-dark;
}
.ant-page-header-back-button {
  color: $t-green-dark;
  text-decoration: none;
  outline: none;
  -webkit-transition: color 0.3s;
  transition: color 0.3s;
  color: #000;
  cursor: pointer;
}
.ant-page-header-back-button:focus,
.ant-page-header-back-button:hover {
  color: $t-green;
}
.ant-progress-steps-item-active {
  background: $t-green-dark;
}
.ant-progress-inner:not(.ant-progress-circle-gradient) .ant-progress-circle-path {
  stroke: $t-green-dark;
}
.ant-progress-success-bg,
.ant-progress-bg {
  position: relative;
  background-color: $t-green-dark;
  border-radius: 100px;
  -webkit-transition: all 0.4s cubic-bezier(0.08, 0.82, 0.17, 1) 0s;
  transition: all 0.4s cubic-bezier(0.08, 0.82, 0.17, 1) 0s;
}
.ant-result-info .ant-result-icon > .anticon {
  color: $t-green-dark;
}
.ant-steps-item-icon .ant-steps-icon {
  position: relative;
  top: -1px;
  color: $t-green-dark;
  line-height: 1;
}
.ant-steps-item-process .ant-steps-item-icon {
  background-color: #fff;
  border-color: $t-green-dark;
}
.ant-steps-item-process .ant-steps-item-icon > .ant-steps-icon {
  color: $t-green-dark;
}
.ant-steps-item-process .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot {
  background: $t-green-dark;
}
.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon {
  background: $t-green-dark;
}
.ant-steps-item-finish .ant-steps-item-icon {
  background-color: #fff;
  border-color: $t-green-dark;
}
.ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
  color: $t-green-dark;
}
.ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot {
  background: $t-green-dark;
}
.ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after {
  background-color: $t-green-dark;
}
.ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-description {
  color: rgba(0, 0, 0, 0.45);
}
.ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-tail::after {
  background-color: $t-green-dark;
}
.ant-steps .ant-steps-item:not(.ant-steps-item-active) > .ant-steps-item-container[role='button']:hover .ant-steps-item-title,
.ant-steps .ant-steps-item:not(.ant-steps-item-active) > .ant-steps-item-container[role='button']:hover .ant-steps-item-subtitle,
.ant-steps .ant-steps-item:not(.ant-steps-item-active) > .ant-steps-item-container[role='button']:hover .ant-steps-item-description {
  color: $t-green-dark;
}
.ant-steps .ant-steps-item:not(.ant-steps-item-active):not(.ant-steps-item-process) > .ant-steps-item-container[role='button']:hover .ant-steps-item-icon {
  border-color: $t-green-dark;
}
.ant-steps .ant-steps-item:not(.ant-steps-item-active):not(.ant-steps-item-process) > .ant-steps-item-container[role='button']:hover .ant-steps-item-icon .ant-steps-icon {
  color: $t-green-dark;
}
.ant-steps-item-custom.ant-steps-item-process .ant-steps-item-icon > .ant-steps-icon {
  color: $t-green-dark;
}
.ant-steps-navigation .ant-steps-item::before {
  background-color: $t-green-dark;
}
.ant-switch-checked:focus {
  -webkit-box-shadow: 0 0 0 2px rgba(18, 204, 179, 0.2);
          box-shadow: 0 0 0 2px rgba(18, 204, 179, 0.2);
}
.ant-switch-checked {
  background-color: $t-green-dark;
}
.ant-switch-checked .ant-switch-loading-icon {
  color: $t-green-dark;
}
.ant-table-column-sorter-up.active,
.ant-table-column-sorter-down.active {
  color: $t-green-dark;
}
.ant-table-filter-trigger.active {
  color: $t-green-dark;
}
.ant-table-row-expand-icon {
  color: $t-green-dark;
}
.ant-table-row-expand-icon:focus,
.ant-table-row-expand-icon:hover {
  color: $t-green;
}
.ant-timeline-item-head-blue {
  color: $t-green-dark;
  border-color: $t-green-dark;
}
.ant-transfer-list-content-item-remove {
  color: $t-green-dark;
}
.ant-transfer-list-content-item-remove:focus,
.ant-transfer-list-content-item-remove:hover {
  color: $t-green;
}
.ant-transfer-list-content-item-remove:hover {
  color: $t-green;
}
.ant-select-tree-checkbox-wrapper:hover .ant-select-tree-checkbox-inner,
.ant-select-tree-checkbox:hover .ant-select-tree-checkbox-inner,
.ant-select-tree-checkbox-input:focus + .ant-select-tree-checkbox-inner {
  border-color: $t-green-dark;
}
.ant-select-tree-checkbox-checked::after {
  border: 1px solid $t-green-dark;
}
.ant-select-tree-checkbox-checked .ant-select-tree-checkbox-inner {
  background-color: $t-green-dark;
  border-color: $t-green-dark;
}
.ant-select-tree-checkbox-indeterminate .ant-select-tree-checkbox-inner::after {
  background-color: $t-green-dark;
}
.ant-select-tree-switcher-loading-icon {
  color: $t-green-dark;
}
.ant-select-tree-node-content-wrapper[draggable='true'] .ant-tree-drop-indicator {
  background-color: $t-green-dark;
}
.ant-select-tree-node-content-wrapper[draggable='true'] .ant-tree-drop-indicator::after {
  border: 2px solid $t-green-dark;
}
.ant-select-tree .ant-select-tree-treenode.drop-container > [draggable] {
  -webkit-box-shadow: 0 0 0 2px $t-green-dark;
          box-shadow: 0 0 0 2px $t-green-dark;
}
.ant-tree.ant-tree-directory .ant-tree-treenode-selected:hover::before,
.ant-tree.ant-tree-directory .ant-tree-treenode-selected::before {
  background: $t-green-dark;
}
.ant-tree-checkbox-wrapper:hover .ant-tree-checkbox-inner,
.ant-tree-checkbox:hover .ant-tree-checkbox-inner,
.ant-tree-checkbox-input:focus + .ant-tree-checkbox-inner {
  border-color: $t-green-dark;
}
.ant-tree-checkbox-checked::after {
  border: 1px solid $t-green-dark;
}
.ant-tree-checkbox-checked .ant-tree-checkbox-inner {
  background-color: $t-green-dark;
  border-color: $t-green-dark;
}
.ant-tree-checkbox-indeterminate .ant-tree-checkbox-inner::after {
  background-color: $t-green-dark;
}
.ant-tree-switcher-loading-icon {
  color: $t-green-dark;
}
.ant-tree-node-content-wrapper[draggable='true'] .ant-tree-drop-indicator {
  background-color: $t-green-dark;
}
.ant-tree-node-content-wrapper[draggable='true'] .ant-tree-drop-indicator::after {
  border: 2px solid $t-green-dark;
}
.ant-tree .ant-tree-treenode.drop-container > [draggable] {
  -webkit-box-shadow: 0 0 0 2px $t-green-dark;
          box-shadow: 0 0 0 2px $t-green-dark;
}
a.ant-typography,
.ant-typography a {
  color: $t-green-dark;
}
a.ant-typography:focus,
.ant-typography a:focus,
a.ant-typography:hover,
.ant-typography a:hover {
  color: $t-green;
}
.ant-typography-expand,
.ant-typography-edit,
.ant-typography-copy {
  color: $t-green-dark;
}
.ant-typography-expand:focus,
.ant-typography-edit:focus,
.ant-typography-copy:focus,
.ant-typography-expand:hover,
.ant-typography-edit:hover,
.ant-typography-copy:hover {
  color: $t-green;
}
.ant-upload.ant-upload-select-picture-card:hover {
  border-color: $t-green-dark;
}
.ant-upload.ant-upload-drag:not(.ant-upload-disabled):hover {
  border-color: $t-green;
}
.ant-upload.ant-upload-drag p.ant-upload-drag-icon .anticon {
  color: $t-green;
  font-size: 48px;
}
.ant-tooltip {
  // width: 350px;
  max-width: none;
}
.ant-select-item-option-active:not(.ant-select-item-option-disabled){
  background-color: #f1f9f8;
}
.ant-select-item-option-selected:not(.ant-select-item-option-disabled){
  background-color: #f1f9f8;
}

.ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector{
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
}
.ant-select-open{
  border:1px solid #12ccb3;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
}
