$important: !important;

@if (config(utilities)) {
  $prefix: 'u-';

  @each $bp in map-keys($breakpoints) {
    @include media-breakpoint-up($bp) {
      $infix: breakpoint-infix($bp, $breakpoints);

      .#{$prefix}text-center#{$infix} {
        text-align: center $important;
      }

      .#{$prefix}text-left#{$infix} {
        text-align: left $important;
      }

      .#{$prefix}text-right#{$infix} {
        text-align: right $important;
      }
    }
  }
}
