$aus-img: '/images/pages/country-aus';
$uk-img: '/images/pages/country-uk';
$nzl-img: '/images/pages/country-nzl';

.display_flex_cm {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px;
  @include mobile {
    justify-content: left;
    padding-left: 10px;
    flex-direction: inherit;
  }
}
.p-country {
  .c-slider {
    .slick-arrow {
      display: none !important;
    }

    .slick-active {
      &:hover {
        transform: scale(1) !important;
        -webkit-transform: scale(1) !important;
        -moz-transform: scale(1) !important;
        -ms-transform: scale(1) !important;
        -o-transform: scale(1) !important;
      }
      &.slick-current {
        > div {
          opacity: 1;
          margin-left: 0;
          margin-right: 0;
          transform: scale(1) !important;
          // box-shadow: 0 4px 30px 0 rgba(0, 0, 0, 0.20);
        }
      }
      > div {
        opacity: 0.8;
        margin-left: $gl-spacer-m;
        margin-right: $gl-spacer-m;
      }
    }
    .slick-next:before,
    .slick-prev:before {
      color: $slider-dot-color;
    }
    .swiper-button-next:before,
    .swiper-button-prev:before {
      color: $slider-dot-color;
    }
    .swiper-button-prev {
      &:after {
        content: ' ';
      }
    }
    .swiper-button-next {
      &:after {
        content: ' ';
      }
    }

    &__item {
      &--center {
        opacity: 0.8;
        margin-left: $gl-spacer-m;
        margin-right: $gl-spacer-m;

        &.slick-current {
          transform: scale(1.3);
          opacity: 1;
          // box-shadow: 0 4px 30px 0 rgba(0, 0, 0, 0.20);
        }
      }
    }
  }
  .order {
    @include mobile {
      order: 1;
    }
  }
  &-aus {
    &_intro {
      background: url('#{$aus-img}/intro_bg.jpg') no-repeat center;
      background-size: cover;
      height: 560px;
      &__hero {
        height: 100%;
      }
    }
    .live {
      &_icon {
        width: 112px;
        height: 112px;
        @include mobile {
          width: 56px;
          height: 56px;
        }
      }
      .icon {
        position: relative;
        top: 40px;
      }
      .mt50 {
        margin-top: 50px;
      }
      &_bg {
        background: $b-gray-1;
        border-radius: 6px;
        padding-bottom: 26px;
        padding-top: 52px;
      }
      .ch {
        font-weight: bold;
        font-size: 28px;
        line-height: 150%;
      }
      &_num_box {
        margin-top: 32px;
        margin-bottom: 32px;
        background:$b-gray-1;
        border-radius: 12px;
        align-items: flex-start;
        padding: 24px 32px;
      }
      .yycj_box {
        margin-bottom: 60px;
        .mt {
          margin-top: 24px;
          margin-bottom: 4px;
        }
        .mt16 {
          margin-top: 16px;
          margin-bottom: 10px;
          @include mobile {
            margin-bottom: 4px;
          }
        }
        .pma0 {
          @include desktop {
            padding: 0;
            margin: 0;
          }

          // @include mobile{
          //   margin:auto;
          //   padding:auto;
          // }
        }
        .border {
          border: 0.5px solid rgba(0, 0, 0, 0.3);
          width: 88px;
          margin-left: -18px;
          margin-top: 13px;
        }
      }
    }
    .essay {
      background: #f0fcfa;
      .essay_bg {
        background: $t-light;
        border: 0.5px solid rgba(151, 151, 151, 0.32);
        border-radius: 6px;
        @include mobile {
          border: none;
        }

      }
      .dot {
        background: #c0c0c0;
        border-radius: 50%;
        width: 6px;
        height: 6px;
        margin-right: 8px;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        -ms-border-radius: 50%;
        -o-border-radius: 50%;
      }
      &_swt {
        // background: url('#{$aus-img}/essay_swt_bg.png') no-repeat;
        padding: 24px 0 19px;
        margin: 48px 0;
        &_bg {
          position: relative;
          z-index: 0;
          height: 163px;
        }
        .fbt {
          // font-family: PingFang SC;
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 150%;
          margin: 32px auto 0;
          text-align: center;
        }
        &_text {
          position: relative;
          z-index: 2;
          top: -145px;
          height: 0;
          padding: 0 54px 0 16px;
          .list_layout {
            margin-top: 36px;
            margin-left: -10px;
          }
          .title {
            font-weight: 500;
            font-size: 20px;
            line-height: 150%;
            margin-bottom: 8px;
          }
        }
      }
    }
  }
  &-uk {
    &_intro {
      background: url('#{$uk-img}/intro_bg.jpg') no-repeat center;
      background-size: cover;
      height: 560px;
      &__hero {
        height: 100%;
      }
    }
  }
  &-nzl {
    &_intro {
      background: url('#{$nzl-img}/intro_bg.jpg') no-repeat center;
      background-size: cover;
      height: 560px;
      &__hero {
        height: 100%;
      }
    }
  }
  .school {
    background:$b-gray-1;
    .school_box {
      margin-top: 72px;
      @include mobile {
        margin-top: 36px;
      }
      .pl0 {
        padding-left: 0;
      }
      .school_list {
        background: $t-light;
        border-radius: 6px;
        margin-bottom: 24px;
        // height: 94px;
        padding: 10px;
        .row-h {
          height: 100%;
          margin: 0;
          .img-h {
            height: 81px;
            position: relative;
            @include mobile {
              height: 36px;
            }
          }
        }
        .text_ai {
          align-items: start;
          padding-left: 12px;
          flex-direction: column;
          justify-content: center;
        }
      }
    }
  }
  .prep {
    background-color: $b-gray-1;
    @include mobile{
      // background-color: #fff;
    }
    .t_m{
      @include mobile{
        background-color:$b-gray-1;
      }
    }
    .prep_box {
      padding: 48px;
      background-color: #fff;
      box-shadow: 0px 24px 20px rgba(108, 111, 110, 0.06);
      border-radius: 12px;
      @include mobile{
        margin: 0 -24px;
        padding:48px 36px;
        background-origin: border-box;
        box-shadow: none;
        border-radius: 0;
        -webkit-border-radius: 0;
        -moz-border-radius: 0;
        -ms-border-radius: 0;
        -o-border-radius: 0;
}
      .num_box {
        background: $b-gray-1;
        border-radius: 12px;
        padding: 24px 40px;
      }
      .img-h {
        height: 126px;
        position: relative;
        @include mobile{
          height: 60px;
        }
      }
    }
    .c_box {
      margin: 40px 0 48px;
      @include mobile{
        margin: 40px 0 10px;
      }
      &_text {
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
      &_bg {
        background: $b-orange-2;
        border-radius: 12px;
        padding: 32px 26px;
        .img {
          // position: relative;

          div {
            border-radius: 50%;
            box-shadow: 0px 12px 22px rgba(0, 91, 79, 0.12);
          }
        }
      }
    }
    .t1 {
      font-weight: 600;
      font-size: 32px;
      line-height: 150%;
    }
    .tab_box {
      margin: 42px auto 32px;
      @include mobile {
        margin: 0;
      }
    }
    .m_tab {
      @include mobile {
        margin: 32px auto 16px;
        border: 1px solid $b-green;
        border-radius: 4px;
        width: 286px;
      }
    }
    .tab_btn {
      cursor: pointer;
      padding: 16px 32px;
      color: $t-gray-1;
      font-weight: 500;
      font-size: 24px;
      line-height: 150%;
      border-radius: 12px;
      margin-right: 42px;
      background: #f4f4f4;
      border-radius: 12px;
      @include mobile {
        color: $t-green;
        background-color: $t-light;
        font-weight: 600;
        font-size: 16px;
        padding: 12px 15px;
        margin-right: 0;
        border-radius: 0;
      }
      &_active {
        color: $t-light;
        background: $b-green;
        box-shadow: 0px 14px 20px rgba(92, 92, 92, 0.12);
        border-radius: 6px;
        @include mobile {
          border-radius: 0;
          -webkit-border-radius: 0;
          -moz-border-radius: 0;
          -ms-border-radius: 0;
          -o-border-radius: 0;
        }
      }
    }
  }
  .sdc {
    .sdc_bg{
      @include mobile {
        background:$b-gray-1;
        border-radius: 6px;
      }
    }
    background: #fff;
    padding-top: 62px;
    &_title {
      text-align: center;
      margin: 48px 0 24px;
      font-weight: 600;
      // font-size: 28px;
      line-height: 150%;
      @include mobile{
        margin: 32px 0 24px;
      }
    }
    .ml50 {
      margin-left: 50px;
      @include mobile{
        margin:0;
      }
    }
    &_box_bg {
      background: $b-orange-2;
      border-radius: 12px;
      margin-right: 32px;
      // padding: 0px 20px;
      margin-bottom: 24px;
      &_w{
        width: 340px;
        height: 150px;
      }
      @include mobile{
        margin-right:-12px;
        // width: 100%;
        // margin:0 0 24px;
      }
      .name {
        font-weight: 500;
        font-size: 20px;
        line-height: 150%;
        padding-top: 15px;
      }

      .text_box {
        margin-left: 26px;
      }
      .bro {
        background: $b-green;
        border-radius: 3px;
        width: 32px;
        height: 4px;
        margin: 8px 0;
      }
    }
  }
  .qa {
    .display_flex_cm_center {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 0px;
    }
    .qa_list {
      @include mobile {
        background: $b-orange-2;
        border-radius: 12px;
      }
    }
    .border {
      border-right: 1px solid rgba(0, 0, 0, 0.1);
      @include mobile {
        border-right: none;
        &::before {
          content: ' ';
          border-bottom: 1px solid rgba(0, 0, 0, 0.1);
          width: 80px;
          position: absolute;
          left: 50%;
          bottom: 0;
          margin-left: -40px;
        }
      }
    }
    .qa_text_bg {
      padding: 28px;
      background: $b-orange-2;
      border-radius: 6px;
      width: 200px;
    }
    .hr_bg {
      background: url('/images/pages/country/qa/pie_right.svg') no-repeat;
      background-size: contain;
      height: 186px;
      width: 186px;
      @include mobile{
        width: 155px;
        height: 155px;
      }
    }
    .hl_bg {
      background: url('/images/pages/country/qa/pie_left.svg') no-repeat;
      background-size: contain;
      height: 190px;
      width: 192px;
      padding-right: 15px;
      @include mobile{
        width: 158px;
        height: 158px;
      }
    }
    .arrows_box {
      margin-right: 30px;
      width: 54px;
      position: relative;
    }
    .arrows_box_left {
      margin-left: 30px;
      margin-right: -30px;
      width: 54px;
      position: relative;
    }
    .text_10 {
      font-size: 10px;
    }
  }
}
