.u-font-bold {
  font-weight: 600;
}
.u-font-bold-en{
  font-weight: bold;
}
.u-font-semibold {
  font-weight: 500;
}

.u-font-normal {
  font-weight: normal;
}

.u-font-thin {
  font-weight: 300;
}