.pre_full {
  max-width: 100%;
  .easyke_doc {
    height: calc(100vh - 60px);
    @media screen and (max-width: $break-small) {
      // height: 78vh;
    }
  }
}
.p-learning-center {
  &__header {
    // background: url('');
    position: relative;
    .bgimg {
      position: absolute;
      right: 0;
      top: 0;
      z-index: 1;
    }
    .title {
      padding: 48px 0 8px;
      font-weight: 600;
      font-size: 30px;
      line-height: 42px;
      color: #222222;
    }
    .title_1 {
      font-size: 14px;
      line-height: 20px;
      color: #9b9b9b;
    }
    .modules {
      .title {
        font-weight: 500;
        font-size: 20px;
        line-height: 28px;
        color: #222222;
        margin-bottom: 24px;
      }
      .box_nowrap {
        flex-wrap: nowrap;
        overflow: hidden;
      }
      .left_icon {
        position: absolute;
        top: 55%;
        left: -60px;
      }
      .right_icon {
        position: absolute;
        top: 55%;
        right: -80px;
      }
      .active {
        transform: scale(1.1);
        -webkit-transform: scale(1.1);
        -moz-transform: scale(1.1);
        -ms-transform: scale(1.1);
        -o-transform: scale(1.1);
      }
      .p-box {
        padding: 32px 24px;
        // border-radius: 16px;
      }
      .c-slider {
        .slick-arrow {
          @include mobile {
            display: none !important;
          }
        }
        &--lt {
          .slick-prev,
          .slick-next {
            width: 42px;
            height: 42px;
          }
          .slick-prev.slick-disabled:before,
          .slick-next.slick-disabled:before {
            opacity: 0.35;
          }
          .slick-prev {
            left: -50px;
            &:before {
              width: 42px;
              height: 42px;
              content: url('/images/user/learning-center/modules/left.svg');
              @include mobile {
                content: ' ';
              }
            }
          }
          .slick-track {
            margin-left: 0;
            margin-right: 0;
          }
          .slick-next {
            right: -50px;
            &:before {
              width: 42px;
              height: 42px;
              content: url('/images/user/learning-center/modules/right.svg');
              @include mobile {
                content: ' ';
              }
            }
          }
        }

        .slick-active {
          > div {
            margin-left: 0;
            margin-right: 0;
          }
          &:hover {
            transform: scale(1) !important;
            -webkit-transform: scale(1) !important;
            -moz-transform: scale(1) !important;
            -ms-transform: scale(1) !important;
            -o-transform: scale(1) !important;
          }
          &.slick-current {
            > div {
              margin-left: 0;
              margin-right: 0;
              transform: scale(1.1) !important;
              //box-shadow: 0 4px 30px 0 rgba(0, 0, 0, 0.20);
              -webkit-transform: scale(1.1) !important;
              -moz-transform: scale(1.1) !important;
              -ms-transform: scale(1.1) !important;
              -o-transform: scale(1.1) !important;
            }
          }
        }

        &__item {
          &--center {
            &.slick-current {
              transform: scale(1.1);
              opacity: 1;
              //box-shadow: 0 4px 30px 0 rgba(0, 0, 0, 0.20);
              -webkit-transform: scale(1.1);
              -moz-transform: scale(1.1);
              -ms-transform: scale(1.1);
              -o-transform: scale(1.1);
            }
          }
        }
      }
      .card_box {
        cursor: pointer;
        border-radius: 8px;
        padding: 24px;
        background-size: 100% 100%;
        background-repeat: no-repeat;
        width: 360px;
        height: 215px;
        text-align: left;
        @include mobile {
          width: 300px;
          // height: 178px;
        }
        .icon {
          margin-bottom: 18px;
          border-radius: 50%;
          box-shadow: 0px 8.57143px 17.1429px rgba(28, 110, 166, 0.16);
        }
        .zt {
          font-weight: 600;
          font-size: 18px;
          line-height: 25px;
          margin: -8px 0px 8px;
        }
      }
      .study {
        background-image: url('/images/user/learning-center/modules/study_bg.png');
        .zt {
          color: #395f79;
        }
      }
      .mathematics {
        background-image: url('/images/user/learning-center/modules/mathematics_bg.png');
        .zt {
          color: #b57210;
        }
      }
      .writing {
        background-image: url('/images/user/learning-center/modules/writing_bg.png');
        .zt {
          color: #267b73;
        }
      }
      .information {
        background-image: url('/images/user/learning-center/modules/information_bg.png');
        .zt {
          color: #4c579e;
        }
      }
      .macroeconomics {
        background-image: url('/images/user/learning-center/modules/information_bg.png' );

        .zt {
          color: #4c579e;
        }
      }
      .microeconomics {
        
        background-image: url('/images/user/learning-center/modules/study_bg.png');
       
        .zt {
          color: #4c579e;
        }
      }
    }
  }
  .no-data-box {
    // padding-top:90px;
    margin: 90px 0 150px;
    text-align: center;

    .no-data {
      margin-top: 32px;
      font-size: 18px;
      line-height: 25px;
      color: #222222;
    }
  }

  .video {
    .title {
      font-weight: 500;
      font-size: 18px;
      line-height: 25px;
      color: #222222;
      margin-bottom: 16px;
    }
    &_box {
      cursor: pointer;
      position: relative;
      border: 1px solid rgba(34, 34, 34, 0.08);
      box-sizing: border-box;
      .video_box_img {
        object-fit: cover;
        width: 192px;
        height: 142px;
        @include mobile {
          width: 156px;
          height: 118px;
        }
      }
      .video_box_cover {
        overflow-y: clip; 
        margin-bottom: 7px; 
        margin-right: 7px; 
      }
      .video_play {
        position: absolute;
        top: 0;
        left: 0;
        width: 192px;
        height: 142px;
        // background-color: rgba(255, 255, 255, 0.35);
        background: url('/images/user/learning-center/modules/play.svg')
          rgba(255, 255, 255, 0.35) no-repeat center;
        @include mobile {
          width: 156px;
          height: 118px;
        }
      }
    }
    .video_pay {
      width: 100%;
      height: 145px;
      .vjs-poster {
        border-radius: 0;
        -webkit-border-radius: 0;
        -moz-border-radius: 0;
        -ms-border-radius: 0;
        -o-border-radius: 0;
      }
    }
    .p {
      max-width: 200px;
      word-break: break-word;
      @include mobile {
        max-width: 156px;
      }
    }
  }
  .file {
    .file_box {
      cursor: pointer;
      background: rgba(255, 255, 255, 0.35);
      border: 1px solid rgba(34, 34, 34, 0.08);
      box-sizing: border-box;
      border-radius: 8px;
      -webkit-border-radius: 8px;
      -moz-border-radius: 8px;
      -ms-border-radius: 8px;
      -o-border-radius: 8px;
      width: 192px;
      @include mobile {
        width: 156px;
      }
      .file_img_h {
        height: 170px;
        padding: 10px;
        @include mobile {
          height: 150px;
        }
      }
      p {
        border-top: 1px solid rgba(34, 34, 34, 0.08);
        padding: 12px;
        max-width: 192px;
        word-break: break-word;
      }
    }
  }
}
#video_play_box {
  max-width: 100%;
  max-height: 100%;
}
