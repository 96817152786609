.center_center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
}
.p-homepage {
  background: $t-light;
  .home_bg_bg1 {
    background-position: center 40%;
    background-image: url('#{$image-path}/pages/index/bg1.jpg');
  }
  .home_bg_bg2 {
    background-position: center center;
    background-image: url('#{$image-path}/pages/index/bg2.jpg');
  }
  .intro_bg {
    .slick-active {
      &.slick-current {
        > div {
          opacity: 1;
          margin-left: 0;
          margin-right: 0;
          transform: scale(1);
          //box-shadow: 0 4px 30px 0 rgba(0, 0, 0, 0.20);
          -webkit-transform: scale(1);
          -moz-transform: scale(1);
          -ms-transform: scale(1);
          -o-transform: scale(1);
        }
      }
      // > div {
      //   opacity: 0.8;
      //   margin-left: $gl-spacer-m;
      //   margin-right: $gl-spacer-m;
      // }
    }
  }
  .home-top {
    height: 560px;
   
    background-size: cover;
    background-repeat: no-repeat;

    &_border {
      width: 120px;
      padding-bottom: 16px;
      border-bottom: 2px solid #ffffff;
    }
    .intro {
      h1 {
        letter-spacing: -1px;
        font-size: 52px;
        line-height: 120%;
        @media screen and (max-width: 640px) {
          font-size: 32px;
          //padding: 0 15px;
          line-height: 1.5;
          margin-bottom: 0.75rem;
        }
      }
      .f1-ns {
        @media screen and (min-width: $break-small) {
          font-size: 36px;
        }
      }
      .btn {
        width: 252px;
        padding: 12px 40px;
        font-size: 18px;
        @media screen and (max-width: 640px) {
          display: block;
          margin: 0 auto 10px !important;
          font-size: 16px;
        }
      }
    }
  }
  .green {
    color: $t-green;
  }
  .gpa {
    .gpa_video {
      width: 100%;
      height: 340px;
    }
    .title {
      font-weight: 600;
      font-size: 42px;
      line-height: 150%;
      margin-bottom: 32px;
    }

    .text {
      font-size: 18px;
      //width: 560px
    }
  }
  .talents {
    background: #f9fafa;
    &_box {
      padding: 24px;
      background: rgba(255, 255, 255, 0.92);
      box-shadow: 0px 32px 40px rgba(0, 0, 0, 0.02);
      backdrop-filter: blur(6px);
      border-radius: 12px;
      max-width: 420px;
      @include mobile {
        margin-left: 10px;
        max-width: 352px;
        box-shadow: none;
      }
    }
    .rc_bg {
      position: absolute;
      top: 80px;
      width: 100%;
    }
    .zIndex9 {
      z-index: 9;
    }

    .desc_w {
      max-width: 600px;
    }
    .py_order {
      @include mobile {
        order: -1;
      }
    }
    .py_box {
      position: relative;
      background: rgba(255, 255, 255, 0.76);
      box-shadow: 0px 32px 40px rgba(0, 0, 0, 0.02);
      backdrop-filter: blur(6px);
      border-radius: 12px;
      height: 146px;
      .img {
        position: absolute;
        bottom: 0;
        // height: 184px;
      }
    }
  }
  .icai_box{
    height: 285px;
    background-position: center center;
    background-size: cover;
    // background-position: center 40%;
    background-image: url('#{$image-path}/pages/index/icai_bg.jpg');
    // background: url('/images/pages/index/icai_bg.jpg') no-repeat;
  }
  .icai_box_m{
    background: #ECF9F7;
    padding:36px 0;
  }
  .lh15{
    line-height:164%
  }
  .lh2{
    line-height:200%
  }
  .icai {
    background: $b-gray-1;
    // padding: 112px 0 55px;
    .imgh {
      height: 643px;
      @include mobile {
        order: 1;
        height: 340px;
      }
    }
  }
  .student_score {
    .score_img {
      // background: #FFFFFF;
      // box-shadow: 0px 36px 40px rgba(0, 0, 0, 0.06);
      border-radius: 6px;
      @include mobile{
        width: 350px;

      }
    }
  }
  .slick_scale {
    .c-slider {
      .slick-active {
        &.slick-current {
          > div {
            opacity: 1;
            margin-left: $gl-spacer-m;
            margin-right: $gl-spacer-m;
            padding-top:10px;
            transform: scale(1.3);
            //box-shadow: 0 4px 30px 0 rgba(0, 0, 0, 0.20);
            -webkit-transform: scale(1.3);
            -moz-transform: scale(1.3);
            -ms-transform: scale(1.3);
            -o-transform: scale(1.3);
          }
        }
        > div {
          opacity: 0.8;
          margin-left: $gl-spacer-m;
          margin-right: $gl-spacer-m;
        }
      }
      &--lt {
        .slick-prev {
          &:before {
            content: url('#{$image-path}/pages/high-school-academic/chevron-left.svg');
            @include mobile {
              content: ' ';
            }
          }
        }
        .slick-next {
          &:before {
            content: url('#{$image-path}/pages/high-school-academic/chevron-right.svg');
            @include mobile {
              content: ' ';
            }
          }
        }
      }
    }
  }
  .user_data {
    background: url('/images/pages/index/user-data-bg.png') #f4f9f8 no-repeat
      center;
    .title-w {
      margin: 0 auto;
      p {
        color: $t-gray-1;
        margin-bottom: 64px;
        text-align: center;
      }
    }
    &_box {
      padding-top: 51px;
      background: rgba(255, 255, 255, 0.6);
      box-shadow: 0 4px 9px 0px #c4c4c432;
      border-radius: 24px;
      -webkit-border-radius: 24px;
      -moz-border-radius: 24px;
      -ms-border-radius: 24px;
      -o-border-radius: 24px;
      li {
        margin-bottom: 56px;
        text-align: center;
        .img {
          height: 48px;
        }
        .text {
          font-size: 14px;
          line-height: 120%;
          text-align: center;
          color: $t-gray-1;
        }
        .unit {
          margin: 23px 0 12px;
          font-size: 16px;
          line-height: 120%;
          color: $t-dark;
          .num {
            font-weight: 500;
            font-size: 28px;
            margin-right: 5px;
          }
        }
      }
    }
  }
  .student_result {
    background: $t-light;
    &__img {
      border-radius: 5px;
      -webkit-border-radius: 5px;
      -moz-border-radius: 5px;
      -ms-border-radius: 5px;
      -o-border-radius: 5px;
    }
    &__school {
      margin-top: 10px;
      text-align: center;
      font-size: 14px;
      line-height: 150%;
      color: $t-dark;
    }
    .title {
      margin-bottom: 40px;
    }
    p {
      margin: 0 auto 56px;
      color: $t-gray-1;
    }
    .info {
      ul {
        margin-bottom: -16px;
        li {
          text-align: center;
          display: inline-block;
          //max-width:248px
          @media screen and (max-width: 640px) {
            margin-bottom: 10px;
          }
          //max-width: 640px
          .num {
            line-height: 150%;
            color: $t-green;
          }
          .text {
            line-height: 150%;
            color: $b-dark;
          }
        }
      }
    }
    .btn {
      margin-top: 24px;
      width: 320px;
      font-size: 18px;
      @media screen and (max-width: 640px) {
        display: block;
        margin: 30px auto 10px !important;
        font-size: 16px;
      }
    }
    .c-slider {
      &--lt {
        .slick-prev {
          &:before {
            content: url('#{$image-path}/pages/high-school-academic/chevron-left.svg');
            @include mobile {
              content: ' ';
            }
          }
        }
        .slick-next {
          &:before {
            content: url('#{$image-path}/pages/high-school-academic/chevron-right.svg');
            @include mobile {
              content: ' ';
            }
          }
        }
      }
    }
  }
  .score_feedback {
    background: $b-gray-1;
    .line {
      width: 100%;
      height: 0;
      margin-top: -8.1rem;
      border: 1px solid rgba(0, 0, 0, 0.06);
      @media (min-width: 640px) and (max-width: 1220px) {
        margin-top: -6.4rem;
      }
    }

    &_bg {
      height: 570px;
      max-width: 1220px;
      width: 100%;
      position: relative;
      margin: 0 auto;
      // @media screen and (max-width: 640px)
      //   margin:10px 0
      //   height: 150px
      @media (min-width: 640px) and (max-width: 1220px) {
        margin: 50px 0;
        height: 350px;
      }
    }
  }
  .tutor {
    background: $t-light;
    // padding-top:112px
    .title-h2 {
      font-weight: 500;
      font-size: 24px;
      line-height: 150%;
      margin: 64px 0 30px;
    }
    .video-box {
      //max-width:940px
      //max-height:540px
      margin: 0 auto;
      .player_box {
        margin: 0 auto;
        width: 80%;
        height: 535px;
        object-fit: fill;
        @include mobile {
          height: 210px;
          width: 100%;
        }
      }
    }
  }
  .tutor-pro {
    background: url('#{$image-path}/pages/index/map.png') no-repeat bottom
      center;
    //background-size: cover
    // padding-bottom: 112px
    .title-h1 {
      margin: 128px 0 64px;
    }
    .sc {
      vertical-align: top;
      .name_zh {
        font-weight: 500;
        font-size: 16px;
        line-height: 150%;
        color: $t-dark;
      }
      .name_en {
        margin-top: 6px;
        font-size: 12px;
        line-height: 14px;
        color: $t-gray-1;
      }
    }
    .map {
      width: 100%;
      background: url('#{$image-path}/pages/index/dq_new.svg') no-repeat center;
      //background-size: contain
      @media screen and (max-width: 640px) {
        background: none;
        padding-left: 10px;
      }
      &_pl {
        padding-left: 30px;
        @media screen and (max-width: 640px) {
          padding-left: 0;
        }
      }
      &_plr {
        padding-left: 130px;
        @media screen and (max-width: 640px) {
          padding-left: 0;
        }
        .border_box {
          text-align: right;
          width: 150px;
          @media screen and (max-width: 640px) {
            display: none;
          }
          .borderr {
            background-image: linear-gradient(
              to left,
              #b6f2ea 0%,
              #b6f2ea 50%,
              transparent 50%
            );
          }
          .pl {
            padding-left: 45px;
          }
        }
      }
      .map_box {
        line-height: 150%;
        display: flex;
        align-items: center;
        margin-bottom: 56px;
        @media screen and (max-width: 640px) {
          display: inherit;
          text-align: left;
          margin-bottom: 28px;
        }
        .content_r {
          text-align: right;
          width: 260px;
          margin-right: 16px;
          @media screen and (max-width: 640px) {
            text-align: left;
          }
        }
        .content_l {
          text-align: left;
          max-width: 500px;
          margin-left: 16px;
        }
        .name {
          color: $t-dark;
          font-size: 16px;
          margin-top: 16px;
        }
        .num {
          font-weight: bold;
          font-size: 32px;
          line-height: 32px;
          color: $t-green;
        }
        .en {
          font-size: 14px;
          color: $t-gray-1;
        }
        .border {
          height: 1.6px;
          background-image: linear-gradient(
            to right,
            #b6f2ea 0%,
            #b6f2ea 50%,
            transparent 50%
          );
          background-size: 20px 1px;
          background-repeat: repeat-x;
          @media screen and (max-width: 640px) {
            height: 0;
            width: 0;
          }
        }
      }
    }
  }
  .ip {
    background: #3e4746;
    color: white;
    padding: 90px 0;
    @include mobile {
      padding: 64px 0;
    }
  }
  .course {
    background: $t-light;
    .order {
      @media screen and (max-width: 640px) {
        order: 1;
      }
    }
    .ai {
      align-items: flex-start;
      @media screen and (max-width: 640px) {
        justify-content: center;
        flex-direction: column;
      }
    }
    .c_text {
      & > div {
        flex: 0 0 20%;
        display: inline-block;
        text-align: center;
        margin: 32px 0 0 0;
        .w88 {
          width: 88px;
        }
        @media screen and (max-width: 640px) {
          .mobile-mh-auto {
            margin: 0 auto;
          }
          .mobile-right {
            float: right;
          }
          flex: 0 0 33.333%;
          margin: 20px 0;
        }
      }
      .zh {
        margin: 2px 0px;
        font-size: 16px;
        line-height: 22px;
      }
      .en {
        margin: 2px 0px;
        font-size: 14px;
        line-height: 120%;
      }
      .c-r {
        .title {
          margin-top: 50px;
          margin-bottom: 32px;
          font-weight: 600;
          font-size: 42px;
          line-height: 150%;
          text-align: left;
          color: $t-dark;
        }
      }
    }
  }
}
.statement {
  line-height: 1.5;
  margin-top: 60px;
  max-width: 100%;
  .line {
    width: 32px;
    height: 1px;
    background: #ececec;
  }
}
