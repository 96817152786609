// Alerts
$alert-padding: $gl-spacer-xxs;
$alert-font-size: $gl-font-size-xs;
$alert-radius: $gl-radius-base;

$alert-variants: (
  alt: (
    background: $b-gray-2
  )
);

// [TODO] Maybe add in icon colors for different variants
.c-alert {
  padding: $alert-padding;
  font-size: $alert-font-size;
  border-radius: $alert-radius;
  background: transparent;
  color: $t-dark;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid transparent;

  @each $variant, $opts in $alert-variants {
    &--#{$variant} {
      background: map-get($opts, background);
    }
  }

  &--outlined {
    border-color: $bd-gray-dark;
  }
}

#__react-alert__ {
  div {
    div {
      width: 100%;
      div {
        width: 80% !important;
        margin: 0 10% 5px !important;
        padding: 0 10px !important;
        border-radius: 0 0 3px 3px !important;
      }
    }
  }
}

.react-alert {
  height: 40px;
  line-height: 40px;
  color: white;
  text-align: center;
}

