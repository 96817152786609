/* ==========================================================================
   #COMMENTS
   ========================================================================== */

// Comment
$comment-accent-background: $theme-primary-ltr;
$comment-warning-background: $theme-alert-lt;
$comment-solution-bg-size: 24px;
$comment-spacing: $gl-spacer-m;

.c-comment {
  padding: rem($comment-spacing);
  word-break: break-word;
  border-bottom: 1px solid $bd-gray;

  // Default tutor comment
  &--primary {
    background-color: $b-gray-2;
  }

  // Qualify tutor comment
  &--accent {
    background-color: $comment-accent-background !important;
  }

  // Unqualify tutor comment
  &--warning {
    background-color: $comment-warning-background !important;
  }

  // Solution comment
  &--solution {
    background: $b-gray-2 url(#{$image-path}/experts/solution.svg) right 1.5rem top 1.5rem no-repeat;
  }

  // Text
  &__text {
    &--sm {
      font-size: rem($gl-font-size-s);
    }

    &--xs {
      font-size: rem($gl-font-size-xxs);
    }
  }

  // User Type
  &__title {
    font-weight: 600;

    &--md {
      font-size: rem($gl-font-size-base);
      border-left: 2px solid $b-green;
      padding-left: rem($gl-spacer-xxs);
      margin-bottom: rem($gl-spacer-xs);
      line-height: 1;
    }

    &--sm {
      font-size: rem($gl-font-size-s);
    }
  }

  // Review Actions
  &__action {
    padding-top: rem($gl-spacer-s);
    border-top: 1px solid  rgba(0,0,0,0.06);
    margin-top: rem($gl-spacer-s);

    &__link {
      color: $t-gray-1;
      font-weight: 600;
      font-size: rem($gl-font-size-xs);
      cursor: pointer;
      display: inline-block;

      &:hover {
        color: $text_color;
      }

      .c-icon {
        margin-right: rem($gl-spacer-xxs);
        stroke: $t-gray-3;
      }

      &--primary {
        &.active {
          color: $t-green-dark;

          .c-icon {
            stroke: $t-green-dark;
          }
        }
      }
      &--accent {
        &.active {
          color: $t-coral-pink ;
          
          .c-icon {
            stroke: $t-coral-pink ;
          }
        }
      }        
    }
  }

  // Hack for raw %p
  p,
  ol,
  ul {
    margin-bottom: rem($gl-spacer-xxs);
    font-size: rem($gl-font-size-base);
  }

  // Reset list style
  ol,
  ul {
    padding-left: 40px;

    li {
      list-style: inherit;
    }
  }
}
