$important: !important;

// Border Radius
.u-border-r-0 {
  @include border-radius(0, true);
}

.u-border-t-r-0 {
  @include border-top-radius(0, true);
}

.u-border-r-r-0 {
  @include border-right-radius(0, true);
}

.u-border-b-r-0 {
  @include border-bottom-radius(0, true);
}

.u-border-l-r-0 {
  @include border-left-radius(0, true);
}

// Border

.u-border-none {
  border: none $important;
}

.u-border-t-none {
  border-top: none $important;
}

.u-border-r-none {
  border-right: none $important;
}

.u-border-b-none {
  border-bottom: none $important;
}

.u-border-l-none {
  border-left: none $important;
}
