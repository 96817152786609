// Profs Result Page

.p-profs {
  // Intro
  &__intro {
    background: $theme-gradient-warm;
    padding: $gl-spacer-l 0;

    @include desktop {
      padding: 40px 0 $gl-spacer-2xl;
    }

    &__subheader {
      font-weight: 500;
      letter-spacing: 0.4px;

      @include desktop {
        font-size: rem($gl-font-size-base);
      }
    }

    &__btn-circle {
      border-radius: 50%;
      padding: 8px;
    }
  }

  // Stats
  &__stats {
    border-top: 1px solid $bd-gray;
    padding-top: $gl-spacer-m;

    &__count {
      font-size: $gl-font-size-h3;
      font-weight: 500;
      display: inline-block;
      line-height: $gl-line-height-xs;
    }
  }

  // Course
  &__course {
    &__title {
      margin-bottom: 0;
      margin-left: $gl-spacer-xs;
      font-weight: 600;
      display: inline-block;
      vertical-align: middle;
    }

    &__link {
      padding: $gl-spacer-m;
      border-radius: $gl-radius-lg;
      height: 100%;

      @include hover {
        box-shadow: 0 4px 30px 0 rgba(0, 0, 0, 0.15);
      }
    }

    &__name {
      font-size: $gl-font-size-base;
      font-weight: 500;
      color: $t-dark;
      margin-bottom: 4px;

      @include desktop {
        font-size: $gl-font-size-sub-header;
      }
    }

    &__avatar {
      background: rgba($b-green, 0.12);
      color: rgba($t-green-dark, 0.7);
    }
  }

  // Blocks
  &__block {
    padding: $gl-spacer-l;
    border-radius: $gl-radius-lg;
    margin-bottom: $gl-spacer-m;
    background: $b-gray-1;
  }

  &__team {
    position: relative;

    &__stats {
      width: 100%;
      position: absolute;
      left: 0;
      bottom: 88px;
      z-index: $gl-z-index-1;
    }
  }

  &__tag {
    color: $t-light;
    background-color: #cba576;
    border-radius: $gl-radius-pill;
    display: inline-block;
    font-size: 13px;
    padding: 4px 16px;
  }

  &__avatar {
    width: 80px;
    height: 80px;
  }

  &__popup {
    min-height: 500px;
  }
}
