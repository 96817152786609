.p-login{
  &-modal{
    border-radius: 16px;
    -webkit-border-radius: 16px;
    -moz-border-radius: 16px;
    -ms-border-radius: 16px;
    -o-border-radius: 16px;
    @include mobile{
      border-radius: 0;
      -webkit-border-radius: 0;
      -moz-border-radius: 0;
      -ms-border-radius: 0;
      -o-border-radius: 0;
}
}
  &-login{
    cursor: pointer;
    &:hover{
      color:$t-green-dark
    }
  }
  &-foot{
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .line{
    width:46px;
    // height: 1px;
    border:1px solid $t-dark;
  }
  &-rucaptcha {
    color: $t-green-dark;
    cursor: pointer;
  }
}
