// SDC Team
.p-sdc {
  // Intro
  &__gradient-intro {
    background: url("#{$image-path}/pages/sdc-team/sdchero.svg") no-repeat center;
    background-size: cover;    
    text-align: center;   
  }

  // Divider
  &__divider {
    margin: rem($gl-spacer-l) auto;
    text-align: center;
  }  
}