///* ========================================================================
//   #Message Counter
//   ======================================================================== */

// A simple notification badge

$message-counter-font-size: $gl-font-size-3xs;
$message-counter-size: 16px;
$message-counter-empty-size: 8px;
$message-counter-empty-size-s: 6px;

.c-message-counter {
  display: block;
  border-radius: 50%;
  z-index: $gl-z-index-1;
  //
  @include size-text-center($message-counter-size);

  background-color: $b-red-1;
  color: $t-light;
  font-size: $message-counter-font-size;

  // Just a dot
  &--empty {
    @include size(rem($message-counter-empty-size));

    overflow: hidden;
    text-indent: -9999px;
    display: none;

    &--sm {
      @include size(rem($message-counter-empty-size-s));
    }
  }

  // Top right sticky position
  &--sticked {
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(50%, -50%);
  }
}
