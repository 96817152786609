// Why

// Challenges
$p-why-challenge-img-height-m: rem(120px);

.p-why {
  &__intro {
    background: url("#{$image-path}/pages/why/intro.jpg") no-repeat center;
    background-size: cover;
    height: 560px;
  }

  // Challenges
  &__challenges{

    &__title {
      position: absolute;
      left: rem($gl-spacer-m);
      bottom: rem($gl-spacer-m);
      color: $t-light;
      z-index: $gl-z-index-1;
      line-height: $gl-line-height-sm;
      margin-bottom: 0;
    }

    &__img-holder {
      position: relative;

      @include mobile{
        height: $p-why-challenge-img-height-m;
        overflow: hidden;
      }
    }

    &__img {
      width: 100%;
    }
    .img-h{
      height: 590px;
      position: relative;
    }
    &__item {
      padding: rem($gl-spacer-s) 0;
      border-bottom: 1px solid $bd-gray;

    }
  }

  // Reasons
  &__reason {
    border-left: 2px solid $b-green;

    & + & {
      margin-top: $gl-spacer-xl;

      @include desktop {
        margin-top: $gl-spacer-2xl;
      }
    }
  }

  &__info{
    .img-h{
      position: relative;
      height: 620px;
      @include mobile{
        height: 400px;
      }
    }
  }
}
