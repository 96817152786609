@use "sass:list"

[class^="side_"]
  background-repeat: no-repeat
  background-position: right 12px top 8px

.nav-tabs
  border: none
  li
    padding: 0 10px
    &:first-child
      padding-left: 0
    a
      padding: 10px 0px
      border: none
      //:font-weight 500
    a:hover, a:active, a:focus
      border-bottom: 3px solid #c8f5f3
      text-decoration: none

.nav-tabs.services_menu
  width: 100%
  margin-top: 140px
  li
    padding: 0
    text-align: center
    width: 10%
    font-weight: bold
    font-size: 16px
    a
      color: white
      margin: 0
    a:hover, a:active
      color: #c8f5f3
  @media screen and (max-width: 800px)
    margin-top: 20px
    li
      width: 25%
      padding: 5px 0
      font-size: 14px
.aus_services
  li
    width: 16.3% !important

body[location='signup_easyke_mentors']
  .navbar
    position: relative !important
.nav-tabs.sm_tabs, .nav-tabs.qa_tab
  @media screen and (max-width: $break-device)
    margin-bottom: 0.5em
    background: white

.nav-tabs.sm_tabs, .nav-tabs.qa_tab, .setting-tab
  border-bottom: 1px solid $bd-gray
  margin-bottom: 24px
  li
    padding: 0
    a
      text-render: optimizeLegibility
      -webkit-font-smoothing: antialiased
      font-weight: bold
      color: #9b9b9b
      padding: 0 0 8px
      width: 100%
      display: inline-block
      &:hover
        color: $text_color
        border: none
  li.active
    a
      border-bottom: 2px solid $b-green
      color: $text_color

.nav-tabs.blog_tab, .nav-tabs.event_tab
  margin: 10px 0
  border: none
  li
    a
      color: #999
      text-decoration: none
      border: none
      text-transform: uppercase
      letter-spacing: 1px
      padding: 0 5px
      font-size: 18px
      &:hover
        color: #333
        background: none
    @media screen and (max-width: 680px)
      padding: 0
      margin: 0 !important
  li.active
    a
      font-weight: 600
      background: none !important
      border: none !important
      color: #333
  li.all-categories-tab
    a
      padding-left: 0
  @media screen and (max-width: 710px)
    margin: 0px 0
    li
      a
        font-size: 14px

.nav-tabs.course-tabs
  width: 100%
  border-bottom: 1px solid #eee
  li
    text-align: center
    width: 50%
    font-weight: bold
    a
      display: block
      width: 80%
      margin: auto
      padding: 12px
      color: #9b9b9b
      &:hover, &:focus
        color: $text_color
        border: none
      @media screen and (max-width: $break-device)
        font-size: 13px
      @media screen and (max-width: $break-small)
        padding: 1em 0
  li.active
    a
      color: $text_color
      text-decoration: none
      border-bottom: 2px solid #618EE3

.setting_tabs
  .nav-tabs
    padding-bottom: 0
    li
      a
        color: #666
        font-weight: normal
        &:hover, &:active, &:focus
          background: none !important
          color: #333
    li.active
      a
        color: #333
        font-weight: bold

.dropdown-menu
  border: none
  border-radius: 0px 0 2px 2px
  width: 200px
  left: auto
  z-index: 500
  margin-top: 0
  li
    border-bottom: 1px solid #f1f2f1
    &:last-child
      border: none
    a
      color: #333 !important
      padding: 10px 25px !important
      display: block !important
      &:hover
        background: #f2f2f2 !important
        text-decoration: none !important

//breadcrumb
.breadcrumb
  background: none
  padding: 0
  font-size: 12px
  margin: 0
  a
    &:hover
      text-decoration: underline !important

//Sidebar
#sidebar
  position: fixed
  top: 65px
  background-color: #fafbfb
  border-right: 1px solid #e8e9e9
  z-index: 100
  width: 180px
  transition: 0.5s
  left: -115px
  bottom: 0
  overflow: hidden
  .sidebar_head
    cursor: pointer
    height: 50px
    padding: 19px 0
    border-bottom: 1px solid #e8e9e9
    &:hover
      background: #f3f4f5
  .scrollable
    position: absolute
    width: 220px
    overflow-y: scroll
    overflow-x: hidden
    left: 0
    top: 50px
    right: 0
    bottom: 0
  #sidebar_image
    padding: 10px 0px
    li
      padding: 8px 10px
  .sidebar_footer
    position: absolute
    width: 100%
    height: 30px
    bottom: 0
    background-image: linear-gradient(to bottom, rgba(250,250,250,0) 0, #fff 100%)
    background-repeat: repeat-x
    background-color: transparent
  #sidebar_text
    width: 180px
    padding: 10px 0
    height: 640px
    ul
      height: 650px
    li
      cursor: pointer
      a
        height: 58px
        width: 180px
        padding: 20px 12px 8px 15px
        display: block
        font-size: 13px
        color: #666
        font-weight: bold
        text-align: left
        text-render: optimizeLegibility
        -webkit-font-smoothing: antialiased
        &:hover
          background-color: #f3f4f5
          text-decoration: none
      .side_course
        +background-image-retina(list.slash(/assets, icons, icon_course), png, 43px, 43px)
      .side_mentor
        +background-image-retina(list.slash(/assets, icons, icon_mentor), png, 43px, 43px)
      .side_notes
        +background-image-retina(list.slash(/assets, icons, icon_notes), png, 43px, 43px)
      .side_guide
        +background-image-retina(list.slash(/assets, icons, icon_guide), png, 43px, 43px)
      .side_essay
        +background-image-retina(list.slash(/assets, icons, icon_essay), png, 43px, 43px)
      .side_mail
        +background-image-retina(list.slash(/assets, icons, icon_mail), png, 43px, 43px)
      .side_scholarship
        +background-image-retina(list.slash(/assets, icons, icon_scholarship), png, 43px, 43px)
      .side_books
        +background-image-retina(list.slash(/assets, icons, icon_books), png, 43px, 43px)
      .side_qa
        +background-image-retina(list.slash(/assets, icons, icon_qa), png, 43px, 43px)

//Document Nav
.doc-panel
  @media screen and (max-width: 767px)
    padding: 1.5rem 0
  .left_nav
    @media screen and (max-width: 767px)
      padding: 0 1.5rem .75rem
      border-bottom: 16px solid #f7f7f7
    .back
      color: $text_color
      font-size: 15px
      font-weight: bold
      &:hover
        text-decoration: underline !important
    ul
      li
        cursor: pointer
        a:hover
          text-decoration: underline !important
        @media screen and (max-width: 767px)
          border-top: 1px solid $bd-gray
          &:first-child
            border: none
      li.active
        @media screen and (min-width: $break-medium)
          background-color: #ade0ef
        @media screen and (max-width: 767px)
          a
            color: $text_color
      a
        color: $text_color
        font-size: 15px
        padding: 0.75rem
        display: block
        @media screen and (max-width: 767px)
          padding: 0.75rem 0
          color: $t-gray-1
    .upload_my_docs
      @media screen and (max-width: 767px)
        margin-top: 1.5rem
      &:hover
        box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.06)
    .topics_list
      padding-left: 0
      li
        list-style: none
        //padding-left: 1.5rem
      a
        @media screen and (max-width: 767px)
          background: none
    .doc-toggle
      .drop
        width: 12px
        height: 12px
        display: inline-block
        vertical-align: middle
        @media screen and (min-width: $break-medium)
          display: none
    .subject_chapters
      ul
        li
          font-size: 14px
          &:hover
            font-weight: bold
            .disc
              background: white
        li.active
          .disc
            background: white
        .disc
          width: 7px
          height: 7px
          border-radius: 50%
          background: #9a82a5
          margin:
            right: 10px
            top: 8px
          float: left
        p
          margin: 0
          float: left
          width: 85%

//Course list tab
.course-list-tab
  border: 1px solid #69bebe
  border-radius: 4px
  a
    display: inline-block
    width: 101%
  li
    width: 49%
    padding: 0
    display: inline-block
    @media screen and (max-width: 1175px)
      width: 48.5%
    &:hover
      border: none
    a
      padding: 16px 5px
      color: #45a2a2
      font-size: 16px
  li.active
    a
      background: #69bebe
      color: white
      font-weight: bold
  li:first-child
    a
      border-radius: 4px 0 0 4px
  li:last-child
    a
      border-radius: 0 4px 4px 0

//Case Nav
.case-nav
  margin-top: -5.5em
  li
    display: inline-block
    vertical-align: top
  li.link
    padding: 3em 2em 1em
    position: relative
    z-index: 100
    @media screen and (max-width: 720px)
      padding: 3em 1.5em 1em
    @media screen and (max-width: $break-small)
      display: none
    a
      color: white
      &:hover
        color: rgba(255,255,255,0.7)
.case-nav.sticky
  position: fixed
  z-index: 100
  top: 0
  left: 0
  right: 0
  transition: all 0.4s ease
  background: rgba(255,255,255,0.95)
  box-shadow: 0 1px 4px rgba(0,0,0,0.12)
  margin-top: 0 !important
  .link
    padding: 1em 2em
    @media screen and (max-width: 720px)
      padding: 1em 1.5em
    @media screen and (max-width: $break-small)
      padding: 0.75em 0.5em
      display: inline-block
      font-size: 12px
    a
      color: #9ea7ae
      &:hover, &:focus
        color: #69777e
      &:focus
        text-decoration: none

//Payment Tabs
.packages_tabs
  margin-top: 2em
  @media screen and (min-width: $break-device)
    width: 365px
    margin: 3em auto 0
  .nav-tabs
    border: none
    padding: 0
    li
      display: inline-block
      margin-bottom: 0
      @media screen and (max-width: $break-device)
        width: 50%
      a
        display: inline-block
        color: white !important
        padding: 0.75em 3em
        font-size: 16px
        font-weight: bold
        &:hover
          background: rgba(255,255,255,0.15)
        @media screen and (max-width: $break-device)
          font-size: 15px
          padding: 1em
          margin: 0
    li.active
      a
        background: rgba(0,0,0,0.1)
        border: none
.au_tab.packages_tabs
  width: 546px

//Demo
.demo_nav
  border-bottom: 1px solid $bd-gray
  @media screen and (max-width: $break-device)
    background: white
    margin-bottom: 0.5em
  li
    display: inline-block
    padding: 1em 0
    margin-right: 3em
    color: #666
    @media screen and (max-width: $break-device)
      width: 32%
      text-align: center
      margin: 0
  li.active
    color: $text_color
    font-weight: bold
    border-bottom: 2px solid #a993ec

//Experts QA
body[location='index_qa_questions'], body[location='life_demo_qa_questions'], body[location='questions_index_parents']
  .qa_tab
    @media screen and (max-width: 365px)
      li
        font-size: 12px


.qa_tab
  @media screen and (max-width: $break-device)
    background: white
    font-size: 13px

//Scholarship Sort Panel
.tab-content
  .sort_panel
    .order
      display: inline-block
      padding: 0.5em 1em
      cursor: pointer
      color: #888
      font-weight: bold
      font-size: 13px
      &:hover
        background-color: #eee
      img
        display: inline-block
        margin-right: 1em
    .order.active
      color: $text_color
    .divider
      display: inline-block
      height: 34px
      border-right: 1px solid #e0e0e0
      margin: 0 0.25em
      vertical-align: middle
      width: 1px

body[location='index_scholarships']
  .nav-tabs.sm_tabs
    margin-bottom: 0
  #matched_sm, #saved_sm
    margin-top: 1em
  .tab-content
    overflow-x: hidden

.qa-nav
  position: relative
  background: white
  .back-icon
    left: 15px
    top: 50%
    margin-top: -8px
    cursor: pointer

body[location='services_pages']
  .nav-tabs
    .active
      border-bottom: 2px solid $t-dark
      a
        color: $t-dark
    a
      padding-left: 0
      padding-right: 0
      color: $t-gray-1
      &:hover, &:focus
        border: none
        color: $t-dark
body[location='why_pages']
  .nav-tabs
    .active
      border-bottom: 2px solid $b-green

.users_menu_items
  .nav_messages
    display: block
    position: relative
    &:hover
      .icon-alarm
        opacity: 0.6
  .count
    position: absolute
    top: 10px
    left: 12px
    z-index: 10
    display: block
    background: #e93036
    border-radius: 50%
    text-align: center
    color: white
    font-size: 9px
    width: 18px
    height: 18px
    line-height: 18px
  .dropdown-menu.notifications
    border: 1px solid rgba(0,0,0,0.02) !important
    box-shadow: 0 10px 30px 10px rgba(0, 0, 0, 0.1)
    left: auto
    right: 20px
    width: 340px
    margin-top: 0px !important
    top: 64px !important
    border-radius: 8px
    .tab-content
      height: 360px
      a.badge_notification
        color: $t-dark !important
        padding: 16px 12px !important
        border-bottom: 1px solid $bd-gray
        text-align: left
        p
          text-render: optimizeLegibility
          -webkit-font-smoothing: antialiased
      a.unread
        background: #f2fbf6
    li.unread_content
      clear: both
    .system_notification
      p
        margin-top: 36%
    li.bottom-last
      a
        color: $t-green-dark !important
        padding: 0 !important
        line-height: 40px
        &:hover
          background: none !important
          text-decoration: underline !important
.notifications
  .nav-tabs
    border-bottom: 1px solid $bd-gray
    li.active
      a
        color: $t-dark !important
    li
      padding: 0
      width: 100%
      border-bottom: none
      a
        font-weight: 400
        padding: 0 !important
        background-color: transparent !important
        line-height: 48px
        box-shadow: none !important
        color: $t-gray-1 !important
        &:hover
          background: none !important
          color: $t-dark !important
          border-radius: 3px
      a:hover, a:focus, a:active
        border-bottom: none !important

.icon-alarm
  width: 24px
  height: 24px
  pointer-events: none // safari bug fix
  opacity: 0.3


.nav-tabs:before, .nav-pills:before, .nav-tabs:after, .nav-pills:after
  display: table
  content: ""
  line-height: 0

.nav-tabs:after, .nav-pills:after
  clear: both

.nav-tabs > li, .nav-pills > li
  float: left

.nav-tabs > li > a, .nav-pills > li > a
  padding-right: 12px
  padding-left: 12px
  margin-right: 2px
  line-height: 14px

.nav-tabs
  border-bottom: 1px solid #ddd
  > li
    margin-bottom: -1px
    > a
      padding-top: 8px
      padding-bottom: 8px
      line-height: 20px
      border: 1px solid transparent
      -webkit-border-radius: 4px 4px 0 0
      -moz-border-radius: 4px 4px 0 0
      border-radius: 4px 4px 0 0
      &:hover, &:focus
        border-color: #eeeeee #eeeeee #dddddd
    .active > a
      cursor: default
      &:hover, &:focus
        cursor: default

.nav-tabs.nav-stacked
  border-bottom: 0
  > li
    > a
      border: 1px solid #ddd
      -webkit-border-radius: 0
      -moz-border-radius: 0
      border-radius: 0
    &:first-child > a
      -webkit-border-top-right-radius: 4px
      -moz-border-radius-topright: 4px
      border-top-right-radius: 4px
      -webkit-border-top-left-radius: 4px
      -moz-border-radius-topleft: 4px
      border-top-left-radius: 4px
    &:last-child > a
      -webkit-border-bottom-right-radius: 4px
      -moz-border-radius-bottomright: 4px
      border-bottom-right-radius: 4px
      -webkit-border-bottom-left-radius: 4px
      -moz-border-radius-bottomleft: 4px
      border-bottom-left-radius: 4px
    > a
      &:hover, &:focus
        border-color: #ddd
        z-index: 2

.pull-right
  float: right

.pull-left
  float: left
