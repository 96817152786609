/* ==========================================================================
   #QUESTIONS
   ========================================================================== */
   
// Default
$qa-title-margin-y: $gl-spacer-xs;
$qa-title-lg: $gl-font-size-h5;
$qa-border-color: $bd-gray;
$qa-spacing: $gl-spacer-s;
$qa-spacing-desk: $gl-spacer-m;

// Question
.c-ques {
  display: block;
  color: inherit;
  position: relative;
  
  &--shadow {
    padding: rem($qa-spacing);
    box-shadow: $gl-box-shadow-m;
    border-radius: $gl-radius-lg;
    margin-bottom: rem($qa-spacing);

    @include desktop {
      padding: rem($qa-spacing-desk);
    }    
  }

  &__format {
    float: left;
    margin-left: -10px;
    line-height: 1.7;
  }

  // Title
  
  &__title {
    position: relative;
    word-break: break-word;
    color: $t-dark;
    font-size: rem($gl-font-size-h3g);
    margin-top: rem($qa-title-margin-y);
    margin-bottom: rem($qa-title-margin-y);
    
    // Hack for %p
    &--lg { 
      p,
      ol,
      ul {
        font-weight: 500;
        font-size: rem($qa-title-lg);
        margin: 0;
      }

      // Reset list style
      ol,
      ul {
        padding-left: 40px;
        
        li {
          list-style: inherit;
        }              
      }
    }
  }

  // Info
  
  &__info {    
    @include list-unstyled();

    // Info with outlined container
    &--outlined {
      border: 1px solid $bd-gray;
      border-radius: 4px;
      padding: rem($gl-spacer-s) rem($gl-spacer-m);
    }
    
    &__item {      
      // Inline separate by mid dot
      &--mdot {
        @extend %list-inline;
        
        font-size: rem($gl-font-size-xs);
        color: $t-gray-1;        
      } 

      // Inline seaprate by vertical bar
      &--vbar {        
        font-size: rem($gl-font-size-xs);
        display: inline-block;
      
        @include desktop {
          font-size: rem($gl-font-size-s);
        }

        & + & {
          &:before {
            content: '\007C';
            margin: 0 4px;
            color: $bd-gray-dark;

            @include desktop {
              margin: 0 rem($gl-spacer-xxs);          
            }          
          }
        }
      }
    }

    &__label {
      font-weight: 600;
      color: $theme-dark-lt;
      padding-right: 4px;
      
      @include desktop {
        padding-right: rem($gl-spacer-xxs);        
      }
    }
  }
  
  // New Dot
  
  &__counter {
    position: absolute;
    left: - rem($gl-spacer-xs);
    top: 0;
    bottom: 0;
    margin: auto;
    display: block;
    z-index: 0;
  }
}