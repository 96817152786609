.payments{
  .ant-table-thead{
    .ant-table-column-sorter-up.active, .ant-table-column-sorter-down.active{
      color: #bfbfbf;
    }
    & > tr > th {
    font-weight: 600;
    white-space: nowrap;
    padding: 0.75rem 1.5rem;
    color: #000;
    background: #f6f6f6;
    box-shadow: 0px 1px 0px rgb(0 0 0 / 7%);
  }
}
  .ant-table-tbody{
    .ant-table-row-hover{
      background: #f1f9f8!important;
    }
    .ant-table-row-hover>td{
      background: #f1f9f8!important;
    }
    
    &>tr{
      
      cursor:pointer;
      &:hover:not(.ant-table-expanded-row) > td {
        background-color: #f1f9f8!important;
      }
      &>td{
        padding-left:25px;
        &.ant-table-column-sort{
          background:transparent;
        }
      }
    }
    
  }
  .light-row{
    background-color: #fff;
  }
  .dark-row{
    background-color: #f8f8f8;
  }
  .ant-picker-input>input{
    padding:3px;
  }
  .p-qa__select__box a{
    height: 100%;
    font-weight: 400;
  }

}
.z_index{
  z-index: 4;
}
