// Breadcrumb
.c-lt-breadcrumbs {
  font-size: rem($gl-font-size-xs);
  font-weight: 500;

  &__item {
    display: inline;
    color: $t-green-dark;

    &:not(.is-active) {
      cursor: pointer;

      @include hover {
        opacity: 0.84;
      }
    }

    @at-root {
      & + &::before {
        content: url('#{$image-path}/lte/icons/chevron-right.svg');
        color: $t-gray-1;
        display: inline-block;
        padding: 0 rem(8px);
      }
    }

    &.is-active {
      color: $t-gray-1;
    }
  }
}
