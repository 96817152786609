/* ==========================================================================
   Table
   ========================================================================== */
// Table Header
@use "sass:math";

$table-border-color: $bd-gray;
$table-border-color-dk: $bd-gray;
// Table Cell
$table-cell-spacing: $gl-spacer-m;
$table-row-hover-bg: $theme-primary-ltr;
$table-row-stripe-bg: tint($b-gray-2, 20%);
$table-form-width: rem(300px);

// Dropdown
$table-dd-width: 152px;

.single_festival .c-table{
  &__row {
    &--td {
      cursor: url(#{$image-path}/mooncakes/click.png), pointer;
    }
  }
  &__th {
    &--link {
      cursor: url(#{$image-path}/mooncakes/click.png), pointer;
    }
  }
}

.c-table {
  // Row
  &__row {
    &--td {
      cursor: pointer;

      &:hover {
        .c-table__td {
          background-color: $table-row-hover-bg !important;
        }
      }

      &:nth-of-type(even) {
        .c-table__td{
          background-color: $table-row-stripe-bg;
        }
      }

      &--noclick {
        cursor: default;
      }
    }

    &--th {
      border: none;
    }

    &:last-child {
      .c-table__td--bordered {
        border: none;
      }
    }
  }

  // Table Header
  &__th {
    font-weight: 600;
    white-space: nowrap;
    padding: rem($table-cell-spacing * 0.5) rem($table-cell-spacing);
    color: $t-dark;
    position: sticky;
    position: -webkit-sticky;
    top: 0;
    z-index: 1;
    background: $b-gray-2;
    box-shadow: 0px 1px 0px $table-border-color-dk;

    &--link {
      cursor: pointer;
    }
  }

  // Table Cell
  &__td {
    padding: rem(math.div($table-cell-spacing, 1.5)) rem($table-cell-spacing);
    position: relative;
    background-color: $theme-light-lt;

    &--nowrap {
      white-space: nowrap;
    }

    &--bordered {
      border-bottom: 1px solid $bd-gray;
    }
  }

  &--freeze {
    .c-table__th:first-child {
      left: 0;
      z-index: 2;
      box-shadow: 1px 1px 0px $table-border-color-dk;
    }

    .c-table__td:first-child {
      position: sticky;
      position: -webkit-sticky;
      left: 0;
      z-index: 1;
      box-shadow: 1px 0px 0px $table-border-color-dk;
    }
  }

  // With 3 fixed size columns
  &--col-3 {
    width: $table-form-width * 3;

    .c-table__td:first-child {
      position: relative;
      box-shadow: none;
      z-index: auto;
    }
  }

  &__col {
    width: $table-form-width;
  }

  // Dropdown
  &__dd {
    width: rem($table-dd-width);
    position: absolute;
    left: 0;
    z-index: $gl-z-index-3;
    box-shadow: $gl-box-shadow-m;

    .chosen-container {
      width: 100% !important;
    }
  }

  // List
  &__list {
    margin: 0;

    &__item {
      & + & {
        border-top: 1px solid $bd-gray;
        padding-top: $gl-spacer-xs;
        margin-top: $gl-spacer-xs;
      }
    }
  }

  // Indentation Line
  &__indent {
    padding-left: $gl-spacer-xs;
    border-left: 3px solid $bd-gray;
  }
}

[class^=".c-table--col"] {
  .c-table__td:first-child {
    position: relative;
    box-shadow: none;
  }
}
