.nomargin
  margin: 0 0 0 0 !important
.nopadding
  padding: 0 0 0 0 !important

.vertical_padding
  padding: $padding_value 0 !important
.double_vertical_padding
  padding: $padding_value*2 0 !important
.horizontal_padding
  padding: 0 $padding_value !important
.double_horizontal_padding
  padding: 0 $padding_value*2 !important
.uniform_padding
  padding: $padding_value !important
.double_uniform_padding
  padding: $padding_value*2 !important

.vertical_margin
  margin: $margin_value 0
.double_vertical_margin
  margin: $margin_value*2 0
.horizontal_margin
  margin: 0 $margin_value
.double_horizontal_margin
  margin: 0 $margin_value*2
.double_uniform_margin
  margin: $margin_value*2
.uniform_margin
  margin: $margin_value
.center_margin
  margin: 0px auto

// Different padding 10px
.padding_left
  padding:
    left: $padding_value !important
.double_padding_left
  padding:
    left: $padding_value*2 !important
.padding_right
  padding:
    right: $padding_value !important
.double_padding_right
  padding:
    right: $padding_value*2 !important
.padding_top
  padding:
    top: $padding_value !important


// Different margin 10px
.margin_right
  margin:
    right: $margin_value !important
.double_margin_right
  margin:
    right: $margin_value*2 !important
.margin_left
  margin:
    left: $margin_value !important
.double_margin_left
  margin:
    left: $margin_value*2 !important
.margin_top
  margin:
    top: $margin_value !important
.double_margin_top
  margin:
    top: $margin_value*2 !important
.margin_bottom
  margin:
    bottom: $margin_value !important
.double_margin_bottom
  margin:
    bottom: $margin_value*2 !important

.margin_left_dec
  margin:
    left: -$margin_value !important
.margin_right_dec
  margin:
    right: -$margin_value !important
.margin_top_dec
  margin:
    top: -$margin_value !important
.margin_bottom_dec
  margin:
    bottom: 0px !important

.text_align_right
  text:
    align: right
.text_align_left
  text:
    align: left
.text_align_center, .text-center
  text:
    align: center

.border_radius_4
  +border-radius(4px)

.huge_text
  font-size: 240%
  @media screen and (min-width: $break-small)
    font-size: 280%
  @media screen and (min-width: 1024px)
    font-size: 300%
.large_text
  font-size: 200%
  @media screen and (min-width: 1024px)
    font-size: 240%
.medium_text
  font-size: 110%
  @media screen and (min-width: $break-small)
    font-size: 140%
.small_text
  font-size: 16px
.reg_text
  font-size: 100%

.logged_in
  .large_text
    font-size: 24px !important
    @media screen and (max-width: $break-device)
      font-size: 20px
  .medium_text
    font-size: 18px !important
    @media screen and (max-width: $break-device)
      font-size: 17px
  .small_text
    @media screen and (max-width: $break-device)
      font-size: 15px

.thin
  font-weight: 300
.normal
  font-weight: 400
.midbold
  font-weight: 500
.bold
  font-weight: 600

.transparent
  background: none transparent
  box-shadow: none

.square-top
  border-radius: 0 0 4px 4px

.borderless
  border-width: 0 0 0 0
.underline
  text:
    decoration: underline
  &:hover
    text:
      decoration: underline
.no_overflow
  overflow: visible

.top
  margin-top: 0 !important

hr.darker
  border-top: 1px solid #e1e3e4
  clear: both

.light_gray
  color: #808080
.dark_gray
  color: $text_color
.muted
  color: $t-gray-2

.block-padding
  padding: 30px 50px
.large-padding
  padding: 11% 40px

.blue.large-padding
  background: #82AECB
.green.large-padding
  background: #7DBDAF

.fa
  font-family: FontAwesome

//Tachyon

.row
  margin-left: -12px
  margin-right: -12px

//Flex
//.flex
//  +flexbox
//  +flex-wrap(wrap)
.flex__item
  -webkit-box-flex: 1
  -webkit-flex: 1
  flex: 1
.flex__item2
  -webkit-box-flex: 2
  -webkit-flex: 2
  flex: 2

//max-width
.mw10
  max-width: 1080px
.mw9
  max-width: 712px
.mw12
  max-width: 1248px
  padding:0 24px
//colours
.title-h1
  font-weight: 500
  font-size: 42px
  line-height: 150%
.text-p
  font-size: 18px
  line-height: 150%
  color: $t-gray-1
.title-w
    max-width:760px
    text-align: center
    margin: 0 auto
.dark-gray
  color: $text_color
.gray
  color: $t-gray-1
.mid-gray
  color: $t-gray-1
.light-gray
  color: $bd-gray
.white
  color: white
.green
  color: $t-green
.orange
  color: #fd7a4e

//background

.bg-gray
  background: $page_background !important
.bg-white
  background: white
.bg-green
  background: $b-green
.bg-darker-gray
  background: #efefef
