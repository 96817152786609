// Careers Show
.p-careers {
  // Intro
  &__job-intro {
    background: url("#{$image-path}/pages/career/bdm.jpg") top center no-repeat;
    background-size: cover;
    text-align: center;
  }

  // List
  &__list {
    @include list-unstyled();    

    margin-left: 20px;
    font-size: rem($gl-font-size-base);
      
    li {
      list-style: disc;
      -webkit-margin-before: 0;        
      line-height: 1.8;
    }    

    li + li {
      margin-top: rem($gl-spacer-xs);
    }
  }
}