/* ==========================================================================
   #FORM LABEL
   ========================================================================== */

$label-spacing: $gl-spacer-xxs;
$label-font-size: $gl-font-size-base;

.c-form-label {
  font-size: rem($label-font-size);
  padding-top: rem($label-spacing);
  padding-bottom: rem($label-spacing);
  line-height: $gl-line-height-sm;
  
  &--inline {
  	display: inline-block;
  }
}

.setting-label {
  display: block;
  margin: 0.875rem 0;
}
