/* ==========================================================================
   #SHARED
   ========================================================================== */

/**
 * Shared declarations for certain elements.
 */

/* stylelint-disable selector-list-comma-newline-after */

h1, h2, h3, h4, h5, h6,
blockquote, p, pre,
dl, ol, ul,
figure,
hr,
fieldset {
  margin-bottom: $gl-spacer-m;
}

/**
 * Consistent indentation for lists.
 */

dd, ol, ul {
  margin-left: 0;
  //margin-left: $gl-spacer-s;
}
