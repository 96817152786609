// ==========================================================================
// #FIXED CONTAINER
// ==========================================================================

.c-fixed {
  &__inner {
    position: relative;
    z-index: $gl-z-index-pre-max;

    &.is-active {
      position: fixed;
      width: 100%;
    }
  }
}

