//Mentor Demo
div[location='life_demo_qa_questions']
  .school_sidebar
    height: 55px
    padding: 7px 0
  li.unlimit_list
    #mentor_school_select_chosen
      width: 200px !important
      .chosen-drop, .chosen-single, .chosen-container-active, .chosen-container-single
        background-image: none !important
        box-shadow: none !important
      .chosen-single
        height: 38px
        span
          color: $t-dark-active
          line-height: 40px
      div
        padding-top: 0.5rem
  .content
    margin: 0
    .lifeqa_demo
      background: #8d7ac4
      height: 100vh
      padding: 4em 1em 6em
      h1, p, label, h4, .percent
        color: white
      @media screen and (max-width: $break-device)
        padding: 1em 1em 4em
        p.large_text
          font-size: 14px !important
        h1
          font-size: 1.25em
        .formError
          left: 20% !important
    .dashboard
      @media screen and (max-width: $break-device)
        .fluid-wrap.margin_top
          margin-top: 0 !important
    .mentors_signup_process
      max-width: 445px
      border-top: 1px solid rgba(255,255,255,0.2)
      margin-top: 1em
      padding: 1em 0
      width: 100%
      @media screen and (max-width: 470px)
        .school_select_field, .mentor_school
          select
            width: 48.5%
      .btn
        padding: 0.8em
      label
        font-weight: 600
      .field
        position: relative
      .fa-times
        color: #d4c5ff
        cursor: pointer
        position: absolute
        top: 15px
        right: -1.5em
        &:hover
          color: white
        @media screen and (max-width: 510px)
          display: none
    .ask_ctn
      color: white
    .step3_div
      img
        width: 150px
        height: 150px
        @media screen and (max-width: $break-device)
          width: 100px
          height: 100px
      .loading-ctn
        max-width: 480px
        width: 100%
//Mentor Program
div[location='mentor_dashboard'], div[location='life_demo_pages'], div[location='life_demo_qa_questions']
  .content
    margin: 0
  .footer
    padding-left: 200px !important
    @media screen and (max-width: $break-mid)            
      padding-left: 150px !important
  .qa_ctn.mw8
    max-width: 895px      
    .question_subject_handle
      color: $t-green-dark
    @media screen and (max-width: $break-mid)
      padding-left: 150px !important
  .qa_ctn.mw8
    max-width: 895px
    .question_subject_handle
      color: $t-green
.regular_mentor
  .dashboard
    padding: 0 !important
    .question_section
      color: white
    .fluid-wrap
      @media screen and (max-width: $break-small)
        margin-top: 0 !important
.mentor_dashboard_page.mentor-dashboard
  @media screen and (min-width: $break-device)
    background: white
  .school_sidebar
    position: fixed
    width: 100%
    z-index: 50
    @media screen and (max-width: $break-small)
      position: relative
    li
      display: inline-block
      a.active
        color: $t-dark-active
        font-weight: 600
      a
        padding: 0 1em
        color: $t-gray-2
        display: block
        font-size: 13px
        &:hover
          color: $t-dark-active
  .dashboard
    width: 100%
    margin-bottom: 0
    //@media screen and (min-width: $break-small)
      //padding-top: 37px
    .life_container
      min-height: 444px
    .question_section
      background: #f7f7f7

    .mentor_section
      background: white
      padding-top: 2em
      position: relative
      .mentor_info
        padding: 4em
        width: 100%
        max-width: 700px
        .mentor_image
          background: #ccc
          border-radius: 50%
          width: 150px
          height: 150px
        .mentor_description
          color: #666
      .mentor_divider
        height: 23px
        position: relative
        img
          width: 193px
          height: 23px
          position: absolute
          left: 0
          right: 0
          top: 0
          z-index: 10
        hr
          position: absolute
          top: -10px
          left: 0
          right: 0
      @media screen and (max-width: $break-device)
        padding-top: 1.5em
        .mentor_info
          padding: 1em 2em 2em
          h3
            margin: 1em 0 0 !important
          .mentor_image
            width: 80px
            height: 80px
        .mentor_divider
          img
            zoom: 0.8

  .qa_ctn
    min-height: 400px
    .mentor_info
      h6
        border-bottom: 1px solid $bd-gray
        padding:
          bottom: 10px
        color: $text_color
        margin-bottom: 24px
        line-height: 1.4
      .mentor_image
        width: 70px
        height: 70px
        background: #ddd
        border-radius: 50%
      .mentor_name, .mentor_description
        word-wrap: break-word
      .media-body
        padding: 1em 0
      @media screen and (max-width: $break-device)
        margin-top: 1.5rem
        margin-bottom: 3rem
        .mentor_description
          font-size: 15px
          text-align: center
        h6
          font-size: 13px
          border-bottom: 0
          padding-bottom: 0

//Mentor Experts Dashboard
div[location='dashboard_easyke_mentors'] 
  .footer_wrap
    display: none     
  .top-ctn
    background: white
    box-shadow: 0 1px 3px rgba(0,0,0,0.1)
    margin-bottom: 2em
    .avatar
      border-radius: 50%
      background-color: #d8d8d8
      @media screen and (max-width: $break-device)
        width: 64px
        height: 64px
      @media screen and (max-width: $break-small)
        width: 48px
        height: 48px
    .name-card
      position: relative
      .profile-edit
        position: absolute
        right: 0
        top: 0
        width: 24px
        height: 24px
        opacity: 0.3
        &:hover
          opacity: 0.7
        @media screen and (max-width: $break-small)
          display: none
      .mentor_description
        max-width: 350px
      h4
        margin-bottom: 0.35em
        line-height: 1.2
  .content
    text-render: optimizeLegibility
    -webkit-font-smoothing: antialiased
    .tabbable
      //border-top: 1px solid rgba(0,0,0,0.05)
      .nav-tabs
        border-bottom: none
        margin-bottom: 0
      li
        position: relative
        .new-count
          background: #f2f9f8
          height: 22px
          line-height: 22px
          padding: 0 10px
          color: #4fb4a8
          margin-left: 4px
        a
          height: 60px
          padding: 1.25rem 0
          color: $t-gray-1
          &:hover
            background: none
            color: $text_color
          &:active, &:focus
            background: none
        //@media screen and (max-width: $break-device)
          //width: 50%
      li.active
        a, a:active
          color: $text_color
  .word-break
    word-break: break-word
  .qa_question_link
    padding: 2em !important
    @media screen and (max-width: 640px)
      .span3, .span9
        width: 100%
        text-align: left
      .span3
        margin-left: 0
        margin-top: 10px
  .question_container
    .question_subject_handle
      color: $t-gray-2
  .question-status
    padding: 5px 8px
  .question-status__answered
    background: #f0f9f5
  .question-status__closed
    background: #fff3ec
  .tag
    //display: inline-block
    //font-size: 11px
    //border: 1px solid #e5e5e5
    //border-radius: 4px
    //padding: 0.2em 0.5em
    //text-transform: uppercase
  .deadline
    color: $t-green-dark
  .medium_text
    @media screen and (min-width: $break-small)
      font-size: 130%
  h4
    @media screen and (min-width: $break-small)
      font-size: 24px
  .unrate_comment
    svg
      width: 16px
      height: 16px


div[location='oneclass_mentor_question_pages']
  //background: white
  //@media screen and (min-width: $break-device)
    //min-width: 1104px

  .content
    // @media screen and (min-width: $break-device)
      //padding-top: 1em
      //.question_info
        //margin-top: 10px
    .qa_question_expanded
      .deadline
        color: $t-green-dark
      .qa_subject
        display: inline-block
      .qa_attachments_list
        margin:
          bottom: 0.5em
          top: 0.25em !important
      .qa_deadline
        margin-top: 10px
      //.question_content
        //margin-bottom: 0.5em !important
      //.qa_tags
      //  margin-bottom: 1em
      //  .tag
      //    display: inline-block
      //    font-size: 11px
      //    border: 1px solid #e5e5e5
      //    border-radius: 4px
      //    padding: 0.3em 1em
      //    text-transform: uppercase
      .medium_text
        @media screen and (min-width: $break-small)
          font-size: 130%
    .accept-ctn
      .btn
        padding: 0.75em 4em
        font-size: 16px
    .characters_left
      display: none
    //.tutor-comment
      //background-color: #f9f9f9
      //background-color: #f1f9f8
    .mark_solution
      display: none
      padding: 8px 12px !important
    .c-comment:hover .mark_solution
      display: initial
    //.tutor-comment.solution
      //background-color: #e4f5f1
    .qa_comment_expanded
      .submit-notice
        color: red
        text-align: right
      //p, .question_content
      //  font-size: 16px
      //p
      //  margin-bottom: 12px
      //  padding: 1.5em
    .default-msg
      label
        border-left: 2px solid $green
      .default_message.active
        background: #eee
        box-shadow: none
    .rating_comment
      #qa_comment
        width: 400px
    .review-action
      cursor: pointer
      .review-action__icon
        margin-bottom: 4px
        line-height: 26px
        svg
          fill: $t-gray-2
          width: 16px
          height: 16px
    .review-action.yes:hover, .review-action.yes.active
      color: $green
      svg
        fill: $green
      .review-action__icon
        border-color: $green
    .review-action.no:hover, .review-action.no.active
      color: $t-red
      svg
        fill: $t-red
      .review-action__icon
        border-color: $t-red
    .fina_review_form
      #review_comment_content_ifr
        max-height: 240px !important
    .starbox
      .star
        width: 32px
        height: 32px
    .mce-panel, .mce-btn
      background: white
    //.bad_answer
      //background: #fff6f6

div[location='oneclass_mentor_question_pages'], div[location='dashboard_easyke_mentors'], .oneclass_mentor_show_q_page, .oneclass_mentor_signup_pages, div[location='settings_easyke_mentors']
  .content
    min-height: 70vh
    min-width: 0
    //background: white
    text-render: optimizeLegibility
    -webkit-font-smoothing: antialiased
  #navbar-height-offset, .tel
    display: none
  .navbar
    position: relative
    .left_block
      width: 20% !important
  .footer_wrap
    display: none
  #need_answers
    a
      color: $text_color
  .question_container
    position: relative
    .red_dot
      position: absolute
      width: 6px
      height: 6px
      background: #F3465D
      left: -1rem
      top: 0
      bottom: 0
      margin: auto
      border-radius: 50%
  .nav-tabs
    a
      font-weight: 500
  .qa_question_link
    background-color: white
    padding: 2em 4em 2em 2em
    border-bottom: 1px solid $bd-gray
    &:hover
      .question_subject_handle
        text-decoration: none
      .question_title
        text-decoration: underline
  a:last-child
    .qa_question_link
      border: none
  .qa_question_expanded
    background-color: white
    .edit
      color: $t-green-dark
      cursor: pointer
  .qa_question_ratings
    border: 1px solid #e8e8e8
    padding: 1.5em
  .qa_attachment_link
    color: $text_color
  //.qa_comment_expanded
  //  border-bottom: 1px solid rgba(0,0,0,0.06)
  //  padding: 1.5em
  .new_qa_comment, .qa_comment_expanded
    textarea
      width: 100%
      height: 200px
    .btn
      padding: 0.75em 2em
  .qa_question_link
    p
      margin: 15px 0
  .qa_question_link.new
    background-position: right 2em center
    background-repeat: no-repeat
  .qa_attachments_list
    li
      display: inline-block
  .accept-ctn
    background: #f8f8f8
    padding: 3em
    border: 1px solid #eee
  .attach_icon
    border-radius: 50%
    width: 37px
    height: 37px
    border: 1px solid #aaa
    line-height: 37px
    .fa
      font-size: 24px   
      margin-top: -4px

.oneclass_mentor_signup_pages
  #accordion
    .choice-accordion__heading
      background: #f5f5f5
      padding: 0.5em
      border: 1px solid #eee
      cursor: pointer
      margin-bottom: 0.5em

.tutor_notifications
  background: white
  .mw7
    .nav-tabs
      border-top: 1px solid $bd-gray
      border-bottom: 1px solid $bd-gray
    .tab-pane
      ul
        li.read
          a
            color: $text_color
          &:hover
            background: $b-gray-2
        li.unread
          a
            color: $text_color
            background: #f2fbf6
          &:hover
            background: $b-gray-2

