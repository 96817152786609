/* ==========================================================================
   #QA INDEX
   ========================================================================== */

// Tab Content
$qa-content-padding-bottom: $gl-spacer-xl;

// Disclaimer
$qa-dclm-spacing: $gl-spacer-m;
$qa-dclm-spacing-l: $gl-spacer-xl;
$qa-dclm-border-color: #000;

//Guide
$qa-guide-bg: $theme-primary-ltr;
$qa-guide-spacer: $gl-spacer-m;

.p-qa {

  // Tab Content
  &__content {
    overflow: visible;
    padding-bottom: rem($qa-content-padding-bottom);
  }

  // Disclaimer
  &__dclm {
    padding: rem($qa-dclm-spacing) 0;

    @include desktop {
      background: $b-gray-2;
      padding-bottom: rem($qa-dclm-spacing-l);
    }

    @include mobile {
      border-top: 16px solid $bd-gray-dark;
      border-bottom: 16px solid $bd-gray-dark;
    }

    &__inner {
      @include mobile {
        border: 1px solid $qa-dclm-border-color;
        padding: rem($qa-dclm-spacing);
      }
    }
  }

  // Life QA Guide
  &__guide {
    display: block;
    color: $t-dark;
    background: $qa-guide-bg;
    padding: rem($qa-guide-spacer);
    margin-bottm: rem($qa-guide-spacer);

    h2 {
      font-size: $gl-font-size-h3g;
    }
  }

  // Avatar
  &__avatar {
    position: relative;
    width: rem($gl-asset-size-xl);
    height: rem($gl-asset-size-xl);
    margin: 0 auto rem($gl-spacer-s);

    &__badge {
      position: absolute;
      bottom: 0;
      right: 0;
      z-index: $gl-z-index-3;
    }
  }

  // Floating Block
  &__sticky {
    position: sticky;
    position: -webkit-sticky;
    top: 88px;
  }

  //select
  &__select {
    a {
      height: 3rem;
      border: none;
      border-bottom: 1px solid $bd-gray;
      border-top-left-radius: 6px;
      border-top-right-radius: 6px;
      background-color: white;
      display: block;
      overflow: hidden;
      white-space: nowrap;
      position: relative;
      padding: 1em;
      color: #000;
      font-weight: bold;
      text-decoration: none;
      -webkit-font-smoothing: antialiased;
      text-rendering: optimizeLegibility;
      transition: 0.1s;
      div {
        position: absolute;
        right: 0.75em;
        top: 0;
        display: block;
        height: 100%;
        width: 10px;
        b {
          background: url(#{$image-path}/icons/caret.svg) no-repeat center;
          width: 10px;
          height: 100%;
          background-size: 16px;
          display: block;
          text-align: right;
        }
      }
    }
  }
  //select
  &__select__box {
    a {
      height: 3rem;
      border: 1px solid $bd-gray-dark;
      border-radius: 3px;
      background-color: white;
      display: block;
      overflow: hidden;
      white-space: nowrap;
      position: relative;
      padding: 1em;
      color: #000;
      font-weight: bold;
      text-decoration: none;
      -webkit-font-smoothing: antialiased;
      text-rendering: optimizeLegibility;
      transition: 0.1s;
      div {
        position: absolute;
        right: 0.75em;
        top: 0;
        display: block;
        height: 100%;
        width: 10px;
        b {
          background: url(#{$image-path}/icons/caret.svg) no-repeat center;
          width: 10px;
          height: 100%;
          background-size: 16px;
          display: block;
          text-align: right;
        }
      }
    }
  }

  &__select__content {
    border-bottom: 1px solid #EEEFF1;
  }

  &__relative {
    position: relative;
  }

  &__datepicker-weight {
    input {
      font-weight: 500 !important;
    }
  }

  &__select-minw {
    min-width: 340px;
  }
  &__select-maxw {
    max-width: 400px;
  }
}

.tox .tox-listboxfield .tox-listbox--select, .tox .tox-textarea, .tox .tox-textfield, .tox .tox-toolbar-textfield{
  min-height: 200px !important;
}
.tox .tox-statusbar{
  border-top:none !important;
}
.tinymce_editor_reset {
  border-radius: 6px;
  width: 100%;
  .mce-panel {
    border: none !important;
    background-color: #fff;
    -webkit-box-shadow: none;
    box-shadow: none;
    padding: inherit;
    .mce-top-part {
      position: static;
      &::before {
        -webkit-box-shadow: none;
        box-shadow: none;
      }
    }
    .mce-btn {
      background-color: white;
      &:hover {
        background-color: $page_background;
        border-color: $page_background;
      }
      .mce-caret {
        border-top-color: initial;
      }
    }
    .mce-active {
      i {
        color: initial;
      }
      &:hover {
        i {
          color: initial;
        }
        .mce-caret {
          border-top-color: initial;
        }
      }
    }
    .mce-ico {
      color: $text_color
    }
    iframe {
      min-height: 205px !important;
    }
  }
  .mce-content-body, .mce-reset {
    color: $text_color;
    font-size: 18px;
  }
  .mce-container-body .mce-flow-layout .mce-container:last-child{
    .mce-last {
      button {
        i {
          background-image: url(#{$image-path}/icons/formula.png) !important;
        }
      }
    }
  }
}

input {
  border: 1px solid #ddd;
  box-shadow: none;
  background: #fff;
  padding: 0.75rem;
  border-radius: 4px;
  font-family: "Roboto", "Helvetica Neue", "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "STHeiti", "WenQuanYi Micro Hei", SimSun, sans-serif;
  &:focus {
    border-color: rgba(82, 168, 236, 0.8);
    outline: 0;
    outline: thin dotted \9;
    -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(82,168,236,.6);
    -moz-box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(82,168,236,.6);
    box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(82,168,236,.6);
  }
}

.clearfix:after{
  content:".";
  display: block;
  height: 0;
  clear: both;
  visibility: hidden;
}

.impersonating {
  width: 300px;
  height: auto;
  position: fixed;
  z-index: 10000;
  background: black;
  color: white;
  bottom: 0px;
  left: 0px;
  padding: 10px;
}
