// Lists

// Unstyled keeps list items block level, just removes default browser padding and list-style
@mixin list-unstyled {
  padding: 0;
  margin: 0;
  list-style: none;
}

@mixin list-inherit {
  padding-left: 40px;

  li {
    list-style: inherit;
  }
}

@mixin list-disc {
  padding-left: 40px;

  li {
    list-style: disc;
  }

  li + li {
    padding-top: $gl-spacer-xs;
  }
}

@mixin list-ordered {
  padding-left: 40px;

  li {
    list-style: decimal;
  }
}

@mixin list-alpha {
  padding-left: 40px;

  li {
    list-style: lower-alpha;
  }
}