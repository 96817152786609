/* ==========================================================================
   #ICON
   ========================================================================== */

// Grey Icon + hover state
$icon-inline-margin: 2px;

$icon-class: 'c-icon';

// [TODO] add docs
.#{$icon-class} {
  font-style: normal;
  font-weight: normal;
  speak: none;
  display: inline-block;
  text-decoration: inherit;
  text-align: center;
  position: relative;
  stroke: currentColor;
  vertical-align: middle;

  &__svg {
    display: block;
  }

  &.no-stroke &__svg {
    stroke: none;
  }

  &.stroke-inherit &__svg {
    stroke: inherit;
  }

  &--w-auto &__svg {
    width: auto;
  }

  &--h-auto &__svg {
    height: auto;
  }

  &--size-auto &__svg {
    width: auto;
    height: auto;
  }

  // Icon sizes
  @each $size, $value in $asset-size {
    // Vertical Size (default)
    &--#{$size} &__svg,
    &--square-#{$size} &__svg {
      height: rem($value);
    }

    // Horizontal size
    &--h#{$size} &__svg,
    &--square-#{$size} &__svg {
      width: rem($value);
    }
  }

  // Colors
  @each $name, $color in $theme-clrs {
    &--#{$name} &__svg {
      stroke: $color;
    }
  }

  &--inline {
    margin-right: $icon-inline-margin;
  }

  // [TODO] Add Round Icon variants
  // [TODO] Add Custom Icons
}

// grey icon with hover
.#{$icon-class}--button {
  * {
    transition: all 0.1s;
  }

  .#{$icon-class}__svg {
    stroke: $t-gray-3;
  }

  // hover and active states
  @at-root {
    &:hover,
    &.is-active,
    .is-active > & {
      .#{$icon-class}__svg {
        stroke: $t-dark;
      }
    }
  }
}
