/* ==========================================================================
   #POPUPS
   ========================================================================== */

// ===== Default vars ====
$modal-content-radius: 6px;
$modal-content-shadow: 0 14px 34px 0 $b-dark;
$modal-padding-x: 24px;
$modal-padding-y: 24px;
$modal-padding-x-desktop: 64px;
$modal-body-desktop-padding-top: 12px;
$modal-body-desktop-padding-bottom: 48px;
$modal-title-font-weight: 500;
$modal-desktop-max-width: 824px;
$modal-s-width: 455px;
$modal-m-width: 720px;
$modal-block-min-height: 424px;


// Modal Backdrop
.modal-backdrop {
  z-index: 10000;

  &-open {
    -webkit-overflow-scrolling: touch !important;
    overflow: hidden !important;

    @at-root {
      html#{&} {
        height: 100% !important;
        // position: relative;
      }

      @include media('<sm') {
        body#{&} {
          height: 100% !important;
        }
      }
    }
  }
}

// Modal
.modal{
  //text-align: center;
}

.c-modal {
  background: rgba(0,0,0,0.6);
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  z-index: $gl-z-index-modal;
  overflow: auto;

  @include media('>sm') {
    position: fixed;
  }

  &__content {
    width: 100%;
    background: white;
    box-shadow: $modal-content-shadow;
    margin: 0;
    position: absolute;
    max-height: 100vh;
    overflow-y: auto;
    // overflow-x: hidden;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;

    &.modal {
      transform: none;
      padding: 0;
    }


    // Hack: the error popup is not completely visible, becaue of the modal content's overflow property
    .formError {
      left: 0;
    }

    @include media('>sm') {
      max-width: rem($modal-desktop-max-width);
      border-radius: $modal-content-radius;
      left: 50%;
      top: 50%;
      bottom: auto;
      right: auto;
      transform: translate(-50%, -50%);
      // overflow-y: hidden;
    }

    // [TODO] Remove on React
    &--s {
      &,
      &.modal {
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        height: 100%;
        width: 100%;
        z-index: $gl-z-index-modal;
        overflow: auto;
        border-radius: 0;

        @include media('>sm') {
          width: rem($modal-s-width);
          max-width: rem($modal-desktop-max-width);
          border-radius: $modal-content-radius;
          left: 50%;
          top: 50%;
          bottom: auto;
          right: auto;
          height: auto;
          transform: translate(-50%, -50%);
        }
      }
    }

    &--m {
      @include media('>sm') {
        width: rem($modal-m-width);
      }
    }

    &--l {
      @include media('>sm') {
        max-width: initial;
      }
    }
  }

  // Modal Header
  &__header {
    padding: rem($gl-spacer-m) rem($gl-spacer-m) 0;
    text-align: right;

    &--absolute {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      z-index: $gl-z-index-modal - 1;
    }

    .c-icon {
      opacity: 0.4;
      cursor: pointer;

      @include hover {
        opacity: 0.8;
      }
    }
  }

  // Modal Body
  &__body {
    background: white;
    &,
    * {
      box-sizing: border-box;
    }

    padding: rem($modal-padding-y) rem($modal-padding-x);

    @include media('>sm') {
      padding: rem($modal-body-desktop-padding-top) rem($modal-padding-x-desktop) rem($modal-body-desktop-padding-bottom)
    }
  }

  // Modal Title
  &__title {
    font-size: rem($gl-font-size-h4);
    font-weight: $modal-title-font-weight;
    margin-top: 0;
    margin-bottom: rem($gl-spacer-xs);
    text-align: center;
  }

  // Modal Text
  &__text {
    font-size: rem($gl-font-size-base);

    &--s {
      font-size: rem($gl-font-size-xs);
    }

    &--xs {
      font-size: rem($gl-font-size-xxs);
    }

    &--thick {
      font-weight: 500;
    }
  }

  &__list {
    @include list-unstyled();
  }

  // Modal Link
  &__link {
    @include hover {
      text-decoration: underline;
    }
  }

  // Modal Footer
  &__footer {
    // background: $b-gray-2;
    text-align: center;
    padding: rem($gl-spacer-s) rem($modal-padding-x);

    &,
    * {
      box-sizing: border-box;
    }

    @include media('>sm') {
      padding: rem($gl-spacer-s) rem($modal-padding-x-desktop);
    }
  }

  // OR Divider
  &__divider {
    &__text {
      font-size: rem($gl-font-size-xs);
      color: $theme-dark-lt;
      text-align: center;
    }
  }

  // Modal Block
  &__block {
    padding: rem($gl-spacer-2xl);
    display: flex;
    min-height: rem($modal-block-min-height);
  }

  // Modal Table
  &__table {
    max-height: 470px;
    overflow: auto;
  }
}
