// stylelint-disable declaration-no-important

// Margin and Padding

@if (config(utilities)) {
  $prefix: 'u-';

  @each $bp in map-keys($breakpoints) {
    @include media-breakpoint-up($bp) {
      $infix: breakpoint-infix($bp, $breakpoints);

      .#{$prefix}w-100#{$infix} {
        width: 100% !important;
      }

      .#{$prefix}w-30#{$infix} {
        width: 30% !important;
      }

      .#{$prefix}w-auto#{$infix} {
        width: auto !important;
      }

      .#{$prefix}h-100#{$infix} {
        height: 100% !important;
      }

      .#{$prefix}h-auto#{$infix} {
        height: auto !important;
      }

      .#{$prefix}h-100vh#{$infix} {
        min-height: 100vh;
      }

      @each $prop, $abbrev in (width: w, height: h) {
        @each $sizeName, $size in $asset-size {
          .#{$prefix}#{$abbrev}-#{$sizeName}#{$infix},
          .#{$prefix}size-#{$sizeName}#{$infix} { #{$prop}: rem($size) !important; }
        }
      }
    }
  }
}
