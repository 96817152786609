// Dashboard class card
$lt-class-width: rem(192px);
$lt-class-header-height: rem(163px);

.c-lt-class {
  border-radius: $gl-radius-lg;
  box-shadow: $gl-box-shadow-l;
  width: $lt-class-width;

  &__header {
    padding: 48px 20px 12px;
    overflow: hidden;
    height: $lt-class-header-height;
    box-sizing: border-box;

    &--teal {
      background: url('#{$image-path}/lte/icons/green-bg.svg') no-repeat top center;
      background-size: cover;
    }

    &--purple {
      background: url('#{$image-path}/lte/icons/purple-bg.svg') no-repeat top center;
      background-size: cover;
    }

    &--orange {
      background: url('#{$image-path}/lte/icons/orange-bg.svg') no-repeat top center;
      background-size: cover;
    }
  }

  &__footer {
    padding: 32px 20px 24px;
  }

  &__title {
    color: $theme-light-lt;
    font-size: $gl-font-size-h4;
    font-weight: 600;
    margin: 0;
    line-height: $gl-line-height-sm;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__type {
    color: $theme-light-lt;
  }

  &__name {
    font-size: $gl-font-size-sub-header;
    font-weight: 500;
    line-height: $gl-line-height-sm;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: $t-dark-active;
  }

  &__time {
    color: $t-gray-1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: rem($gl-font-size-xs);
  }

  &__avatar {
    width: 40px;
    height: 40px;
    margin-top: rem($gl-spacer-s);
  }
}
