.tutor-applicant {
  .faq {
    .title {
      cursor: pointer;
      h3 {
        color: #000;
        font-weight: 500;
        &:hover {
          color: $b-green-dark;
        }
      }
    }
  }
}
