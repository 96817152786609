///* ========================================================================
//   #GLOBAL
//   ======================================================================== */
// The global settings file contains any project-wide variables; things that
// need to be made available to the entire codebase.

// Variables should follow the `$component-state-property-size` formula for
// consistent naming. Ex: $nav-link-disabled-color and $modal-content-box-shadow-xs.
// Use 'gl-' prefix for common global variables

// Standardise some UI treatments.

// ===== GlOBAL =====

// Animation
$gl-duration-base    : 300ms !default;
$gl-timing-func : ease-in-out !default;
$gl-transition-base  : all $gl-duration-base $gl-timing-func !default;

// Font families
$gl-font-family-base : 'Roboto','Source Han Sans CN',"Barlow","PingFang SC","Hiragino Sans GB","Microsoft YaHei","STHeiti","WenQuanYi Micro Hei",SimSun,sans-serif !default;

// Borderss
$gl-border-width-base : 1px !default;
$gl-border-width-md   : 2 * $gl-border-width-base !default;
$gl-radius-base       : 3px !default;
$gl-radius-md         : 6px !default;
$gl-radius-lg         : 10px !default;
$gl-radius-pill       : 9999px !default;

// Disabled elements
$gl-disabled-opacity: 0.6 !default;

//Box Shadows
$gl-box-shadow-base : 0 1px 3px rgba(0, 0, 0, 0.08)!default;
$gl-box-shadow-sm   : 0 2px 4px 0 rgba(0,0,0,0.08) !default;
$gl-box-shadow-m    : 0 2px 20px 0 rgba(0, 0, 0, 0.08) !default;
$gl-box-shadow-l    : 0 4px 30px 0 rgba(0, 0, 0, 0.07) !default;

//Asset sizes (e.g. icons)
$gl-asset-size-3xs  : 12px !default;
$gl-asset-size-xxs  : 14px !default;
$gl-asset-size-xls  : 16px !default;
$gl-asset-size-xs  : 20px !default;
$gl-asset-size-sm   : 24px !default;
$gl-asset-size-md   : 32px !default;
$gl-asset-size-lg   : 48px !default;
$gl-asset-size-xl   : 64px !default;
$gl-asset-size-xxl  : 96px !default;
$gl-asset-size-3xl  : 128px !default;

$asset-size: () !default;
$asset-size: map-merge(
  (
    '3xs' : $gl-asset-size-3xs,
    'xxs' : $gl-asset-size-xxs,
    'xls'  : $gl-asset-size-xls,
    'xs'  : $gl-asset-size-xs,
    'sm'   : $gl-asset-size-sm,
    'md'   : $gl-asset-size-md,
    'lg'   : $gl-asset-size-lg,
    'xl'  : $gl-asset-size-xl,
    'xxl' : $gl-asset-size-xxl,
    '3xl' : $gl-asset-size-3xl
  ),
  $asset-size
);

// z-index
$gl-z-index-0: 0 !default;
$gl-z-index-1: 1 !default;
$gl-z-index-2: 2 !default;
$gl-z-index-3: 3 !default;
$gl-z-index-pre-max: 9999 !default;
$gl-z-index-max: 10000 !default;
$gl-z-index-modal: 100000 !default;
$gl-z-index-select-option: 100001 !default;

// Headings
$gl-font-size-h1:  48px !default;
$gl-font-size-h2:  40px !default;
$gl-font-size-h3:  30px !default;
$gl-font-size-h4:  24px !default;
$gl-font-size-h5:  20px !default;
$gl-font-size-h6:  16px !default;
$gl-font-size-sub-header: 18px;

$gl-header-font-sizes: () !default;
$gl-header-font-sizes: map-merge(
  (
    'h1'  : $gl-font-size-h1,
    'h2'  : $gl-font-size-h2,
    'h3'  : $gl-font-size-h3,
    'h4'  : $gl-font-size-h4,
    'h5'  : $gl-font-size-h5,
    'h6'  : $gl-font-size-h6,
    'sub' : $gl-font-size-sub-header,
  ),
  $gl-header-font-sizes
);

// ===== [C] FORMS =====

// Animation
$gl-input-transition         : all 0.1s !default;
// Font
$gl-input-font-family        : $gl-font-family-base !default;
$gl-input-line-height        : $gl-line-height-base !default;
// Input

// Border
$gl-input-border-radius      : $gl-radius-base !default;
$gl-input-border-width       : $gl-border-width-base !default;
//States
$gl-input-disabled-opacity   : $gl-disabled-opacity !default;

$gl-form-group-btn-padding-x: 10px !default;
// Variants
$gl-input-primary-background: $theme-light-lt !default;
$gl-input-primary-placeholder-color: $theme-dark-lt !default;

$gl-input-dark-border-color: transparent !default;

$gl-input-variants: () !default;
$gl-input-variants: map-merge(
  (
    primary: (
      background         : $gl-input-primary-background,
      color              : $t-dark,
      border-color       : $bd-gray,
      placeholder-color  : $gl-input-primary-placeholder-color,
      focus-border-color : $b-green
    ),
    dark: (
      background         : $b-gray-2,
      color              : $t-dark,
      border-color       : $gl-input-dark-border-color,
      placeholder-color  : $t-gray-1
    )
  ),
  $gl-input-variants
);

// sizes

$gl-input-xs-padding-x   : 8px !default;
$gl-input-xs-padding-y   : 6px !default;
$gl-input-xs-font-size   : $gl-font-size-xxs !default;

$gl-input-sm-padding-x   : 12px !default;
$gl-input-sm-padding-y   : 8px !default;
$gl-input-sm-font-size   : $gl-font-size-s !default;

$gl-input-lg-padding-x   : 16px !default;
$gl-input-lg-padding-y   : 12px !default;
$gl-input-lg-font-size   : $gl-font-size-base !default;

$gl-input-sizes: () !default;
$gl-input-sizes: map-merge(
  (
    'xs': (
      padding-x: $gl-input-xs-padding-x,
      padding-y: $gl-input-xs-padding-y,
      font-size: $gl-input-xs-font-size,
    ),
    'sm': (
      padding-x: $gl-input-sm-padding-x,
      padding-y: $gl-input-sm-padding-y,
      font-size: $gl-input-sm-font-size,
    ),
    'lg': (
      padding-x: $gl-input-lg-padding-x,
      padding-y: $gl-input-lg-padding-y,
      font-size: $gl-input-lg-font-size,
    )
  ),
  $gl-input-sizes
);

// Validation
$gl-input-icon-size       : $gl-asset-size-sm !default;
$gl-input-icon-position-y : 8px !default; // Hack: icon vertical centering
$gl-input-icon-position-x : 6px !default;
