.logged_in.pages_home
    background: $page_background

.p-homepage
    .content
        //min-width: 320px
        text-render: optimizeLegibility
        -webkit-font-smoothing: antialiased

    .btn
        text-render: optimizeLegibility
        -webkit-font-smoothing: antialiased

    .btn-large
        @media screen and (max-width: $break-small)
            font-size: 14px

    .feature-list .item
        +flex(1 1 33%)

        @media screen and (max-width: 640px)
            +flex(1 1 100%)

    .slick-dots
        li
            margin: 0

            button:before
                font-size: 36px
                color: rgba(0, 0, 0, 0.5)

        .slick-active button:before
            color: $t-green

    .tabs
        li
            width: (100% * 0.25)

        li.active
            a
                padding-bottom: 12px
                border-bottom: 5px solid $b-green

    .tab-content
        overflow: visible

    .modal
        text-align: center

    #videoModal
        .modal-content
            max-height: 422px
            max-width: 936px
            margin: auto
            top: 0
            bottom: 0
            left: 0
            right: 0
            position: absolute

    .f1-ns.f2
        @media screen and (min-width: $break-small)
            font-size: 40px

        span
            margin-left: -8px
            margin-right: -8px

    .pd112
        padding:112px 0

    .mt
        margin-bottom:112px

    .partners_box
        // margin-bottom:32px
        & > div
            // width:200px
            // display: inline-block
            // text-align: center
            // margin:46px 82px 0 10px

    .school
        .school-lists#usa
            background: url("#{$image-path}/home/school-us.svg") no-repeat center
            background-size: contain

        .school-lists#canada
            background: url("#{$image-path}/home/school-ca.svg") no-repeat center
            background-size: contain

        .school-lists#aus
            background: url("#{$image-path}/home/school-au.svg") no-repeat center
            background-size: contain

        .school-lists#uk
            background: url("#{$image-path}/home/school-uk.svg") no-repeat center
            background-size: contain

        .school-lists
            height: 510px

            .school-list
                background: rgba(255, 255, 255, 0.5)
                box-shadow: 0 0 16px rgba(0, 0, 0, 0.06)
                border: 1px solid $b-green

                .school-list__school
                    list-style: none
                    font-size: 13px

        @media screen and (max-width: $break-medium)
            .school-lists
                margin-top: 2rem

        @media screen and (min-width: $break-medium)
            h2
                max-width: 246px

            .school-list
                max-width: 265px
                margin-left: 10vw

    .why
        //background: image-url('#{$image-path}/home/slanted-bg.svg') no-repeat center top
        //background-size: cover
        .video
            .video__play
                left: 0
                right: 0
                top: 0
                bottom: 0
                z-index: 1
                margin: auto

                @media screen and (max-width: 480px)
                    width: 64px
                    height: 64px

        @media screen and (max-width: $break-medium)
            .video-ctn
                margin-top: 2rem

        @media screen and (max-width: $break-small)
            .items-start
                img
                    width: 48px

                .pl3
                    padding-left: 1.5rem !important

    .features
        .mw8
            max-width: 56rem

        .feature-list
            a:hover
                border-color: #d7f1eb
                background: #d7f1eb

        @media screen and (max-width: $break-medium)
            padding-bottom: 3rem

            .feature-list a
                padding: 1rem !important

    .cases
        .slick-list
            padding-top: 35px !important

        .case.slick-center
            opacity: 1
            z-index: 999

        .case-intro
            @media screen and (min-width: $break-medium)
                width: 240px
                margin-top: 12rem

            @media screen and (max-width: $break-medium)
                margin-bottom: 1rem

        .case
            opacity: 0.3
            border-radius: 4px
            border: solid 3px #48E0CA
            width: 100%

            .case__grade
                color: #ff7070

            .img--case
                top: -35px

            li
                line-height: 1.5
                text-indent: -1rem
                margin-left: 1rem

                &:before
                    color: $t-green
                    content: "•"
                    margin-right: 0.75rem

            &:not(.slick-center)
                -webkit-transform: scale(0.8)
                -moz-transform: scale(0.8)
                transform: scale(0.8)

            @media screen and (max-width: $break-medium)
                .wd5
                    width: 4rem !important

                .img--case
                    zoom: 0.8

        .slick-prev, .slick-next
            width: 20px
            height: 35px
            z-index: 99

            &:before
                content: ""

        .slick-prev
            left: 1.5rem
            background: url("#{$image-path}/home/prev.svg") no-repeat center left
            background-size: 20px 35px

            @media screen and (max-width: $break-medium)
                left: 0
                background-size: 12px

        .slick-next
            right: 1.5rem
            background: url("#{$image-path}/home/next.svg") no-repeat center right
            background-size: 20px 35px

            @media screen and (max-width: $break-medium)
                right: 0
                background-size: 12px

        .slick-dots
            bottom: -20px

    .transcripts
        .red
            color: #ff7070

    .world-bg
        background: url("#{$image-path}/home/world.svg") no-repeat center top
        background-size: 100%

    .slanted-bg
        background: url("#{$image-path}/home/slanted-bg2.svg") no-repeat center top
        background-size: 100%

    .tutors
        .img-tutors
            max-width: 243px

        @media screen and (max-width: $break-medium)
            .tutors-intro
                margin-bottom: 2rem

                .img-tutors
                    max-width: 180px

            h3
                margin-bottom: 1.5rem

    .testi
        .jackie
            background-size: cover

        .roger
            background-size: cover

        .anne
            background-size: cover

        .slick-dots
            bottom: -32px

        @media screen and (max-width: $break-medium)
            .testi-slick
                background: white
                border: 2px solid $b-gray-1

                .speachbox
                    padding: 2rem !important

                    .wd4.h4
                        width: 32px !important
                        height: 32px !important

        @media screen and (min-width: $break-medium)
            .slick-dots
                width: 20px
                right: 0
                bottom: inherit
                top: 42%

                li
                    display: block

    .join
        .why
            h2
                a, span.fw3
                    margin-left: -0.5rem

                @media screen and (max-width: 768px)
                    a
                        margin-left: -0.3rem

            a
                &:hover
                    border-bottom: 1px solid $t-green-dark

    .action
        background: $b-gray-1

        .btn
            padding: 12px 40px
            width: 200px

        .btn-gray
            border-color: $text_color

            @media screen and (max-width: $break-small)
                margin-bottom: 8px

    .modal#Testi, .modal#Testi_top
        .modal-dialog
            width: 768px
            height: 510px

    #myModal
        .modal-content
            position: absolute
            left: 0
            right: 0
            top: 0
            bottom: 0
            margin: auto

    .elite-banner
        background: url("#{$image-path}/home/elite-banner@2x.jpg") no-repeat center
        background-size: cover
        height: 472px

        .btn
            width: 250px

        @media screen and (max-width: $break-small)
            background: url("#{$image-path}/home/elite-banner-m.svg") no-repeat center
            background-size: cover
            height: 350px

    .online-university
        background: url("#{$image-path}/home/ek-online-university@2x.png") no-repeat center
        background-size: cover
        height: 660px
        margin: 0 auto

        @media(max-width: 767px)
            background: url("#{$image-path}/home/ek-online-university-mobile@1x.png") center

        .try_button
            width: 200px
            margin-top: 16px

        .know_more
            text-align: left

            @media(max-width: 767px)
                text-align: center

        .mw10
            height: 100%

            .wd-60-m
                height: 100%
                padding-top: 200px

                @media(max-width: 767px)
                    padding-top: 30px

                .title
                    margin-bottom: 44px
                    font-size: 56px
                    font-wight: 500
                    font-style: normal
                    color: #000
                    letter-spacing: normal
                    font-wight: 500

                    @media(max-width: 767px)
                        margin-bottom: 20px
                        text-align: center
                        font-size: 26px

                .l-col-md-5
                    line-height: 40px
                    font-size: 16px
                    padding-bottom: 18px

                    @media(max-width: 767px)
                        padding-bottom: 8px
                        font-size: 12px
                        line-height: 24px

                    .icon
                        border-radius: 6px
                        width: 12px
                        height: 12px
                        margin-top: 13px
                        margin-right: 16px
                        background-color: $b-green
                        float: left

                        @media(max-width: 767px)
                            margin-top: 5px
                            margin-right: 6px

    .p-home__sdc
        padding-top: 4rem

        &__block
            padding: 4rem 1.5rem
            height: 100%
            position: relative
            color: $text_color
            transition: all 0.3s ease-in-out

            &:hover
                transform: translateY(-15px)

            &--ltr
                background: #f0f9f7

            &--lt
                background: #d7f1eb

            &--dk
                background: #caeae4

            &--dkr
                background: #b9e4dc

        .u-absolute
            position: absolute

            &--br
                bottom: 1.5rem
                right: 1.5rem
