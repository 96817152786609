// Task
$task-spacing: $gl-spacer-xs;
$task-icon-size: 10px;
$task-border-radius: $gl-radius-md;

.p-abroad-info {
  .create-time {
    margin-left: 4px;
  }
  .lt2 {
    line-height: 2;
  }
  .line {
    border: 1px solid $bd-gray;
    width: 100%;
  }
  .img-container {
    max-height: 200px;
    overflow: hidden;
  }
  .max3 {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    section {
      text-align: left !important;
    }
    img {
      display: none;
    }
    p {
      margin-bottom: 0 !important;
    }
    br {
      display: none;
    }
  }
  .detail {
    .__bg_gif {
      display: none;
    }
  }
  &__dry {
    &__intro {
      background: url("#{$image-path}/pages/abroad-info/abroad-dry-min.jpg") no-repeat center;
      background-size: cover;
    }
  }
  .prep-next {
    a {
      color: black;
      &:hover {
        filter: opacity(0.5);
        img {
          filter: opacity(0.5);
        }
      }
    }
    img {
      margin-bottom: 2px;
    }
  }
  .wechat {
    background: $b-gray-2;
    justify-content: space-between;
    @include mobile {
      justify-content: center;
    }
  }
}
