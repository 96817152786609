/* ==========================================================================
   #FORM FIELD
   ========================================================================== */
// Textual form fields (e.g. input[type='text'], textarea, etc)
// [TODO] replace selectors with .c-form-field on React

input.c-form-field,
textarea.c-form-field,
select.c-form-field,
%form-field {
  display: block;
  width: 100%;
  max-width: 100%;
  margin: 0;
  padding: rem($gl-input-sm-padding-y) rem($gl-input-sm-padding-x);
  font-size: rem($gl-input-sm-font-size);
  font-family: $gl-input-font-family;
  line-height: $gl-input-line-height;
  // Reset unusual Firefox-on-Android default style; see https://github.com/necolas/normalize.css/issues/214.
  background-image: none;
  background-color: $theme-light-lt;
  color: $t-dark;
  background-clip: padding-box;
  border: $gl-input-border-width solid transparent;
  border-radius: $gl-input-border-radius;
  transition: $gl-input-transition;
  // [TODO] Remove on React
  box-sizing: border-box;
  height: auto;
  // Unstyle the caret on `<select>`s in IE10+.
  &::-ms-expand {
    background-color: transparent;
    border: 0;
    display: none;
  }

  // Placeholder
  &::placeholder {
    // Override Firefox's unusual default opacity; see https://github.com/twbs/bootstrap/pull/11526.
    opacity: 1;
  }

  &:-ms-input-placeholder {
    // Override IE placeholder color
    color: $theme-dark-lt !important;
  }

  // Disabled and read-only inputs
  &:disabled,
  &[readonly],
  &.is-disabled,
  &.is-readonly {
    opacity: $gl-disabled-opacity;
    cursor: not-allowed;
  }

  // Variants
  @each $variant, $opts in $gl-input-variants {
    &--#{$variant} {
      @include form-field-variant(
        map-get($opts, color),
        map-get($opts, background),
        map-get($opts, placeholder-color),
        map-get($opts, border-color),
        map-get($opts, focus-border-color)
      );
    }
  }

  // sizes
  @each $size, $opts in $gl-input-sizes {
    &--#{$size} {
      padding: map-get($opts, padding-y) map-get($opts, padding-x);
      font-size: map-get($opts, font-size);
    }
  }
}

select.c-form-field,
%form-select {
  -webkit-appearance: none;
  background: $theme-light-lt url('#{$image-path}/icons/caret.svg') center right $gl-spacer-xxs no-repeat;
  background-size: 16px;
}
